import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./BillingInfo.css";

class BillingInfo extends Component {
  render() {
    const { ClientDetail, customerNr } = this.props;
    return (
      <>
        <div className="your-billing-info">
          <label>Your billing information</label>
          <Link to={"/client-settings/edit-info"} className="edit-billing-info">
            Edit info
          </Link>
          <div className="billing-info">
            <p>{ClientDetail?.contactPerson}</p>
            <p>{ClientDetail?.name}</p>
            <p>
              {ClientDetail?.address} {ClientDetail?.city}
            </p>
            <p>{ClientDetail?.country}</p>
            <p>{ClientDetail?.phone}</p>
            <p>{ClientDetail?.zipCode}</p>
            <p>{ClientDetail?.email}</p>

            <p>customerNr: {customerNr}</p>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(BillingInfo);
