import { faPlusCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import add_reward_1 from "../../../../../assets/images/add_reward_1.png";
import add_reward_2 from "../../../../../assets/images/add_reward_2.png";
import bar_code from "../../../../../assets/images/qrcode.svg";
import qr_code from "../../../../../assets/images/barcode.svg";
import React, { useState, useEffect } from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import "./AddRewardModal.css";
import ErrorModal from "./ErrorModal";
import { addReward } from "../../../../services/MasterServices";
import { getKeyTranslation } from "../../../../utils/Translations";
import toast, { Toaster } from "react-hot-toast";

const AddRewardModal = ({ selectedClient, dashboardRewardCall }) => {
  const [addRewardModal_1, setAddRewardModal_1] = useState(false);
  const [addRewardModal_2, setAddRewardModal_2] = useState(false);
  const [type, setType] = useState("");
  const [monetaryValueError, setMonetaryValueError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [noteError, setNoteError] = useState("");
  const [monetaryValue, setMonetaryValue] = useState(Number);
  const [note, setNote] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errors, setErrors] = useState("");
  const [btnUpload, setBtnUpload] = useState(false);
  const [rewardCodeType, setRewardCodeType] = useState(1);
  const [duplicateEntriesModal, setDuplicateEntriesModal] = useState(false);
  const [duplicateEntries, setDuplicateEntries] = useState(false);

  const closeErrModal = () => {
    setShowErrorModal(false);
  };
  useEffect(() => {
    setType("");
    setMonetaryValue("");
    setNote("");
    setFile([]);
    setFileName("");
    setRewardCodeType(1);
  }, []);
  const imgFilehandler = (e) => {
    if (e.target.files.length !== 0) {
      setFileName(e.target.files[0]?.name);
      setFile(e.target.files[0]);
      setFileError("");
    }
  };
  const next = () => {
    setTypeError("");
    setMonetaryValueError("");
    setNoteError("");
    setAddRewardModal_1(false);
    setAddRewardModal_2(true);
  };
  const onSubmit = () => {
    if (file == "") {
      setFileError("required");
    } else {
      setFileError("");
      let obj = {
        Type: type,
        MonetaryValue: monetaryValue,
        Disclaimer: note,
        ClientId: parseInt(selectedClient),
        File: file,
        CodeType: rewardCodeType,
      };
      const form_formatted = (obj_1, form_data) => {
        Object.keys(obj_1).forEach((key) => {
          form_data.append(`${key}`, obj_1[key]);
        });
        return form_data;
      };
      const duplicateCount = (res) => {
        if (res?.data?.data?.duplicateCodeCount > 0) {
          setDuplicateEntriesModal(true);
          setDuplicateEntries(res?.data?.data?.duplicateCodeCount);
          setBtnUpload(false);
        } else {
          setBtnUpload(false);
          discardChanges();
          dashboardRewardCall();
        }
      };
      const rewards = async () => {
        setBtnUpload(true);
        const result = await addReward(form_formatted(obj, new FormData()));
        if (result?.status === 200) {
          duplicateCount(result);
          setTimeout(() => {
            toast.success(getKeyTranslation(result.data.message));
          }, 2000);
        } else {
          setBtnUpload(false);
          setShowErrorModal(true);
          setErrors(getKeyTranslation(result?.data?.message));
        }
      };
      rewards();
    }
  };
  const closeDuplicateEntriesModal = () => {
    setDuplicateEntriesModal(false);
    discardChanges();
    dashboardRewardCall();
  };
  const keydown = (e) => {
    var invalidChars = ["-", "+", "e", "E", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  const checkMonetaryValue = (e) => {
    if (e.target.value >= 0 && e.target.value <= 999999) {
      let value = Math.round(e.target.value).toString().replace(/^0+/, "");
      setMonetaryValue(value);
    }
  };
  const noteCheck = (e) => {
    e.target.value.length <= 80 && setNote(e.target.value);
  };
  const discardChanges = () => {
    setAddRewardModal_1(false);
    setAddRewardModal_2(false);
    setType("");
    setNote("");
    setMonetaryValue("");
    setFile("");
    setFileName("");
    setRewardCodeType(1);
  };
  const checkType = (e) => {
    if (e.target.value.length <= 20) {
      setType(e.target.value);
    }
  };
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <button
        onClick={() => setAddRewardModal_1(true)}
        className="add-license mt-0"
      >
        <FontAwesomeIcon
          icon={faPlusCircle}
          style={{ fontSize: "15px" }}
          className="me-1"
        />
        add reward
      </button>

      <Modal
        show={addRewardModal_1}
        centered
        size="lg"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="add-license-modal">
            <div className="modal-header">
              <h1 className="mb-0">Add new reward</h1>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={discardChanges}
              ></button> */}
              <img
                src="/assets/icons/Cross.png"
                onClick={discardChanges}
                style={{ cursor: "pointer" }}
              />
            </div>
            <span
              style={{
                color: "#34636d",
              }}
            >
              Step 1 out of 2
            </span>
            <Row className="mt-4">
              <Col lg={6} className="reward-form">
                <label className="pb-1 font-weight-500">Type</label>
                <br />
                <input
                  type="text"
                  placeholder="E.g. Coffee"
                  className="w-100"
                  pattern="[0-9]{10}"
                  style={{ wordBreak: "break-all" }}
                  value={type}
                  onChange={(e) => checkType(e)}
                />
                <span className="error-decoration">{typeError}</span>
                <br />
                {/* <br /> */}
                <label className="mt-3 font-weight-500">
                  Monetary Value (EUR)
                </label>
                <br />
                <input
                  type="number"
                  placeholder=""
                  className="w-100"
                  value={monetaryValue}
                  min="1"
                  onChange={(e) => checkMonetaryValue(e)}
                  onKeyDown={(e) => keydown(e)}
                />
                <span className="error-decoration">{monetaryValueError}</span>
                <br />
                {/* <br /> */}
                <label className="mt-3 font-weight-500">Disclaimer</label>
                <br />
                <div className="position-relative">
                  <textarea
                    style={{ resize: "none" }}
                    placeholder="Use this to describe to which limits the voucher can be used. E.g. Only at specific bakeries"
                    rows={5}
                    onChange={noteCheck}
                    value={note}
                  />
                  <span
                    id="display_count"
                    style={{
                      position: "absolute",
                      top: "74%",
                      left: "83%",
                      color: "#2c8ca3",
                      fontSize: "13px",
                      textAlign: "right",
                      width: "33px",
                    }}
                  >
                    {note?.length}/80
                  </span>
                </div>
                <span className="error-decoration">{noteError}</span>
                <br />
                <div className="ui-options d-flex">
                  {[
                    { id: 1, name: "QR-Code", value: 1, icon: qr_code },
                    { id: 2, name: "BAR-Code", value: 2, icon: bar_code },
                  ].map((item, index) => (
                    <div className="text-center" key={index}>
                      <div
                        className={`option ${
                          rewardCodeType !== item?.value && "active"
                        }`}
                        key={item.id}
                        onClick={() => {
                          setRewardCodeType(item?.value);
                        }}
                      >
                        <img src={item?.icon} height={26} width={26} />
                        {/* <FontAwesomeIcon icon={item?.icon} /> */}
                      </div>
                      <p
                        className="text-capitalize mb-0"
                        style={{
                          opacity: rewardCodeType !== item?.value && "0.5",
                          fontSize: "9px",
                          fontWeight: "bold",
                        }}
                      >
                        {item?.name}
                      </p>
                    </div>
                  ))}
                </div>
                <br />
                <div className="d-flex ">
                  <button
                    className="reward-cancel me-3"
                    onClick={discardChanges}
                  >
                    cancel
                  </button>
                  <button
                    className="reward-next"
                    disabled={
                      type.length !== 0 &&
                      monetaryValue != 0 &&
                      note.length != 0
                        ? false
                        : true
                    }
                    style={{
                      opacity:
                        type.length !== 0 &&
                        monetaryValue != 0 &&
                        note.length != 0
                          ? ""
                          : "0.6",
                    }}
                    onClick={next}
                  >
                    Next
                  </button>
                </div>
              </Col>
              <Col lg={6}>
                <img src={add_reward_1} className="w-100" />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addRewardModal_2}
        centered
        size="lg"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          {/* <form enctype="multipart/form-data"> */}
          <div className="add-license-modal">
            <div className="modal-header">
              <h1 className="mb-0">Add new reward</h1>
              <img
                src="/assets/icons/Cross.png"
                onClick={discardChanges}
                style={{ cursor: "pointer" }}
              />
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // disabled={this.state.disableButton}
                onClick={discardChanges}
              ></button> */}
            </div>
            <span
              style={{
                color: "#34636d",
              }}
            >
              Step 2 out of 2
            </span>
            <Row className="mt-4">
              <Col lg={6} className="reward-form-2 d-flex">
                <Col lg={7}>
                  <span>Type</span>
                  <p className="mb-2" style={{ wordBreak: "break-all" }}>
                    {type}
                  </p>
                  <span>Monetary Value (EUR)</span>
                  <p className="mb-2" style={{ wordBreak: "break-all" }}>
                    {monetaryValue}
                  </p>
                  <span style={{ fontSize: "13px", wordBreak: "break-all" }}>
                    Disclaimer
                  </span>
                  <p
                    style={{
                      fontWeight: "normal",
                      fontSize: "14px",
                      wordBreak: "break-all",
                    }}
                  >
                    {note}
                  </p>
                </Col>
                <Col lg={5}>
                  <p>Upload codes</p>
                  <button
                    className="btn-upload w-100 position-relative"
                    style={{
                      padding: "0.5rem 1.3rem",
                      fontSize: "10px",
                      fontWeight: "normal",
                    }}
                  >
                    upload excel list
                    <input
                      className="custom-file-upload"
                      type="file"
                      onChange={imgFilehandler}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </button>
                  <div className="d-flex flex-row pt-2 justify-content-between">
                    {fileName.length > 0 ? (
                      <>
                        <p className="mb-0" style={{ fontSize: "12px" }}>
                          {fileName}
                        </p>
                        <FontAwesomeIcon
                          style={{
                            fontSize: "12px",
                            color: "",
                            width: "29px",
                          }}
                          icon={faCheck}
                        />
                      </>
                    ) : null}
                    {fileError !== "" && (
                      <span className="error-decoration">{fileError}</span>
                    )}
                    <>
                      {/* <p style={{ fontSize: "12px" }}>Rewardlist.xcl</p>
                      <FontAwesomeIcon
                        style={{ fontSize: "20px", color: "" }}
                        icon={faCheck}
                      /> */}
                    </>
                  </div>
                </Col>
              </Col>
              <Col lg={6} className="list-image d-flex flex-row">
                <div className="d-flex h-100 align-items-center">
                  <div className="vertical-line"></div>
                </div>
                <div>
                  <img src={add_reward_2} width="80%" />

                  <p style={{ fontSize: "12px" }} className="ms-4">
                    Excel List Example.
                  </p>
                </div>
              </Col>
            </Row>
            <div className="d-flex mt-5">
              <button className="reward-cancel me-3" onClick={discardChanges}>
                cancel
              </button>
              <button className="reward-next" onClick={onSubmit}>
                {btnUpload ? (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ height: "23px", width: "23px" }}
                  >
                    <span
                      className="visually-hidden"
                      style={{
                        height: "23px",
                        width: "23px",
                      }}
                    >
                      Loading...
                    </span>
                  </Spinner>
                ) : null}
                Finish
              </button>
            </div>
          </div>
          {/* </form> */}
        </Modal.Body>
      </Modal>
      {/* duplicate modal  */}
      <Modal
        show={duplicateEntriesModal}
        // show={true}
        centered
        size="md"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="add-license-modal">
            <div className="modal-header">
              <h1 className="mb-0 mt-3 me-2">Duplicate entries</h1>
              <img
                src="/assets/icons/Cross.png"
                onClick={closeDuplicateEntriesModal}
                // style={{ cursor: "pointer" }}
              />
            </div>
            <p className="mb-0 mt-3 me-2">
              You have {duplicateEntries} duplicate codes in this file, which
              are not added against this reward
            </p>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="reward-next"
                onClick={closeDuplicateEntriesModal}
              >
                Okay
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {showErrorModal ? (
        <ErrorModal
          errors={errors}
          showErrorModal={showErrorModal}
          closeErrModal={closeErrModal}
        />
      ) : null}
    </>
  );
};

export default AddRewardModal;
