import React from "react";
import { Modal } from "react-bootstrap";

const ImportSuccessPopup = ({
  setShowSuccessModal,
  questionCount,
  showSuccessModal,
}) => {
  return (
    <Modal
      show={showSuccessModal}
      centered
      size="sm"
      className="modal-screen updateModal-screen py-0"
    >
      <Modal.Body>
        <div className="modal-header add-license-modal">
          <h6 className="mb-2 mt-3 me-2" style={{ color: "#575757" }}>
            Upload Successful
          </h6>
        </div>
        <p style={{ fontSize: "14px" }}>{questionCount} question found</p>
        <div>
          <div className="create-content-buttons text-center">
            <button
              className="save-and-edit d-inline px-5 w-auto"
              onClick={() => setShowSuccessModal(false)}
            >
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImportSuccessPopup;
