import React from "react";
import { BASE_URL_IMAGE } from "../../../../../constant/ApiUrls";
import "../questionType/QuestionType.css";
import selected_img_grid from "../../../../../../assets/images/selected_img_grid.png";

const PreviewQuestion = ({
  item,
  selectedLanguage,
  createImgGrid,
  gridType,
  selectedQuestionValue,
  selectedGridsFunction,
  createQuestionArray,
  selectedTabIndex,
}) => {
  const defineLength = (value) => {
    let dots = value.length > 50 ? "..." : "";
    return value.slice(0, 50) + dots;
  };
  const defineLengthOption = (value) => {
    let dots = value.length > 30 ? "..." : "";
    return value.slice(0, 30) + dots;
  };
  return (
    // <div>
    <div className="d-flex justify-content-evenly h-100 align-items-center flex-wrap">
      <div className="phone-image-1 bbb">
        <div
          className="img-container"
          style={{ backgroundImage: `url(${BASE_URL_IMAGE}${item.image})` }}
        >
          {item.image != "" && (
            <img
              src={`${BASE_URL_IMAGE}${item.image}`}
              style={{ border: "2px solid #2C8CA3" }}
            />
          )}
        </div>
        <div
          className="mt-5 text-center preview-text p-1"
          style={{ overflowWrap: "break-word" }}
        >
          {defineLength(selectedQuestionValue(item?.questionTitles))}
        </div>
      </div>
      {(item.typeId == 1 || item.typeId == 2) && (
        <div className="phone-image-2">
          <div
            className="img-container"
            style={{ background: `url(${BASE_URL_IMAGE}${item.image})` }}
          >
            {item.image !== "" && (
              <img
                src={`${BASE_URL_IMAGE}${item.image}`}
                style={{ border: "2px solid #2C8CA3" }}
              />
            )}
          </div>
          <div>
            <div
              className="my-4 text-center preview-text p-1"
              style={{
                overflowWrap: "break-word",
              }}
            >
              {defineLength(selectedQuestionValue(item?.questionTitles))}
            </div>
            <div className="answer">
              <div
                className={`d-flex mb-3 ${
                  item.typeId == 1 ? "flex-column" : "flex-row d-flex flex-wrap"
                } `}

                // key={"aa" + item2}
              >
                {item.options.map((i, ind) => {
                  return (
                    <div
                      className={`${item.typeId == 2 ? "col-6" : ""}`}
                      key={ind}
                    >
                      <div
                        key={ind}
                        className={`box m-2 preview-text text-center p-1`}
                        style={{
                          border: i.isCorrect ? "4px solid #707070" : "",
                          overflowWrap: "break-word",
                          minHeight: `${item.typeId == 2 ? "60px" : ""}`,
                        }}
                      >
                        {i.optionTitles
                          ?.filter((i2) => {
                            return i2.languageId == selectedLanguage;
                          })
                          .map(function (obj) {
                            return defineLengthOption(obj.optionTitle);
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      {item.typeId == 3 && (
        <div className="phone-image-1">
          <div
            className="img-container d-grid"
            style={{
              backgroundImage:
                item.image !== "" ? `url(${BASE_URL_IMAGE}${item.image})` : "",
              border: item.image !== "" ? "2px solid #2C8CA3" : "",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "232px",
            }}
          >
            {createImgGrid(
              item.matrixSize ? item.matrixSize : gridType,
              selected_img_grid,
              "",
              "preview_2",
              selectedGridsFunction,
              createQuestionArray,
              selectedTabIndex
            )}
          </div>
          <div
            className="text-center mt-5 preview-text p-1"
            style={{ wordBreak: "break-all" }}
          >
            {defineLength(selectedQuestionValue(item?.questionTitles))}
          </div>
        </div>
      )}
      {item.typeId == 4 && (
        <div className="phone-image-2">
          <div
            className="img-container"
            style={{ backgroundImage: `url(${BASE_URL_IMAGE}${item.image})` }}
          >
            {item.image !== "" && (
              <img
                src={`${BASE_URL_IMAGE}${item.image}`}
                loading="lazy"
                style={{ border: "2px solid #2C8CA3" }}
              />
            )}
          </div>
          <div>
            <div
              className="my-4 text-center preview-text p-1"
              style={{
                overflowWrap: "break-word",
              }}
            >
              {defineLength(selectedQuestionValue(item?.questionTitles))}
            </div>
            <div className="answer">
              {item.options.map((i, ind) => {
                return (
                  <div className="d-flex mb-3 flex-column" key={ind}>
                    <div
                      key={ind}
                      className="box m-2 text-center preview-text p-1"
                      style={{
                        border: i.isCorrect ? "4px solid #707070" : "",
                        wordBreak: "break-all",
                        // height: `${item.typeId == 2 ? "60px" : ""}`,
                      }}
                    >
                      {i.optionTitles
                        ?.filter((i2) => {
                          return i2.languageId == selectedLanguage;
                        })
                        .map(function (obj) {
                          return defineLengthOption(obj.optionTitle);
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
    // </div>
  );
};

export default PreviewQuestion;
