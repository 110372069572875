import React, { Component } from "react";
import { Row, Col, Table } from "react-bootstrap";
import sortIcon from "../../../../assets/icons/sort-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import "../../contractor/requests/RequestList.css";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import SimpleBar from "simplebar-react";
import { fetchContractorRanking } from "../../../services/ContractorServices";
import { RANKING_SORT_LIST } from "../../../constant/AppConstant";
import { getLocalStorage } from "../../../utils/UserDataManagement";
class RankingList extends Component {
  state = {
    rankings: [],
    screenLoader: false,
    sortList: RANKING_SORT_LIST,
    dropdownKey: "",
    searchKeyword: "",
    ownCompanyName: "",
    enteredKeyword: "",
  };
  componentDidMount = () => {
    let searchUrl = `?SearchBy=&OrderBy=`;
    this.getRankingList(searchUrl);
  };

  getRankingList = async (searchUrl) => {
    this.setState({
      screenLoader: true,
    });
    let rankings = await fetchContractorRanking(searchUrl);
    if (rankings.status == 200) {
      this.setState({
        rankings: rankings.data.data,
        screenLoader: false,
      });
    } else {
      toast.error("Error fetching ranking data");
      this.setState({
        rankings: [],
        screenLoader: false,
      });
    }
    let obj = getLocalStorage("staySafeUserData");
    this.setState({
      ownCompanyName: obj.companyName,
    });
  };
  getRankingListSort = async (searchUrl, sortColumn, searchKey) => {
    let rankings = await fetchContractorRanking(searchUrl);
    var sortedLis = [];
    setTimeout(async () => {
      sortedLis = rankings.data.data;
      console.log(searchKey);
      if (searchKey != "") {
        let query = searchKey;

        let prevData = sortedLis;

        let filteredData = prevData?.filter((item) => {
          return item.contractorName
            ?.toLowerCase()
            .includes(query.toLowerCase());
        });

        sortedLis = filteredData;
      }
      const valueCheck = (value) => {
        return sortedLis.sort((a, b) => {
          if (a[value] > b[value]) {
            return 1;
          } else if (b[value] > a[value]) {
            return -1;
          } else {
            return 0;
          }
        });
      };
      if (sortColumn == "company") {
        valueCheck("companyName");
      } else if (sortColumn == "activeSince") {
        valueCheck("createdAt");
      } else if (sortColumn == "rating") {
        valueCheck("averageRating");
      } else if (sortColumn == "rank") {
        valueCheck("rank");
      }
      this.setState({
        rankings: sortedLis,
        screenLoader: false,
      });
    }, 500);
  };
  onChangeInput = (e) => {
    this.setState({
      searchKeyword: e.target.value,
    });
  };
  onEnter = (e) => {
    if (e.code === "Enter") {
      this.searchAInput();
    }
  };
  searchAInput = (e) => {
    const { dropdownKey } = this.state;
    this.setState({
      enteredKeyword: this.state.searchKeyword,
    });
    let searchUrl = `?SearchBy=${this.state.searchKeyword}&OrderBy=${dropdownKey}`;
    this.setState({
      searchKeyword: this.state.searchKeyword,
      searchTrue: this.state.searchKeyword.length == 0 ? false : true,
      screenLoader: true,
    });
    setTimeout(async () => {
      await this.getRankingListSort(
        searchUrl,
        dropdownKey,
        this.state.searchKeyword
      );
    }, 500);
  };

  searchAndSorting = (e) => {
    const { enteredKeyword } = this.state;
    this.setState({
      dropdownKey: e.target.value,
      screenLoader: true,
    });
    let searchUrl = `?SearchBy=${enteredKeyword}&OrderBy=${e.target.value}`;
    setTimeout(async () => {
      await this.getRankingListSort(searchUrl, e.target.value, "");
    }, 500);
  };

  resetSearch = () => {
    let searchUrl = `?SearchBy=&OrderBy=`;
    this.getRankingList(searchUrl);
    this.setState({
      searchTrue: false,
      searchKeyword: "",
      dropdownKey: "",
      enteredKeyword: "",
    });
  };
  avgRating = (item) => {
    if (item.averageRating > 0) {
      return item.averageRating.toFixed(3) + "/ 110";
    } else if (item.averageRating == null) {
      return "-";
    } else {
      return item?.averageRating + "/ 110";
    }
  };
  render() {
    const {
      screenLoader,
      rankings,
      sortList,
      searchTrue,
      searchKeyword,
      dropdownKey,
    } = this.state;
    return (
      <>
        <div className="my-section d-flex justify-content-between sidebar-for-mobile ranking-list">
          <div className="my-container container-fluid px-5">
            <Toaster position="top-right" reverseOrder={false} />

            <div className="myDiv">
              <h1>Rankings</h1>
              <Row>
                <Col lg={3}>
                  <div className="d-flex search-type">
                    <p className="p-width">Sort by</p>
                    <img
                      src={sortIcon}
                      alt="sort-icon"
                      width={25}
                      height={25}
                    />
                    <select
                      className="select"
                      onChange={this.searchAndSorting}
                      value={dropdownKey}
                    >
                      {sortList?.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Company name, person etc."
                      onChange={this.onChangeInput}
                      onKeyDown={this.onEnter}
                      value={searchKeyword}
                    />
                    <FontAwesomeIcon
                      onClick={
                        searchTrue ? this.resetSearch : this.searchAInput
                      }
                      icon={searchTrue ? faTimes : faSearch}
                    />
                  </div>
                </Col>
              </Row>

              <LoadingOverlay
                spinner={<HashLoader color="#5ba6b7" size={90} />}
                active={screenLoader}
              >
                <Row className="mt-5" style={{ height: "350px" }}>
                  <Col lg={12}>
                    <div className="categories">
                      <SimpleBar
                        className="my-scrollbar"
                        contentclassname="my-scrollbar-content"
                        style={{ maxHeight: 350 }}
                      >
                        <div className="request-table-data">
                          <Table
                            borderless
                            responsive
                            className="req-table text-center"
                          >
                            <thead>
                              <tr>
                                <td>Rank</td>
                                <td>Avg. Rating</td>
                                <td>Company</td>
                                <td>Active since</td>
                              </tr>
                            </thead>
                            <tbody>
                              {rankings?.map((item, index) => (
                                <tr key={index} className="req-tr">
                                  <td>{item.rank}</td>
                                  <td>{this.avgRating(item)}</td>
                                  <td>
                                    <b>
                                      {item.contractorName
                                        ? item.contractorName
                                        : "-"}
                                    </b>
                                  </td>
                                  <td>
                                    {item.createdAt?.slice(8, 10) +
                                      "." +
                                      item.createdAt?.slice(5, 7) +
                                      "." +
                                      item.createdAt?.slice(0, 4)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </SimpleBar>
                    </div>
                  </Col>
                </Row>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RankingList;
