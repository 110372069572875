import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUserTimes } from "@fortawesome/free-solid-svg-icons";
import { deleteMasterClient } from "../services/MasterServices";
import { toast } from "react-hot-toast";
import { getKeyTranslation } from "../utils/Translations";

class DeleteClientAccount extends Component {
  state = {
    deleteUserModal: false,
    disableButton: false,
  };

  deleteClient = async () => {
    const { id, callBack } = this.props;
    this.setState({
      disableButton: true,
    });
    let deleteUser = await deleteMasterClient(id, 3);
    if (deleteUser?.status === 200) {
      toast.success("User has been successfully deleted!");
      this.setState({
        deleteUserModal: false,
        disableButton: false,
      });
      callBack();
    } else {
      this.setState({
        deleteUserModal: false,
        disableButton: false,
      });
      toast.error(getKeyTranslation(deleteUser?.message));
    }
  };

  render() {
    const { companyName } = this.props;
    const { disableButton, deleteUserModal } = this.state;
    return (
      <div className="d-inline">
        <FontAwesomeIcon
          icon={faTrash}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            this.setState({
              deleteUserModal: true,
            });
          }}
        />
        <Modal
          show={deleteUserModal}
          centered
          size="md"
          className="edit-add modal-screen"
        >
          <div className="edit-add-model">
            <div className="d-flex justify-content-center mb-3">
              <FontAwesomeIcon
                style={{ fontSize: "40px", color: "red" }}
                icon={faUserTimes}
              />
            </div>
            <div className="d-flex justify-content-center mb-3">
              <h4>DELETE CONFIRMATION</h4>
            </div>

            <Row>
              <Col lg={12}>
                <div className="d-flex justify-content-center">
                  <label>
                    Are you sure you want to delete <b>{companyName}</b>
                  </label>
                </div>

                <br />
              </Col>
            </Row>
            <Row className="mt-5">
              <div className="double-buttons d-flex justify-content-center  h-100">
                <div className="d-flex float-end add-edit-buttons">
                  {disableButton ? (
                    <button style={{ opacity: "0.7", pointerEvents: "none" }}>
                      Delete
                    </button>
                  ) : (
                    <button onClick={this.deleteClient}>Delete</button>
                  )}
                  <button
                    onClick={() => {
                      this.setState({
                        deleteUserModal: false,
                      });
                    }}
                  >
                    cancel
                  </button>
                </div>
              </div>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(DeleteClientAccount);
