export const EMPTY_FIELD = "*please enter valid input";
export const UPDATE_ERROR = "Error while updating data!";
export const masterLicenses = [
  { name: "companyName", type: false },
  { name: "contactPerson", type: false },
  { name: "NoOfLicensesInInt", type: false },
  { name: "NoOfLicensesUsedInInt", type: false },
  { name: "averageRating", type: false },
  { name: "CreatedDate", type: false },
];
export const clientLicenses = [
  { name: "companyName", type: false },
  { name: "contactPerson", type: false },
  { name: "NoOfLicensesInInt", type: false },
  { name: "NoOfLicensesUsedInInt", type: false },
  { name: "averageRating", type: false },
  { name: "CreatedDate", type: false },
];
export const contractorLicenses = [
  { name: "companyName", type: false },
  { name: "contactPerson", type: false },
  { name: "NoOfLicensesInInt", type: false },
  { name: "NoOfLicensesUsedInInt", type: false },
  { name: "averageRating", type: false },
  { name: "CreatedDate", type: false },
];
export const MASTER_SORT_LIST = [
  { value: "", name: "Select" },
  { value: "companyName", name: "Company Name" },
  { value: "contactPerson", name: "Contact Person" },
  { value: "email", name: "Email" },
  { value: "NoOfLicensesInInt", name: "No. of Licenses" },
];
export const RANKING_SORT_LIST = [
  { value: "", name: "Select" },
  { value: "rank", name: "Rank" },
  { value: "rating", name: "Avg. Rating" },
  { value: "company", name: "Company" },
  { value: "activeSince", name: "Active Since" },
];
export const ROUTES = [
  {
    menuId: 1,
    menuGroupId: "client",
    route: "/client-licenses",
    permission: true,
    componentName: "ClientLicensesList",
    componentPath: "client/licences/LicensesList",
  },
  {
    menuId: 2,
    menuGroupId: "client",
    route: "/client-finances",
    permission: true,
    componentName: "ClientFinances",
    componentPath: "client/finances/Finances",
  },

  {
    menuId: 3,
    menuGroupId: "client",
    route: "/client-settings/:id",
    permission: true,
    componentName: "ClientSettings",
    componentPath: "client/settings/Setting",
  },
  {
    menuId: 4,
    menuGroupId: "client",
    route: "/client-info",
    permission: true,
    componentName: "LoginInformationClient",
    componentPath: "login/loginInfo/LoginInformationClient",
  },
  {
    menuId: 5,
    menuGroupId: "master",
    route: "/master-licenses",
    permission: true,
    componentName: "MasterLicensesList",
    componentPath: "master/licences/LicensesList",
  },
  {
    menuId: 6,
    menuGroupId: "master",
    route: "/master-finances",
    permission: true,
    componentName: "MasterFinances",
    componentPath: "master/finances/Finances",
  },

  {
    menuId: 7,
    menuGroupId: "master",
    route: "/master-settings/:id",
    permission: true,
    componentName: "MasterSettings",
    componentPath: "master/settings/Setting",
  },
  {
    menuId: 8,
    menuGroupId: "contractor",
    route: "/contractor-licenses",
    permission: true,
    componentName: "ContractorLicensesList",
    componentPath: "contractor/licences/LicensesList",
  },

  {
    menuId: 9,
    menuGroupId: "contractor",
    route: "/contractor-requests",
    permission: true,
    componentName: "RequestList",
    componentPath: "contractor/requests/RequestList",
  },

  {
    menuId: 10,
    menuGroupId: "contractor",
    route: "/contractor-settings/:id",
    permission: true,
    componentName: "ContractorSettings",
    componentPath: "contractor/settings/Setting",
  },
  {
    menuId: 11,
    menuGroupId: "contractor",
    route: "/contractor-info",
    permission: true,
    componentName: "LoginInformationContractor",
    componentPath: "login/loginInfo/LoginInformationContractor",
  },
];
export const CLIENT_DELETE_KEY = 1;
export const CONTRACTOR_DELETE_KEY = 2;
export const END_USER_DELETE_KEY = 3;
export const VALID_EMAIL_1 =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALID_EMAIL_2 = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
export const VALID_PASSOWRD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
export const VALID_NAME = /^[a-zA-Z ]$/;
export const VALID_REGION = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
export const VALID_ZIP = /^\d{5}(?:[- ]?\d{4})?$/;
export const ALPHABET_AND_SPACE_ONLY = /^[a-zA-Z ]*$/;
export const NUMERIC_ONLY = /^[0-9]+$/;
export const PHONE_ONLY = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;
export const userKPI = [
  { name: "Name", type: false },
  { name: "ContractorName", type: false },
  { name: "LastPlayed", type: false },
];
