import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../../../assets/images/close.png";
const ErrorModal = ({ errors, showErrorModal, closeErrModal }) => {
  return (
    <>
      <Modal
        show={showErrorModal}
        centered
        size="md"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="detail-reward-modal">
            <div className="d-flex flex-row justify-content-between">
              <h2 style={{ color: "rgb(52, 99, 109)" }}>Upload cancelled</h2>
              <img
                src={close}
                height={12}
                width={12}
                onClick={closeErrModal}
                style={{ cursor: "pointer" }}
              />
            </div>
            <p className="col-11">
              {errors ? errors : "Error in uploading File"}
            </p>
            {/* <div>
              <span className="d-block default-color">CHJGAS1298</span>
              <span className="d-block default-color">CHJGAS1298</span>
            </div> */}
            <div className="d-flex mt-2 edit-buttons justify-content-end w-100">
              <button
                className="save text-uppercase me-0"
                style={{ fontSize: "12px", minWidth: "110px" }}
                onClick={closeErrModal}
              >
                Okay
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ErrorModal;
