import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Login from "../domain/login/Login";
import { getLoginStorage } from "../utils/UserDataManagement";
import SideBar from "../sideBar/SideBar";
import CreateQuestion from "../domain/client/content/createQuestion/CreateQuestion";
import ContentDetail from "../domain/client/content/overview/contentDetail/ContentDetail";

const Routes = (props) => {
  if (
    JSON.parse(localStorage.getItem("staySafeUserData")) == null &&
    !props.history.location.pathname.includes("login")
  ) {
    window.location = "/login";
  }
  let currentPath = props.history.location.pathname;
  let showNav =
    getLoginStorage("email") &&
    (currentPath.includes("contractor-info") ||
      currentPath.includes("client-info"))
      ? false
      : true;

  if (JSON.parse(localStorage.getItem("staySafeUserData")) == null) {
    showNav = false;
  }

  return (
    <div className="h-100">
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login" component={Login}></Route>
        <React.Fragment>
          {showNav && <SideBar />}
          {JSON.parse(localStorage.getItem("menuStructure"))?.map(
            (item, index) => {
              try {
                let DynamicComponent = require("../domain/" +
                  item.componentPath).default;
                return (
                  <Route
                    key={index}
                    path={item.route}
                    render={() => {
                      return <DynamicComponent key={item.componentName} />;
                    }}
                  />
                );
              } catch (e) {
                console.log(e);
              }
            }
          )}

          <Route
            // key={"i"}
            path={"/create-question"}
            render={() => {
              return <CreateQuestion />;
            }}
          />
          <Route
            // key={"i"}
            path={"/content-detail"}
            render={() => {
              return <ContentDetail />;
            }}
          />
        </React.Fragment>
      </Switch>
    </div>
  );
};

export default withRouter(Routes);
