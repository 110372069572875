import React, { useState, useEffect } from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import edit_icon from "../../../../../assets/images/edit.png";
import {
  editDistributionSetting,
  searchAndSortDashboardRewards,
} from "../../../../services/MasterServices";
import { arr20 } from "../rewardsData";
import toast, { Toaster } from "react-hot-toast";
import "./EditRewardModal.css";
import { HashLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import { getKeyTranslation } from "../../../../utils/Translations";

const EditRewardModal = ({
  selectedClient,
  rewardPlaceListApidata,
  recallDistributionApi,
}) => {
  const [editRewardModal, setEditRewardModal] = useState(false);
  const [selectedCount, setSelectedCount] = useState(Number);
  const [updatedList, setUpdatedList] = useState([]);
  const [changeCountList, setChangeCountList] = useState([]);
  const [changeCountClick, setChangeCountClick] = useState(false);
  const [btnUpload, setBtnUpload] = useState(false);
  const [concatedArr, setConcatedArr] = useState([]);
  const [rewardsExist, setRewardsExist] = useState(false);
  let [optionArr, setOptionArr] = useState([]);
  let [defaultEmpty, setDefaultEmpty] = useState(false);
  let [optionErrorMessage, setOptionErrorMessage] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const arrWithSpecificLength = arr20;
  useEffect(() => {
    let opt_res;
    const fetchSearchResults2 = async () => {
      setScreenLoader(true);
      opt_res = await searchAndSortDashboardRewards(
        `?clientId=${selectedClient}&IsAll=true`
      );
      if (opt_res?.status === 200) {
        getDropdownOptions(opt_res);
      } else {
        setScreenLoader(false);
        toast.error("Error getting optinons");
        setOptionErrorMessage(true);
        closeModal();
      }
    };
    fetchSearchResults2();
  }, []);
  const getDropdownOptions = (result) => {
    setOptionErrorMessage(false);
    if (result?.data?.data?.items.length !== 0) {
      setScreenLoader(false);
      setRewardsExist(true);
      let opt = result?.data?.data?.items.map(function (obj, ind) {
        return { value: obj.id, label: obj.rewardName };
      });
      setOptionArr(opt);
      // updated initial list
      getAndSetInitialList(opt);
    } else {
      setScreenLoader(false);
      setRewardsExist(false);
      setUpdatedList([]);
      setChangeCountList([]);
      setSelectedCount(0);
      setOptionArr([]);
    }
  };
  const getAndSetInitialList = (options) => {
    if (rewardPlaceListApidata?.length !== 0) {
      setSelectedCount(rewardPlaceListApidata.length);
      let newArr = rewardPlaceListApidata?.map(function (obj) {
        return {
          options: options,
          place: obj.place,
          rewardId: obj.rewardId,
        };
      });
      if (newArr.length < 20) {
        let newArr2 = [
          ...Array(20 - newArr.length)
            .fill(0)
            .map((x, ind) => ({
              options: options,
              place: ind + newArr.length + 1,
              rewardId: options[0].value,
            })),
        ];

        setUpdatedList(newArr);
        var result = [...newArr, ...newArr2];

        setConcatedArr(result);
      } else {
        setUpdatedList(newArr);
      }
    } else {
      setDefaultEmpty(true);
      setSelectedCount(0);

      setUpdatedList([]);
    }
  };

  const EditDistributionCall = () => {
    let placeListArray = [];
    if (changeCountClick) {
      placeListArray = changeCountList.map(function (obj1) {
        return { place: obj1.place, rewardId: obj1.rewardId };
      });
    } else {
      placeListArray = updatedList.map(function (obj2) {
        return { place: obj2.place, rewardId: obj2.rewardId };
      });
    }
    let obj = {
      clientId: selectedClient,
      topPlayersCount: selectedCount,
      rewardPlaceList: placeListArray,
    };
    const editDistribution = async () => {
      setBtnUpload(true);
      const result = await editDistributionSetting(obj);
      if (result?.status == 200) {
        setBtnUpload(false);
        recallDistributionApi();
        closeModal();
        setTimeout(() => {
          toast.success(getKeyTranslation(result.data.message));
        }, 1000);
      } else {
        setBtnUpload(false);
        toast.error(getKeyTranslation(result.data.message));
        closeModal();
      }
    };
    editDistribution();
  };
  const keydown = (e) => {
    var invalidChars = ["-", "+", "e", "E", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  const changeCount = (val) => {
    if (val >= 0 && val <= 20) {
      if (defaultEmpty) {
        let newArr = arrWithSpecificLength.map(function (obj, ind) {
          return {
            place: ind + 1,
            options: optionArr,
            rewardId: optionArr[0].value,
          };
        });
        let arr = newArr.slice(0, val);
        setSelectedCount(arr);
        setUpdatedList(arr);
        setSelectedCount(val);
      } else {
        setSelectedCount(val);
        let copiedArray = [];

        if (concatedArr.length == 0) {
          copiedArray = updatedList.slice();
        } else {
          copiedArray = concatedArr.slice();
        }
        let updatedArr = copiedArray.slice(0, val);
        setChangeCountList(updatedArr);
        setChangeCountClick(true);
      }
    }
  };
  const onchangeOption = (e) => {
    console.log(e.target.value);
    const selectedPlace = e.target.value.slice(e.target.value.indexOf("-") + 1);
    const selectedValue = e.target.value.slice(0, e.target.value.indexOf("-"));
    let arr = [];
    if (concatedArr.length !== 0 && changeCountClick) {
      arr = concatedArr.slice();
    } else {
      arr = updatedList.slice();
    }
    arr.forEach((item) => {
      if (item.place == selectedPlace) {
        return (item.rewardId = parseInt(selectedValue));
      }
    });
    setUpdatedList(arr);
  };
  const closeModal = () => {
    let arr;
    if (optionArr.length != 0) {
      if (rewardPlaceListApidata?.length !== 0) {
        setSelectedCount(rewardPlaceListApidata?.length);
        arr = rewardPlaceListApidata?.map(function (obj) {
          return {
            options: optionArr,
            place: obj.place,
            rewardId: obj.rewardId,
          };
        });
        setUpdatedList(arr);
        setChangeCountList(arr);
      } else {
        setSelectedCount(0);
        arr = [];
        setUpdatedList(arr);
        setChangeCountList(arr);
        setChangeCountList(arr);
      }
    } else {
      toast.error("Error fetching option");
      setRewardsExist(false);
      setUpdatedList([]);
      setSelectedCount(0);
      setChangeCountList([]);
    }
    setEditRewardModal(false);
  };
  const showRewards = () => {
    return rewardPlaceListApidata.length > 0 ? (
      <img
        onClick={() => {
          setEditRewardModal(true);
        }}
        className="text-end"
        src={edit_icon}
        height={30}
        width={30}
        style={{ cursor: "pointer" }}
      />
    ) : (
      <>
        <div className="d-flex mt-2 edit-buttons justify-content-end w-100">
          <button
            className="save text-uppercase"
            onClick={() => {
              setEditRewardModal(true);
            }}
          >
            Setup
          </button>
        </div>
      </>
    );
  };
  const showMessage = () => {
    return (
      <div>
        {!optionErrorMessage && !screenLoader ? (
          <p>Please add Reward to Continue</p>
        ) : (
          <>
            <LoadingOverlay
              spinner={<HashLoader color="#5ba6b7" size={30} />}
              active={screenLoader}
            ></LoadingOverlay>
          </>
        )}
      </div>
    );
  };
  const editList = (changeCountClickvalue) => {
    return changeCountClickvalue ? changeCountList : updatedList;
  };
  const position = (item) => {
    switch (item?.place) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const slicedList = (start, end) => {
    return editList(changeCountClick)
      ?.slice(start, end)
      .map((item, index) => {
        return (
          <div
            key={index}
            className="d-flex flex-row mt-2 justify-content-between align-items-center"
          >
            <div style={{ color: "#2C8CA3", fontSize: "14px" }}>
              {item?.place}
              {position(item)}
              {"  "}
              {"Place"}
            </div>
            <select
              className="edit-select"
              onChange={(e) => onchangeOption(e)}
              defaultValue={item?.rewardId + "-" + item?.place}
            >
              {item?.options?.map((option, index_opt) => {
                return (
                  <option
                    key={index_opt}
                    value={option?.value + "-" + item?.place}
                  >
                    {option?.label}
                  </option>
                );
              })}
            </select>
          </div>
        );
      });
  };
  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      {rewardsExist ? showRewards() : showMessage()}
      <Modal
        show={editRewardModal}
        // show={true}
        centered
        size="lg"
        className="modal-screen updateModal-screen rewards-edit-modal"
      >
        <Modal.Body>
          <div className="">
            <div className="modal-header">
              <h1 className="mb-0">Edit distribution settings</h1>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button> */}
              <img
                src="/assets/icons/Cross.png"
                onClick={closeModal}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="d-flex my-4 edit-buttons justify-content-end w-100">
              <button className="cancel text-uppercase" onClick={closeModal}>
                cancel
              </button>
              <button
                className="save text-uppercase"
                // onClick={() => {
                //   setEditRewardModal(false);
                //   setUpdatedList(editRewardList);
                //   setSelectedCount(0);
                //   EditDistributionCall
                // }}
                onClick={EditDistributionCall}
              >
                {btnUpload && (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ height: "23px", width: "23px" }}
                  >
                    <span
                      className="visually-hidden"
                      style={{
                        height: "23px",
                        width: "23px",
                      }}
                    >
                      Loading...
                    </span>
                  </Spinner>
                )}
                save changes
              </button>
            </div>
            <p style={{ fontWeight: "normal", fontSize: "16px" }}>
              Top Amount of players per contractor that will receive rewards
            </p>
            <input
              className="count"
              type="number"
              value={selectedCount}
              onChange={(e) => {
                changeCount(e.target.value);
              }}
              onKeyDown={(e) => keydown(e)}
            ></input>
            <Row className="mt-4">
              <Col lg={6} className="reward-edit-form">
                {slicedList(0, 5)}
              </Col>
              <Col lg={6} className="reward-edit-form">
                {editList(changeCountClick).length >= 6 && slicedList(5, 10)}
              </Col>
              <Col lg={12}>
                <hr />
              </Col>
              <Col lg={6} className="reward-edit-form">
                {editList(changeCountClick).length >= 11 && slicedList(10, 15)}
              </Col>
              <Col lg={6} className="reward-edit-form">
                {editList(changeCountClick).length >= 16 &&
                  editList(changeCountClick).length <= 20 &&
                  slicedList(15, 20)}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditRewardModal;
