import { deleteMethod } from "./NetworkManager";
import { DELETE_END_USER, DELETE_USER } from "../constant/ApiUrls";

export const downloadUserInstructions = async (type) => {
  var link = document.createElement("a");
  link.href = type;
  link.download = type;
  link.dispatchEvent(new MouseEvent("click"));
};

export const deleteUser = async (id, type) => {
  const url = DELETE_USER(id, type);
  console.log("delete Url", url);
  return deleteMethod(url);
};
export const deleteEndUser = async (id, type) => {
  return deleteMethod(`${DELETE_END_USER}${id}`);
};
