import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import close from "../../../../../assets/images/close.png";
import { deleteReward } from "../../../../services/MasterServices";
import { getKeyTranslation } from "../../../../utils/Translations";
const DeleteRewardModal = ({
  rewardid,
  clientid,
  closeModal,
  dashboardRewardCall,
  usedCodes,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDelete2, setConfirmDelete2] = useState(false);
  const deleteRewardFunc = () => {
    let obj = {
      rewardId: rewardid,
      clientId: clientid,
      isConfirm: false,
    };

    const detailRewardApiCall = async () => {
      const result = await deleteReward(obj);
      if (result?.status == 200) {
        setTimeout(() => {
          toast.success(getKeyTranslation(result.data.message));
        }, 2000);
        dashboardRewardCall();
        setConfirmDelete(false);
        closeModal();
      } else if (result?.status == 452) {
        setConfirmDelete(false);
        setConfirmDelete2(false);
      } else if (result?.status == 409) {
        setConfirmDelete(false);
        setConfirmDelete2(true);
      } else {
        setConfirmDelete(false);
        toast.error(getKeyTranslation(result?.data.message));
      }
    };
    detailRewardApiCall().catch(console.log("error"));
  };
  const deleteRewardFunc2 = () => {
    let obj = {
      rewardId: rewardid,
      clientId: clientid,
      isConfirm: true,
    };

    const detailRewardApiCall = async () => {
      const result = await deleteReward(obj);
      if (result.status == 200) {
        setTimeout(() => {
          toast.success("Reward deleted successfully");
        }, 2000);
        setConfirmDelete2(false);
        dashboardRewardCall();
        closeModal();
      } else if (result.status == 452) {
        setConfirmDelete(false);
        setConfirmDelete2(false);
      } else {
        setConfirmDelete2(false);
        toast.error("error deleting reward");
      }
    };
    detailRewardApiCall().catch(console.log("error"));
  };
  const firstClick = () => {
    if (usedCodes > 0) {
      setConfirmDelete2(true);
    } else {
      setConfirmDelete(true);
    }
  };
  return (
    <>
      <button className="btn-delete-reward d-inline" onClick={firstClick}>
        Delete reward
      </button>
      <Modal
        show={confirmDelete}
        centered
        size="md"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="detail-reward-modal">
            <div className="d-flex flex-row justify-content-between">
              <p className="col-11">
                Are you sure you want to delete the reward?
              </p>
              <img
                src={close}
                height={12}
                width={12}
                onClick={() => {
                  setConfirmDelete(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div></div>
            <div className="d-flex mt-2 edit-buttons justify-content-end w-100">
              <button
                className="cancel text-uppercase"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  setConfirmDelete(false);
                }}
              >
                cancel
              </button>
              <button
                className="save text-uppercase"
                style={{ fontSize: "12px" }}
                onClick={deleteRewardFunc}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={confirmDelete2}
        centered
        size="md"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="detail-reward-modal">
            <div className="d-flex flex-row justify-content-between">
              <p className="col-11">
                Some of the codes of this reward are{" "}
                <b>currently distributed to the users</b>. Deleting the reward
                will forcefully remove the rewards from these users. Are you
                sure you want to continue?
              </p>
              <img
                src={close}
                height={12}
                width={12}
                onClick={() => {
                  setConfirmDelete2(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div></div>
            <div className="d-flex mt-2 edit-buttons justify-content-end w-100">
              <button
                className="cancel text-uppercase"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  setConfirmDelete2(false);
                }}
              >
                cancel
              </button>
              <button
                className="save text-uppercase"
                style={{ fontSize: "12px" }}
                onClick={deleteRewardFunc2}
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteRewardModal;
