import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, Col, Modal, ProgressBar, Row } from "react-bootstrap";
import side_arrow from "../../../../../../assets/icons/side_arrow.png";
import { HashLoader } from "react-spinners";
import { questionTypesData } from "../../contentData";
import InfoPopup from "../../createContent/infoPopup/InfoPopup";
import AssignContentToContractor from "../assignContentToContractor/AssignContentToContractor";
import { BASE_URL_IMAGE } from "../../../../../constant/ApiUrls";
import LoadingOverlay from "react-loading-overlay";
import toast, { Toaster } from "react-hot-toast";
import { getKeyTranslation } from "../../../../../utils/Translations";
import "./ContentDetail.css";
import {
  copyContentPack,
  deleteContentPack,
  getCategoryContentPack,
  getCategoryQuestions,
  getContentPackReportingQuestions,
  updateVisibilityContentPack,
} from "../../../../../services/ClientServices";

const ContentDetail = () => {
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [categoryQuestion, setCategoryQuestion] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [enteredKey, setEnteredKey] = useState("");
  const [arrPercentage, setArrPercentage] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [languageAny, setLanguageAny] = useState(true);
  const [popUpModal, setPopUpModal] = useState(false);
  const [menuPopup, setMenuPopup] = useState("");
  const category_id = JSON.parse(localStorage.getItem("contentDetail")).id;
  const pack_visibility = JSON.parse(
    localStorage.getItem("contentDetail")
  ).contentPackVisibility;

  const createPercentageArray = (result2) => {
    let total = result2?.data?.data?.items;
    let totalCount = result2?.data?.data?.items.length;
    let single = total?.filter((i) => i.typeId === 1).length;
    let multiple = total?.filter((i) => i.typeId === 2).length;
    let flaw = total?.filter((i) => i.typeId === 3).length;
    let trueFalse = total?.filter((i) => i.typeId === 4).length;
    let single_percentage = (Math.round(single) / totalCount) * 100;
    let multiple_percentage = (Math.round(multiple) / totalCount) * 100;
    let flaw_percentage = (Math.round(flaw) / totalCount) * 100;
    let trueFalse_percentage = (Math.round(trueFalse) / totalCount) * 100;
    let arr = [
      single_percentage,
      multiple_percentage,
      flaw_percentage,
      trueFalse_percentage,
    ];
    setArrPercentage(arr);
  };
  useEffect(async () => {
    let result = await getCategoryContentPack(category_id);

    if (result?.status == 200) {
      setCategoryDetail(result?.data.data);
      let obj = {
        categoryId: result?.data.data.id,
        categoryName: result?.data.data.name,
        createdAt: result?.data.data.createdAt,
        updatedAt: result?.data.data.updatedAt,
        storage_languages: result?.data.data.languages,
      };
      localStorage.setItem("question_category", JSON.stringify(obj));
    }
    let searchUrl = `?CategoryId=${category_id}`;
    setLoader(true);
    if (localStorage.getItem("tab_visibility") != "reporting") {
      let result2 = await getCategoryQuestions(searchUrl);
      if (result2?.status == 200) {
        setLoader(false);
        setCategoryQuestion(result2?.data?.data?.items);
        createPercentageArray(result2);
      } else {
        setLoader(false);
      }
    } else {
      let result2 = await getContentPackReportingQuestions(searchUrl);
      if (result2?.status == 200) {
        setLoader(false);
        setCategoryQuestion(result2?.data?.data?.items);
        createPercentageArray(result2);
      } else {
        setLoader(false);
      }
    }
  }, []);
  const deleteContent = (menu_id) => {
    setMenuPopup(menu_id);
    setPopUpModal(true);
  };
  const deletePackConfirm = async () => {
    setPopUpModal(false);
    let obj = {
      categoryId: category_id,
    };
    let result = await deleteContentPack(obj);
    if (result?.status === 200) {
      toast.success(getKeyTranslation(result.data.message));
      window.location = "/content";
    }
  };
  const moveToPublic = (menu_id) => {
    setMenuPopup(menu_id);
    setPopUpModal(true);
  };
  const moveToPublicConfirm = async () => {
    let v = pack_visibility.toLowerCase() == "public" ? 1 : 2;
    let obj = {
      categoryId: category_id,
      packVisibilityId: v,
    };
    setPopUpModal(false);
    let result = await updateVisibilityContentPack(obj);
    if (result?.status === 200) {
      toast.success(getKeyTranslation(result.data.message));
    }
  };
  const copyContent = (menu_id) => {
    setMenuPopup(menu_id);
    setPopUpModal(true);
  };
  const copyContentConfirm = async () => {
    setPopUpModal(false);
    let obj = {
      categoryId: category_id,
    };
    let result = await copyContentPack(obj);
    if (result?.status === 200) {
      toast.success(getKeyTranslation(result.data.message));
    }
  };

  const round_to_100 = (arr) => {
    let output = [];
    let acc = 0;

    for (let i = 0; i < arr?.length; i++) {
      let roundedCur = Math.round(arr[i]);
      const currentAcc = acc;
      if (acc == 0) {
        output.push(roundedCur);
        acc += arr[i];
        continue;
      }
      acc += arr[i];
      output.push(Math.round(acc) - Math.round(currentAcc));
    }

    return output;
  };

  const questionApiCall = async (params) => {
    let result2 = await getCategoryQuestions(params);
    if (result2?.status == 200) {
      setLoader(false);
      setCategoryQuestion(result2?.data?.data?.items);
    } else {
      setLoader(false);
    }
  };
  const searchContentPack = async () => {
    setEnteredKey(searchKey);
    let searchUrl = `?CategoryId=${category_id}&SearchKey=${searchKey}&QuestionTypeIds=${selectedQuestions}&LanguageIds=${selectedLanguages}`;
    if (localStorage.getItem("tab_visibility") !== "reporting") {
      setLoader(true);
      questionApiCall(searchUrl);
    } else {
      setLoader(true);
      let result2 = await getContentPackReportingQuestions(searchUrl);
      if (result2?.status == 200) {
        setLoader(false);
        setCategoryQuestion(result2?.data?.data?.items);
      } else {
        setLoader(false);
      }
    }
  };
  const changeSearchKey = (e) => {
    setSearchKey(e.target.value);
  };
  const onEnter = (e) => {
    if (e.code === "Enter") {
      searchContentPack();
    }
  };
  const onclickQuestionType = async (ele_id, id) => {
    let selectedPair = [...selectedQuestions];
    if (selectedPair.includes(id)) {
      selectedPair.splice(
        selectedPair.findIndex((v) => v === id),
        1
      );
    } else {
      selectedPair.push(id);
    }
    let searchUrl = `?CategoryId=${category_id}&QuestionTypeIds=${selectedPair}&SearchKey=${enteredKey}&LanguageIds=${selectedLanguages}`;
    if (localStorage.getItem("tab_visibility") == "reporting") {
      let result2 = await getContentPackReportingQuestions(searchUrl);
      setLoader(true);
      if (result2?.status == 200) {
        setLoader(false);
        setCategoryQuestion(result2?.data?.data?.items);
        setSelectedQuestions(selectedPair);
      } else {
        setLoader(false);
      }
    } else {
      setLoader(true);
      questionApiCall(searchUrl);
      setSelectedQuestions(selectedPair);
    }
  };
  const onclickLanguages = async (ele_id, id) => {
    let selectedPair = [...selectedLanguages];
    if (selectedPair.includes(id)) {
      selectedPair.splice(
        selectedPair.findIndex((v) => v === id),
        1
      );
    } else {
      selectedPair.push(id);
    }
    let searchUrl = `?CategoryId=${category_id}&QuestionTypeIds=${selectedQuestions}&LanguageIds=${selectedPair}&SearchKey=${enteredKey}`;
    if (localStorage.getItem("tab_visibility") == "reporting") {
      let result2 = await getContentPackReportingQuestions(searchUrl);
      setLoader(true);
      if (result2?.status == 200) {
        setLoader(false);
        setCategoryQuestion(result2?.data?.data?.items);
        createPercentageArray(result2);
        setSelectedLanguages(selectedPair);
        selectedPair.length ? setLanguageAny(false) : setLanguageAny(true);
      } else {
        setLoader(false);
      }
    } else {
      setLoader(true);
      questionApiCall(searchUrl);
      setSelectedLanguages(selectedPair);
      selectedPair.length ? setLanguageAny(false) : setLanguageAny(true);
    }
  };
  const anyLanguageClick = async () => {
    categoryDetail.languages?.forEach((item) => {
      return (document.getElementById(
        item.id + "selected-languages"
      ).checked = false);
    });
    let searchUrl = `?CategoryId=${category_id}&QuestionTypeIds=${selectedQuestions}&LanguageIds=&SearchKey=${enteredKey}`;
    if (localStorage.getItem("tab_visibility") == "reporting") {
      let result2 = await getContentPackReportingQuestions(searchUrl);
      setLoader(true);
      if (result2?.status == 200) {
        setLoader(false);
        setCategoryQuestion(result2?.data?.data?.items);
        setLanguageAny(true);
        setSelectedLanguages([]);
      } else {
        setLoader(false);
      }
    } else {
      setLoader(true);
      questionApiCall(searchUrl);
      setLanguageAny(true);
      setSelectedLanguages([]);
    }
  };
  const createImgGrid = (dimensions, opts, preview2) => {
    let rows = [];
    let cols = [];
    let d_row = dimensions.charAt(0);
    let d_col = dimensions.charAt(dimensions.length - 1);
    rows = Array.from({ length: d_row }, (v, k) => k + 1);
    cols = Array.from({ length: d_col }, (v, k) => k + 1);
    return rows.map((row, ind) => {
      return (
        <div key={ind} className="grid-outside">
          {cols.map((col, ind2) => {
            return (
              <div
                key={ind2}
                className="grid-div grid-inside"
                style={{
                  background: opts.includes(`${col}${row}`)
                    ? "rgba(92, 231, 65, 0.8)"
                    : "",
                  position: "relative",
                }}
              ></div>
            );
          })}
        </div>
      );
    });
  };
  const callingToaster = (value, name) => {
    if (name == "success") {
      toast.success(value);
    } else {
      toast.error(value);
    }
  };
  const openEditor = () => {
    window.location = "./create-question";
  };
  const typeOneTwoFourOptions = (item) => {
    return item?.options.map((opt, option_id) => {
      return (
        <p
          className="mb-1"
          key={option_id}
          style={{
            color: opt.correct ? "rgb(120, 212, 101)" : "",
          }}
        >
          {opt.option}
        </p>
      );
    });
  };
  const reportingConditions = (item) => {
    return localStorage.getItem("tab_visibility") == "reporting" ? (
      item.translations.filter((i) => {
        return i.languageId == 3;
      })[0]?.reportings?.length > 1 ||
      item.translations.filter((i) => i.reportings.length > 0).length > 1 ? (
        <div style={{ color: "red", fontWeight: "bold" }}>
          Reported multiple times
        </div>
      ) : item.translations.filter((i) => {
          return i.languageId == 3;
        })[0]?.reportings?.length == 1 ? (
        <div style={{ color: "red", fontWeight: "bold" }}>
          Reported: ,,{reportingStatement(item)}''
        </div>
      ) : (
        <div style={{ color: "red", fontWeight: "bold" }}>
          "Reported in other than default language"
        </div>
      )
    ) : null;
  };
  const reportingStatement = (item) => {
    if (localStorage.getItem("tab_visibility") == "reporting") {
      return item?.translations?.filter((i) => {
        return i.languageId == 3;
      })[0]?.reportings != null ||
        item?.translations?.filter((i) => {
          return i.languageId == 3;
        })[0]?.reportings != []
        ? getKeyTranslation(
            item?.translations?.filter((i) => {
              return i.languageId == 3;
            })[0]?.reportings[0]?.reportingType?.identifier
          )
        : "Reported in other than default language";
    } else {
      return null;
    }
  };
  const typeSingleOrMultiple = (item, ind) => {
    return (
      <div className=" masonry-box mb-3" key={ind}>
        <Card
          className="p-2 me-lg-2 me-4"
          style={{
            border: "1px solid #2C8CA3",
            borderRadius: "0",
          }}
        >
          <div className="question-container">
            {reportingConditions(item)}
            <span>
              Q{ind + 1} {item.typeId === 1 ? "Single" : "Multiple"} Choice
            </span>
            <p
              style={{
                color: "#575757",
                fontWeight: "bold",
              }}
            >
              {item?.question}
            </p>
            <img
              src={`${BASE_URL_IMAGE}${item.image}`}
              className="w-100 card-image"
              style={{ aspectRatio: "1 / 1" }}
            />
            <div className="mt-1">{typeOneTwoFourOptions(item)}</div>
          </div>
        </Card>
      </div>
    );
  };

  const typeFlawOrTrueFalse = (item, ind) => {
    return item.typeId === 3 ? (
      <div className=" masonry-box mb-3 find-the-flaw" key={ind}>
        <Card
          className="p-2 me-lg-2 me-4"
          style={{
            border: "1px solid #2C8CA3",
            borderRadius: "0",
          }}
        >
          <div className="question-container">
            {reportingConditions(item)}
            <span>
              Q{ind + 1} Find the Flaw, {item.matrixSize}
            </span>
            <p
              style={{
                color: "#575757",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              {item?.question}
            </p>
            <div
              style={{
                backgroundImage: `url(${BASE_URL_IMAGE}${item.image})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "grid",
                aspectRatio: "1 / 1",
              }}
              className="card-image position-relative"
            >
              {createImgGrid(item.matrixSize, item.options[0].option)}
            </div>
            <div></div>
          </div>
        </Card>
      </div>
    ) : (
      <div className=" masonry-box mb-3" key={ind}>
        <Card
          className="p-2 me-lg-2 me-4"
          style={{
            border: "1px solid #2C8CA3",
            borderRadius: "0",
          }}
        >
          <div className="question-container mt-1">
            {reportingConditions(item)}
            <span>Q{ind + 1} True False</span>
            <p
              style={{
                color: "#575757",
                fontWeight: "bold",
              }}
            >
              {item?.question}
            </p>
            <img
              src={`${BASE_URL_IMAGE}${item.image}`}
              className="w-100 card-image"
              style={{ aspectRatio: "1 / 1" }}
            />
            <div>{typeOneTwoFourOptions(item)}</div>
          </div>
        </Card>
      </div>
    );
  };
  const popupHeadingPublicOrCopy = () => {
    return menuPopup == 2 ? "Move to public" : "Copy";
  };
  const popupDescriptionPublicOrCopy = () => {
    return menuPopup == 2
      ? "By moving into public the content pack will available publically. Are you sure you want to move?"
      : "Are you sure you want to make a copy to private";
  };
  const popupButtonPublicOrCopy = () => {
    return menuPopup == 2 ? moveToPublicConfirm() : copyContentConfirm();
  };
  const popupButtonTextPublicOrCopy = () => {
    return menuPopup == 2 ? "Move" : "Copy";
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />

      <div className="my-section d-flex justify-content-between sidebar-for-mobile">
        <div className="my-container-grid">
          <div className="myDiv mt-5 ms-5" id="detail">
            <LoadingOverlay
              spinner={<HashLoader color="#5ba6b7" size={90} />}
              active={loader}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location = "/content";
                }}
              >
                <img src={side_arrow} className="me-2" height={8} />
                <u>back to content overview</u>
              </div>
              <h1 style={{ wordBreak: "break-all", maxWidth: "50%" }}>
                {categoryDetail.name}
              </h1>
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="d-flex flex-wrap">
                  by {categoryDetail.clientName}&nbsp;&nbsp;
                  {categoryDetail.contentPackTags?.map((item, ind) => {
                    return (
                      <span key={ind} className="content-tags mb-0">
                        #{item.label}
                      </span>
                    );
                  })}
                </div>
                <div className="d-flex my-2 flex-wrap">
                  {categoryDetail.languages?.map((item2, ind) => {
                    return (
                      <img
                        key={ind}
                        className="me-2"
                        height={16}
                        width={20}
                        src={BASE_URL_IMAGE + "Flags/" + item2?.icon}
                      />
                    );
                  })}
                </div>
              </div>
              {/* <div>flags</div> */}
              <div className="d-flex align-items-center flex-wrap">
                <div className="search-input col-lg-3">
                  <input
                    type="text"
                    placeholder="Search by question or answer…"
                    onKeyDown={(e) => onEnter(e)}
                    value={searchKey}
                    onChange={(e) => changeSearchKey(e)}
                  />
                  <FontAwesomeIcon
                    onClick={searchContentPack}
                    icon={faSearch}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {!selectedLanguages.length && !selectedQuestions.length && (
                  <div>
                    Showing questions from <b>all question types</b> and{" "}
                    <b>all languages</b>
                  </div>
                )}
              </div>
              <Row className="mt-4 question-divs">
                <Col lg={9} className="questions">
                  {/* <div> */}
                  <div className="masonry-columns first-div">
                    {categoryQuestion?.map((item, ind) => {
                      return item.typeId === 1 || item.typeId === 2
                        ? typeSingleOrMultiple(item, ind)
                        : typeFlawOrTrueFalse(item, ind);
                    })}
                  </div>
                  {/* </div> */}
                </Col>
                <Col lg={3} className="questions">
                  {categoryDetail?.contentPackVisibility?.toLowerCase() ==
                    "public" &&
                    localStorage.getItem("tab_visibility") !== "reporting" && (
                      <>
                        <div className="mb-1 create-content-buttons">
                          <button
                            style={{
                              width: "auto",
                              padding: "8px",
                              minWidth: "217px",
                            }}
                            className="save-and-edit d-inline px-4 me-4 text-start"
                            onClick={() => copyContent(3)}
                          >
                            copy to my private library
                          </button>
                        </div>

                        <div className="mb-1 create-content-buttons">
                          <AssignContentToContractor
                            categoryId={category_id}
                            detail="detail"
                            callingToaster={callingToaster}
                          />
                        </div>
                        {categoryDetail?.isSameClient && (
                          <div className="mb-3 create-content-buttons">
                            <button
                              style={{
                                width: "auto",
                                padding: "8px",
                                minWidth: "217px",
                                borderColor: "red",
                                color: "red",
                              }}
                              className="save d-inline px-4 text-start"
                              onClick={() => deleteContent(1)}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  {categoryDetail?.contentPackVisibility?.toLowerCase() ==
                    "private" &&
                    localStorage.getItem("tab_visibility") !== "reporting" && (
                      <>
                        <div className="mb-1 create-content-buttons">
                          <button
                            style={{
                              width: "auto",
                              padding: "8px",
                              minWidth: "217px",
                            }}
                            className="save-and-edit d-inline px-4 me-4 text-start"
                            onClick={openEditor}
                          >
                            Open in editor
                          </button>
                        </div>
                        <div className="mb-1 create-content-buttons">
                          <button
                            style={{
                              width: "auto",
                              padding: "8px",
                              minWidth: "217px",
                            }}
                            className="save d-inline px-4 me-4 text-start"
                            onClick={() => copyContent(3)}
                          >
                            copy to my private library
                          </button>
                        </div>
                        {JSON.parse(localStorage.getItem("staySafeUserData"))
                          .role !== "KA" && (
                          <>
                            <div className="mb-1 create-content-buttons">
                              <button
                                style={{
                                  width: "auto",
                                  padding: "8px",
                                  minWidth: "217px",
                                }}
                                className="save d-inline px-4 text-start"
                                onClick={() => moveToPublic(2)}
                              >
                                Move to public
                              </button>
                            </div>
                            <div className="mb-1 create-content-buttons">
                              <AssignContentToContractor
                                categoryId={category_id}
                                detail="detail"
                                callingToaster={callingToaster}
                              />
                            </div>
                          </>
                        )}
                        <div className="mb-3 create-content-buttons">
                          <button
                            style={{
                              width: "auto",
                              padding: "8px",
                              minWidth: "217px",
                              borderColor: "red",
                              color: "red",
                            }}
                            className="save d-inline px-4 text-start"
                            onClick={() => deleteContent(1)}
                          >
                            Delete
                          </button>
                        </div>
                      </>
                    )}
                  {localStorage.getItem("tab_visibility") == "reporting" && (
                    <div className="mb-1 create-content-buttons">
                      <button
                        style={{
                          width: "auto",
                          padding: "8px",
                          minWidth: "217px",
                        }}
                        className="save-and-edit d-inline px-4 me-4 text-start"
                        onClick={openEditor}
                      >
                        Open in editor
                      </button>
                    </div>
                  )}
                  <div>
                    <h6 className="mt-4">
                      Question Types&nbsp;
                      <InfoPopup
                        paragraph_1="Distribution of question types. Single choice,
                            Multiple choice, Find the Flaw and True or False."
                      />
                    </h6>
                    {questionTypesData?.map((item, ind) => {
                      return (
                        <div
                          className="content-checkbox d-flex align-items-center"
                          key={ind}
                        >
                          <div className="reward-checkbox styled-input-single d-inline me-2 checkbox-type-name">
                            <input
                              style={{
                                top: "7px",
                                left: "2px",
                                zIndex: "1",
                                visibility: "visible",
                                opacity: "0",
                              }}
                              className="cursor-pointer"
                              onChange={() =>
                                onclickQuestionType("type" + ind, ind + 1)
                              }
                              type="checkbox"
                              id={"type" + ind}
                            />
                            <label />
                            {item.name}
                          </div>
                          <ProgressBar
                            now={round_to_100(arrPercentage)[ind]}
                            className="d-flex flex-fill me-4"
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <h6 className="mt-4">Languages</h6>
                    <div className="content-checkbox checkbox-radio">
                      <div className="reward-checkbox styled-input-single d-inline">
                        <input
                          type="checkbox"
                          name="all"
                          id="all"
                          checked={languageAny}
                          onChange={anyLanguageClick}
                          className="cursor-pointer"
                        />
                        <label htmlFor={"all"} />
                        Any
                      </div>
                    </div>
                    <div className="content-checkbox">
                      <div className="content-checkbox">
                        {categoryDetail.languages?.map((item, ind) => {
                          return (
                            <div
                              className="reward-checkbox styled-input-single"
                              key={ind}
                            >
                              <input
                                style={{
                                  top: "7px",
                                  left: "2px",
                                  zIndex: "1",
                                  visibility: "visible",
                                  opacity: "0",
                                }}
                                type="checkbox"
                                id={item.id + "selected-languages"}
                                name={item.name}
                                onChange={() =>
                                  onclickLanguages(
                                    item.id + "selected-languages",
                                    item.id
                                  )
                                }
                                className="cursor-pointer"
                              />
                              <label htmlFor={item.name} />
                              {item.title}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </LoadingOverlay>
          </div>
        </div>
      </div>

      <Modal
        show={popUpModal}
        centered
        size="md"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="modal-header add-license-modal">
            <h4 className="mb-2 mt-3 me-2" style={{ color: "#34636d" }}>
              {menuPopup == 1
                ? "Delete content pack"
                : popupHeadingPublicOrCopy()}
            </h4>
            <img
              src="/assets/icons/Cross.png"
              onClick={() => setPopUpModal(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <p>
            {menuPopup == 1
              ? "Are you sure you want to delete the content pack?"
              : popupDescriptionPublicOrCopy()}
          </p>

          <div>
            <Col lg={6}>
              <div className="h-100 align-items-start d-flex justify-content-end create-content-buttons">
                <button
                  className="save d-inline px-4 me-4"
                  onClick={() => setPopUpModal(false)}
                >
                  cancel
                </button>
                <button
                  className="save-and-edit d-inline px-4"
                  onClick={
                    menuPopup == 1 ? deletePackConfirm : popupButtonPublicOrCopy
                  }
                >
                  {menuPopup == 1 ? "Delete" : popupButtonTextPublicOrCopy()}
                </button>
              </div>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContentDetail;
