import React, { Component } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { requestAcceptDecline } from "../../../../services/ContractorServices";
import { getLoginStorage } from "../../../../utils/UserDataManagement";
import toast from "react-hot-toast";
import { getKeyTranslation } from "../../../../utils/Translations";
class DeclineUser extends Component {
  state = { declineUser: false };

  approveRequest = async () => {
    const { declineData } = this.props;
    let requestPayload = {
      requestId: declineData?.id,
      contractorId: getLoginStorage("contractorId"),
      status: "decline",
      userId: declineData?.userId,
    };
    console.log(requestPayload);
    let requestApproved = await requestAcceptDecline(requestPayload);
    if (requestApproved.status === 200) {
      this.setState({
        declineUser: false,
      });
      toast.success(getKeyTranslation(requestApproved.data.message));
      this.props.list();
    } else {
      toast.error(getKeyTranslation(requestApproved.data.message));
    }
  };
  render() {
    const { declineUser } = this.state;
    const { declineData } = this.props;
    return (
      <>
        <button
          onClick={() =>
            this.setState({
              declineUser: true,
            })
          }
        >
          decline
        </button>
        <Modal
          show={declineUser}
          centered
          size="lg"
          className="edit-add modal-screen"
        >
          <div className="edit-add-modal accept-user-modal">
            <div className="modal-header">
              <h1>Decline user</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ declineUser: false });
                }}
              ></button>
            </div>
            <Row>
              <Col lg={12}>
                <label className="mt-4 d-block">
                  You are denying {declineData?.firstName}’s request to re-join
                  StaySafe.{" "}
                </label>

                <label className="mt-3 mb-5">
                  They may request access again in the future.{" "}
                </label>
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <Row>
              <col-12>
                <div class="modal-footer p-0">
                  <div className="double-buttons d-flex justify-content-end float-end flex-column">
                    <div className="d-flex float-end add-edit-buttons">
                      <button
                        onClick={() => {
                          this.setState({ declineUser: false });
                        }}
                      >
                        cancel
                      </button>
                      <button onClick={this.approveRequest}>confirm</button>
                    </div>
                  </div>
                </div>
              </col-12>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}
export default DeclineUser;
