import React, { Component } from "react";
import { Modal, Table } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "./membersList.css";
import "react-multi-carousel/lib/styles.css";
import { deleteEndUser, deleteUser } from "../../../services/CommonServices";
import {
  fetchClientContractorDetail,
  getRoleList,
  updateRole,
} from "../../../services/ClientServices";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import SimpleBar from "simplebar-react";
import dummyUser from "../../../../assets/icons/brandHolder.jpg";
import { BASE_URL_IMAGE } from "../../../constant/ApiUrls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";

import {
  faPeopleArrows,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getLoginStorage } from "../../../utils/UserDataManagement";
import { getKeyTranslation } from "../../../utils/Translations";
import WarningPopUp from "../users/WarningPopup";
import ChangeEndUserRole from "../users/ChangeEndUserRole";

class MembersList extends Component {
  state = {
    showCardDetails: false,
    showMemberDetails: false,
    contractorAdminUsers: [],
    contractorEndUsers: [],
    detailLoder: false,
    selectedUser: {},
    showConfirmModal: false,
    showConfirmModal: false,
  };
  handleClose = () => {
    this.setState({ showConfirmModal: false });
  };
  handleShow = () => {
    this.setState({ showConfirmModal: true });
  };

  modalOpen = async () => {
    this.setState({
      showMemberDetails: true,
    });

    let companyId = this.props?.companyId;
    let detail = await fetchClientContractorDetail(companyId);
    if (detail?.status === 200) {
      this.setState({
        contractorAdminUsers: detail?.data?.data.contractorAdminUsers,

        contractorEndUsers: detail?.data?.data.contractorEndUsers,
        showCardDetails: true,
      });
      console.log(
        detail?.data?.data.contractorAdminUsers.find(
          (item) => item.isMainAdmin == true
        ).id,
        "kkkkkkkkkk"
      );
    } else {
      this.setState({
        contractorAdminUsers: [],
        contractorEndUsers: [],
        showCardDetails: false,
      });
      toast.error("Error while fetching data!");
    }
    console.log("admin user", this.state.contractorAdminUsers);
    console.log("end user", this.state.contractorEndUsers);
  };
  deleteUser = async ({ id, type }) => {
    let currentUser = this.state.selectedUser;

    if (currentUser.role == "End-User") {
      await deleteEndUser(id, type);
    } else {
      await deleteUser(id, type);
    }
    return this.modalOpen();
  };

  callback = () => {
    this.props.refresh();
    return this.modalOpen();
  };
  toasterMessage = (type, msg) => {
    if (type == "hold") {
      toast.error(msg);
    } else if (type == "error") {
      toast.error(msg);
    } else {
      toast.success(msg);
    }
  };

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />

        <div>
          <p
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              this.modalOpen();
            }}
          >
            {this.props.noOfLicensesUsed}
          </p>
        </div>
        <Modal centered size="md" show={this.state.showConfirmModal}>
          <div className="bg-white change-delete-modals">
            <div className="modal-header">
              <h1>Delete {this.state.selectedUser.role}</h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => this.handleClose()}
              ></button>
            </div>
            <label className="mt-3">
              Are you sure you want to delete
              <b> {this.state.selectedUser.companyName}</b>?
            </label>
            <div className="screen-buttons float-end">
              <button onClick={() => this.handleClose()}>cancel</button>
              <button
                onClick={() => {
                  this.deleteUser(this.state.selectedUser);
                  this.handleClose();
                }}
              >
                delete
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showMemberDetails}
          centered
          size="xl"
          contentClassName="members-list"
        >
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              this.setState({
                showMemberDetails: false,
                showCardDetails: false,
                contractorAdminUsers: [],
                contractorEndUsers: [],
              });
            }}
          />

          <h3 style={{ marginTop: "2rem" }}>Contractor Users</h3>
          <Carousel itemClass="my-item" responsive={responsive}>
            {this.state.contractorAdminUsers.map((data, index) => (
              <div className="member-card contractor-card">
                <div className="d-flex justify-content-end">
                  <FontAwesomeIcon
                    className={`ms-0 ${
                      data.isMainAdmin === true ? "invisible" : ""
                    } `}
                    icon={faTrash}
                    onClick={(e) => {
                      let selectedUser = {
                        id: data.id,
                        type: 3,
                        companyName: data.name,
                        role: "Contractor",
                      };
                      this.setState({ selectedUser });

                      this.handleShow();
                    }}
                  />
                  &nbsp;
                  {getLoginStorage("isMainAdmin") && !data?.isMainAdmin && (
                    <ChangeEndUserRole
                      isHold={this.props.isHold}
                      id={data?.id}
                      email={data.email}
                      clientId={getLoginStorage("companyId")}
                      contractorId={this.props.companyId}
                      callBack={this.callback}
                      currentRole={3}
                      toasterCalling={this.toasterMessage}
                    />
                  )}
                </div>
                <img
                  src={
                    data?.picture ? BASE_URL_IMAGE + data?.picture : dummyUser
                  }
                  width="140px"
                  height="100px"
                  alt="user"
                  className="mt-3"
                />
                <h3 className="mb-0 mt-3" title={data?.name}>
                  {data.name ? data.name.slice(0, 13) : "Contractor User"}
                </h3>
                <p className="mb-0 mt-2">{data.email}</p>
                {/* <div className="d-flex justify-content-between mt-4">
                  <p className="label-text mb-0">
                    <b>Total Licenses:</b>
                  </p>
                  <p className="mb-0">{data.totalLicenses}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p className="label-text mb-0">
                    <b>Code:</b>
                  </p>
                  <p className="mb-0">{data.code}</p>
                </div> */}
              </div>
            ))}
          </Carousel>

          <div
            className="contractor-card-details"
            style={{
              display: this.state.showCardDetails ? "block" : "none",
              cursor: "pointer",
            }}
          >
            <LoadingOverlay
              spinner={<HashLoader color="#5ba6b7" size={90} />}
              active={this.state.detailLoder}
            >
              <SimpleBar style={{ maxHeight: 250 }}>
                <h5 className="mb-3 mt-2">Contractor End Users</h5>
                <Table bordered size="sm">
                  <thead>
                    <tr>
                      <th className="table-td">Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.contractorEndUsers?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name ? item.name : "-"}</td>
                        <td>
                          <b>{item.email}</b>
                        </td>
                        <td className="delete-icon-td">
                          {item.isMainAdmin === false && (
                            <>
                              <FontAwesomeIcon
                                className="ps-10 "
                                icon={faTrash}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let selectedUser = {
                                    id: item.id,
                                    type: 3,
                                    companyName: item.name,
                                    role: "End-User",
                                  };
                                  this.setState({ selectedUser });

                                  this.handleShow();
                                }}
                              />
                              &nbsp;&nbsp;
                              {getLoginStorage("isMainAdmin") && (
                                <ChangeEndUserRole
                                  isHold={this.props.isHold}
                                  id={item?.id}
                                  email={item.email}
                                  clientId={getLoginStorage("companyId")}
                                  contractorId={this.props.companyId}
                                  callBack={this.callback}
                                  currentRole={4}
                                  toasterCalling={this.toasterMessage}
                                />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </SimpleBar>
            </LoadingOverlay>
          </div>
        </Modal>
      </>
    );
  }
}
export default MembersList;
