import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import ReactSwitch from "../../../../common/ReactSwitch";
import {
  getReaminingLicenses,
  requestAcceptDecline,
} from "../../../../services/ContractorServices";
import { getLoginStorage } from "../../../../utils/UserDataManagement";
import toast from "react-hot-toast";
import { getKeyTranslation } from "../../../../utils/Translations";
class AcceptUser extends Component {
  state = {
    acceptUser: false,
    ArrayOfIds: [],
    disableAcceptReq: false,
  };
  changeSelect = async (id) => {
    const { companyListCheckBox, ArrayOfIds } = this.state;
    let array = ArrayOfIds;
    if (array.includes(id)) {
      let arr = array.filter((i) => i !== id);
      this.setState({
        ArrayOfIds: arr,
      });
      companyListCheckBox.map((item, index) => {
        if (id === item.clientAgreementId) {
          item.status = false;
        }
      });
    } else {
      this.setState({
        ArrayOfIds: [...ArrayOfIds, id],
      });
      companyListCheckBox.map((item, index) => {
        if (id === item.clientAgreementId) {
          item.status = true;
        }
      });
    }

    this.setState({
      companyListCheckBox: companyListCheckBox,
    });
    console.log(ArrayOfIds);
  };
  approveRequest = async () => {
    const { acceptData } = this.props;
    const { ArrayOfIds } = this.state;
    let requestPayload = {
      requestId: acceptData?.id,
      contractorId: getLoginStorage("contractorId"),
      status: "accept",
      userId: acceptData?.userId,
      clientAgreementIds: ArrayOfIds,
    };
    this.setState({
      disableAcceptReq: true,
    });
    let requestApproved = await requestAcceptDecline(requestPayload);
    if (requestApproved?.status === 200) {
      this.setState({
        acceptUser: false,
        disableAcceptReq: false,
      });
      toast.success(getKeyTranslation(requestApproved.data.message));
      this.props.list();
    } else {
      toast.error(getKeyTranslation(requestApproved.data.message));
    }
  };
  AcceptionUser = async () => {
    let detail = await getReaminingLicenses();
    if (detail?.status === 200) {
      this.setState({
        companyListCheckBox: detail?.data.data,
        acceptUser: true,
      });
    } else {
      toast.error("Error while fetching data!");
    }
  };
  render() {
    const { acceptUser, companyListCheckBox, ArrayOfIds, disableAcceptReq } =
      this.state;
    const { acceptData } = this.props;
    return (
      <>
        <button onClick={this.AcceptionUser}>accept</button>
        <Modal
          show={acceptUser}
          centered
          size="lg"
          className="edit-add modal-screen"
        >
          <div className="edit-add-modal accept-user-modal">
            <div className="modal-header">
              <h1>Accept User</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ acceptUser: false });
                }}
              ></button>
            </div>
            <Row>
              <Col lg={9}>
                <h5 className="mt-5">
                  Adding {acceptData?.firstName}'s back into StaySafe.{" "}
                </h5>
                {companyListCheckBox?.map((item, index) => {
                  return (
                    <div className="mt-3 w-75" style={{ minHeight: "120px" }}>
                      <div className="d-flex justify-content-between">
                        <label>
                          They will use 1 license from {item.companyName}
                        </label>

                        <div style={{ marginTop: "auto" }}>
                          <ReactSwitch
                            value={item.status}
                            handleChange={() =>
                              this.changeSelect(item.clientAgreementId)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <h6 style={{ color: "#dc3545", fontSize: "12px" }}>
                  User needs a least 1 license to re-join StaySafe
                </h6>
              </Col>
              <Col lg={6}>
                <div class="modal-footer p-0">
                  <div className="double-buttons d-flex justify-content-end float-end flex-column">
                    <div className="d-flex float-end add-edit-buttons">
                      <button
                        onClick={() => {
                          this.setState({
                            acceptUser: false,
                            ArrayOfIds: [],
                            disableAcceptReq: false,
                          });
                        }}
                      >
                        cancel
                      </button>
                      <button
                        style={
                          ArrayOfIds?.length === 0 || disableAcceptReq
                            ? { opacity: "0.5" }
                            : {}
                        }
                        disabled={ArrayOfIds?.length === 0 || disableAcceptReq}
                        onClick={this.approveRequest}
                      >
                        confirm
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}
export default AcceptUser;
