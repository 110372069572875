import React, { Component } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import Invoices from "./Invoices/Invoices";
import {
  fetchFinanceList,
  searchFinences,
} from "../../../services/MasterServices";
import toast, { Toaster } from "react-hot-toast";
import { Switch, withRouter } from "react-router-dom";
import sortIcon from "../../../../assets/icons/sort-icon.png";
import SimpleBar from "simplebar-react";
import "./Finances.css";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import AuthGuard from "../../../routes/AuthGuard";
const PATH_NAME = "/master-finances";
class Finances extends Component {
  state = {
    financeList: [],
    screenLoader: true,
    colSize: this.props.history.location.pathname === PATH_NAME ? 12 : 6,
    searchKey: "",
  };

  componentDidMount = async () => {
    await this.fetchFinance();
  };
  fetchFinance = async () => {
    let financeList = await fetchFinanceList();
    if (financeList.status === 200) {
      this.setState({
        financeList: financeList.data.data,
        screenLoader: false,
      });
    } else {
      toast.error("Error while fetching data!");
    }
  };
  resetSearch = async () => {
    this.setState({
      searchTrue: false,
      searchKey: "",
    });
    await this.fetchFinance();
  };
  getDetail = (id) => {
    this.setState({
      colSize: 6,
    });
    this.props.history.push(`${PATH_NAME}/${id}`);
  };
  closeDetail = () => {
    this.setState({
      colSize: 12,
    });
    this.props.history.push(PATH_NAME);
  };
  searchInput = async (e) => {
    this.setState({
      searchKey: e.target.value,
    });
    let searchUrl = `?SearchBy=${e.target.value}&PageNumber=1&PageSize=10&OrderType=&OrderBy=`;
    let searchFinencesResult = await searchFinences(searchUrl);
    if (searchFinencesResult.status === 200) {
      this.setState({
        financeList: searchFinencesResult.data.data,
        searchTrue: e.target.value.length === 0 ? false : true,
      });
    } else {
      toast.error("Error while searching data!");
    }
  };
  render() {
    const { financeList, screenLoader, searchKey, searchTrue } = this.state;
    const { history } = this.props;

    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />
        <div className="finance-section d-flex justify-content-between sidebar-for-mobile">
          <Container className="my-container px-5">
            <div className="myDiv">
              <h1>Finances</h1>
              <Row>
                <Col lg={3}>
                  <div className="search-input">
                    <input
                      onFocus={this.closeDetail}
                      type="text"
                      placeholder="Company name, person etc."
                      onChange={this.searchInput}
                      value={searchKey}
                    />
                    <FontAwesomeIcon
                      onClick={this.resetSearch}
                      icon={searchTrue ? faTimes : faSearch}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col lg={history.location.pathname === PATH_NAME ? 12 : 6}>
                  <LoadingOverlay
                    className="loader_overlay "
                    spinner={<HashLoader color="#5ba6b7" size={90} />}
                    active={screenLoader}
                  >
                    <div className="company-list-table">
                      <SimpleBar style={{ maxHeight: 700 }}>
                        <Table borderless className="sticky-table">
                          <thead>
                            <tr>
                              <th>
                                <img
                                  src={sortIcon}
                                  className="img-fluid"
                                  alt=""
                                />
                                <label>Company name</label>
                              </th>
                              <th>
                                <img
                                  src={sortIcon}
                                  className="img-fluid"
                                  alt=""
                                />
                                <label>Date</label>
                              </th>
                              <th>
                                <img
                                  src={sortIcon}
                                  className="img-fluid"
                                  alt=""
                                />
                                <label>Amount</label>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {financeList?.map((item, index) => (
                              <tr
                                onClick={() => this.getDetail(item.financeId)}
                                key={index}
                              >
                                <td className="company-name">
                                  {item.companyName}
                                </td>
                                <td>{item.date}</td>
                                <td>{item.amount} €</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </SimpleBar>
                    </div>
                  </LoadingOverlay>
                </Col>
                <Col lg={6}>
                  <Switch>
                    <AuthGuard
                      path="/master-finances/:id"
                      component={(props) => <Invoices {...props} />}
                    />
                  </Switch>

                  {/* <Invoices /> */}
                </Col>
              </Row>
            </div>
          </Container>
          {/* <SideBar linkName='finances' /> */}
        </div>
      </>
    );
  }
}

export default withRouter(Finances);
