import { deleteMethod, getMethod, postMethod } from "./NetworkManager";
import {
  GET_CONTRACT_COMPANY_LIST,
  END_USER,
  CONTRACTOR_REQUEST,
  REMOVE_END_USER,
  REQUEST_APPROVAL,
  GET_USER_REMAINING_LICENSES,
  GET_CONTRACTOR_RANKING,
  DELETE_END_USER,
} from "../constant/ApiUrls";
import { getLocalStorage, getLoginStorage } from "../utils/UserDataManagement";
export const fetchContractorCompanyList = async (params) => {
  let { SearchBy, PageNumber, PageSize, OrderType, OrderBy } = params;

  let contractorListing = `${GET_CONTRACT_COMPANY_LIST}${getLoginStorage(
    "contractorId"
  )}/companies?SearchBy=${SearchBy}&PageNumber=${+PageNumber}&PageSize=${+PageSize}&OrderType=${OrderType}&OrderBy=${OrderBy}`;

  return getMethod(contractorListing);
};

export const fetchContractorRanking = async (params) => {
  let obj = getLocalStorage("staySafeUserData");
  return getMethod(
    GET_CONTRACTOR_RANKING +
      `${obj.contractorId}/company/${obj.companyId}/ranking` +
      params
  );
};

export const fetchContractorCompanyDetail = async (id) => {
  let contractorListing = `${GET_CONTRACT_COMPANY_LIST}${getLoginStorage(
    "contractorId"
  )}/companies`;
  return getMethod(contractorListing + "/" + id);
};

export const fetchContractorEndUsers = async () => {
  let id = getLoginStorage("contractorId");
  return getMethod(`${END_USER}/${id}`);
};

export const fetchContractorRequests = async () => {
  return getMethod(CONTRACTOR_REQUEST);
};

export const revokeEndUser = async (id) => {
  return getMethod(`${REMOVE_END_USER}${id}`);
};
export const deleteEndUser = async (id) => {
  return deleteMethod(`${DELETE_END_USER}${id}`);
};
export const requestAcceptDecline = async (requestPayload) => {
  return postMethod(REQUEST_APPROVAL, requestPayload);
};

export const getReaminingLicenses = async () => {
  let id = getLoginStorage("contractorId");
  return getMethod(`${GET_USER_REMAINING_LICENSES}${id}`);
};
