export const setLoginStorage = (data) => {
  localStorage.setItem("staySafeUserData", JSON.stringify(data));
};

export const setTranslationStorage = (data) => {
  localStorage.setItem("translationsData", JSON.stringify(data));
};

export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const getLoginStorage = (key) => {
  let userData = JSON.parse(localStorage.getItem("staySafeUserData"));
  return userData && userData[key];
};
