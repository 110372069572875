import {
  getMethod,
  postMethod,
  deleteMethod,
  putMethod,
  content_type,
  auth_token,
  api_version,
  deleteMethod2,
} from "./NetworkManager";
import {
  GET_CLIENT_CONTRACTOR_COMPANY,
  ADD_CLIENT_CONTRACTOR_COMPANY,
  GET_CATEGORIES,
  FINANCE_DETAIL,
  DELETE_CLEINT_CONTRACTOR,
  GET_CLIENT_RANKING,
  GET_client_CONTRACTOR_Detail,
  GET_CLIENT_SECONDARY_USERS,
  BASE_URL,
  LANGUAGE_CMS,
  CONTENT_PACK_LIST_CMS,
  MARK_FAV_CONTENT_PACK_CMS,
  PRIMARY_CLIENT_CREATOR_CMS,
  CREATE_CONTENT_PACK,
  DELETE_CONTENT_PACK,
  CATEGORY_CONTENT_PACK,
  EDIT_CONTENT_PACK,
  UPDATE_VISIBILITY_CONTENT_PACK,
  CATEGORY_CONTRACTORS,
  ASSIGN_CONTRACTOR_TO_CATEGORY,
  COPY_CONTENT_PACK,
  GET_CATEGORY_QUESTION,
  CONTENT_PACK_SETTING,
  UPLOAD_QUESTION_IMAGE,
  ADD_QUESTIONS,
  DELETE_QUESTION,
  EXPORT_QUESTIONS,
  IMPORT_QUESTIONS,
  EXPORT_EMPTY_TEMPLATE,
  GET_USER_KPI,
  USER_KPI_EXPORT,
  CONTENT_PACK_REPORTING_LIST,
  CONTENT_PACK_REPORTING_QUESTIONS,
  CONTENT_PACK_REPORTING_REMOVE,
  SECONDARY_CONTRACTOR,
  ROLE_LIST,
  CHANGE_ROLE,
} from "../constant/ApiUrls";
import { getLocalStorage, getLoginStorage } from "../utils/UserDataManagement";
import axios from "axios";
export const fetchClientContractorCompany = async (
  PageNumber = 1,
  PageSize = 20
) => {
  let clientIdUrl = `${GET_CLIENT_CONTRACTOR_COMPANY}${getLoginStorage(
    "clientId"
  )}/contractors?PageNumber=${PageNumber}&PageSize=${PageSize}`;
  return getMethod(clientIdUrl);
};

export const fetchClientContractorCompanyDetail = async (id) => {
  let clientIdUrl = `${GET_CLIENT_CONTRACTOR_COMPANY}${getLoginStorage(
    "clientId"
  )}/contractors`;
  return getMethod(clientIdUrl + "/" + id);
};

export const fetchClientRanking = async (params) => {
  let obj = getLocalStorage("staySafeUserData");
  return getMethod(GET_CLIENT_RANKING + `${obj.clientId}/rankings` + params);
};
export const fetchClientContractorDetail = async (id) => {
  let url_contractor_detail = GET_client_CONTRACTOR_Detail(id);
  console.log(url_contractor_detail);
  return getMethod(url_contractor_detail);
};

export const addNewContractorClientCompany = async (obj) => {
  return postMethod(ADD_CLIENT_CONTRACTOR_COMPANY, obj);
};
export const addSecondaryContractor = async (obj) => {
  return postMethod(SECONDARY_CONTRACTOR, obj);
};

export const getCategories = async () => {
  return getMethod(GET_CATEGORIES);
};
export const getRoleList = async () => {
  return getMethod(ROLE_LIST);
};
export const fetchFinanceDetail = async () => {
  let id = getLoginStorage("clientId");
  return getMethod(`${FINANCE_DETAIL}/${id}`);
};

export const searchAndSortClient = async (searchURL) => {
  let clientIdUrl = `${GET_CLIENT_CONTRACTOR_COMPANY}${getLoginStorage(
    "clientId"
  )}/contractors`;
  return getMethod(`${clientIdUrl}${searchURL}`);
};
export const getAllClient = async () => {
  let clientIdUrl = `${GET_CLIENT_CONTRACTOR_COMPANY}${getLoginStorage(
    "clientId"
  )}/contractors`;
  return getMethod(`${clientIdUrl}?isAll=true`);
};

export const clientSecondaryUsers = async () => {
  let url_secondary_client = `${GET_CLIENT_SECONDARY_USERS}${getLoginStorage(
    "clientId"
  )}/secondaryusers`;
  return getMethod(url_secondary_client);
};

export const deleteClientCompany = async (id) => {
  let clientIdUrl = `${DELETE_CLEINT_CONTRACTOR}${id}/client/${getLoginStorage(
    "clientId"
  )}`;
  return deleteMethod(clientIdUrl);
};

export const updateContractorDetails = async (id, obj) => {
  return putMethod(`${ADD_CLIENT_CONTRACTOR_COMPANY}/${id}`, obj);
};
export const markFavourite = async (obj) => {
  return putMethod(`${MARK_FAV_CONTENT_PACK_CMS}`, obj);
};
export const creatorsClients = async () => {
  return getMethod(`${PRIMARY_CLIENT_CREATOR_CMS}?isAll=true`);
};
export const editContentPack = async (obj) => {
  return putMethod(`${EDIT_CONTENT_PACK}`, obj);
};
export const updateVisibilityContentPack = async (obj) => {
  return putMethod(`${UPDATE_VISIBILITY_CONTENT_PACK}`, obj);
};
export const getCategoryContentPack = async (id) => {
  return getMethod(
    `${CATEGORY_CONTENT_PACK}/contentPackDetail?CategoryId=${id}`
  );
};
export const categoryContractors = async (params) => {
  return getMethod(`${CATEGORY_CONTRACTORS}${params}`);
};
export const contentPackSetting = async () => {
  return getMethod(`${CONTENT_PACK_SETTING}`);
};
export const getCategoryQuestions = async (params) => {
  return getMethod(`${GET_CATEGORY_QUESTION}${params}&OrderBy=CreatedAtASC`);
};

export const exportQuestions = async (params, c_name) => {
  let url = `${EXPORT_QUESTIONS}${params}`;
  try {
    return await axios
      .get(BASE_URL + url, {
        responseType: "blob",
        headers: {
          "Content-Type": content_type(),
          Authorization: auth_token(),
          "x-api-version": api_version(),
        },
      })
      .then(async (response) => {
        if (response.status == 200) {
          let data = { status: 200 };
          const url_contractor = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url_contractor;
          link.setAttribute("download", `${c_name}` + ".xlsx");
          document.body.appendChild(link);
          link.click();
          return data;
        } else {
          return JSON.parse(await response.data.text());
        }
      });
  } catch (error) {
    return error.response;
  }
};
export const exportEmptyTemplate = async (c_name) => {
  let url = `${EXPORT_EMPTY_TEMPLATE}`;
  try {
    return await axios
      .get(BASE_URL + url, {
        responseType: "blob",
        headers: {
          "Content-Type": content_type(),
          Authorization: auth_token(),
          "x-api-version": api_version(),
        },
      })
      .then(async (response) => {
        if (response.status == 200) {
          let data = { status: 200 };
          const url_contractor = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url_contractor;
          link.setAttribute("download", `${c_name}` + ".xlsx");
          document.body.appendChild(link);
          link.click();
          return data;
        } else {
          return JSON.parse(await response.data.text());
        }
      });
  } catch (error) {
    return error.response;
  }
};

export const deleteContentPack = async (obj) => {
  return deleteMethod2(`${DELETE_CONTENT_PACK}`, obj);
};

export const deleteQuestion = async (obj) => {
  return deleteMethod2(`${DELETE_QUESTION}`, obj);
};
export const deleteReportedQuestion = async (obj) => {
  return deleteMethod2(`${CONTENT_PACK_REPORTING_REMOVE}`, obj);
};

export const exportClientListContractors = async (params) => {
  let { PageNumber, PageSize, SearchBy, OrderBy, OrderType } = params;
  let url = `/v1/contractor/${getLoginStorage(
    "clientId"
  )}/companies/export?SearchBy=${SearchBy}&PageNumber=${PageNumber}&PageSize=${PageSize}&OrderBy=${OrderBy}&OrderType=${OrderType}`;

  try {
    return await axios
      .get(BASE_URL + url, {
        responseType: "blob",
        headers: {
          "Content-Type": content_type(),
          Authorization: auth_token(),
          "x-api-version": api_version(),
        },
      })
      .then((response) => {
        let data = { status: 200 };
        const url_contractor = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url_contractor;
        link.setAttribute("download", "contractor" + ".xlsx");
        document.body.appendChild(link);
        link.click();
        return data;
      });
  } catch (error) {
    return error.response;
  }
};
export const exportUserKPI = async (params) => {
  let url = `${USER_KPI_EXPORT}${params}`;
  try {
    return await axios
      .get(BASE_URL + url, {
        responseType: "blob",
        headers: {
          "Content-Type": content_type(),
          Authorization: auth_token(),
          "x-api-version": api_version(),
        },
      })
      .then((response) => {
        let data = { status: 200 };
        const url_kpi = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url_kpi;
        link.setAttribute("download", "user-kpi" + ".xlsx");
        document.body.appendChild(link);
        link.click();
        return data;
      });
  } catch (error) {
    return error.response;
  }
};

export const getClientDetails = async () => {
  let url_client_detail = `/v1/client/${getLoginStorage(
    "clientId"
  )}/getClientLicenseInfo`;

  return getMethod(url_client_detail);
};

export const getLanguageCms = async () => {
  return getMethod(LANGUAGE_CMS);
};
export const getContentPack = async (searchURL) => {
  return getMethod(`${CONTENT_PACK_LIST_CMS}${searchURL}`);
};

export const createContentPack = async (obj) => {
  return postMethod(`${CREATE_CONTENT_PACK}`, obj);
};
export const copyContentPack = async (obj) => {
  return postMethod(`${COPY_CONTENT_PACK}`, obj);
};
export const assignContractorToCategory = async (obj) => {
  return postMethod(`${ASSIGN_CONTRACTOR_TO_CATEGORY}`, obj);
};
export const uploadQuestionImage = async (obj) => {
  return postMethod(`${UPLOAD_QUESTION_IMAGE}`, obj);
};
export const importQuestion = async (obj) => {
  return postMethod(`${IMPORT_QUESTIONS}`, obj);
};
export const updateRole = async (obj) => {
  return postMethod(`${CHANGE_ROLE}`, obj);
};
export const addQuestions = async (obj) => {
  return putMethod(`${ADD_QUESTIONS}`, obj);
};
export const fetchUserKPI = async (params) => {
  return getMethod(GET_USER_KPI + params);
};
export const getContentPackReporting = async (searchURL) => {
  return getMethod(`${CONTENT_PACK_REPORTING_LIST}${searchURL}`);
};
export const getContentPackReportingQuestions = async (searchURL) => {
  return getMethod(`${CONTENT_PACK_REPORTING_QUESTIONS}${searchURL}`);
};
export const fetchReportingContentPactList = async (params) => {
  return getMethod(GET_USER_KPI + params);
};
