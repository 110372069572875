import React, { Component } from "react";
import { Container, ListGroup, Row, Col } from "react-bootstrap";
import { Link, Switch, withRouter } from "react-router-dom";
import AuthGuard from "../../../routes/AuthGuard";
import EditAccountInfo from "./editDetails/EditAccountInfo";
import EditBillingInfo from "./editDetails/EditBillingInfo";
import EditPassword from "./editDetails/EditPassword";
import EditSupport from "./editDetails/EditSuppport";
import "./Setting.css";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
class ClientSettings extends Component {
  state = {
    screenLoader: true,
  };
  componentDidMount() {
    setTimeout(
      this.setState({
        screenLoader: false,
      }),
      3000
    );
  }
  render() {
    const { screenLoader } = this.state;
    return (
      <>
        <div className="settings-section d-flex justify-content-between sidebar-for-mobile">
          <Container className="my-container px-5">
            <div className="myDiv">
              <h1>Settings</h1>
              <LoadingOverlay
                className="loader_overlay"
                spinner={<HashLoader color="#5ba6b7" size={90} />}
                active={screenLoader}
              >
                <Row>
                  <Col lg={4}>
                    <div className="setting-tabs">
                      <ListGroup as="ul">
                        <ListGroup.Item
                          as="li"
                          className={`${
                            this.props.match.params.id === "edit-info" &&
                            "active-tab"
                          }`}
                        >
                          <Link to="edit-info">Edit Account Info</Link>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className={`${
                            this.props.match.params.id === "edit-billing" &&
                            "active-tab"
                          }`}
                        >
                          <Link to="edit-billing">Edit Billing Info</Link>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className={`${
                            this.props.match.params.id === "edit-password" &&
                            "active-tab"
                          }`}
                          onClick={() =>
                            this.setState({
                              activeTab: "password",
                            })
                          }
                        >
                          <Link to="edit-password">Change Password</Link>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className={`${
                            this.props.match.params.id === "support" &&
                            "active-tab"
                          }`}
                        >
                          <Link to="support">Support</Link>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>

                  <Col lg={8}>
                    <Switch>
                      <AuthGuard
                        path="/client-settings/edit-info"
                        component={EditAccountInfo}
                      />
                      <AuthGuard
                        path="/client-settings/edit-billing"
                        component={EditBillingInfo}
                      />
                      <AuthGuard
                        path="/client-settings/edit-password"
                        component={EditPassword}
                      />
                      <AuthGuard
                        path="/client-settings/support"
                        component={EditSupport}
                      />
                    </Switch>
                  </Col>
                </Row>
              </LoadingOverlay>
            </div>{" "}
          </Container>
          {/* <SideBar linkName='settings' /> */}
        </div>
      </>
    );
  }
}

export default withRouter(ClientSettings);
