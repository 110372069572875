import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import "./OverviewCard.css";
import star from "../../../../../assets/icons/star.png";
import star_filled from "../../../../../assets/icons/star_colored.png";
import three_dots from "../../../../../assets/icons/three_dots.png";
import AssignContentToContractor from "./assignContentToContractor/AssignContentToContractor";
import CreateContent from "../createContent/CreateContent";
import { BASE_URL_IMAGE } from "../../../../constant/ApiUrls";

const OverviewCard = ({
  access_specifier,
  listing,
  markedFavourite,
  deleteApi,
  languages,
  recallListApi,
  updateVisibility,
  copyCategory,
  callingToaster,
  retainFilters,
}) => {
  const [starToggleIndex, setStarToggleIndex] = useState([]);
  const [popUpModal, setPopUpModal] = useState(false);
  const [deletedID, setDeletedID] = useState("");
  const [categoryIdToMove, setCategoryIdToMove] = useState(Number);
  const [categoryIdToCopy, setCategoryIdToCopy] = useState(Number);
  const [menuPopup, setMenuPopup] = useState(Number);

  const accessSpecifier = access_specifier;

  const toggleFav = (id, fav_id) => {
    let index = starToggleIndex.indexOf(id);
    if (index === -1) {
      markedFavourite(fav_id);
      setStarToggleIndex([...starToggleIndex, id]);
    } else {
      markedFavourite(fav_id);
      setStarToggleIndex(starToggleIndex.filter((ind) => ind !== id));
    }
  };
  const deleteContent = (pack_id, menu_id) => {
    setMenuPopup(menu_id);
    setPopUpModal(true);
    setDeletedID(pack_id);
  };
  const deletePackConfirm = () => {
    setPopUpModal(false);
    deleteApi(deletedID);
  };
  const moveToPublic = (id, menu_id) => {
    setMenuPopup(menu_id);
    setPopUpModal(true);
    setCategoryIdToMove(id);
  };
  const moveToPublicConfirm = () => {
    setPopUpModal(false);
    updateVisibility(categoryIdToMove);
  };
  const copyContent = (id, menu_id) => {
    setMenuPopup(menu_id);
    setPopUpModal(true);
    setCategoryIdToCopy(id);
  };
  const copyContentConfirm = () => {
    setPopUpModal(false);
    copyCategory(categoryIdToCopy);
  };
  const showDetail = (item_detail) => {
    retainFilters();
    localStorage.setItem("contentDetail", JSON.stringify(item_detail));
    window.location = "/content-detail";
  };
  const show_menu = (value, item) => {
    return value === "private" ? (
      <>
        <Dropdown.Item eventKey="4">
          {languages.length > 0 && (
            <CreateContent
              callingToaster={callingToaster}
              edit="edit-mode"
              languages={languages}
              recallListApi={recallListApi}
              categoryId={item?.id}
            />
          )}
        </Dropdown.Item>
        <Dropdown.Divider />

        <Dropdown.Item eventKey="3" onClick={() => copyContent(item?.id, 3)}>
          Make a copy to my private library
        </Dropdown.Item>
        <Dropdown.Divider />
        {JSON.parse(localStorage.getItem("staySafeUserData")).role !== "KA" && (
          <>
            <Dropdown.Item
              eventKey="3"
              onClick={() => moveToPublic(item?.id, 2)}
            >
              Move to public
            </Dropdown.Item>
            <Dropdown.Divider />

            <Dropdown.Item eventKey="4">
              <AssignContentToContractor
                categoryId={item?.id}
                detail=""
                callingToaster={callingToaster}
              />
            </Dropdown.Item>
            <Dropdown.Divider />
          </>
        )}
        <Dropdown.Item
          eventKey="3"
          className="delete"
          style={{ color: "red" }}
          onClick={() => deleteContent(item?.id, 1)}
        >
          Delete
        </Dropdown.Item>
      </>
    ) : (
      <>
        <Dropdown.Item eventKey="3" onClick={() => copyContent(item?.id, 3)}>
          Make a copy to my private library
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="4">
          <AssignContentToContractor
            categoryId={item?.id}
            detail=""
            callingToaster={callingToaster}
          />
        </Dropdown.Item>
        {item?.isSameClient && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item
              eventKey="3"
              className="delete"
              style={{ color: "red" }}
              onClick={() => deleteContent(item?.id, 1)}
            >
              Delete
            </Dropdown.Item>
          </>
        )}
      </>
    );
  };
  const favourite_public = () => {
    let publicFav = listing?.map((item, ind) => {
      if (item?.contentPackVisibility.toLowerCase() === "public") {
        return (
          <React.Fragment key={ind}>
            <Card className="overview-card">
              <Row style={{ cursor: "pointer" }}>
                <Col xl={3} onClick={() => showDetail(item)}>
                  <h6 className="heading mb-0">{item.name}</h6>
                  <span>by</span>{" "}
                  <span className="heading mb-0">{item.clientName}</span>
                </Col>
                <Col xl={6} onClick={() => showDetail(item)}>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <div className="d-flex flex-wrap">
                      {renderTags(item.contentPackTags)}
                    </div>
                    <div className="d-flex mt-2 flex-wrap">
                      {renderLanguages(item.languages)}
                    </div>
                  </div>
                </Col>
                <Col xl={2} onClick={() => showDetail(item)}>
                  <div className="questions text-xl-end text-sm-center text-xs-center">
                    <p className="mb-0">Total Questions</p>
                    <p className="mb-0">{item.totalQuestions}</p>
                  </div>
                </Col>
                <Col
                  xl={1}
                  className="text-center"
                  style={{ marginTop: "-8px" }}
                >
                  <div className="">
                    <DropdownButton
                      key={"direction"}
                      id={"ddd"}
                      drop={"right"}
                      title={
                        <>
                          {" "}
                          <span>
                            <img src={three_dots} />
                          </span>
                        </>
                      }
                    >
                      {show_menu("public", item)}
                    </DropdownButton>
                    <div>
                      <img
                        id={ind + "fav_img"}
                        style={{ cursor: "pointer" }}
                        src={item.isFavourite ? star_filled : star}
                        onClick={() => toggleFav(ind + "fav_img", item?.id)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        );
      } else return;
    });
    return listing.filter((item) => {
      return item?.contentPackVisibility.toLowerCase() === "public";
    }).length ? (
      publicFav
    ) : (
      <p className="text-center" style={{ fontSize: "20px" }}>
        No data to show
      </p>
    );
  };
  const favourite_private = () => {
    let privateFav = listing?.map((item, ind) => {
      if (item?.contentPackVisibility.toLowerCase() === "private") {
        return (
          <React.Fragment key={ind}>
            <Card className="overview-card">
              <Row style={{ cursor: "pointer" }}>
                <Col xl={3} onClick={() => showDetail(item)}>
                  <h6 className="heading mb-0">{item.name}</h6>
                  <span>by</span>{" "}
                  <span className="heading mb-0">{item.clientName}</span>
                </Col>
                <Col xl={6} onClick={() => showDetail(item)}>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <div className="d-flex flex-wrap">
                      {renderTags(item.contentPackTags)}
                    </div>
                    <div className="d-flex mt-2 flex-wrap">
                      {renderLanguages(item.languages)}
                    </div>
                  </div>
                </Col>
                <Col xl={2} onClick={() => showDetail(item)}>
                  <div className="questions text-xl-end text-sm-center text-xs-center">
                    <p className="mb-0">Total Questions</p>
                    <p className="mb-0">{item.totalQuestions}</p>
                  </div>
                </Col>
                <Col
                  xl={1}
                  className="text-center"
                  style={{ marginTop: "-8px" }}
                >
                  <div className="">
                    <DropdownButton
                      key={"direction"}
                      id={"ddd"}
                      drop={"right"}
                      title={
                        <>
                          {" "}
                          <span>
                            <img src={three_dots} />
                          </span>
                        </>
                      }
                    >
                      {show_menu("private", item)}
                    </DropdownButton>
                    <div>
                      <img
                        id={ind + "fav_img"}
                        style={{ cursor: "pointer" }}
                        src={item.isFavourite ? star_filled : star}
                        onClick={() => toggleFav(ind + "fav_img", item?.id)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        );
      } else return;
    });

    return listing.filter((item) => {
      return item?.contentPackVisibility.toLowerCase() === "private";
    }).length ? (
      privateFav
    ) : (
      <p className="text-center" style={{ fontSize: "20px" }}>
        No data to show
      </p>
    );
  };
  const renderLanguages = (item_languages) => {
    return item_languages?.map((item_lan, ind_lan) => {
      return (
        <img
          key={ind_lan}
          className="me-2 mb-1"
          height={16}
          width={20}
          src={BASE_URL_IMAGE + "Flags/" + item_lan?.icon}
        />
      );
    });
  };
  const renderTags = (item_tags) => {
    return item_tags?.map((item_tag, ind_tag) => {
      return (
        <span key={ind_tag} className="content-tags">
          #{item_tag.label}
        </span>
      );
    });
  };
  const listingLengthCheck = (list) => {
    return list ? (
      list?.map((item, ind) => {
        return (
          <React.Fragment key={ind}>
            <Card className="overview-card">
              <Row style={{ cursor: "pointer" }}>
                <Col xl={3} onClick={() => showDetail(item)}>
                  <h6 className="heading mb-0">{item?.name}</h6>
                  <span>by</span>{" "}
                  <span className="heading mb-0">{item.clientName}</span>
                </Col>
                <Col xl={6} onClick={() => showDetail(item)}>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <div className="d-flex flex-wrap">
                      {renderTags(item.contentPackTags)}
                    </div>
                    <div className="d-flex mt-2 flex-wrap">
                      {renderLanguages(item.languages)}
                    </div>
                  </div>
                </Col>
                <Col
                  xl={
                    localStorage.getItem("tab_visibility") == "reporting"
                      ? "3"
                      : "2"
                  }
                  className={
                    localStorage.getItem("tab_visibility") == "reporting"
                      ? "justify-content-center"
                      : ""
                  }
                  onClick={() => showDetail(item)}
                >
                  <div className="questions text-xl-end text-sm-center text-xs-center">
                    <p
                      className={
                        localStorage.getItem("tab_visibility") == "reporting"
                          ? "pe-4 mb-0"
                          : "mb-0"
                      }
                    >
                      Total Questions
                    </p>
                    <p
                      className={
                        localStorage.getItem("tab_visibility") == "reporting"
                          ? "pe-4 mb-0"
                          : "mb-0"
                      }
                    >
                      {localStorage.getItem("tab_visibility") !== "reporting"
                        ? item.totalQuestions
                        : item.totalReportedQuestions}
                    </p>
                  </div>
                </Col>
                {localStorage.getItem("tab_visibility") !== "reporting" && (
                  <Col
                    xl={1}
                    className="text-center"
                    style={{ marginTop: "-8px" }}
                  >
                    <div className="">
                      <DropdownButton
                        key={"direction"}
                        id={"ddd"}
                        drop={"right"}
                        title={
                          <>
                            {" "}
                            <span>
                              <img src={three_dots} />
                            </span>
                          </>
                        }
                      >
                        {show_menu(accessSpecifier, item)}
                      </DropdownButton>
                      <div>
                        {localStorage.getItem("tab_visibility") !==
                          "reporting" && (
                          <img
                            id={ind + "fav_img"}
                            style={{ cursor: "pointer" }}
                            src={item.isFavourite ? star_filled : star}
                            onClick={() => toggleFav(ind + "fav_img", item?.id)}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </Card>
          </React.Fragment>
        );
      })
    ) : (
      <p className="text-center" style={{ fontSize: "20px" }}>
        No data to show
      </p>
    );
  };
  const popupHeading = () => {
    if (menuPopup === 1) {
      return "Delete content pack";
    } else if (menuPopup === 2) {
      return "Move to public";
    } else if (menuPopup === 3) {
      return "Copy";
    } else {
      return "";
    }
  };
  const popupDescription = () => {
    if (menuPopup === 1) {
      return "Are you sure you want to delete the content pack?";
    } else if (menuPopup === 2) {
      return "By moving into public the content pack will available publically. Are you sure you want to move?";
    } else if (menuPopup === 3) {
      return "Are you sure you want to make a copy to private";
    } else {
      return "";
    }
  };
  const popupButtonClick = () => {
    if (menuPopup === 1) {
      deletePackConfirm();
    } else if (menuPopup === 2) {
      moveToPublicConfirm();
    } else if (menuPopup === 3) {
      copyContentConfirm();
    }
  };
  const popupButtonText = () => {
    if (menuPopup === 1) {
      return "Delete";
    } else if (menuPopup === 2) {
      return "Move";
    } else if (menuPopup === 3) {
      return "Copy";
    } else {
      return "";
    }
  };
  return (
    <>
      {accessSpecifier === "favourite" ? (
        <>
          {JSON.parse(localStorage.getItem("staySafeUserData")).role !==
            "KA" && (
            <>
              <p style={{ margin: "0 40px" }} className="mb-2">
                Public content
                <br />
              </p>
              {favourite_public()}
            </>
          )}
          <p style={{ margin: "0 40px" }} className="mb-2">
            Private content
            <br />
          </p>
          {favourite_private()}
        </>
      ) : (
        listingLengthCheck(listing)
      )}
      <Modal
        show={popUpModal}
        centered
        size="md"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="modal-header add-license-modal">
            <h4 className="mb-2 mt-3 me-2" style={{ color: "#34636d" }}>
              {popupHeading()}
            </h4>
            <img
              src="/assets/icons/Cross.png"
              onClick={() => setPopUpModal(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <p>{popupDescription()}</p>

          <div>
            <Col lg={6}>
              <div className="h-100 align-items-start d-flex justify-content-end create-content-buttons">
                <button
                  className="save d-inline px-4 me-4"
                  onClick={() => setPopUpModal(false)}
                >
                  cancel
                </button>
                <button
                  className="save-and-edit d-inline px-4"
                  onClick={popupButtonClick}
                >
                  {popupButtonText()}
                </button>
              </div>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OverviewCard;
