import {
  CHANGE_PASSWORD,
  EDIT_USER_INFO,
  FORGET_PASSWORD,
  MENU_ROUTES,
  SUPPORT,
  VERIFY_PASSWORD,
} from "../constant/ApiUrls";
import { putMethod, postMethod, getMethod } from "./NetworkManager";
import { getLoginStorage } from "../utils/UserDataManagement";
export const editPassword = async (requestPayload) => {
  return postMethod(CHANGE_PASSWORD, requestPayload);
};

export const editUserInfo = async (requestPayload) => {
  let getId =
    getLoginStorage("clientId") === 0
      ? getLoginStorage("contractorId")
      : getLoginStorage("clientId");
  return putMethod(`${EDIT_USER_INFO}/${getId}`, requestPayload);
};

export const forgetPassword = async (requestPayload) => {
  return postMethod(FORGET_PASSWORD, requestPayload);
};
export const fetchSideBarRoutes = async (id) => {
  return getMethod(MENU_ROUTES);
};

export const fetchUserInfo = async (id) => {
  return getMethod(`${EDIT_USER_INFO}/${id}/getcompanyinfo`);
};

export const supportDetail = async (requestPayload) => {
  return postMethod(SUPPORT, requestPayload);
};
export const verifyPassword = async (payLoad) => {
  return postMethod(VERIFY_PASSWORD, payLoad);
};
