import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import {
  faSearch,
  faFileExport,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";
import moment from "moment";
import { exportUserKPI, fetchUserKPI } from "../../../services/ClientServices";
import { getKeyTranslation } from "../../../utils/Translations";
import { userKPI } from "../../../constant/AppConstant";

const UserKPI = () => {
  const [screenLoader, setScreenLoader] = useState(false);
  const [userKpiData, setUserKpiData] = useState([]);
  const [sortingTableObj, setSortingTableObj] = useState(userKPI);
  const [pageNumberForSearch, setPageNumberForSearch] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [enteredValue, setEnteredValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const sorting = (value) => {
    let newObj = sortingTableObj.map((item) => {
      value == item.name ? (item.type = !item.type) : (item.type = false);
      return item;
    });
    newObj.forEach((item) => {
      if (value == item.name) {
        sortingurl(value, item);
      }
    });
    setSortingTableObj(newObj);
  };
  const sortingurl = (value_name, obj) => {
    if (obj.type == false) {
      const call = async () => {
        let searchUrl = `?OrderBy=${value_name}ASC&PageNumber=${pageNumberForSearch}&SearchKey=${enteredValue}&PageSize=20`;
        setPageNumberForSearch(pageNumberForSearch);
        await getUserKpi(searchUrl);
        setSortValue(value_name + "ASC");
      };
      return call();
    } else {
      const call = async () => {
        let searchUrl = `?OrderBy=${value_name}DESC&PageNumber=${pageNumberForSearch}&SearchKey=${enteredValue}&PageSize=20`;
        setPageNumberForSearch(pageNumberForSearch);
        await getUserKpi(searchUrl);
        setSortValue(value_name + "DESC");
      };
      return call();
    }
  };

  const sortIconPosition = (ind) => {
    return sortingTableObj[ind].type ? (
      <FontAwesomeIcon icon={faChevronUp} />
    ) : (
      <FontAwesomeIcon icon={faChevronDown} />
    );
  };
  const downloadKPIUsers = async () => {
    let searchUrl = `?OrderBy=${sortValue}&PageNumber=${pageNumberForSearch}&SearchKey=${enteredValue}&PageSize=20`;

    await exportUserKPI(searchUrl);
  };

  useEffect(() => {
    let searchUrl = `?PageNumber=1&PageSize=20`;
    getUserKpi(searchUrl);
  }, []);
  const getUserKpi = async (searchUrl) => {
    setScreenLoader(true);
    let result = await fetchUserKPI(searchUrl);
    if (result?.status == 200) {
      setScreenLoader(false);
      setUserKpiData(result.data.data?.items);
      setPageCount(result?.data?.data?.totalPages);
      setTotalCount(result?.data?.data?.totalCount);
    } else {
      setScreenLoader(false);
      setUserKpiData([]);
      setPageCount("");
      setTotalCount("");
      toast.error(getKeyTranslation(result?.data?.message));
    }
  };
  const SearchInput = async () => {
    setPageNumberForSearch(1);
    setEnteredValue(searchInputValue);
    let searchUrl = `?SearchKey=${searchInputValue.replace(
      "+",
      "%2B"
    )}&OrderBy=${sortValue}&PageNumber=${pageNumberForSearch}&PageSize=20`;
    await getUserKpi(searchUrl);
  };
  const onEnter = (e) => {
    if (e.code === "Enter") {
      SearchInput();
    }
  };

  const nextPageApiCall = async (data) => {
    let doc = document.getElementsByTagName("li");
    let firstDoc = doc[1].children[0];
    firstDoc.classList.remove("active-link");
    let pageNumberForSearchNext = data.selected + 1;
    let searchUrl = `?SearchKey=${enteredValue.replace(
      "+",
      "%2B"
    )}&OrderBy=${sortValue}&PageNumber=${pageNumberForSearchNext}&PageSize=20`;
    setPageNumberForSearch(pageNumberForSearchNext);
    await getUserKpi(searchUrl);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="my-section d-flex justify-content-between sidebar-for-mobile">
        <div className="my-container" style={{ minHeight: "100vh" }}>
          <LoadingOverlay
            spinner={<HashLoader color="#5ba6b7" size={90} />}
            active={screenLoader}
          >
            <div className="myDiv" id="detail" style={{ minHeight: "94vh" }}>
              <h1
                className="mb-0"
                style={{ fontWeight: "bold", fontSize: "34px" }}
              >
                USER-KPI
              </h1>

              <Row className="mt-5 my-4">
                <Col lg={3}>
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Company name, full name, etc."
                      className="screen-input"
                      onChange={(e) => {
                        setSearchInputValue(e.target.value);
                      }}
                      onKeyDown={(e) => onEnter(e)}
                      value={searchInputValue}
                    />
                    <FontAwesomeIcon
                      id="searchButton"
                      onClick={SearchInput}
                      icon={faSearch}
                    />
                  </div>
                </Col>

                <Col lg={6} className="d-flex justify-content-end">
                  <div
                    className="d-flex mt-2 me-3"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={downloadKPIUsers}
                  >
                    <FontAwesomeIcon
                      icon={faFileExport}
                      style={{
                        color: "#2b4755",
                      }}
                    />{" "}
                    <p className="text-decoration-underline ms-2 mb-0 text-uppercase">
                      Export
                    </p>{" "}
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table
                  bordered
                  size="sm"
                  className="list-view-table user-kpi-table mt-5"
                >
                  <thead>
                    <tr>
                      <th onClick={() => sorting("Name")}>
                        Full name
                        {sortIconPosition(0)}
                      </th>
                      <th onClick={() => sorting("ContractorName")}>
                        Company name
                        {sortIconPosition(1)}
                      </th>
                      <th onClick={() => sorting("LastPlayed")}>
                        Last played
                        {sortIconPosition(2)}
                      </th>
                      {/* <th
                      >
                        Total time played
                      </th>
                      <th
                      >
                        Single-games played
                      </th>
                      <th
                      >
                        multiplayer-games played
                      </th>
                      <th
                      >
                        Ranking
                      </th>
                      <th
                      >
                        Rewards won
                      </th>
                      <th
                      >
                        Average rating
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {userKpiData?.map((item, ind) => {
                      return (
                        <tr key={ind}>
                          <td>
                            {item.name} {item.surname}
                          </td>
                          <td>
                            {item.contractorName && item.contractorName != ""
                              ? item.contractorName
                              : item.companyName}
                          </td>
                          <td>
                            {item?.lastPlayed != "" &&
                              item?.lastPlayedTimeAgo + " "}
                            {item?.lastPlayed &&
                              "(" +
                                moment(item?.lastPlayed).format("DD.MM.YYYY") +
                                ")"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-around align-items-center">
                  <p
                    style={{
                      fontSize: "12px",
                    }}
                    className="m-0"
                  >
                    Showing{" "}
                    <b>
                      {userKpiData?.length} of {totalCount}
                    </b>{" "}
                    Records
                  </p>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={nextPageApiCall}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    activeLinkClassName={"active-link"}
                    forcePage={pageNumberForSearch - 1}
                  />
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </div>
    </>
  );
};

export default UserKPI;
