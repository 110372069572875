import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { verifyPassword } from "../services/UserManagementServices";
import { getKeyTranslation } from "../utils/Translations";

/**
 * @author
 * @class VerifyPasswordModal
 **/

class VerifyPasswordModal extends Component {
  state = {
    password: "",
    wrongPassword: "",
  };

  submitVerification = async () => {
    const { password } = this.state;
    if (password.length === 0) {
      this.setState({
        wrongPassword: "Password is required",
      });
    } else {
      let payLoad = {
        password: password,
      };
      let verify = await verifyPassword(payLoad);
      if (verify?.status === 200) {
        this.setState({
          wrongPassword: "",
          password: "",
        });
        await this.props.updateFunction();
      } else {
        this.setState({
          wrongPassword: getKeyTranslation(verify.data.message),
        });
      }
    }
  };
  closeModal = () => {
    this.setState({
      wrongPassword: "",
      password: "",
    });
    this.props.close();
  };

  render() {
    const { password, wrongPassword } = this.state;
    return (
      <div>
        <Modal
          show={this.props.show}
          centered
          size="md"
          className="edit-add modal-screen"
        >
          <div className="edit-add-modal">
            <div className="modal-header">
              <h1>Verify Password</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={this.closeModal}
              ></button>
            </div>
            <Row>
              <Col lg={12}>
                <label>Enter your Password</label>
                <br />
                <input
                  type="password"
                  placeholder="Password"
                  className="screen-input w-75"
                  value={password}
                  onChange={(e) =>
                    this.setState({
                      password: e.target.value,
                      wrongPassword: "",
                    })
                  }
                />
                <p className="mb-0" style={{ color: "red" }}>
                  {wrongPassword}
                </p>
              </Col>
            </Row>
            <Row className="mt-5">
              <div className="double-buttons d-flex justify-content-end float-end flex-column h-100">
                <div className="d-flex float-end add-edit-buttons">
                  <button onClick={this.submitVerification}>Verify</button>
                  <button onClick={this.closeModal}>cancel</button>
                </div>
              </div>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

VerifyPasswordModal.propTypes = {};
export default withRouter(VerifyPasswordModal);
