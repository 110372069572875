import { BASE_URL } from "../constant/ApiUrls";
import axios from "axios";
import { getLoginStorage } from "../utils/UserDataManagement";
export const auth_token = () => {
  return `Bearer ${getLoginStorage("token")}`;
};
export const content_type = () => {
  return "application/json";
};
export const api_version = () => {
  return "3";
};
const hookRequestInterceptorsWithAxiosInstance = (instance) => {
  return instance.interceptors.request.use(
    // Any status code that lie within the range of 2xx cause this function to trigger

    (config) => {
      return config;
    }
  );
};

const hookResponseInterceptorsWithAxiosInstance = (instance) =>
  instance.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger

    (response) => {
      return response;
    },

    // Any status codes that falls outside the range of 2xx cause this function to trigger

    (error) => {
      return Promise.reject(error);
    }
  );
function getAxios(tokenizeInstance, accessToken = null) {
  const instance = axios.create({
    baseURL: BASE_URL,
  });

  // Hooking a request interceptor

  hookRequestInterceptorsWithAxiosInstance(instance);

  // Hooking a response interceptor

  hookResponseInterceptorsWithAxiosInstance(instance);

  if (tokenizeInstance) {
    instance.defaults.headers = {
      "Content-Type": content_type(),
      Authorization: auth_token(),
      "x-api-version": api_version(),
      // "Content-Type": "application/json",
      // Authorization: `Bearer ${bearer}`,
      // "x-api-version": 3,
    };
  }

  return instance;
}

axios.defaults.timeout = 300000;

export async function getMethod(type) {
  try {
    return await getAxios(true).get(type);
  } catch (error) {
    return error.response;
  }
}

export async function postMethod(type, data) {
  try {
    return await getAxios(true).post(type, data);
  } catch (error) {
    return error.response;
  }
}

export async function putMethod(type, data) {
  try {
    return await getAxios(true).put(type, data);
  } catch (error) {
    return error.response;
  }
}

export async function deleteMethod(type) {
  try {
    return await getAxios(true).delete(type);
  } catch (error) {
    return error.response;
  }
}
export async function deleteMethod2(type, data) {
  try {
    return await getAxios(true).delete(type, { data });
  } catch (error) {
    return error.response;
  }
}
export async function downLoadFile(type) {
  try {
    return await axios({
      url: BASE_URL + type,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Invoice.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  } catch (error) {
    return error.response;
  }
}
