import React from "react";
import { Modal } from "react-bootstrap";
import cross_faded from "../../../../../../assets/images/cross_unfaded.png";

const TypeChangeWarningPopup = ({ showModal, closeWarningChangeType }) => {
  return (
    <>
      <Modal
        show={showModal}
        centered
        size={"md"}
        className={"modal-screen updateModal-screen"}
        style={{ borderRadius: "0" }}
      >
        <Modal.Body>
          <div className="modal-header add-license-modal">
            <>
              <h4 className="mb-2 mt-3 me-2" style={{ color: "#34636d" }}>
                Warning
              </h4>
              <img
                src={cross_faded}
                onClick={() => closeWarningChangeType()}
                style={{ cursor: "pointer" }}
              />
            </>
          </div>
          <div>
            <p className="editor-dark-color mb-4" style={{ fontSize: "14px" }}>
              {" "}
              By changing the question type, the existing question data entered
              in other languages will be lost, would you like to continue?
            </p>
          </div>
          <div>
            <div className="create-content-buttons">
              <button
                className="save d-inline me-4"
                onClick={() => closeWarningChangeType()}
              >
                cancel
              </button>
              <button
                className="save-and-edit d-inline"
                // onClick={() => closeWarningChangeType()}
                disabled
              >
                Continue
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TypeChangeWarningPopup;
