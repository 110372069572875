import React, { Component } from "react";
import { Row, Col, Tooltip } from "react-bootstrap";
import sortIcon from "../../../../assets/icons/sort-icon.png";
import {
  faSearch,
  faFileExport,
  faThLarge,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLoader } from "react-spinners";
import AddLicenseModal from "./addLicense/AddLicenseModal";
import {
  exportMasterListClients,
  fetchClientCompany,
  fetchTotalMasterClient,
  searchAndSort,
} from "../../../services/MasterServices";
import toast, { Toaster } from "react-hot-toast";
import { withRouter } from "react-router-dom";
import {
  MASTER_SORT_LIST,
  masterLicenses,
} from "../../../constant/AppConstant";
import GridView from "./views/GridView";
import ListView from "./views/ListView";
import LoadingOverlay from "react-loading-overlay";

class LicensesList extends Component {
  state = {
    masterClientList: [],
    totalClients: 0,
    screenLoader: true,
    searchKeyword: "",
    dropdownKey: "",
    sortList: MASTER_SORT_LIST,
    pageNumberForSearch: 1,
    showCard: false,
    clientId: 0,
    dataView: "grid",
    sortValueType: "",
    sortValueName: "",
    sortingArr: masterLicenses.slice(0),
    screenLoaderTable: true,
    enteredKeyword: "",
  };
  componentDidMount = async () => {
    await this.licensesList(1, 20);
    await this.getTotalClients();
  };
  licensesList = async (page, size) => {
    let clientsList = await fetchClientCompany(page, size);
    if (clientsList?.status === 200) {
      this.setState({
        masterClientList: clientsList.data.data,
        screenLoader: false,
        screenLoaderTable: false,
      });
    } else {
      toast.error("Error while fetching data!");
      this.setState({
        masterClientList: [],
        screenLoader: false,
        screenLoaderTable: false,
      });
    }
  };
  getTotalClients = async () => {
    let totalClient = await fetchTotalMasterClient();
    if (totalClient?.status === 200) {
      this.setState({
        totalClients: totalClient.data.data,
        screenLoader: false,
      });
    } else {
      toast.error("Error while fetching data!");
    }
  };
  companyDetail = (id) => {
    this.setState({
      showCard: true,
      clientId: id,
    });
    localStorage.setItem("showCard", true);
  };
  closeCompanyCard = () => {
    this.setState({
      showCard: false,
    });
  };
  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {"Simple tooltip"}
    </Tooltip>
  );
  addorDeleteLicenseSuccess = async () => {
    await this.licensesList();
    await this.getTotalClients();
  };

  closeDetail = () => {
    this.props.history.push(`/master-licenses`);
  };
  changeSearchValue = async (e) => {
    this.setState({
      searchKeyword: e.target.value,
      searchTrue: e.target.value.length == 0 ? false : true,
    });
  };
  onEnter = (e) => {
    if (e.code === "Enter") {
      this.searchAInput();
    }
  };
  searchAInput = async () => {
    const { searchKeyword } = this.state;
    this.setState({
      enteredKeyword: searchKeyword,
      pageNumberForSearch: 1,
    });
    let searchUrl = `?SearchBy=${searchKeyword}&PageNumber=1&PageSize=20&OrderType=${
      this.state.sortValueType
    }&OrderBy=${
      this.state.dataView == "grid"
        ? this.state.dropdownKey
        : this.state.sortValueName
    }`;
    await this.fetchSearchResults(searchUrl);
  };
  searchAndSorting = async (e) => {
    const { pageNumberForSearch, enteredKeyword } = this.state;
    this.setState({
      dropdownKey: e.target.value,
      sortValueType: "DESC",
    });
    let searchUrl = `?SearchBy=${enteredKeyword}&PageNumber=${pageNumberForSearch}&PageSize=20&OrderType=DESC&OrderBy=${e.target.value}`;
    await this.fetchSearchResults(searchUrl);
  };

  searchAndSortingListView = async (value) => {
    const { pageNumberForSearch, enteredKeyword } = this.state;

    this.setState({
      sortValueName: value,
    });
    this.setState({
      dropdownKey: value,
    });
    let sort_value_type = this.state.sortValueType;
    if (this.state.sortValueType == "ASC") {
      sort_value_type = "DESC";
      this.setState({
        sortValueType: "DESC",
      });
    } else {
      sort_value_type = "ASC";
      this.setState({
        sortValueType: "ASC",
      });
    }
    let newArr = this.state.sortingArr.map((item) => {
      if (item.name == value) {
        if (sort_value_type == "ASC") {
          item.type = true;
        } else {
          item.type = false;
        }
      } else {
        item.type = false;
      }
      return item;
    });
    this.setState({
      sortingArr: newArr.slice(),
    });

    let searchUrl = `?SearchBy=${enteredKeyword}&PageNumber=${pageNumberForSearch}&PageSize=20&OrderType=${sort_value_type}&OrderBy=${value}`;
    console.log(searchUrl);
    await this.fetchSearchResults(searchUrl);
  };

  fetchSearchResults = async (searchUrl) => {
    this.setState({
      screenLoaderTable: true,
    });
    let searchFeedBack = await searchAndSort(searchUrl);
    if (searchFeedBack?.status === 200) {
      this.setState({
        masterClientList: searchFeedBack?.data?.data,
        screenLoader: false,
        screenLoaderTable: false,
      });
    } else {
      toast.error("Error while getting data!");
      this.setState({
        masterClientList: [],
        screenLoader: false,
        screenLoaderTable: false,
      });
    }
  };
  resetSearch = async () => {
    await this.licensesList();
    this.setState({
      searchTrue: false,
      searchKeyword: "",
      enteredKeyword: "",
    });
  };

  nextPageApiCall = async (data) => {
    const { enteredKeyword } = this.state;

    let doc = document.getElementsByTagName("li");
    let firstDoc = doc[1].children[0];
    firstDoc.classList.remove("active-link");
    let pageNumberForSearch = data.selected + 1;
    let searchUrl = `?SearchBy=${enteredKeyword}&PageNumber=${pageNumberForSearch}&PageSize=20&OrderType=${
      this.state.sortValueType
    }&OrderBy=${
      this.state.dataView == "grid"
        ? this.state.dropdownKey
        : this.state.sortValueName
    }`;

    this.setState({
      pageNumberForSearch,
    });
    await this.fetchSearchResults(searchUrl);
  };

  gridClick = (name) => {
    if (name === "grid") {
      let arr = this.state.sortingArr.map((item) => {
        item.type = false;
        return item;
      });
      this.setState({
        sortingArr: arr,
      });
    }
  };
  downloadContractorUsers = async () => {
    const { dropdownKey, sortValueType, enteredKeyword, pageNumberForSearch } =
      this.state;
    let params = {
      SearchBy: enteredKeyword,
      PageNumber: pageNumberForSearch,
      PageSize: 20,
      OrderBy: dropdownKey,
      OrderType: sortValueType,
    };

    await exportMasterListClients(params);
  };

  render() {
    const { searchKeyword, sortList, totalClients, masterClientList } =
      this.state;
    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />
        <div className="my-section d-flex justify-content-between sidebar-for-mobile">
          <div className="my-container">
            <div className="myDiv" id="detail">
              <div className="d-flex justify-content-between align-items-center">
                <h1>Licenses</h1>
                <h2
                  style={{
                    color: "#34636d",
                    fontWeight: "bold",
                  }}
                >
                  {totalClients} used
                </h2>
              </div>
              <Row className="mt-5 my-4">
                <Col lg={1}>
                  <div className="ui-options d-flex">
                    {[
                      { id: 1, name: "grid", icon: faThLarge },
                      { id: 2, name: "list", icon: faBars },
                    ].map((item, index) => (
                      <div className="text-center" key={index}>
                        <div
                          className={`option ${
                            this.state.dataView !== item?.name && "active"
                          }`}
                          key={item.id}
                          onClick={() => {
                            this.closeCompanyCard();
                            this.setState({
                              dataView: item?.name,
                            });
                            this.gridClick(item?.name);
                          }}
                        >
                          <FontAwesomeIcon icon={item?.icon} />
                        </div>
                        <p
                          className="text-capitalize mb-0"
                          style={{
                            opacity:
                              this.state.dataView !== item?.name && "0.5",
                          }}
                        >
                          {item?.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </Col>

                <Col lg={3}>
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Company name, person etc."
                      onFocus={this.closeDetail}
                      onChange={this.changeSearchValue}
                      onKeyUp={this.onEnter}
                      value={searchKeyword}
                    />
                    <FontAwesomeIcon
                      id="searchButton"
                      onClick={this.searchAInput}
                      icon={faSearch}
                      //onClick={searchTrue && this.resetSearch}
                      //icon={searchTrue ? faTimes : faSearch}
                    />
                  </div>
                </Col>
                <Col lg={3}>
                  {this.state.dataView === "grid" && (
                    <div className="d-flex search-type">
                      <p className="p-width">Sort by</p>
                      <img
                        src={sortIcon}
                        alt="sort-icon"
                        width={25}
                        height={25}
                      />
                      <select
                        className="select"
                        onChange={this.searchAndSorting}
                      >
                        {sortList?.map((item, i) => (
                          <option key={i} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </Col>
                <Col lg={5} className="d-flex justify-content-end">
                  <div
                    className="d-flex mt-2 me-3"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={this.downloadContractorUsers}
                  >
                    <FontAwesomeIcon
                      icon={faFileExport}
                      style={{
                        color: "#2b4755",
                      }}
                    />{" "}
                    <p className="text-decoration-underline ms-2 mb-0 text-uppercase">
                      Export
                    </p>{" "}
                  </div>
                  <div>
                    <AddLicenseModal
                      LicenseAddedSuccess={this.addorDeleteLicenseSuccess}
                    />
                  </div>
                </Col>
              </Row>

              {this.state.dataView === "grid" ? (
                <GridView
                  updateList={this.licensesList}
                  LicenseDeletedSuccess={this.addorDeleteLicenseSuccess}
                  clientId={this.state.clientId}
                  closeCard={this.closeCompanyCard}
                  list={masterClientList}
                  companyDetails={this.companyDetail}
                  card={this.state.showCard}
                  nextPage={this.nextPageApiCall}
                  page={this.state.pageNumberForSearch}
                />
              ) : (
                <LoadingOverlay
                  spinner={<HashLoader color="#5ba6b7" size={90} />}
                  active={this.state.screenLoaderTable}
                >
                  <ListView
                    updateList={this.licensesList}
                    clientId={this.state.clientId}
                    closeCard={this.closeCompanyCard}
                    list={masterClientList}
                    companyDetails={this.companyDetail}
                    card={this.state.showCard}
                    sort={this.searchAndSortingListView}
                    nextPage={this.nextPageApiCall}
                    page={this.state.pageNumberForSearch}
                    sortingArr={this.state.sortingArr}
                  />
                </LoadingOverlay>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(LicensesList);
