import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import ScrollBar from "react-scrollbar";
import {
  getCategories,
  updateContractorDetails,
} from "../../../../services/ClientServices";
import { getLoginStorage } from "../../../../utils/UserDataManagement";
import toast from "react-hot-toast";
import { withRouter } from "react-router";
import { getKeyTranslation } from "../../../../utils/Translations";
class EditPlan extends Component {
  state = {
    categoriesList: [],
    selectdCategories: [],
    editPlanModal: false,
    editPlanModal_2: false,
    reward: false,
  };

  getCategoryList = async () => {
    let idarray = [];
    for (let i = 0; i < this.props.detail?.categories.length; i++) {
      idarray.push(this.props.detail?.categories[i].categoryId);
    }
    let categories = await getCategories();

    this.setState({
      categoriesList: categories?.data?.data,
      editPlanModal: true,
      selectdCategories: idarray,
      amountOfLiscenses: this.props.detail.noOfLicenses,
      reward: this.props.detail.qualifiedForRewards,
    });
  };
  handleChangeCategories = async (item) => {
    if (item === "all") {
      if (
        this.state.selectdCategories.length === this.state.categoriesList.length
      ) {
        this.setState({
          selectdCategories: [],
        });
      } else {
        let array = [];
        for (let i of this.state.categoriesList) {
          array.push(i.categoryId);
        }

        this.setState({
          selectdCategories: [...array],
        });
      }
    } else {
      let array = this.state.selectdCategories;
      console.log(array);
      if (array.includes(item.categoryId)) {
        let arr = array.filter((i) => i !== item.categoryId);
        this.setState({
          selectdCategories: arr,
        });
      } else {
        this.setState({
          selectdCategories: [...this.state.selectdCategories, item.categoryId],
        });
      }
    }
  };
  numberOfLiscences = (e) => {
    if (e.target.value >= 0) {
      this.setState({
        amountOfLiscenses: e.target.value,
      });
    }
  };

  keyDown = (e) => {
    var invalidChars = ["-", "+", "e", "E", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  updateContractor = async () => {
    const { selectdCategories, amountOfLiscenses } = this.state;
    let payLoad = {
      clientId: getLoginStorage("clientId"),
      amountOfLicenses: amountOfLiscenses,
      categories: selectdCategories,
      qualifiedForRewards: this.state.reward,
    };
    let id = this.props?.detail?.companyId;
    console.log(id);
    let response = await updateContractorDetails(id, payLoad);
    if (response.status === 200) {
      toast.success(getKeyTranslation(response?.data?.message));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error(getKeyTranslation(response?.data?.message));
    }
  };
  publicAndPrivateContent = (visibility_type) => {
    let content = this.state.categoriesList.map((item, index) => {
      if (item?.type.toLowerCase() === visibility_type) {
        return (
          <div className="category d-flex" key={index}>
            <div className="styled-input-single mt-1">
              <input
                type="checkbox"
                name="fieldset-5"
                id={"checkbox-example-one" + index}
                value={item.categoryId}
                checked={this.state.selectdCategories.includes(item.categoryId)}
                onChange={() => this.handleChangeCategories(item)}
              />
              <label htmlFor={"checkbox-example-one" + index} />
            </div>
            <p style={{ marginTop: "11px" }}>{item?.categoryName}</p>
          </div>
        );
      } else return;
      //

      //
    });
    return this.state.categoriesList.filter((item) => {
      return item?.type.toLowerCase() === visibility_type;
    }).length ? (
      content
    ) : (
      <p className="text-start" style={{ fontSize: "20px" }}>
        No data to show
      </p>
    );
  };
  closeModals = () => {
    this.setState({
      editPlanModal: false,
      editPlanModal_2: false,
    });
  };
  render() {
    return (
      <>
        <button onClick={this.getCategoryList}>edit plan</button>
        <Modal
          show={this.state.editPlanModal}
          centered
          size="md"
          className="edit-add modal-screen"
        >
          <div className="edit-add-modal">
            <div className="modal-header">
              <h1>Edit plan</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={this.closeModals}
              ></button>
            </div>
            <Row>
              <Col lg={6}>
                <label>Amount of Licenses</label>
                <br />
                <input
                  type="number"
                  value={this.state.amountOfLiscenses}
                  className="screen-input"
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "500",
                  }}
                  onChange={(e) => {
                    this.numberOfLiscences(e);
                  }}
                  onKeyDown={(e) => {
                    this.keyDown(e);
                  }}
                />
                <div className="category d-flex flex-column mt-4">
                  <p>Rewards</p>
                  <div
                    className="styled-input-single"
                    style={{ paddingTop: 0 }}
                  >
                    <input
                      type="checkbox"
                      name="fieldset-5"
                      id={"reward"}
                      onChange={() =>
                        this.setState({
                          reward: !this.state.reward,
                        })
                      }
                      checked={this.state.reward}
                    />
                    <label
                      htmlFor={"reward"}
                      style={{ marginTop: "-3px" }}
                      className="default-color d-block"
                    >
                      Qualify for rewards
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <col-12>
                <div className="">
                  <div className="double-buttons d-flex justify-content-end float-end flex-column">
                    <div className="d-flex float-end add-edit-buttons">
                      <button onClick={this.closeModals}>cancel</button>
                      <button
                        onClick={() =>
                          this.setState({
                            editPlanModal_2: true,
                            editPlanModal: false,
                          })
                        }
                        style={
                          {
                            // opacity:
                            //   this.state.selectdCategories.length < 1
                            //     ? "0.7"
                            //     : "1",
                            // pointerEvents:
                            //   this.state.selectdCategories.length < 1
                            //     ? "none"
                            //     : "",
                          }
                        }
                      >
                        Next Step
                      </button>
                    </div>
                  </div>
                </div>
              </col-12>
            </Row>
          </div>
        </Modal>
        <Modal
          show={this.state.editPlanModal_2}
          centered
          size="md"
          className="edit-add modal-screen"
        >
          <div className="edit-add-modal">
            <div className="modal-header">
              <h1>Edit plan</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={this.closeModals}
              ></button>
            </div>
            <p className="mt-1 sm-heading mb-0">
              Categories ({this.state.selectdCategories.length})
            </p>
            <div className="category d-flex d-none">
              <div className="styled-input-single mt-2">
                <input
                  type="checkbox"
                  name="fieldset-5"
                  id={"checkbox-example-one bbb"}
                  onChange={(e) => this.handleChangeCategoriesAll(e)}
                />
                <label htmlFor={"checkbox-example-one bbb"} />
              </div>
              <p style={{ marginTop: "11px" }}>All</p>
            </div>
            <div className="category d-flex align-items-center mb-2">
              <div className="styled-input-single">
                <input
                  type="checkbox"
                  name="fieldset-5"
                  id={"checkbox-example-one"}
                  onChange={() => this.handleChangeCategories("all")}
                  checked={
                    this.state.selectdCategories.length ===
                    this.state.categoriesList.length
                  }
                />
                <label htmlFor={"checkbox-example-one"} />
              </div>
              {/* <img src={sortIcon} alt='brand-icon' /> */}
              <p className="name mb-0">All</p>
            </div>
            <Row>
              <Col lg={6}>
                <p className="sm-heading mb-0">Public Content</p>
                <div className="categories">
                  <ScrollBar
                    style={{ maxHeight: 350 }}
                    className="my-scrollbar"
                    contentClassName="my-scrollbar-content"
                    speed={0.6}
                  >
                    {this.publicAndPrivateContent("public")}
                  </ScrollBar>
                </div>
              </Col>
              <Col lg={6}>
                <p className="sm-heading mb-0">Private Content</p>
                <div className="categories">
                  <ScrollBar
                    style={{ maxHeight: 350 }}
                    className="my-scrollbar"
                    contentClassName="my-scrollbar-content"
                    speed={0.6}
                  >
                    {this.publicAndPrivateContent("private")}
                  </ScrollBar>
                </div>
              </Col>
            </Row>
            <Row>
              <col-12>
                {/* <div class="modal-footer p-0"> */}
                <div>
                  <div className="double-buttons d-flex justify-content-end float-end flex-column">
                    <div className="d-flex float-end add-edit-buttons">
                      <button
                        onClick={() =>
                          this.setState({
                            editPlanModal_2: false,
                            editPlanModal: true,
                          })
                        }
                        style={{
                          opacity:
                            this.state.selectdCategories.length < 1
                              ? "0.7"
                              : "1",
                          pointerEvents:
                            this.state.selectdCategories.length < 1
                              ? "none"
                              : "",
                        }}
                      >
                        Back
                      </button>
                      <button
                        onClick={this.updateContractor}
                        style={{
                          opacity:
                            this.state.selectdCategories.length < 1
                              ? "0.7"
                              : "1",
                          pointerEvents:
                            this.state.selectdCategories.length < 1
                              ? "none"
                              : "",
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </col-12>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}
export default withRouter(EditPlan);
