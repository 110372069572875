import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import brandIcon from "../../assets/icons/brand-icon.png";
import { getClientDetails } from "../services/ClientServices";
import { getLoginStorage } from "../utils/UserDataManagement";
import "./SideBar.css";

class SideBar extends Component {
  state = {
    menuArray: [],
    userdata: {},
  };
  async componentDidMount() {
    let user = getLoginStorage("role");
    this.setState({
      userdata: { role: user },
    });
    if (user === "CA") {
      let result = await getClientDetails();
      if (result?.data?.success) {
        this.setState({
          userdata: { ...this.state.userdata, user: result?.data?.data },
        });
      }
    }
  }
  logoutMethod = () => {
    localStorage.clear();
    this.props.history.push("/login");
  };

  render() {
    return (
      <>
        <>
          <div className="side-bar-mobile-view justify-content-between">
            <img src={brandIcon} alt="mobile-view" />

            <div className="side-bar-links">
              {JSON.parse(localStorage.getItem("menuStructure"))?.map(
                (item, i) => {
                  if (
                    item.type === "menuLinks" &&
                    item.componentName !== "Finances"
                  ) {
                    return (
                      <Link
                        key={i}
                        to={item.route}
                        className={`link mobile-link ${
                          this.props.history.location.pathname === item.route &&
                          "link-active"
                        }`}
                      >
                        {item.componentName}
                        {item?.componentName === "Licenses" &&
                          this.state.userdata?.role === "CA" &&
                          this.state.userdata?.user?.isHold && (
                            <img
                              src="/assets/icons/warning-red.png"
                              width="18px"
                              className="ms-2"
                              alt="warning"
                            />
                          )}
                      </Link>
                    );
                  }
                }
              )}
            </div>
            <p className="logout-link" onClick={this.logoutMethod}>
              Log out
            </p>
          </div>
        </>

        <>
          <div className="side-bar desktop float-end">
            <img src={brandIcon} alt="brand-icon" />
            <div className="side-bar-links d-flex flex-column">
              {JSON.parse(localStorage.getItem("menuStructure"))?.map(
                (item, i) => {
                  if (
                    item.type === "menuLinks" &&
                    item.componentName !== "Finances"
                  ) {
                    return (
                      <Link
                        key={i}
                        to={item.route}
                        className={`link ${
                          this.props.history.location.pathname.split("/")[1] ===
                            item.route.split("/")[1] && "link-active"
                        }
                        ${
                          this.props.history.location.pathname.split("/")[1] ===
                            "content-detail" &&
                          item.route.split("/")[1] === "content"
                            ? "link-active"
                            : ""
                        } ${
                          this.props.history.location.pathname.split("/")[1] ===
                            "create-question" &&
                          item.route.split("/")[1] === "content"
                            ? "link-active"
                            : ""
                        }
                         `}
                      >
                        {item.componentName}
                        {item?.componentName === "Licenses" &&
                          this.state.userdata?.role === "CA" &&
                          this.state.userdata?.user?.isHold && (
                            <img
                              src="/assets/icons/warning-red.png"
                              width="18px"
                              className="ms-2"
                              alt="warning"
                            />
                          )}
                      </Link>
                    );
                  }
                }
              )}
              <hr className="link-hr" />
              <p className="logout-link" onClick={this.logoutMethod}>
                Log out
              </p>
            </div>
          </div>
        </>
      </>
    );
  }
}
export default withRouter(SideBar);
