import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import sortIcon from "../../../../assets/icons/sort-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faThLarge, faBars } from "@fortawesome/free-solid-svg-icons";
import { fetchContractorCompanyList } from "../../../services/ContractorServices";
import toast, { Toaster } from "react-hot-toast";
import { withRouter } from "react-router-dom";
import {
  MASTER_SORT_LIST,
  contractorLicenses,
} from "../../../constant/AppConstant";
import { MASTER_INSTRUCTIONS } from "../../../constant/ApiUrls";
import RestrictedAction from "../../../common/modal/RestrictedAction";
import GridView from "./views/GridView";
import ListView from "./views/ListView";
class ContractorLicensesList extends Component {
  state = {
    companiesList: [],
    sortItem: "Contact Person",
    screenLoader: false,
    searchKeyword: "",
    dropdownKey: "",
    pageNumber: 1,
    sortList: MASTER_SORT_LIST,
    showCard: false,
    clientId: 0,
    restrictedActionModal: false,
    firstTime: true,
    dataView: "grid",
    sortValueType: "",
    sortValueName: "",
    sortingArr: contractorLicenses,
    screenLoaderTable: true,
    enteredKeyword: "",
  };
  componentDidMount = async () => {
    if (localStorage.getItem("isHold") === "true") {
      this.setState({
        restrictedActionModal: true,
      });
    }

    let params = {
      SearchBy: "",
      PageNumber: 1,
      PageSize: 20,
      OrderType: "",
      OrderBy: "",
      showCard: false,
      clientId: 0,
    };
    setTimeout(await this.companiesList(params), 2000);
  };
  companiesList = async (params) => {
    this.setState({
      screenLoader: true,
      screenLoaderTable: true,
    });
    let companyList = await fetchContractorCompanyList(params);
    if (companyList?.status === 200) {
      this.setState({
        companiesList: companyList?.data?.data,
        screenLoader: false,
        screenLoaderTable: false,
      });
    } else {
      setTimeout(
        this.setState({
          companiesList: [],
          screenLoader: false,
          screenLoaderTable: false,
        }),
        2000
      );
      toast.error("Error while fetching data!");
    }
  };
  changeSearchValue = async (e) => {
    this.setState({
      searchKeyword: e.target.value,
      searchTrue: e.target.value.length == 0 ? false : true,
    });
  };
  onEnter = (e) => {
    if (e.code === "Enter") {
      this.searchAInput();
    }
  };
  searchAInput = async () => {
    let { searchKeyword } = this.state;
    this.setState({
      enteredKeyword: searchKeyword,
      pageNumberForSearch: 1,
      PageNumber: 1,
    });
    let params = {
      SearchBy: searchKeyword,
      PageNumber: 1,
      PageSize: 20,
      OrderType: "",
      OrderBy:
        this.state.dataView == "grid"
          ? this.state.dropdownKey
          : this.state.sortValueName,
    };
    await this.companiesList(params);
  };
  searchAndSorting = async (e) => {
    let { pageNumber, enteredKeyword } = this.state;
    let params = {
      SearchBy: enteredKeyword,
      PageNumber: pageNumber,
      PageSize: 20,
      OrderType: "DESC",
      OrderBy: e.target.value,
    };
    this.setState({
      dropdownKey: e.target.value,
      sortValueType: "DESC",
    });
    await this.companiesList(params);
  };

  searchAndSortingListView = async (value) => {
    const { pageNumber, enteredKeyword } = this.state;
    this.setState({
      sortValueName: value,
    });
    this.setState({
      dropdownKey: value,
    });
    let sort_value_type = this.state.sortValueType;
    if (this.state.sortValueType == "ASC") {
      sort_value_type = "DESC";
      this.setState({
        sortValueType: "DESC",
      });
    } else {
      sort_value_type = "ASC";
      this.setState({
        sortValueType: "ASC",
      });
    }
    let newArr = this.state.sortingArr.map((item) => {
      if (item.name == value) {
        if (sort_value_type == "ASC") {
          item.type = true;
        } else {
          item.type = false;
        }
      } else {
        item.type = false;
      }
      return item;
    });
    this.setState({
      sortingArr: newArr,
    });
    let params = {
      SearchBy: enteredKeyword,
      PageNumber: pageNumber,
      PageSize: 20,
      OrderType: sort_value_type,
      OrderBy: value,
    };
    await this.companiesList(params);
  };
  gridClick = (name) => {
    if (name === "grid") {
      let arr = this.state.sortingArr.map((item) => {
        item.type = false;
        return item;
      });
      this.setState({
        sortingArr: arr,
      });
    }
  };
  resetSearch = async () => {
    let params = {
      SearchBy: "",
      PageNumber: 1,
      PageSize: 20,
      OrderType: "",
      OrderBy: "",
    };
    setTimeout(await this.companiesList(params), 1000);
    this.setState({
      searchTrue: false,
      searchKeyword: "",
      enteredKeyword: "",
    });
  };
  nextPageApiCall = async (data) => {
    let { enteredKeyword } = this.state;
    let pageNumberForSearch = data.selected + 1;

    let doc = document.getElementsByTagName("li");
    let firstDoc = doc[1].children[0];
    firstDoc.classList.remove("active-link");

    this.setState({
      pageNumber: pageNumberForSearch,
    });
    let params = {
      SearchBy: enteredKeyword,
      PageNumber: pageNumberForSearch,
      PageSize: 20,
      OrderType: this.state.sortValueType,
      OrderBy:
        this.state.dataView == "grid"
          ? this.state.dropdownKey
          : this.state.sortValueName,
    };
    await this.companiesList(params);
  };

  companyDetail = (id, item) => {
    this.setState({
      showCard: true,
      clientId: id,
    });
  };
  closeCompanyCard = () => {
    this.setState({
      showCard: false,
    });
  };
  closeDetail = () => {
    this.props.history.push(`/contractor-licenses`);
  };

  render() {
    const { companiesList, sortList, searchKeyword } = this.state;
    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />
        <div className="my-section d-flex justify-content-between sidebar-for-mobile">
          <div className="my-container px-5">
            <div className="myDiv" id="detail">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1>Licenses</h1>
              </div>
              <Row className="mt-5 my-4">
                <Col lg={1}>
                  <div className="ui-options d-flex">
                    {[
                      { id: 1, name: "grid", icon: faThLarge },
                      { id: 2, name: "list", icon: faBars },
                    ].map((item, index) => (
                      <div className="text-center" key={index}>
                        <div
                          className={`option ${
                            this.state.dataView !== item?.name && "active"
                          }`}
                          key={item.id}
                          onClick={() => {
                            this.closeCompanyCard();
                            this.setState({
                              dataView: item?.name,
                            });
                            this.gridClick(item?.name);
                          }}
                        >
                          <FontAwesomeIcon icon={item?.icon} />
                        </div>
                        <p
                          className="text-capitalize mb-0"
                          style={{
                            opacity:
                              this.state.dataView !== item?.name && "0.5",
                          }}
                        >
                          {item?.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Company name, person etc."
                      onFocus={this.closeDetail}
                      onChange={this.changeSearchValue}
                      onKeyUp={this.onEnter}
                      value={searchKeyword}
                    />
                    <FontAwesomeIcon
                      // onClick={searchTrue && this.resetSearch}
                      // icon={searchTrue ? faTimes : faSearch}
                      onClick={this.searchAInput}
                      icon={faSearch}
                    />
                  </div>
                </Col>
                <Col lg={3}>
                  {this.state.dataView === "grid" && (
                    <div className="d-flex search-type">
                      <p className="p-width">Sort by</p>
                      <img
                        src={sortIcon}
                        alt="sort-icon"
                        width={25}
                        height={25}
                      />
                      <select
                        className="select"
                        onChange={this.searchAndSorting}
                      >
                        {sortList?.map((item, i) => (
                          <option key={i} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </Col>
                <Col lg={5}>
                  <a
                    href={MASTER_INSTRUCTIONS}
                    download={MASTER_INSTRUCTIONS}
                    target="_blank"
                  >
                    <button className="add-license mt-0">
                      Download Instructions
                    </button>
                  </a>
                </Col>
              </Row>
              {this.state.dataView === "grid" ? (
                <GridView
                  clientId={this.state.clientId}
                  closeCard={this.closeCompanyCard}
                  list={companiesList}
                  companyDetails={this.companyDetail}
                  card={this.state.showCard}
                  nextPage={this.nextPageApiCall}
                  page={this.state.pageNumber}
                />
              ) : (
                <ListView
                  clientId={this.state.clientId}
                  closeCard={this.closeCompanyCard}
                  list={companiesList}
                  companyDetails={this.companyDetail}
                  card={this.state.showCard}
                  sort={this.searchAndSortingListView}
                  nextPage={this.nextPageApiCall}
                  page={this.state.pageNumber}
                  sortingArr={this.state.sortingArr}
                />
              )}
            </div>
          </div>
        </div>
        <RestrictedAction
          show={this.state.restrictedActionModal}
          handleClose={() => {
            this.setState({
              restrictedActionModal: false,
            });
            localStorage.setItem("isHold", false);
            setTimeout(() => {
              this.setState({
                firstTime: false,
              });
            }, 1000);
          }}
          newText={true}
          text={
            localStorage.getItem("isHold") === "true"
              ? "You are currently on hold!"
              : "Contractor is currently on Hold. Please contact with client for further information."
          }
        />
      </>
    );
  }
}
export default withRouter(ContractorLicensesList);
