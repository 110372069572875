import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import EditAccountInfo from "../../contractor/settings/editDetails/EditAccountInfo";

class LoginInformationContractor extends Component {
  render() {
    return (
      <div className="login-info mt-4">
        <Container>
          <Row>
            <Col lg={8}>
              <h2>Account Information</h2>
              <EditAccountInfo key="first-login" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default LoginInformationContractor;
