import React, { Component } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import sortIcon from "../../../../../assets/icons/sort-icon.png";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import DeleteClient from "../deleteClient/DeleteClient";
import EditLicense from "../editLicense/EditLicense";
import { withRouter } from "react-router-dom";
import { fetchMasterClientDetail } from "../../../../services/MasterServices";
import toast from "react-hot-toast";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import { BASE_URL_IMAGE } from "../../../../constant/ApiUrls";
import brandHolder from "../../../../../assets/icons/brandHolder.jpg";
import MembersList from "../../members/MembersList";

class LicenseDetails extends Component {
  state = {
    editLicenseModal: false,
    deleteClientModal: false,
    detail: {},
    screenLoader: true,
    editDetail: "",
  };
  closeEditLicenseModal = () => {
    this.setState({
      editLicenseModal: false,
    });
  };
  closeDeleteClientModal = () => {
    this.setState({
      deleteClientModal: false,
    });
  };
  componentDidMount = async () => {
    this.setState({
      screenLoader: true,
    });
  };
  componentDidUpdate = async () => {
    let check = localStorage.getItem("showCard");
    if (check == "true") {
      localStorage.removeItem("showCard");
      await this.clientDetailMethod();
    }
  };
  clientDetailMethod = async () => {
    let id = this.props?.clientId;
    this.setState({
      screenLoader: true,
    });
    let detail = await fetchMasterClientDetail(id);
    if (detail?.status === 200) {
      this.setState({
        detail: detail?.data?.data,
        screenLoader: false,
      });
    } else {
      this.setState({
        detail: {},
        screenLoader: false,
      });
      toast.error("Error while fetching data!");
    }
  };
  closeDetail = () => {
    this.props.history.push("/master-licenses");
  };
  renderTooltip = (detail) => (
    <Tooltip id="button-tooltip" {...detail}>
      Lisences used by clients : {detail.noOfLicensesUsedByClient}, contractors
      : {detail.noOfLicensesUsedByContractors}
    </Tooltip>
  );
  render() {
    const { detail, screenLoader } = this.state;
    return (
      <>
        <LoadingOverlay
          spinner={<HashLoader color="#5ba6b7" size={90} />}
          active={screenLoader}
        >
          <div className="containerMaster manageLeft mb-5 item-details-section">
            {detail?.isHold && (
              <div className="disabled-card">
                <label> On Hold</label>
              </div>
            )}
            <Row className="w-100 pl-5 h-100">
              <Col lg={2} sm={12}>
                <div className="item-card pl-5">
                  <div className="text-start">
                    <img
                      width={160}
                      height={100}
                      src={
                        detail.companyIcon
                          ? `${BASE_URL_IMAGE}${detail.companyIcon}`
                          : brandHolder
                      }
                      alt="brand-icon"
                    />
                  </div>
                  <p className="item-name">{detail?.companyName}</p>
                  <p>{detail?.contactPerson}</p>
                  <p>{detail?.noOfLicenses} Licenses</p>
                  <p>{detail?.email}</p>
                  <p>{detail?.phoneNo}</p>
                </div>
              </Col>
              <Col lg={2} sm={12}>
                <div className="item-count">
                  <p>Licenses</p>
                  <p className="qty">
                    <span
                      style={{
                        textDecoration:
                          detail?.isActive == true && "line-through",
                        color: detail?.isActive == true && "red",
                      }}
                    >
                      {detail?.noOfLicenses}
                    </span>{" "}
                    {detail?.isActive == true && (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Licenses have been reduced!</Tooltip>}
                      >
                        <span
                          style={{
                            color: "#34636d",
                            cursor: "pointer",
                          }}
                        >
                          {detail.requestedLicencesCount}
                        </span>
                      </OverlayTrigger>
                    )}
                  </p>

                  <p>Licenses Used</p>
                  <p className="qty">{detail?.noOfLicensesUsed}</p>

                  <MembersList
                    totalNoOfContractors={detail?.totalNoOfContractors}
                    companyId={detail?.companyId}
                  />
                </div>
              </Col>
              <Col lg={3} sm={12}>
                <div className="categories">
                  <p>Categories</p>
                  <SimpleBar style={{ maxHeight: 400 }}>
                    {detail?.categories?.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="category d-flex">
                            <img src={sortIcon} alt="brand-icon" />
                            <p className="name">{item.categoryName}</p>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </SimpleBar>
                </div>
              </Col>
              <Col lg={3} sm={12}>
                <div className="billing">
                  <p>Billing Information</p>
                  <p>{detail?.buyerName}</p>

                  <p>{detail?.address}</p>
                  <p>{detail?.email}</p>
                  <p>Customer nr:{detail?.customerNr}</p>
                </div>
              </Col>
              <Col
                lg={2}
                sm={12}
                className="h-100 my-progress-circle d-flex flex-column justify-content-between"
              >
                <div style={{ marginTop: "1.5rem " }}>
                  <CircularProgressbarWithChildren
                    value={detail?.averageRating?.toFixed(1)}
                  >
                    <div style={{ fontSize: 10 }}>
                      <strong style={{ fontSize: "18px", fontWeight: "900" }}>
                        {detail?.averageRating?.toFixed(1)}
                      </strong>
                      <br />
                      <p
                        className="m-0 text-center"
                        style={{ marginTop: "-6px !important" }}
                      >
                        /110
                      </p>
                    </div>
                  </CircularProgressbarWithChildren>
                  <h5 className="mt-2" style={{ marginLeft: "1rem" }}>
                    Avg. Rating
                  </h5>
                </div>
                <div className="detail-buttons">
                  <button
                    onClick={() =>
                      this.setState({
                        deleteClientModal: true,
                      })
                    }
                  >
                    delete client
                  </button>

                  <EditLicense
                    id={detail?.companyId}
                    closeCard={this.props.closeCard}
                  />
                  <button
                    className="d-none"
                    onClick={() => {
                      this.props.history.push("/master-finances");
                    }}
                  >
                    see invoices
                  </button>
                  <button
                    onClick={() => {
                      this.props.closeCard();
                    }}
                  >
                    cancel
                  </button>
                </div>
              </Col>
            </Row>
            <DeleteClient
              id={this.props?.clientId}
              companyName={detail?.companyName}
              deleteLicense={this.state.deleteClientModal}
              closeDeleteClientModal={this.closeDeleteClientModal}
              updateLicensesList={this.props.LicenseDeletedSuccess}
              closeCard={this.props.closeCard}
            />
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
export default withRouter(LicenseDetails);
