import React, { useState } from "react";
import sortIcon from "../../assets/icons/sort-icon.png";
import "./MoreItems.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const MoreItems = (props) => {
  const [showMore, setShowMore] = useState(true);
  const expandMore = () => {
    setShowMore(false);
  };
  const closeMore = () => {
    setShowMore(true);
  };
  const showOrHideMoreItems = (showMoreItems, propsItems) => {
    return showMoreItems ? (
      <div className="d-flex mt-2 more-name">
        {propsItems.totalItems.slice(0, 3).map((item, index) => {
          return (
            <img key={index} src={sortIcon} width={25} height={25} alt="" />
          );
        })}
        <p className="cursor-behavior" onClick={expandMore}>
          + {propsItems.totalItems.length - 3} more
        </p>
      </div>
    ) : (
      <ul className="list-group">
        {propsItems.totalItems.slice(0).map((item, index) => {
          return (
            <li key={index} className="list-group-item pl-1">
              <img key={index} src={sortIcon} width={25} height={25} alt="" />
              <span className="list-font-size"> {item.categoryName}</span>
            </li>
          );
        })}
        <p onClick={closeMore} className="text-right">
          <FontAwesomeIcon className="cursor-behavior" icon={faTimes} />
        </p>
      </ul>
    );
  };
  return (
    <>
      {props.totalItems?.length > 3 ? (
        showOrHideMoreItems(showMore, props)
      ) : (
        <div className="d-flex mt-2 more-name">
          {props.totalItems?.map((item, index) => (
            <img key={index} src={sortIcon} width={25} height={25} alt="" />
          ))}
        </div>
      )}
    </>
  );
};

export default MoreItems;
