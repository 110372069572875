import { getMethod } from "./NetworkManager";
import { GET_TRANSLATIONS } from "../constant/ApiUrls";
import {
  getLocalStorage,
  setTranslationStorage,
} from "../utils/UserDataManagement";
export const translationData = async () => {
  if (getLocalStorage("translationsData") == null) {
    let tranlationData = await getMethod(
      GET_TRANSLATIONS + "/" + "en?fromWebsite=true"
    );
    setTranslationStorage(tranlationData?.data?.data);
    return tranlationData;
  }
};
