import React, { Component } from "react";
import {
  editUserInfo,
  fetchUserInfo,
} from "../../../../services/UserManagementServices";
import toast, { Toaster } from "react-hot-toast";
import {
  EMPTY_FIELD,
  UPDATE_ERROR,
  PHONE_ONLY,
  ALPHABET_AND_SPACE_ONLY,
} from "../../../../constant/AppConstant";
import {
  getLocalStorage,
  getLoginStorage,
  setLoginStorage,
} from "../../../../utils/UserDataManagement";
import { jsonToForm } from "../../../../utils/JsonToFormObject";
import { Card, Col, Row } from "react-bootstrap";
import rightArrow from "../../../../../assets/icons/arrow-right.png";
import { withRouter } from "react-router-dom";
import VerifyPasswordModal from "../../../../common/VerifyPasswordModal";
import {
  BASE_URL_IMAGE,
  CONTRACTOR_INSTRUCTIONS,
} from "../../../../constant/ApiUrls";
class EditAccountInfo extends Component {
  state = {
    companyName: "",
    contactPerson: "",
    phone: "",
    file: "",
    errorImage: "",
  };
  componentDidMount = async () => {
    await this.fetchAccountInfo();
  };

  fetchAccountInfo = async () => {
    let id = getLoginStorage("id");
    let info = await fetchUserInfo(id);
    console.log(info);
    if (info?.status === 200) {
      let information = info.data.data;
      this.setState({
        companyName: information.name || "",
        phone: information.phoneNo || "",
        contactPerson: information.contactPerson || "",
      });
    } else if (info.status === 401) {
      toast.error(UPDATE_ERROR);
    }
  };
  validation = async (item, type) => {
    if (type === "companyName") {
      const valid = item.target.value.length <= 50;
      if (valid) {
        this.setState({
          companyName: item.target.value.trimStart(),
          errorCompany: "",
        });
      }
    } else if (type === "contctPerson") {
      const valid =
        item.target.value.match(ALPHABET_AND_SPACE_ONLY) &&
        item.target.value.length <= 50;
      if (valid || item.target.value === "") {
        this.setState({
          contactPerson: item.target.value.trimStart(),
          errorContactperson: "",
        });
      }
    } else {
      const valid =
        item.target.value.match(PHONE_ONLY) && item.target.value.length <= 15;
      if (valid || item.target.value === "") {
        this.setState({
          phone: item.target.value.trimStart(),
          errorPhone: "",
        });
      }
    }
  };

  submitForm = async () => {
    const { companyName, contactPerson, phone, file } = this.state;
    let error = false;
    if (companyName?.trim().length === 0) {
      error = true;
      this.setState({
        errorCompany: EMPTY_FIELD,
      });
    }
    if (contactPerson?.trim().length === 0) {
      error = true;
      this.setState({
        errorContactperson: EMPTY_FIELD,
      });
    }
    if (phone?.trim().length === 0) {
      error = true;
      this.setState({
        errorPhone:
          phone.length > 15
            ? "Phone number can have max length of 15 numbers"
            : EMPTY_FIELD,
      });
    }
    if (phone.length > 15) {
      error = true;
      this.setState({
        errorPhone:
          phone.length > 15
            ? "Phone number can have max length of 15 numbers"
            : EMPTY_FIELD,
      });
    }
    if (!JSON.parse(localStorage.getItem("staySafeUserData"))["profilePic"]) {
      if (!file && getLoginStorage("isFirstLogin")) {
        error = true;
        this.setState({
          errorImage: "Please select profile image",
        });
      }
    }
    if (!error) {
      this.setState({
        showVerificationModal: true,
      });
    }
  };
  verifiedPassowrd = async () => {
    const { companyName, contactPerson, phone, fileString } = this.state;
    let requestPayload = {
      CompanyName: companyName,
      ContactPerson: contactPerson,
      PhoneNumber: phone,
      CompanyIcon: fileString ? fileString : null,
      Type: "contractor",
    };
    let formData = jsonToForm(requestPayload);
    let infoEditResponse = await editUserInfo(formData);
    if (infoEditResponse.status === 200) {
      toast.success("Data has been successfully updated");
      await this.fetchAccountInfo();
      this.setState({
        showVerificationModal: false,
      });
      if (getLoginStorage("isFirstLogin")) {
        let storage = getLocalStorage("staySafeUserData");
        storage.isFirstLogin = false;
        setLoginStorage(storage);
        this.props.history.push("contractor-licenses");
      }
    } else if (infoEditResponse.status === 401) {
      toast.error(UPDATE_ERROR);
    }
  };
  closeVerificationModal = async () => {
    this.setState({
      showVerificationModal: false,
    });
  };
  imageChange = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(e.target.value)) {
      alert(
        "Please upload correct file, allowed file types are .jpg, .jpeg, .png"
      );
      return false;
    } else {
      reader.onloadend = () => {
        let fileStringGenerator = reader.result.split(",")[1];
        this.setState({
          file: e.target.files[0],
          fileName: e.target.files[0].name,
          preview: URL.createObjectURL(e.target.files[0]),
          fileString: fileStringGenerator,
        });
        e.target.value = null;
      };
    }
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.submitForm();
    }
  };
  render() {
    const { showVerificationModal } = this.state;
    return (
      <>
        <Toaster position="top-center" reverseOrder={true} />
        <div className="edit-account d-flex flex-column">
          <label className="screen-label">Email</label>
          <input
            disabled
            value={getLoginStorage("email")}
            type="email"
            className="screen-input"
          />
          <label className="screen-label">Company Name</label>
          <input
            onChange={(item) => this.validation(item, "companyName")}
            type="text"
            className="screen-input"
            value={this.state.companyName}
            maxLength={50}
            onKeyPress={this.handleKeyPress}
          />
          <p className="text-color text-danger">{this.state.errorCompany}</p>
          <label className="screen-label">Contact Person</label>
          <input
            onChange={(item) => this.validation(item, "contctPerson")}
            type="text"
            className="screen-input"
            value={this.state.contactPerson}
            maxLength={50}
            onKeyPress={this.handleKeyPress}
          />
          <p className="text-color text-danger">
            {this.state.errorContactperson}
          </p>
          <label className="screen-label">Phone Nr.</label>
          <input
            onChange={(item) => this.validation(item, "phone")}
            type="text"
            className="screen-input"
            value={this.state.phone}
            maxLength={15}
            onKeyPress={this.handleKeyPress}
          />
          <p className="text-color text-danger">{this.state.errorPhone}</p>
          {getLoginStorage("isFirstLogin") && (
            <div>
              <input
                type="file"
                onChange={this.imageChange}
                id="uploadImage"
                style={{ display: "none" }}
              />
              <button
                className="screen-button"
                onClick={() => document.getElementById("uploadImage").click()}
              >
                {this.state.fileName ? "Change Image" : "Upload Image"}
              </button>
              <p className="text-info">{this.state.fileName}</p>
              {this.state.fileName && (
                <Card style={{ width: "18rem", height: "10rem" }}>
                  <Card.Img
                    style={{ width: "100%", height: "100%" }}
                    variant="top"
                    src={this.state.preview}
                  />
                </Card>
              )}
              {getLoginStorage("isFirstLogin") &&
                getLoginStorage("profilePic") &&
                this.state.file == "" && (
                  <Card style={{ width: "18rem", height: "10rem" }}>
                    <Card.Img
                      style={{ width: "100%", height: "100%" }}
                      variant="top"
                      src={
                        BASE_URL_IMAGE +
                        JSON.parse(localStorage.getItem("staySafeUserData"))[
                          "profilePic"
                        ]
                      }
                    />
                  </Card>
                )}
              <p className="text-color text-danger">{this.state.errorImage}</p>

              {this.state.fileName && (
                <button
                  className="screen-button"
                  onClick={() =>
                    this.setState({
                      file: "",
                      fileName: "",
                      preview: "",
                    })
                  }
                >
                  Cancel
                </button>
              )}
            </div>
          )}
          <Row className="mb-3">
            <Col lg={6}>
              {!getLoginStorage("isFirstLogin") && (
                <button onClick={this.submitForm} className="screen-button">
                  Send
                </button>
              )}
            </Col>
            <Col lg={3}>
              {getLoginStorage("isFirstLogin") && (
                <a
                  href={CONTRACTOR_INSTRUCTIONS}
                  download={CONTRACTOR_INSTRUCTIONS}
                  target="_blank"
                >
                  <button className="screen-button w-100">
                    Download Instructions
                  </button>
                </a>
              )}
            </Col>
            <Col lg={2}>
              {getLoginStorage("isFirstLogin") && (
                <button
                  onClick={this.submitForm}
                  className="screen-button"
                  style={{ marginLeft: "auto", marginRight: "11.5rem" }}
                >
                  <img src={rightArrow} alt="" />
                </button>
              )}
            </Col>
          </Row>
          <VerifyPasswordModal
            show={showVerificationModal}
            close={this.closeVerificationModal}
            updateFunction={this.verifiedPassowrd}
          />
        </div>
      </>
    );
  }
}
export default withRouter(EditAccountInfo);
