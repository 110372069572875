import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Spinner, Tab } from "react-bootstrap";
import "./CreateQuestion.css";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import ScrollBar from "react-scrollbar";
import "./questionType/QuestionType.css";
import cross_faded from "../../../../../assets/images/cross_unfaded.png";
import selected_img from "../../../../../assets/images/selected_img.png";
import { getKeyTranslation } from "../../../../utils/Translations";
import {
  addQuestions,
  deleteQuestion,
  exportEmptyTemplate,
  importQuestion,
  exportQuestions,
  getCategoryQuestions,
  uploadQuestionImage,
  getContentPackReportingQuestions,
  deleteReportedQuestion,
} from "../../../../services/ClientServices";
import { BASE_URL_IMAGE } from "../../../../constant/ApiUrls";
import question_back from "../../../../../assets/icons/question_back.png";
import DropdownIndicator from "../../../../common/DropdownIndicator";
import {
  findFlawQuestionOption,
  multipleQuestionOption,
  questionTranslation,
  singleQuestionOption,
  trueFalseQuestionOption,
} from "../contentData";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";
import PreviewQuestion from "./previewQuestion/PreviewQuestion";
import PopOverExport from "./popOverExport/PopOverExport";
import QuestionList from "./questionList/QuestionList";
import EditorWarningPopup from "./editorWarningPopup/EditorWarningPopup";
import ImportSuccessPopup from "./importSuccessPopup/ImportSuccessPopup";
import QuestionType from "./questionType/QuestionType";
import TypeChangeWarningPopup from "./questionType/TypeChangeWarningPopup";
import CreateImgGrid from "../../../../common/CreateImgGrid";

const CreateQuestion = () => {
  const [createQuestionArray, setCreateQuestionArray] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [imgArr, setImgArr] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [defaultLanguage, setDefaultLanguage] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalText, setModalText] = useState("");
  // child start here
  const [questionTypes, setQuestionTypes] = useState(1);

  const [loader, setLoader] = useState(false);
  const [optionsSingle, setOptionsSingle] = useState(
    singleQuestionOption(defaultLanguage)
  );
  const [optionsMultiple, setOptionsMultiple] = useState(
    multipleQuestionOption(defaultLanguage)
  );
  const [optionsFlaw, setOptionsFlaw] = useState(
    findFlawQuestionOption(defaultLanguage)
  );
  const [optionsTrueFalse, setOptionsTrueFalse] = useState(
    trueFalseQuestionOption(defaultLanguage)
  );
  const [questionTitlesArr, setQuestionTitlesArr] = useState(
    questionTranslation(defaultLanguage)
  );
  const [gridType, setGridType] = useState("4x4");
  let category_id = JSON.parse(
    localStorage.getItem("question_category")
  ).categoryId;
  const [arrayToSend, setArrayToSend] = useState([]);
  const [deleteInd, setDeleteInd] = useState(Number);
  const [saveLoader, setSaveLoader] = useState(false);
  const [importLoader, setImportLoader] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [questionCount, setQuestionCount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const jobSettings = JSON.parse(localStorage.getItem("jobTypes"));

  useEffect(() => {
    initial_state();
  }, []);
  useEffect(() => {
    if (createQuestionArray.length > 0) {
      let arr = [...createQuestionArray];
      let existQuestion = false;
      let existOption = false;
      arr.forEach((item) => {
        item.questionTitles.map((obj) => {
          if (selectedLanguage === obj.languageId) {
            existQuestion = true;
          }
          return obj;
        });
        item.options.map((op) => {
          op.optionTitles.map((o) => {
            if (selectedLanguage === o.languageId) {
              existOption = true;
            }
          });
          return op;
        });
        if (!existQuestion) {
          item.questionTitles.push({
            languageId: selectedLanguage,
            questionTitle: "",
          });
        }
        if (!existOption) {
          if (item.typeId != 3)
            item.options.forEach((element) => {
              element.optionTitles.push({
                languageId: selectedLanguage,
                optionTitle: "",
              });
            });
        }
        existQuestion = false;
        existOption = false;
      });
      setCreateQuestionArray(arr);
    }
  }, [selectedLanguage]);
  const new_question_Structure = (def_lan) => {
    return {
      questionId: 0,
      typeId: 1,
      matrixSize: "",
      categoryId: JSON.parse(localStorage.getItem("question_category"))
        .categoryId,
      jobDescriptionId: 1,
      image: "",
      questionTitles: JSON.parse(JSON.stringify(questionTranslation(def_lan))),
      options: JSON.parse(JSON.stringify(singleQuestionOption(def_lan))),
    };
  };
  const options = languages?.map((item, i) => {
    return {
      value: item.id,
      label: (
        <>
          <img
            height={16}
            width={20}
            src={BASE_URL_IMAGE + "Flags/" + item?.icon}
          />
          <span>
            {"  "} {item?.title}
          </span>
        </>
      ),
    };
  });
  const showWarningChangeType = () => {
    setShowModal(true);
  };
  const closeWarningChangeType = () => {
    setShowModal(false);
  };
  const initial_state = async () => {
    setLoader(true);
    setLanguages(
      JSON.parse(localStorage.getItem("question_category")).storage_languages
    );
    const default_lan = JSON.parse(
      localStorage.getItem("question_category")
    ).storage_languages.filter((i) => {
      return i.isDefault == true;
    })[0].id;
    setDefaultLanguage(default_lan);
    setSelectedLanguage(default_lan);

    setOptionsSingle(
      optionsSingle.map((item) => {
        item.optionTitles[0].languageId = default_lan;
        return item;
      })
    );
    setQuestionTitlesArr(
      questionTitlesArr.map((item) => {
        item.languageId = default_lan;
        return item;
      })
    );
    setOptionsMultiple(
      optionsMultiple.map((item) => {
        item.optionTitles[0].languageId = default_lan;
        return item;
      })
    );
    setOptionsFlaw(
      optionsFlaw.map((item) => {
        item.optionTitles[0].languageId = default_lan;
        return item;
      })
    );
    setOptionsTrueFalse(
      optionsTrueFalse.map((item) => {
        item.optionTitles[0].languageId = default_lan;
        return item;
      })
    );
    callingContentApi(default_lan);
  };
  const callingContentApi = async (def_lan) => {
    setLoader(true);
    let searchUrl = `?CategoryId=${category_id}`;
    let result;
    if (localStorage.getItem("tab_visibility") == "reporting") {
      result = await getContentPackReportingQuestions(searchUrl);
    } else {
      result = await getCategoryQuestions(searchUrl);
    }

    let newArr = [];
    if (result?.status == 200) {
      if (result.data.data.items.length == 0) {
        if (localStorage.getItem("tab_visibility") == "reporting") {
          window.location = "/content";
        } else {
          let newQuestionStructure = new_question_Structure(def_lan);
          newArr.push(newQuestionStructure);
          setCreateQuestionArray(newArr);
          setArrayToSend(newArr);
          let arrImg = [...imgArr];
          arrImg.push("");
          setImgArr(arrImg);
        }
      } else {
        renderingQuestions(result?.data?.data.items, false, true);
      }
      setLoader(false);
    } else {
      setLoader(false);
      toast.error(getKeyTranslation(result?.data?.message));
    }
  };
  const renderingQuestions = (items, import_check, initial_render) => {
    let arrImg = [];
    let arr = items.map((item) => {
      let opts = item.options.map((op) => {
        return {
          isCorrect: op.correct,
          optionId: op.id,
          optionTitles: op.translations,
        };
      });

      let nameIndex = item.image.lastIndexOf("/");
      let name = item.image.substring(nameIndex + 1);
      arrImg.push(name);

      return {
        questionId: item.id,
        typeId: item.typeId,
        matrixSize: item.matrixSize,
        categoryId: item.categoryId,
        jobDescriptionId: item.difficultyId,
        image: item.image,
        questionTitles: item.translations,
        options: opts,
      };
    });
    setImgArr(arrImg);
    if (!initial_render) {
      setCreateQuestionArray(createQuestionArray.concat(arr));
    } else {
      setCreateQuestionArray(arr);
    }
    if (import_check) {
      setArrayToSend(arrayToSend.concat(arr));
    }
  };
  const onchangeLanguage = (e) => {
    setSelectedLanguage(e.value);
  };
  const deleteQuestionClick = async (event, question_number, tab_number) => {
    setModalText("delete");
    setShowErrorModal(true);
    setDeleteInd(question_number);
  };
  const objectsEqual = (o1, o2) => {
    return typeof o1 === "object" && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
          Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
      : o1 === o2;
  };
  const deleteConfirm = async () => {
    let arr = [...createQuestionArray];
    if (arr[deleteInd].questionId != 0 && arr[deleteInd].questionId !== "") {
      let q_id = {
        questionId: arr[deleteInd].questionId,
      };
      let result = await deleteQuestion(q_id);
      if (result?.status === 200) {
        setSelectedTabIndex(0);
        let question_id = arr[deleteInd].questionId;
        arr.splice(deleteInd, 1);
        let arr_Img = [...imgArr];
        arr_Img.splice(deleteInd, 1);
        setImgArr(arr_Img);
        setCreateQuestionArray(arr);
        let arr2 = [...arrayToSend];
        let index_to_delete = -1;
        arr2.forEach((item, ind) => {
          if (item.questionId === question_id) {
            index_to_delete = ind;
          }
        });

        // if arraytosend has deleted question
        if (index_to_delete != -1) {
          arr2.splice(index_to_delete, 1);
        }
        setArrayToSend(arr2);

        setShowErrorModal(false);
        toast.success(getKeyTranslation(result.data.message));
      } else {
        setShowErrorModal(false);
        toast.error(getKeyTranslation(result.data.message));
      }
    } else {
      //in case delete has 0 index
      let arr2 = [...arrayToSend];
      let index_to_delete = -1;
      arr2.forEach((item, ind) => {
        if (
          objectsEqual(item.questionTitles, arr[deleteInd].questionTitles) &&
          objectsEqual(item.options, arr[deleteInd].options)
        ) {
          index_to_delete = ind;
        }
      });
      console.log(index_to_delete);
      arr.splice(deleteInd, 1);
      let arr_Img = [...imgArr];
      arr_Img.splice(deleteInd, 1);
      setImgArr(arr_Img);
      setCreateQuestionArray(arr);
      setSelectedTabIndex(0);
      // if arraytosend has deleted question
      if (index_to_delete !== -1) {
        arr2.splice(index_to_delete, 1);
      }
      setArrayToSend(arr2);
      setShowErrorModal(false);
    }
  };
  const emptyQuestionCheck = (q) => {
    let empty_check = false;
    q.questionTitles.map((item) => {
      if (item.languageId === defaultLanguage) {
        if (item.questionTitle == "") {
          empty_check = true;
        }
      }
    });
    if (q.image == "") {
      empty_check = true;
    }
    q.options.map((item) => {
      item.optionTitles.map((i) => {
        if (i.languageId === defaultLanguage) {
          if (i.optionTitle == "") {
            empty_check = true;
          }
        }
      });
    });
    return empty_check;
  };
  const openOrCloseValidationPopup = (invalid_check) => {
    if (invalid_check) {
      setModalText("Please fill all questions data correctly");
      setShowErrorModal(true);
      return false;
    } else {
      setModalText("");
      setShowErrorModal(false);
      setSelectedLanguage(defaultLanguage);
      return true;
    }
  };
  const validateUpdatedQuestion = (qsn) => {
    if (qsn) {
      let question_invalid = false;
      if (qsn.typeId == 1 || qsn.typeId == 2 || qsn.typeId == 4) {
        question_invalid = emptyQuestionCheck(qsn);
        //option iscorrect validation
        if (
          qsn.options.filter((item) => {
            return item.isCorrect == true;
          }).length <= 0
        ) {
          question_invalid = true;
        }
        return openOrCloseValidationPopup(question_invalid);
      } else if (qsn.typeId == 3) {
        question_invalid = emptyQuestionCheck(qsn);
        if (qsn.matrixSize == "") {
          question_invalid = true;
        }
        return openOrCloseValidationPopup(question_invalid);
      }
    }
  };
  const ontabclick = (item, ind) => {
    setSelectedTabIndex(ind);
  };

  const createNewQuestion = () => {
    let newQuestionStructure = new_question_Structure(defaultLanguage);
    let newArr = [...createQuestionArray];
    newArr.push(newQuestionStructure);
    setSelectedLanguage(defaultLanguage);
    setCreateQuestionArray(newArr);
    setSelectedTabIndex(newArr.length - 1);
    let arrImg = [...imgArr];
    arrImg.push("");
    setImgArr(arrImg);
    let array_to_send = [...arrayToSend];
    array_to_send.push(newQuestionStructure);
    setArrayToSend(array_to_send);
  };
  const save = async (item) => {
    let send_check = true;
    for (const index of arrayToSend.keys()) {
      if (!validateUpdatedQuestion(arrayToSend[index])) {
        send_check = false;
        break;
      }
    }
    if (send_check) {
      if (
        localStorage.getItem("tab_visibility") !== "reporting" ||
        item == "save"
      ) {
        setSaveLoader(true);
      }
      let removedEmptyTitleArray = arrayToSend.map((item) => {
        let new_i = item.questionTitles.filter((q) => q.questionTitle !== "");
        let new_options = item.options.map((op) => {
          let new_opt = (op.optionTitles = op.optionTitles.filter(
            (o) => o.optionTitle !== ""
          ));
          op.optionTitles = new_opt;
          return op;
        });
        //removed empty titles
        item.questionTitles = new_i;
        item.options = new_options;
        return item;
      });

      let result = await addQuestions(removedEmptyTitleArray);
      if (result?.status == 200) {
        setSaveLoader(false);
        setImgArr([]);
        setArrayToSend([]);
        callingContentApi();
        setSelectedLanguage(defaultLanguage);
        if (
          localStorage.getItem("tab_visibility") !== "reporting" ||
          item == "save"
        ) {
          toast.success(getKeyTranslation(result?.data?.message));
        } else {
          if (item !== "save") {
            removeReport(item);
          }
        }
        return true;
      } else {
        setSaveLoader(false);
        setSelectedLanguage(defaultLanguage);
        toast.error(getKeyTranslation(result?.data?.message));
        return false;
      }
    } else {
      return false;
    }
  };

  {
    console.log("createQuestionArray", createQuestionArray);
    console.log("imgArr", imgArr);
  }
  //child starts here
  const singleMultipleTypeClick = (selection) => {
    return (selection.options = selection.options.map((item) => {
      let exist = item.optionTitles.filter(
        (i) => i.languageId == selectedLanguage
      ).length;
      if (!exist) {
        item.optionTitles.push({
          languageId: selectedLanguage,
          optionTitle: "",
        });
      }
      return item;
    }));
  };
  const questionTypeTabClick = (type) => {
    let arr = [...createQuestionArray];
    arr[selectedTabIndex].typeId = type;
    if (questionTypes == 1 && type == 2) {
      let value2 = JSON.parse(
        JSON.stringify(multipleQuestionOption(selectedLanguage))
      );
      let copy_into_multiple = value2.map((item, ind) => {
        if (arr[selectedTabIndex].options[ind]) {
          item.optionTitles = arr[selectedTabIndex].options[ind].optionTitles;
          item.isCorrect = false;
          return item;
        } else {
          let lastindex = JSON.parse(
            JSON.stringify(arr[selectedTabIndex].options[0].optionTitles)
          );
          item.optionTitles = lastindex;
          item.optionTitles.map((i) => {
            i.optionTitle = "";
          });
          item.isCorrect = false;
          return item;
        }
      });
      arr[selectedTabIndex].options = copy_into_multiple;
      setCreateQuestionArray(arr);
    } else if (questionTypes == 2 && type == 1) {
      let value2 = JSON.parse(
        JSON.stringify(singleQuestionOption(selectedLanguage))
      );
      let copy_into_single = value2.map((item, ind) => {
        if (arr[selectedTabIndex].options[ind]) {
          item.optionTitles = arr[selectedTabIndex].options[ind].optionTitles;

          item.isCorrect = false;
          return item;
        }
        return item;
      });
      arr[selectedTabIndex].options = copy_into_single;

      setCreateQuestionArray(arr);
    } else if (type == 1) {
      arr[selectedTabIndex].options = JSON.parse(
        JSON.stringify(singleQuestionOption(selectedLanguage))
      );
      singleMultipleTypeClick(arr[selectedTabIndex]);
    } else if (type == 2) {
      arr[selectedTabIndex].options = JSON.parse(
        JSON.stringify(multipleQuestionOption(selectedLanguage))
      );
      singleMultipleTypeClick(arr[selectedTabIndex]);
    } else if (type == 3) {
      arr[selectedTabIndex].options = JSON.parse(
        JSON.stringify(findFlawQuestionOption(selectedLanguage))
      );
      arr[selectedTabIndex].matrixSize = gridType;
    } else if (type == 4) {
      arr[selectedTabIndex].options = JSON.parse(
        JSON.stringify(trueFalseQuestionOption(selectedLanguage))
      );
    }
    setCreateQuestionArray(arr);

    setQuestionTypes(type);
  };
  const handleJob = (id, ele, item) => {
    document.getElementById(ele).checked = true;
    let arr = [...createQuestionArray];
    if (arr.length > 0) {
      arr[selectedTabIndex].jobDescriptionId = id;
      setCreateQuestionArray(arr);
      initializeArrayToSend(arr[selectedTabIndex]);
    }
  };
  const removeReport = async (item) => {
    let obj = {
      questionId: item?.questionId,
      languageId: selectedLanguage,
    };

    const result = await deleteReportedQuestion(obj);
    if (result?.status == 200) {
      toast.success(getKeyTranslation(result.data.message));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error(getKeyTranslation(result.data.message));
    }
  };
  const radioButtons = (i, qusetion_index_no) => {
    return (
      <>
        <h6 className="editor-dark-color" style={{ fontSize: "16px" }}>
          Assign to Job Description
        </h6>
        <div className="content-checkbox">
          <div className="customized-radio-btn">
            <div className="radios d-flex flex-row justify-content-between w-100">
              {jobSettings?.map((item, ind) => {
                return (
                  <div
                    key={ind}
                    className="d-flex flex-row align-items-center"
                    onClick={(e) =>
                      handleJob(ind + 1, `${ind + 1}job${qusetion_index_no}`, i)
                    }
                  >
                    <input
                      type="radio"
                      name={"job_q_" + qusetion_index_no}
                      checked={i.jobDescriptionId == `${ind + 1}`}
                      onChange={(e) =>
                        handleJob(1, 1 + "job" + qusetion_index_no)
                      }
                      id={`${ind + 1}job${qusetion_index_no}`}
                    />
                    <label className="radio" htmlFor="job"></label>
                    <span className="radio-button-label">
                      {item.jobDescription}
                    </span>
                  </div>
                );
              })}
            </div>
            {/* </div> */}
          </div>
        </div>
      </>
    );
  };
  const initializeArrayToSend = (obj) => {
    let arr_to_send = [...arrayToSend];
    let if_exist = false;
    let exist_id;
    arr_to_send.forEach((item, ind) => {
      if (item.questionId == obj.questionId && obj.questionId != 0) {
        if_exist = true;
        exist_id = ind;
      }
    });
    if (if_exist) {
      arr_to_send[exist_id] = obj;
    } else if (obj.questionId != 0) {
      arr_to_send.push(obj);
    }
    setArrayToSend(arr_to_send);
  };
  const onchangeQuestion = (e, type, index) => {
    if (e.target.value.length <= 200) {
      let arr = [...createQuestionArray];
      let questions_title = arr[index].questionTitles;
      questions_title?.map((item) => {
        if (selectedLanguage === item.languageId) {
          return (item.questionTitle = e.target.value);
        }
        return item;
      });
      setCreateQuestionArray(arr);
      initializeArrayToSend(arr[index]);
    }
  };
  {
    console.log(arrayToSend, "arrayToSend");
  }
  const formElement = (evt) => {
    if (evt.target.value) {
      try {
        evt.target.value = ""; //for IE11, latest Chrome/Firefox/Opera...
      } catch (err) {}
      if (evt.target.value) {
        //for IE5 ~ IE10
        var form = document.createElement("form"),
          parentNode = evt.target.parentNode,
          ref = evt.target.nextSibling;
        form.appendChild(evt);
        form.reset();
        parentNode.insertBefore(evt, ref);
      }
    }
  };
  const removeImg = (i) => {
    let arr = [...createQuestionArray];
    arr[selectedTabIndex].image = "";
    setCreateQuestionArray(arr);
    let img_arr = [...imgArr];
    img_arr[selectedTabIndex] = "";
    setImgArr(img_arr);
    initializeArrayToSend(i);
  };
  const form_formatted = (obj_1, form_data) => {
    Object.keys(obj_1).forEach((key) => {
      form_data.append(`${key}`, obj_1[key]);
    });
    return form_data;
  };

  const uploadImg = async (e, first_id, img_val, ind, q_id) => {
    e.preventDefault();
    let uploaded_file = e.target.files[0];
    if (img_val == "") {
      if (e.target.files[0]) {
        setLoader(true);
        let obj = {
          ImageFile: uploaded_file,
          questionId: q_id,
        };
        const upload = await uploadQuestionImage(
          form_formatted(obj, new FormData())
        );
        if (upload?.status === 200) {
          let arr = [...createQuestionArray];
          let img_ar = [...imgArr];
          img_ar[selectedTabIndex] = uploaded_file.name;
          setImgArr(img_ar);
          arr[selectedTabIndex].image = upload.data.data;
          setCreateQuestionArray(arr);
          e.target.value = null;
          toast.success(getKeyTranslation(upload.data.message));
          setTimeout(() => {
            setLoader(false);
          }, 2000);
        } else {
          toast.error(getKeyTranslation(upload.data.message));
          setLoader(false);
        }
      }
    }

    formElement(e);
  };
  const onchangeSingleMultipleTrueFalse = (e, index) => {
    if (e.target.value.length <= 350) {
      let arr_ = [...createQuestionArray];
      let value = arr_[selectedTabIndex].options;
      value[index].optionTitles.map((element) => {
        if (element.languageId === selectedLanguage) {
          element.optionTitle = e.target.value;
        }
        return element;
      });
      let arr = [...createQuestionArray];
      arr[selectedTabIndex].options = value;
      setCreateQuestionArray(arr);
      initializeArrayToSend(arr[selectedTabIndex]);
    }
  };
  const multipleChecked = (id, ind) => {
    let arr = [...createQuestionArray];
    arr[selectedTabIndex].options.map((item, i) => {
      if (i == ind && item.isCorrect == false) {
        return (item.isCorrect = true);
      } else if (i == ind && item.isCorrect == true) {
        return (item.isCorrect = false);
      }
      return item;
    });
    setCreateQuestionArray(arr);
    initializeArrayToSend(arr[selectedTabIndex]);
  };
  const singleOrTrueFalseChecked = (id, ind) => {
    let arr = [...createQuestionArray];
    arr[selectedTabIndex].options.map((item, i) => {
      if (i == ind) {
        return (item.isCorrect = true);
      } else {
        item.isCorrect = false;
      }
      return item;
    });
    setCreateQuestionArray(arr);
    initializeArrayToSend(arr[selectedTabIndex]);
  };
  const exportQuestionClick = async () => {
    let param = `?CategoryId=${category_id}&LanguageId=${selectedLanguage}`;
    const l_name = languages.filter((item) => {
      return item.id == selectedLanguage;
    })[0].title;
    let category_name =
      JSON.parse(localStorage.getItem("question_category")).categoryName +
      `_${l_name}`;
    let result = await exportQuestions(param, category_name);
    if (result?.status === 200) {
      toast.success("Data exported");
    } else {
      toast.error(getKeyTranslation(result.message));
    }
  };
  const exportEmptyClick = async () => {
    const l_name = languages.filter((item) => {
      return item.id == selectedLanguage;
    })[0].title;
    let category_name =
      JSON.parse(localStorage.getItem("question_category")).categoryName +
      `_${l_name}`;
    let result = await exportEmptyTemplate(category_name);
    if (result?.status === 200) {
      toast.success("Data exported");
    } else {
      toast.error(getKeyTranslation(result.message));
    }
  };
  const gridTypeClicked = (value) => {
    let arr = [...createQuestionArray];
    arr[selectedTabIndex].matrixSize = value;
    arr[selectedTabIndex].options[0].optionTitles[0].optionTitle = "";
    setGridType(value);
    setCreateQuestionArray(arr);
    initializeArrayToSend(arr[selectedTabIndex]);
  };
  const selectedGridsFunction = (r, c, matrix) => {
    let arr_ = [...createQuestionArray];
    let pins = arr_[selectedTabIndex].options[0].optionTitles[0].optionTitle;
    let pins_array = [];
    if (pins != "") {
      pins_array = pins.split(",");
    } else {
      pins_array = [];
    }
    let selectedPair = `${c}${r}`;
    if (pins_array.includes(selectedPair)) {
      pins_array.splice(
        pins_array.findIndex((v) => v === selectedPair),
        1
      );
    } else {
      if (pins_array.length < 4) {
        pins_array.push(selectedPair);
      }
    }
    arr_[selectedTabIndex].options[0].optionTitles[0].optionTitle =
      pins_array.toString();
    arr_[selectedTabIndex].matrixSize = matrix;
    setCreateQuestionArray(arr_);
    initializeArrayToSend(arr_[selectedTabIndex]);
  };

  const replace = (re, object_structure, importLanguage) => {
    let replace_ = re.map((q, ind1) => {
      if (object_structure.options.length) {
        q.isCorrect = object_structure.options[ind1].isCorrect;

        q.optionTitles.map((q2, ind2) => {
          if (object_structure.typeId == 3 && ind2 == 0) {
            q2.optionTitle =
              object_structure.options[ind1].optionTitles[0].optionTitle;
            q2.languageId = defaultLanguage;
          }
          if (q2.languageId === importLanguage) {
            q2.optionTitle =
              object_structure.options[ind1].optionTitles[0].optionTitle;
            return q2;
          } else {
            return q2;
          }
        });
      }
      console.log(q);
      return q;
    });

    console.log(replace_);
    return replace_;
  };
  const importClick = async (e) => {
    let wronge_import = false;
    if (e.target.files[0] != 0) {
      let obj = {
        File: e.target.files[0],
        languageId: selectedLanguage,
        categoryId: category_id,
      };
      setImportLoader(true);
      const result = await importQuestion(form_formatted(obj, new FormData()));
      if (result?.status == 200) {
        let create_question_array = [...createQuestionArray];
        let array_to_send = [...arrayToSend];
        let arrImg = [...imgArr];
        result.data.data.forEach((imported_item) => {
          let opts = imported_item.options.map((op) => {
            return {
              isCorrect: op.correct,
              optionTitles: op.translations,
              optionId: op.id,
            };
          });
          let object_structure = {
            questionId: imported_item.id,
            typeId: imported_item.typeId,
            matrixSize: imported_item.matrixSize,
            categoryId: imported_item.categoryId,
            jobDescriptionId: imported_item.difficultyId,
            image: imported_item.image,
            questionTitles: imported_item.translations,
            options: opts,
          };
          if (imported_item.id == 0 || imported_item.id == "") {
            arrImg.push("");
            if (object_structure.typeId == 3) {
              object_structure.options[0].optionTitles[0].languageId =
                defaultLanguage;
            }
            create_question_array.push(object_structure);
            array_to_send.push(object_structure);
          } else {
            let existing_question_index = create_question_array.findIndex(
              (ind) => ind.questionId === object_structure.questionId
            );
            //in case id is correct w.r.t category
            if (existing_question_index != -1) {
              // import language
              let importLanguage =
                object_structure.questionTitles[0].languageId;
              // if import language already exist in create question array
              let alreadyLanguage = false;
              create_question_array[
                existing_question_index
              ].questionTitles.forEach((i) => {
                if (i.languageId == importLanguage) {
                  alreadyLanguage = true;
                }
              });
              create_question_array[existing_question_index].matrixSize =
                object_structure.matrixSize;
              create_question_array[existing_question_index].jobDescriptionId =
                imported_item.difficultyId;
              object_structure.image =
                create_question_array[existing_question_index].image;
              // arrange question array approved*
              create_question_array[existing_question_index].questionTitles =
                alreadyLanguage
                  ? create_question_array[
                      existing_question_index
                    ].questionTitles.map((q) => {
                      if (q.languageId === importLanguage) {
                        return object_structure.questionTitles[0];
                      } else {
                        return q;
                      }
                    })
                  : create_question_array[
                      existing_question_index
                    ].questionTitles.push(object_structure.questionTitles[0]);

              create_question_array[existing_question_index].options =
                alreadyLanguage
                  ? replace(
                      create_question_array[existing_question_index].options,
                      object_structure,
                      importLanguage
                    )
                  : create_question_array[existing_question_index].options.map(
                      (q, ind1) => {
                        q.isCorrect = object_structure.options[ind1].isCorrect;
                        q.optionTitles.push(
                          object_structure.options[ind1].optionTitles
                        );
                      }
                    );
            } else {
              wronge_import = true;
            }
            let exist_in_to_send = -1;
            if (array_to_send.length) {
              exist_in_to_send = array_to_send?.findIndex(
                (x) =>
                  x.questionId ===
                  create_question_array[existing_question_index]?.questionId
              );
            } else {
              exist_in_to_send = -1;
            }
            if (exist_in_to_send != -1) {
              array_to_send.splice(existing_question_index, 1);
              array_to_send.push(
                create_question_array[existing_question_index]
              );
            } else if (existing_question_index != -1) {
              array_to_send.push(
                create_question_array[existing_question_index]
              );
            }
          }
        });
        if (!wronge_import) {
          setCreateQuestionArray(create_question_array);
          setArrayToSend(array_to_send);
          setImgArr(arrImg);
          setImportLoader(false);
          setQuestionCount(result?.data?.data?.length);
          setShowSuccessModal(true);
          toast.success(getKeyTranslation(result?.data?.message));
        } else {
          toast.error("Error importing some questions");
          setImportLoader(false);
        }
      } else {
        setImportLoader(false);
        getKeyTranslation(result?.data?.message);
      }
    }
    formElement(e);
  };
  const selectedQuestionValue = (item) => {
    let myItem = item?.filter((q) => {
      return q.languageId == selectedLanguage;
    });
    if (myItem[0]?.questionTitle.length) return myItem[0]?.questionTitle;
    else return "";
  };
  const backClick = () => {
    setModalText("back");
    arrayToSend.length ? setShowErrorModal(true) : goToBack();
  };
  const goToBack = () => {
    window.location = "/content-detail";
  };
  const questions = (item, ind) => {
    return (
      <>
        <h6 className="editor-dark-color pt-3" style={{ fontSize: "16px" }}>
          Question
        </h6>
        <div className="position-relative">
          <textarea
            style={{ resize: "none" }}
            value={selectedQuestionValue(item?.questionTitles)}
            onChange={(e) => onchangeQuestion(e, 1, ind)}
            className="p-2 preview-text"
          />
          <span
            id="display_count"
            style={{
              position: "absolute",
              top: "60%",
              left: "82%",
              width: "46px",
              textAlign: "right",
              fontSize: "12px",
            }}
          >
            {selectedQuestionValue(item?.questionTitles).length}
            /200
          </span>
        </div>
      </>
    );
  };

  const renderUploadAndCancelButton = (item, ind, type_name) => {
    return (
      <div className="d-flex flex-row w-100 my-4">
        <div className="col-6 d-flex align-items-start">
          <h6 className="editor-dark-color" style={{ fontSize: "16px" }}>
            Image
          </h6>

          {item.image == "" && (
            <button
              className="upload-qsn-img position-relative"
              style={{
                cursor: "pointer",
                fontSize: "15px",
              }}
            >
              Upload
              <input
                className="custom-file-upload-detail"
                type="file"
                style={{
                  pointerEvents: item.image == "" ? "" : "none",
                }}
                id={`${type_name}${ind}`}
                onChange={(e) =>
                  uploadImg(
                    e,
                    `${type_name}${ind}`,
                    item.image,
                    ind,
                    item.questionId
                  )
                }
                accept="image/png, image/gif, image/jpeg"
              />
            </button>
          )}
        </div>
        <div className="col-6 d-flex justify-content-end align-items-baseline ">
          {item.image !== "" && (
            <>
              <p
                className="me-3 preview-text"
                style={{
                  fontSize: "12px",
                  wordBreak: "break-word",
                }}
              >
                {imgArr[ind]}
              </p>
              <img
                height={10}
                width={10}
                src={cross_faded}
                className="close"
                onClick={() => removeImg(item)}
                style={{
                  cursor: "pointer",
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />

      <div className="my-section h-100 d-flex justify-content-between sidebar-for-mobile">
        <div className="my-container p-0">
          <LoadingOverlay
            spinner={<HashLoader color="#5ba6b7" size={100} />}
            active={loader}
          >
            <div className="myDiv" style={{ minHeight: "100vh" }} id="detail">
              <div className="create-question" style={{ minHeight: "100vh" }}>
                <Row
                  className=""
                  style={{ borderBottom: "1px solid #E2E2E2", zIndex: "-1" }}
                >
                  <Col lg={3} className="d-flex flex-row align-items-center">
                    <p
                      style={{
                        cursor: "pointer",
                        background: "#E2E2E2",
                        width: "40px",
                      }}
                      onClick={backClick}
                      className="m-0 d-flex align-items-center justify-content-center h-100"
                    >
                      <img src={question_back} />
                    </p>
                    <p className="mb-0 ps-3">
                      {
                        JSON.parse(localStorage.getItem("question_category"))
                          .categoryName
                      }
                      ,{" "}
                      {moment(
                        JSON.parse(localStorage.getItem("question_category"))
                          .createdAt
                      ).format("DD.MM.YYYY")}
                    </p>
                  </Col>
                  <Col lg={3} className="py-2">
                    <div className="d-flex flex-row align-items-center text-bold">
                      <h6 className="editor-dark-color mb-0">Language&nbsp;</h6>
                      {languages.length > 0 && (
                        <ReactSelect
                          className="w-50"
                          options={options}
                          components={{
                            DropdownIndicator,
                            IndicatorSeparator: () => null,
                          }}
                          onFocus={false}
                          onChange={onchangeLanguage}
                          isSearchable={false}
                          value={options.filter(function (option) {
                            return option.value === selectedLanguage;
                          })}
                        />
                      )}
                    </div>
                  </Col>
                  <Col lg={6} className="">
                    <div className="h-100 align-items-center d-flex justify-content-start create-content-buttons">
                      {localStorage.getItem("tab_visibility") !==
                        "reporting" && (
                        <PopOverExport
                          exportQuestionClick={exportQuestionClick}
                          exportEmptyClick={exportEmptyClick}
                          question_length={createQuestionArray.length}
                        />
                      )}
                      {localStorage.getItem("tab_visibility") !==
                        "reporting" && (
                        <button className="btn-excel save me-3 position-relative w-auto px-4 py-2">
                          {importLoader ? (
                            <Spinner
                              animation="border"
                              role="status"
                              style={{ height: "23px", width: "23px" }}
                            >
                              <span
                                className="visually-hidden"
                                style={{
                                  height: "23px",
                                  width: "23px",
                                }}
                              >
                                Loading...
                              </span>
                            </Spinner>
                          ) : (
                            "IMPORT .XCL"
                          )}
                          <input
                            className="custom-file-upload-detail w-100 h-100"
                            type="file"
                            onChange={importClick}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          />
                        </button>
                      )}
                      <button
                        className="save-and-edit d-inline px-4 w-auto py-2"
                        onClick={() => {
                          save("save");
                        }}
                      >
                        {saveLoader ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ height: "23px", width: "23px" }}
                          >
                            <span
                              className="visually-hidden"
                              style={{
                                height: "23px",
                                width: "23px",
                              }}
                            >
                              Loading...
                            </span>
                          </Spinner>
                        ) : (
                          "SAVE CONTENT"
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row
                  className="create"
                  style={{ height: "calc(100vh - 54px)" }}
                >
                  <Col lg={12} className="h-100">
                    <Tab.Container
                      id="question-tab"
                      activeKey={selectedTabIndex + "question"}
                    >
                      <Row className="h-100 small-screen-questions">
                        <Col lg={2} className="border-right qsn-con pe-0">
                          <ScrollBar
                            style={{ maxHeight: 600 }}
                            className="my-scrollbar pt-2"
                            speed={0.6}
                          >
                            <div className="pe-2">
                              <Nav
                                variant="pills"
                                className="flex-column question-nav"
                              >
                                <QuestionList
                                  selectedTabIndex={selectedTabIndex}
                                  ontabclick={ontabclick}
                                  deleteQuestionClick={deleteQuestionClick}
                                  createQuestionArray={createQuestionArray}
                                  selectedQuestionValue={selectedQuestionValue}
                                />
                              </Nav>
                            </div>
                          </ScrollBar>
                          {localStorage.getItem("tab_visibility") !==
                            "reporting" && (
                            <div className=" align-items-start mb-4 d-flex justify-content-center mt-2 create-content-buttons">
                              <button
                                className="save d-inline px-4 me-4"
                                onClick={createNewQuestion}
                              >
                                <FontAwesomeIcon
                                  icon={faPlusCircle}
                                  style={{ fontSize: "15px" }}
                                  className="me-1"
                                />
                                Create new question
                              </button>
                            </div>
                          )}
                        </Col>
                        <Col lg={10} className="ps-3 qsn-con">
                          {createQuestionArray?.map((item, ind) => {
                            return (
                              <Tab.Content
                                key={ind}
                                className={`${
                                  selectedTabIndex === ind ? "h-100" : ""
                                }`}
                              >
                                <Tab.Pane
                                  eventKey={ind + "question"}
                                  className={`${
                                    selectedTabIndex === ind ? "h-100" : ""
                                  }`}
                                >
                                  {/* //start  */}
                                  <div className="d-flex flex-row flex-wrap h-100">
                                    {createQuestionArray.length && (
                                      <QuestionType
                                        removeReport={() => {
                                          save(item);
                                        }}
                                        showWarningChangeType={
                                          showWarningChangeType
                                        }
                                        ind={ind}
                                        selected_img={selected_img}
                                        selectedLanguage={selectedLanguage}
                                        item={item}
                                        radioButtons={radioButtons}
                                        questionTypeTabClick={
                                          questionTypeTabClick
                                        }
                                        questions={questions}
                                        renderUploadAndCancelButton={
                                          renderUploadAndCancelButton
                                        }
                                        onchangeOptionSingle={
                                          onchangeSingleMultipleTrueFalse
                                        }
                                        singleChecked={singleOrTrueFalseChecked}
                                        onchangeOptionMultiple={
                                          onchangeSingleMultipleTrueFalse
                                        }
                                        multipleChecked={multipleChecked}
                                        trueFalseChecked={
                                          singleOrTrueFalseChecked
                                        }
                                        onchangeOptionTrueFalse={
                                          onchangeSingleMultipleTrueFalse
                                        }
                                        createImgGrid={CreateImgGrid}
                                        selectedGridsFunction={
                                          selectedGridsFunction
                                        }
                                        createQuestionArray={
                                          createQuestionArray
                                        }
                                        selectedTabIndex={selectedTabIndex}
                                        gridTypeClicked={gridTypeClicked}
                                        gridType={gridType}
                                      />
                                    )}

                                    {/* right side  */}
                                    <Col lg={8} className="preview">
                                      {createQuestionArray.length && (
                                        <PreviewQuestion
                                          item={item}
                                          selectedLanguage={selectedLanguage}
                                          createImgGrid={CreateImgGrid}
                                          gridType={gridType}
                                          selectedQuestionValue={
                                            selectedQuestionValue
                                          }
                                          selectedGridsFunction={
                                            selectedGridsFunction
                                          }
                                          createQuestionArray={
                                            createQuestionArray
                                          }
                                          selectedTabIndex={selectedTabIndex}
                                        />
                                      )}
                                    </Col>
                                  </div>

                                  {/* end */}
                                </Tab.Pane>
                              </Tab.Content>
                            );
                          })}
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Col>
                </Row>
              </div>
            </div>
          </LoadingOverlay>
        </div>
        {/* error and warning popup */}
        <EditorWarningPopup
          showErrorModal={showErrorModal}
          modalText={modalText}
          setShowErrorModal={setShowErrorModal}
          deleteConfirm={deleteConfirm}
          goToBack={goToBack}
          cross_faded={cross_faded}
        />
        {/* success popup */}
        <ImportSuccessPopup
          showSuccessModal={showSuccessModal}
          questionCount={questionCount}
          setShowSuccessModal={setShowSuccessModal}
        />
        <TypeChangeWarningPopup
          showModal={showModal}
          closeWarningChangeType={closeWarningChangeType}
        />
      </div>
    </>
  );
};

export default CreateQuestion;
