import {
  getMethod,
  postMethod,
  downLoadFile,
  deleteMethod,
  deleteMethod2,
  putMethod,
  content_type,
  auth_token,
  api_version,
} from "./NetworkManager";
import {
  GET_MASTER_CLIENT_COMPANY,
  FINANCE_DETAIL,
  CHANGE_PASSWORD,
  GET_EDIT_DETAIL_CLIENT,
  GET_TOTAL_MASTER_CLIENT,
  GET_MASTER_RANKING,
  DELETE_CLEINT_CONTRACTOR,
  BASE_URL,
  GET_AUDIT_TRAILS,
  GET_DASHBOARD_REWARDS,
  GET_DETAIL_REWARDS,
  DELETE_REWARDS,
  DELETE_REWARD_CODE,
  ADD_REWARD,
  GET_DETAIL_REWARDS_CODES,
  DELETE_REWARDS_CODES,
  EDIT_REWARD,
  REWARD_DISTRIBUTION_SETTING,
  REWARD_EDIT_DISTRIBUTION_SETTING,
} from "../constant/ApiUrls";
import axios from "axios";
export const fetchRewardDistribution = async (clientId) => {
  return getMethod(REWARD_DISTRIBUTION_SETTING + `?ClientId=${clientId}`);
};
export const addReward = async (obj) => {
  return postMethod(ADD_REWARD, obj);
};
export const editDistributionSetting = async (obj) => {
  return postMethod(REWARD_EDIT_DISTRIBUTION_SETTING, obj);
};
export const editReward = async (obj) => {
  return putMethod(EDIT_REWARD, obj);
};
export const fetchDetailReward = async (
  rewardId,
  PageNumber = 1,
  PageSize = 1
) => {
  return getMethod(GET_DETAIL_REWARDS + `?rewardId=${rewardId}`);
};
export const fetchDashboardRewards = async (
  clientId,
  PageNumber = 1,
  PageSize = 10
) => {
  return getMethod(
    GET_DASHBOARD_REWARDS +
      `?clientId=${clientId}&PageNumber=${PageNumber}&PageSize=${PageSize}`
  );
};
export const searchAndSortDashboardRewards = async (searchURL) => {
  return getMethod(`${GET_DASHBOARD_REWARDS}${searchURL}`);
};
export const deleteReward = async (obj) => {
  return deleteMethod2(`${DELETE_REWARDS}`, obj);
};
export const deleteRewardCode = async (obj) => {
  return deleteMethod2(`${DELETE_REWARD_CODE}`, obj);
};
export const searchAndSortAuditTrails = async (searchURL) => {
  return getMethod(`${GET_AUDIT_TRAILS}${searchURL}`);
};

export const fetchAuditTrails = async (PageNumber = 1, PageSize = 20) => {
  return getMethod(
    GET_AUDIT_TRAILS + `?PageNumber=${PageNumber}&PageSize=${PageSize}`
  );
};
export const fetchClientCompany = async (PageNumber = 1, PageSize = 20) => {
  return getMethod(
    GET_MASTER_CLIENT_COMPANY + `?PageNumber=${PageNumber}&PageSize=${PageSize}`
  );
};
export const fetchAllClientCompany = async (PageNumber = 1, PageSize = 20) => {
  return getMethod(GET_MASTER_CLIENT_COMPANY + `?IsAll=true`);
};

export const fetchMasterClientDetail = async (id) => {
  return getMethod(GET_MASTER_CLIENT_COMPANY + "/" + id);
};

export const fetchTotalMasterClient = async (id) => {
  return getMethod(GET_TOTAL_MASTER_CLIENT);
};

export const fetchMasterRanking = async (params) => {
  return getMethod(GET_MASTER_RANKING + params);
};

export const addNewClientMasterCompany = async (obj) => {
  return postMethod(GET_MASTER_CLIENT_COMPANY, obj);
};
export const fetchFinanceList = async () => {
  return getMethod(FINANCE_DETAIL);
};

export const fetchFinanceInvoice = async (id) => {
  return getMethod(`${FINANCE_DETAIL}/${id}/invoice`);
};

export const editPassword = async (requestPayload) => {
  return postMethod(CHANGE_PASSWORD, requestPayload);
};

export const donwlaodInvoice = async (id) => {
  return downLoadFile(`${FINANCE_DETAIL}/${id}/invoice/pdf`);
};

export const searchAndSort = async (searchURL) => {
  return getMethod(`${GET_MASTER_CLIENT_COMPANY}${searchURL}`);
};

export const searchFinences = async (searchURL) => {
  return getMethod(`${FINANCE_DETAIL}${searchURL}`);
};

export const deleteMasterClient = async (id, type) => {
  return deleteMethod(`${GET_MASTER_CLIENT_COMPANY}/${id}/${type}`);
};
export const fetchEditClientDetail = async (id) => {
  return getMethod(`${GET_EDIT_DETAIL_CLIENT}${id}`);
};

export const updateEditClientDetail = async (id, obj) => {
  return putMethod(`${GET_EDIT_DETAIL_CLIENT}${id}`, obj);
};
export const fetchMasterUserDetail = async (companyId) => {
  return getMethod(GET_MASTER_CLIENT_COMPANY + "/" + companyId + "/detail");
};
export const fetchContractorUserDetail = async (contractorId) => {
  return getMethod(DELETE_CLEINT_CONTRACTOR + "/" + contractorId + "/detail");
};

export const exportMasterListClients = async (params) => {
  let { PageNumber, PageSize, SearchBy, OrderBy, OrderType } = params;
  let url = `/v1/client/export?SearchBy=${SearchBy}&PageNumber=${PageNumber}&PageSize=${PageSize}&OrderBy=${OrderBy}&OrderType=${OrderType}`;

  try {
    return await axios
      .get(BASE_URL + url, {
        responseType: "blob",
        headers: {
          "Content-Type": content_type(),
          Authorization: auth_token(),
          "x-api-version": api_version(),
        },
      })
      .then((response) => {
        let data = { status: 200 };
        const url_client = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url_client;
        link.setAttribute("download", "client" + ".xlsx");
        document.body.appendChild(link);
        link.click();
        return data;
      });
  } catch (error) {
    return error.response;
  }
};
export const exportAuditListClients = async (params) => {
  let {
    PageNumber,
    PageSize,
    SearchKey,
    StartDate,
    EndDate,
    // OrderType,
    OrderBy,
  } = params;
  let url = `/v1/auditTrail/export?SearchKey=${SearchKey}&PageNumber=${PageNumber}&PageSize=${PageSize}&StartDate=${StartDate}&EndDate=${EndDate}&OrderBy=${OrderBy}`;

  try {
    return await axios
      .get(BASE_URL + url, {
        responseType: "blob",
        headers: {
          "Content-Type": content_type(),
          Authorization: auth_token(),
          "x-api-version": api_version(),
        },
      })
      .then((response) => {
        let data = { status: 200 };
        const url_href = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url_href;
        link.setAttribute("download", `Audit_Trail` + ".xlsx");
        document.body.appendChild(link);
        link.click();
        return data;
      });
  } catch (error) {
    return error.response;
  }
};
export const fetchRewardCodes = async (
  rewardId,
  orderBy = "",
  Status = "",
  PageNumber = 1,
  PageSize = 100
) => {
  return getMethod(
    GET_DETAIL_REWARDS_CODES +
      `?rewardId=${rewardId}&Status=${Status}&PageNumber=${PageNumber}&PageSize=${PageSize}&OrderBy=${orderBy}`
  );
};
export const deleteRewardCodes = async (payload) => {
  return deleteMethod2(DELETE_REWARDS_CODES, payload);
};
