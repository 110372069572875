import React, { Component } from "react";
class EditAccountInfo extends Component {
  render() {
    return (
      <>
        <div className="edit-account d-flex flex-column ">
          <label className="screen-label">Email</label>
          <input className="screen-input" type="text" />
          <button className="screen-button">Save changes</button>
        </div>
      </>
    );
  }
}
export default EditAccountInfo;
