import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import YourPlan from "./YourPlan/YourPlan";
import BillingInfo from "./BillingInfo/BillingInfo";
import { fetchFinanceDetail } from "../../../services/ClientServices";
import toast from "react-hot-toast";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";

class Finances extends Component {
  state = {
    financeDetail: "",
    screenLoader: true,
  };
  componentDidMount = async () => {
    let detail = await fetchFinanceDetail();
    if (detail?.status === 200) {
      this.setState({
        financeDetail: detail?.data?.data,
        screenLoader: false,
      });
    } else {
      this.setState({
        financeDetail: "",
        screenLoader: false,
      });
      toast.error("Error while fetching data!");
    }
  };
  render() {
    const { financeDetail, screenLoader } = this.state;

    return (
      <>
        <div className="finance-section d-flex justify-content-between sidebar-for-mobile">
          <div className="my-container container-fluid px-5">
            <div className="myDiv">
              <h1>Finances</h1>
              <LoadingOverlay
                className=" loader_overlay w-100"
                spinner={<HashLoader color="#5ba6b7" size={90} />}
                active={screenLoader}
              >
                <Row className="mt-5">
                  <Col lg={4}>
                    <YourPlan FinanceDetail={financeDetail} />
                  </Col>
                  <Col lg={4}>
                    <BillingInfo
                      ClientDetail={financeDetail?.clientDetail}
                      customerNr={financeDetail?.customerNr}
                    />
                  </Col>
                </Row>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Finances;
