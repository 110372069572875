import React, { Component } from "react";
import "./ForgetPassword.css";
import { Modal } from "react-bootstrap";
import { forgetPassword } from "../../../services/UserManagementServices";
import { Link, withRouter } from "react-router-dom";
import { VALID_EMAIL_1 } from "../../../constant/AppConstant";
import { translationData } from "../../../services/TranslationServices";
import { getKeyTranslation } from "../../../utils/Translations";

class ForgetPassword extends Component {
  state = {
    forgotPasswordModal: false,
    email: "",
    emailError: "",
    emailFieldColor: "",
    emailErrorColor: "",
    disableButton: true,
  };

  sendEmail = async () => {
    const { email } = this.state;
    if (email.length === 0) {
      this.setState({
        emailError: "Please provide an Email",
      });
    } else {
      let requestPayload = {
        email: email,
      };
      await translationData();
      let password = await forgetPassword(requestPayload);
      if (password && password.status === 200) {
        var message = getKeyTranslation(password?.data?.message);
        this.setState({
          emailError: message,
          emailErrorColor: "lightgreen",
        });
      } else {
        this.setState({
          // emailError: "An error occure while sending email",
          emailError: getKeyTranslation(password?.data?.message),
        });
      }
    }
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.sendEmail();
    }
  };

  emailValidator = (e) => {
    const { value } = e.target;
    let valid = VALID_EMAIL_1.test(String(value).toLowerCase());
    if (!valid) {
      this.setState({
        emailError: "Invalid E-mail!",
        email: value,
        emailFieldColor: "2px solid red",
        emailErrorColor: "red",
        disableButton: true,
      });
      if (!value) {
        this.setState({
          emailError: "",
          emailFieldColor: "1px solid #d2e7ef",
        });
      }
    } else if (valid) {
      this.setState({
        emailError: "* E-mail Valid!",
        email: value,
        emailFieldColor: "1px solid lightgreen",
        emailErrorColor: "lightgray",
        disableButton: false,
      });
    }
  };
  closebutton = () => {
    this.setState({
      forgotPasswordModal: false,
      emailError: "",
      email: "",
      emailErrorColor: "lightgray",
      emailFieldColor: "1px solid #d2e7ef",
    });
  };
  render() {
    const {
      email,
      emailError,
      emailFieldColor,
      emailErrorColor,
      disableButton,
    } = this.state;
    return (
      <>
        <Link
          to="#"
          className="forgot-link"
          onClick={() => {
            this.setState({
              forgotPasswordModal: true,
            });
          }}
        >
          Forgot Password?
        </Link>
        <Modal centered size="lg" show={this.state.forgotPasswordModal}>
          <div className="bg-white forget-screen">
            <div className="modal-header">
              <h1>Forgot Password?</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={this.closebutton}
              ></button>
            </div>
            <label>
              Enter your E-Mail down below to receive a new password.
            </label>
            <br />
            <input
              style={{ border: emailFieldColor }}
              onChange={this.emailValidator}
              type="email"
              placeholder="error@mail.com"
              value={email}
              onKeyPress={this.handleKeyPress}
            />
            <p className="mb-0 text-danger" style={{ color: emailErrorColor }}>
              {emailError}
            </p>
            <div className="screen-buttons float-end">
              <button onClick={this.closebutton}>cancel</button>
              <button disabled={disableButton} onClick={this.sendEmail}>
                send
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default withRouter(ForgetPassword);
