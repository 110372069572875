import React from "react";
import { Modal } from "react-bootstrap";
import { BsXCircleFill } from "react-icons/bs";
import { IconContext } from "react-icons";

import { getLoginStorage } from "../../utils/UserDataManagement";

const RestrictedAction = ({
  show,
  handleClose,
  newText,
  text,
  redirectionKey,
  redirection,
}) => {
  const checkHold = () => {
    if (getLoginStorage("role") === "MA") {
      return "Client is on hold. You cannot perform this action.";
    } else if (
      localStorage.getItem("releaseMessage") &&
      localStorage.getItem("releaseMessage") != ""
    ) {
      return localStorage.getItem("releaseMessage");
    } else {
      return "You are on hold and cannot perform this action.";
    }
  };
  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <div className="bg-white change-delete-modals">
        <div className="modal-header">
          <h4>
            {" "}
            <IconContext.Provider
              value={{ style: { fontSize: "30px", color: "red" } }}
            >
              <BsXCircleFill className="mb-2" />
            </IconContext.Provider>{" "}
            Restricted Action
          </h4>
        </div>
        <label className="mt-3">{newText ? text : checkHold()}</label>
        <div className="screen-buttons float-end">
          <button
            onClick={() => {
              handleClose();
              if (redirection) {
                window.location = redirectionKey;
              }
            }}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RestrictedAction;
