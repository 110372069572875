import React, { Component } from "react";
import toast, { Toaster } from "react-hot-toast";
import { getLoginStorage } from "../../../../utils/UserDataManagement";
import { editPassword } from "../../../../services/UserManagementServices";
import { VALID_PASSOWRD } from "../../../../constant/AppConstant";
import { getKeyTranslation } from "../../../../utils/Translations";
class EditPassword extends Component {
  state = {
    newPassword: "",
    oldPassword: "",
    confirmPassword: "",
  };
  validation = (item, type) => {
    if (type === "oldPassword") {
      this.setState({
        oldPassword: item.target.value,
        errorOldPassword: "",
      });
    } else if (type === "NewPassword") {
      this.setState({
        newPassword: item.target.value,
        errornewPassword: "",
        errorconfirmPassword: "",
      });
    } else {
      this.setState({
        confirmPassword: item.target.value,
        errorconfirmPassword: "",
      });
    }
  };
  submitForm = async (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const validPassword = newPassword.match(VALID_PASSOWRD);
    const validPasswordConfirm = confirmPassword.match(VALID_PASSOWRD);
    let error = false;
    if (!validPassword) {
      if (!newPassword.includes(".")) {
        error = true;
        this.setState({
          errornewPassword:
            "*Password must contain at least 8-35 characters, 1 uppercase, 1 special character and 1 digit",
        });
      }
    }
    if (!validPasswordConfirm) {
      if (!confirmPassword.includes(".")) {
        error = true;
        this.setState({
          errorconfirmPassword:
            "*Password must contain at least 8-35 characters, 1 uppercase, 1 special character and 1 digit",
        });
      }
    }
    if (oldPassword.length === 0) {
      error = true;
      this.setState({
        errorOldPassword: "*Please Enter the old password",
      });
    }
    if (newPassword.length === 0) {
      error = true;
      this.setState({
        errornewPassword: "*Please enter the  New password",
      });
    }
    if (confirmPassword.length === 0) {
      error = true;
      this.setState({
        errorconfirmPassword: "*Please re-enter  Password",
      });
    }
    if (confirmPassword.length !== 0 && newPassword !== confirmPassword) {
      error = true;
      this.setState({
        errorconfirmPassword: "*Your password do not match",
      });
    }
    if (!error) {
      let requestPayload = {
        userId: getLoginStorage("id"),
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      let eidtPasswordResponse = await editPassword(requestPayload);
      if (eidtPasswordResponse.status === 200) {
        this.setState({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        toast.success(getKeyTranslation(eidtPasswordResponse?.data?.message));
      } else {
        this.setState({
          errorOldPassword: getKeyTranslation(
            eidtPasswordResponse?.data?.message
          ),
        });
      }
    }
  };
  render() {
    return (
      <>
        <Toaster position="top-center" reverseOrder={true} />
        <form>
          <div className="edit-password d-flex flex-column">
            <label className="screen-label">Current Password</label>
            <input
              onChange={(item) => this.validation(item, "oldPassword")}
              className="screen-input"
              type="password"
              value={this.state.oldPassword}
            />
            <p className="text-color text-danger">
              {this.state.errorOldPassword}
            </p>
            <label className="screen-label">New Password</label>
            <input
              onChange={(item) => this.validation(item, "NewPassword")}
              className="screen-input"
              type="password"
              value={this.state.newPassword}
            />
            <p className="text-color text-danger">
              {this.state.errornewPassword}
            </p>
            <label className="screen-label">Re-enter New Password</label>
            <input
              onChange={(item) => this.validation(item, "ConfirmPassword")}
              className="screen-input"
              type="password"
              value={this.state.confirmPassword}
            />
            <p className="text-color text-danger">
              {this.state.errorconfirmPassword}
            </p>
            <button onClick={this.submitForm} className="screen-button">
              Save changes
            </button>
          </div>
        </form>
      </>
    );
  }
}
export default EditPassword;
