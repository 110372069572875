import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { deleteMasterClient } from "../../../../services/MasterServices";
import toast from "react-hot-toast";
import { withRouter } from "react-router-dom";
import { CLIENT_DELETE_KEY } from "../../../../constant/AppConstant";
import { getKeyTranslation } from "../../../../utils/Translations";
class DeleteClient extends Component {
  state = {
    deleteButton: false,
  };

  deleteConfirmation = async () => {
    this.setState({
      deleteButton: true,
    });
    let deleteUser = await deleteMasterClient(this.props.id, CLIENT_DELETE_KEY);
    if (deleteUser.status === 200) {
      toast.success("User has been successfully deleted!");
      this.props.updateLicensesList();
      this.props.closeCard();
    } else {
      toast.error(getKeyTranslation(deleteUser?.data?.message));
    }
  };

  render() {
    const { deleteLicense, companyName } = this.props;
    return (
      <>
        <Modal centered size="lg" show={deleteLicense}>
          <div className="bg-white change-delete-modals">
            <div className="modal-header">
              <h1>Delete Client</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.closeDeleteClientModal()}
              ></button>
            </div>
            <label className="mt-3">
              Are you sure you want to delete <b>{companyName}</b>?
            </label>
            <div className="screen-buttons float-end">
              <button onClick={() => this.props.closeDeleteClientModal()}>
                cancel
              </button>
              <button
                disabled={this.state.deleteButton}
                onClick={this.deleteConfirmation}
              >
                delete client
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default withRouter(DeleteClient);
