export const rewards = [
  {
    name: "Blackeri 5 €",
    created_by: "BASF",
    used_code: "89",
    unused_code: "31",
    date: "17 days ago (23.01.2021)",
  },
  {
    name: "Blackeri 5 €",
    created_by: "BASF",
    used_code: "89",
    unused_code: "31",
    date: "17 days ago (23.01.2021)",
  },
  {
    name: "Blackeri 5 €",
    created_by: "BASF",
    used_code: "89",
    unused_code: "31",
    date: "17 days ago (23.01.2021)",
  },
];
export const rewardsDetail = [
  {
    id: 1,
    check: false,
    date_updated: "01.03.2022",
    code: "89123456",
    status: "not distributed",
  },
  {
    id: 2,
    check: false,
    date_updated: "01.03.2022",
    code: "89123456",
    status: "not distributed",
  },
  {
    id: 3,
    check: false,
    date_updated: "01.03.2022",
    code: "89123456",
    status: "not distributed",
  },
  {
    id: 4,
    check: false,
    date_updated: "01.03.2022",
    code: "89123456",
    status: "not distributed",
  },
  {
    id: 5,
    check: false,
    date_updated: "01.03.2022",
    code: "89123456",
    status: "not distributed",
  },
  {
    id: 6,
    check: false,
    date_updated: "01.03.2022",
    code: "89123456",
    status: "In used",
  },
  {
    id: 7,
    check: false,
    date_updated: "01.03.2022",
    code: "89123456",
    status: "Marked as used",
  },
  {
    id: 8,
    check: false,
    date_updated: "01.03.2022",
    code: "89123456",
    status: "Marked as used",
  },
  {
    id: 9,
    check: false,
    date_updated: "01.03.2022",
    code: "89123456",
    status: "not distributed",
  },
  {
    id: 10,
    check: false,
    date_updated: "01.03.2022",
    code: "89123456",
    status: "not distributed",
  },
];
export const distributions = [
  { value: "Bäckerei 15", bgColor: "#FFDB41" },
  { value: "Bäckerei 15€", bgColor: "#D8D8D8" },
  { value: "Bäckerei 15€", bgColor: "#F8C97C" },
  { value: "Bäckerei 15€", bgColor: "" },
  { value: "Bäckerei 15€", bgColor: "" },
  { value: "Bäckerei 15€", bgColor: "" },
  { value: "Bäckerei 15€", bgColor: "" },
  { value: "Bäckerei 15€", bgColor: "" },
  { value: "Bäckerei 15€", bgColor: "" },
  { value: "Bäckerei 15€", bgColor: "" },
];

export const sortList = [
  { value: "", name: "BASF" },
  { value: "companyName", name: "Company Name" },
  { value: "contactPerson", name: "Contact Person" },
  { value: "email", name: "Email" },
  { value: "NoOfLicensesInInt", name: "No. of Licenses" },
];
export const editRewardList = [
  {
    options: [
      { value: "5", label: "Bäckerei 5€" },
      { value: "10", label: "Bäckerei 10€" },
      { value: "15", label: "Bäckerei 15€" },
    ],
    place: 1,
    rewardId: 5,
  },
  {
    options: [
      { value: "5", label: "Bäckerei 5€" },
      { value: "10", label: "Bäckerei 10€" },
      { value: "15", label: "Bäckerei 15€" },
    ],
    place: 2,
    rewardId: 5,
  },
  {
    options: [
      { value: "5", label: "Bäckerei 5€" },
      { value: "10", label: "Bäckerei 10€" },
      { value: "15", label: "Bäckerei 15€" },
    ],
    place: 3,
    rewardId: 5,
  },
  {
    options: [
      { value: "5", label: "Bäckerei 5€" },
      { value: "10", label: "Bäckerei 10€" },
      { value: "15", label: "Bäckerei 15€" },
    ],
    place: 4,
    rewardId: 5,
  },
  {
    options: [
      { value: "5", label: "Bäckerei 5€" },
      { value: "10", label: "Bäckerei 10€" },
      { value: "15", label: "Bäckerei 15€" },
    ],
    place: 5,
    rewardId: 5,
  },
];
export const arr20 = [
  { item: "1" },
  { item: "2" },
  { item: "3" },
  { item: "4" },
  { item: "5" },
  { item: "6" },
  { item: "7" },
  { item: "8" },
  { item: "9" },
  { item: "10" },
  { item: "11" },
  { item: "12" },
  { item: "13" },
  { item: "14" },
  { item: "15" },
  { item: "16" },
  { item: "17" },
  { item: "18" },
  { item: "19" },
  { item: "20" },
];
export const editRewardOptions = [
  { value: "5", label: "Bäckerei 5€" },
  { value: "10", label: "Bäckerei 10€" },
  { value: "15", label: "Bäckerei 10€" },
];
export const sortListNames2 = [
  { name: "Reward name, A-Z", asc: "RewardCodeIdASC" },
  { name: "Contact Person", asc: "RewardCodeStatusIdASC" },
  { name: "Email" },
  { name: "Amount of licenses" },
  { name: "Assigned content" },
];
export const sortListNames = [
  { name: "RewardNameDESC", label: "Reward Name" },
  { name: "UpdatedAtDESC", label: "Updated At" },
  { name: "usedCodeCountDESC", label: "Used Code" },
  { name: "UnDistributedCodeCountDESC", label: "UnDistributed Code" },
];
export const codeStatuses = [
  { name: "", label: "All" },
  { name: "Expired", label: "Expired" },
  { name: "InUse", label: "In Use" },
  { name: "MarkedAsUsed", label: "Marked As Used" },
  { name: "NotDistributed", label: "Not Distributed" },
];
export const sortListRewardCodes = [
  { name: "RewardCodeIdDESC", label: "Reward Code" },
  { name: "RewardCodeStatusIdDESC", label: "Reward Code Status" },
  { name: "CodeDESC", label: "Code" },
  { name: "CreatedAtDESC", label: "Date" },
  { name: "UpdatedAtDESC", label: "Updated At" },
];
export const rewardsTableSorting = [
  { name: "RewardName", type: false },
  { name: "UsedCodeCount", type: false },
  { name: "UnDistributedCodeCount", type: false },
  { name: "UpdatedAt", type: false },
];
export const rewardsCodesTableSorting = [
  { name: "UpdatedAt", type: false },
  { name: "Code", type: false },
  { name: "StatusKey", type: false },
];
export const auditTableSorting = [
  { name: "AuditId", type: false },
  { name: "Action", type: false },
  { name: "InitiatedBy", type: false },
  { name: "Module", type: false },
  { name: "Timestamp", type: false },
];
