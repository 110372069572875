import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Row, Button, Spinner } from "react-bootstrap";
import arrow_up from "../../../../../assets/images/arrow_up.png";
import arrow_down from "../../../../../assets/images/arrow_down.png";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import bar_code from "../../../../../assets/images/qrcode.svg";
import qr_code from "../../../../../assets/images/barcode.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";
import {
  editReward,
  fetchDetailReward,
  fetchRewardCodes,
} from "../../../../services/MasterServices";
import { rewardsCodesTableSorting, codeStatuses } from "../rewardsData";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import DeleteRewardModal from "./DeleteRewardModal";
import ErrorModal from "../addReward/ErrorModal";
import "./DetailRewardModal.css";
import { getKeyTranslation } from "../../../../utils/Translations";

const DetailRewardModal = ({
  show,
  close,
  detail,
  clientId,
  dashboardRewardCall,
}) => {
  const [rewardsDetailState, setRewardsDetailState] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectAllClick, setSelectAllClick] = useState(false);
  const [toggleList, setToggleList] = useState(true);
  const [toggleProperties, setToggleProperties] = useState(true);
  const [type, setType] = useState("");
  const [monetaryValue, setMonetaryValue] = useState("");
  const [note, setNote] = useState("");
  const [typeError, setTypeError] = useState("");
  const [monetaryValueError, setMonetaryValueError] = useState("");
  const [noteError, setNoteError] = useState("");

  const [detailRewardData, setDetailRewardData] = useState({});
  const [clickedItems, setClickedItems] = useState([]);
  const [fileName, setFileName] = useState("");
  const [btnUpload, setBtnUpload] = useState(false);
  const [btnFileUpload, setBtnFileUpload] = useState(false);
  const [dropdownKey, setDropdownKey] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errors, setErrors] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumberForSearch, setPageNumberForSearch] = useState(1);
  const [sortingTableObj, setSortingTableObj] = useState(
    rewardsCodesTableSorting
  );
  const [sortValue, setSortValue] = useState("");
  const [screenLoader, setScreenLoader] = useState(false);
  const [rewardCodeType, setRewardCodeType] = useState(1);
  const [duplicateEntriesModal, setDuplicateEntriesModal] = useState(false);
  const [duplicateEntries, setDuplicateEntries] = useState(0);

  const sorting = async (value) => {
    let newObj = sortingTableObj.map((item) => {
      value == item.name ? (item.type = !item.type) : (item.type = false);
      return item;
    });
    newObj.forEach((item) => {
      if (value == item.name) {
        sortingurl(value, item);
      }
    });
    setSortingTableObj(newObj);
  };
  const sortingurl = (value_name, obj) => {
    if (obj.type == false) {
      const call = async () => {
        codesApi(
          detail?.id,
          value_name + "ASC",
          dropdownKey,
          pageNumberForSearch,
          100
        );
      };
      return call();
    } else {
      const call = async () => {
        codesApi(
          detail?.id,
          value_name + "DESC",
          dropdownKey,
          pageNumberForSearch,
          100
        );
      };
      return call();
    }
  };
  const closeErrModal = () => {
    setShowErrorModal(false);
  };
  const seekchange2 = () => {
    const detailReward = async () => {
      detailApi();
      setScreenLoader(true);
      codesApi(detail?.id, sortValue, dropdownKey, 1, 100);
    };
    detailReward();
  };
  useEffect(() => {
    seekchange2();
  }, []);
  const codesApi = async (id, sort_value, dropdown_value, page, pagesize) => {
    setScreenLoader(true);
    const rewardDetails = await fetchRewardCodes(
      id,
      sort_value,
      dropdown_value,
      page,
      pagesize
    );
    if (rewardDetails?.status == 200) {
      setRewardsDetailState(rewardDetails?.data?.data?.items);
      setPageCount(rewardDetails?.data?.data?.totalPages);
      setTotalCount(rewardDetails?.data?.data?.totalCount);
      setPageNumberForSearch(page);
      setSortValue(sort_value);
      setScreenLoader(false);
    } else {
      setScreenLoader(false);
      setRewardsDetailState([]);
      setSortValue(sort_value);
      setPageCount(0);
      setTotalCount(0);
      setPageNumberForSearch(1);
      toast.error(getKeyTranslation(rewardDetails?.data?.message));
    }
  };
  const detailApi = async () => {
    const result = await fetchDetailReward(detail?.id);
    if (result?.status == 200) {
      setDetailRewardData(result?.data?.data);
      setType(result?.data?.data?.type);
      setMonetaryValue(result?.data?.data?.monetaryValue);
      setNote(result?.data?.data?.disclaimer);
      setRewardCodeType(result?.data?.data?.codeType);
    } else {
      toast.error(getKeyTranslation(result?.data?.message));
    }
  };
  const nextPageApiCall = async (data) => {
    let parent = document.getElementsByTagName("ul")[0];
    let doc = parent.getElementsByTagName("li");
    let firstDoc = doc[1].children[0];
    firstDoc.classList.remove("active-link");
    let pageNumberForSearchnext = data.selected + 1;
    codesApi(detail?.id, sortValue, dropdownKey, pageNumberForSearchnext, 100);
  };
  const emptyDeletedItems = () => {
    console.log("clicked");
    setClickedItems([]);
    setCheckedAll(false);
    dashboardRewardCall();
  };
  useEffect(() => {
    if (selectAllClick) {
      if (checkedAll) {
        setClickedItems(rewardsDetailState);
        rewardsDetailState.forEach((item) => {
          let ele = document.getElementById(item.id);
          ele.checked = true;
        });
        setCheckedAll(true);
      } else {
        rewardsDetailState?.forEach((item) => {
          let ele = document.getElementById(item.id);
          ele.checked = false;
        });
        setClickedItems([]);
      }
      setSelectAllClick(false);
    }
  }, [checkedAll]);
  const checkType = (e) => {
    if (e.target.value.length <= 20) {
      setType(e.target.value);
    }
  };
  const checkMonetaryValue = (e) => {
    if (e.target.value >= 0 && e.target.value <= 999999) {
      let value = Math.round(e.target.value).toString().replace(/^0+/, "");
      setMonetaryValue(value);
    }
  };
  const keydown = (e) => {
    var invalidChars = ["-", "+", "e", "E"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  const noteCheck = (e) => {
    if (e.target.value.length <= 80) {
      setNote(e.target.value);
    }
  };
  const handleCheckbox = (e, item) => {
    let existsIndex = false;
    let arr = clickedItems?.slice();
    arr?.forEach((ele) => {
      if (ele.id == item.id) {
        existsIndex = true;
        arr.splice(
          arr.findIndex((v) => v.id === item.id),
          1
        );
        setClickedItems(arr);
      }
    });
    if (!existsIndex) {
      arr.push(item);
      setClickedItems(arr);
    }
    if (rewardsDetailState.length == arr.length) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  };
  const selectAllClickfun = () => {
    setCheckedAll(!checkedAll);
    setSelectAllClick(true);
  };
  const form_formatted = (obj, form_data) => {
    Object.keys(obj).forEach((key) => {
      form_data.append(`${key}`, obj[key]);
    });
    return form_data;
  };
  const uploadSuccess = (res) => {
    if (res?.data?.data?.duplicateCodeCount > 0) {
      setDuplicateEntriesModal(true);
      setDuplicateEntries(res?.data?.data?.duplicateCodeCount);
    }
    setScreenLoader(true);
    codesApi(detail?.id, sortValue, dropdownKey, 1, 100);
    dashboardRewardCall();
    setBtnFileUpload(false);
    const detailReward = async () => {
      detailApi();
      setScreenLoader(true);
    };
    detailReward();
  };
  const formElement = (evt) => {
    if (evt.target.value) {
      try {
        evt.target.value = ""; //for IE11, latest Chrome/Firefox/Opera...
      } catch (err) {}
      if (evt.target.value) {
        //for IE5 ~ IE10
        var form = document.createElement("form"),
          parentNode = evt.target.parentNode,
          ref = evt.target.nextSibling;
        form.appendChild(evt);
        form.reset();
        parentNode.insertBefore(evt, ref);
      }
    }
  };
  const uploadFile = (e) => {
    if (e.target.files.length !== 0) {
      setFileName(e.target.files[0]?.name);
    }
    let obj = {
      File: e.target.files[0],
      RewardId: parseInt(detail.id),
    };

    const editRewardData = async () => {
      const result = await editReward(form_formatted(obj, new FormData()));
      setBtnFileUpload(true);
      if (result?.status == 200) {
        uploadSuccess(result);
        formElement(e);
        setFileName("");
        setTimeout(() => {
          toast.success(getKeyTranslation(result?.data.message));
        }, 3000);
      } else {
        setBtnFileUpload(false);
        setShowErrorModal(true);
        setErrors(getKeyTranslation(result?.data?.message));
        setClickedItems([]);
        setCheckedAll(false);
        formElement(e);
        setFileName("");
      }
    };
    editRewardData();
  };
  const fieldValidations = () => {
    if (type?.length == 0) {
      setTypeError("Required");
    } else if (monetaryValue == 0) {
      setTypeError("");
      setMonetaryValueError("Required");
    } else if (note.length == 0) {
      setTypeError("");
      setMonetaryValueError("");
      setNoteError("required");
    } else {
      setTypeError("");
      setMonetaryValueError("");
      setNoteError("");
    }
  };
  const submit = () => {
    fieldValidations();
    let obj = {
      Type: type,
      MonetaryValue: monetaryValue,
      Disclaimer: note,
      RewardId: parseInt(detail.id),
      CodeType: rewardCodeType,
    };
    const editRewardData = async () => {
      setBtnUpload(true);
      const result = await editReward(form_formatted(obj, new FormData()));
      if (result?.status === 200) {
        setBtnUpload(false);
        detailApi();
        dashboardRewardCall();
        setTimeout(() => {
          toast.success(getKeyTranslation(result.data.message));
        }, 1000);
      } else {
        setBtnUpload(false);
        toast.error(getKeyTranslation(result?.data.message));
      }
    };
    editRewardData();
  };
  const dashboardReward = () => {
    dashboardRewardCall();
  };
  const closeModal = () => {
    close();
  };
  const onDropdownChange = (e) => {
    setDropdownKey(e.target.value);
    const detailReward = async () => {
      codesApi(detail?.id, sortValue, e.target.value, 1, 100);
    };
    detailReward();
  };
  const toggleSwitch = (toggle) => {
    return toggle ? arrow_up : arrow_down;
  };
  const addTotal = () => {
    if (
      !isNaN(
        detailRewardData.unDistributedCodeCount + detailRewardData.usedCodeCount
      )
    ) {
      return (
        detailRewardData.unDistributedCodeCount + detailRewardData.usedCodeCount
      );
    }
    return 0;
  };
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Modal
        show={show}
        centered
        size="lg"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="detail-reward-modal">
            <div className="modal-header">
              <h1 className="mb-0" style={{ wordBreak: "break-all" }}>
                {detailRewardData?.rewardName}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={close}
              ></button>
            </div>
            <span
              style={{
                color: "#34636d",
              }}
            >
              <Row>
                <Col lg={9}>
                  <div className="d-flex flex-wrap justify-content-between detail-reward mt-4">
                    <div className="flex-even">
                      <span>Created by</span>
                      <p style={{ wordBreak: "break-all" }}>
                        {detailRewardData?.createdBy}
                      </p>
                    </div>
                    <div className="flex-even">
                      <span>Total codes</span>
                      <p>{addTotal()}</p>
                    </div>
                    <div className="flex-even">
                      <span>Used codes</span>
                      <p>{detailRewardData?.usedCodeCount}</p>
                    </div>
                    <div className="flex-even">
                      <span>Unused codes</span>
                      <p>{detailRewardData?.unDistributedCodeCount}</p>
                    </div>
                    <div className="flex-even">
                      <span>Date added</span>
                      <p>
                        {detailRewardData?.createdAt &&
                          detailRewardData.createdAtTimeAgo + " "}
                        (
                        {moment(detailRewardData?.createdAt).format(
                          "DD.MM.YYYY"
                        )}
                        )
                      </p>
                    </div>
                  </div>
                  <DeleteRewardModal
                    rewardid={parseInt(detail.id)}
                    clientid={parseInt(clientId)}
                    closeModal={closeModal}
                    usedCodes={detailRewardData?.usedCodeCount}
                    dashboardRewardCall={dashboardReward}
                  />
                  <Accordion
                    defaultActiveKey="0"
                    className="detail-accordion mt-4"
                  >
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          onClick={() => {
                            setToggleProperties(!toggleProperties);
                          }}
                        >
                          <h4
                            className="mt-4 d-inline me-3"
                            style={{ fontWeight: "400" }}
                          >
                            Properties
                          </h4>
                          <img
                            className="position-relative mt-1"
                            src={toggleSwitch(toggleProperties)}
                            height={7}
                            width={7}
                            style={{ top: "-5px" }}
                          />
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <Row className="mt-4 form">
                            <Col lg={10} className="reward-form">
                              <div className="d-flex align-items-end">
                                <div className="flex-fill">
                                  <label className="pb-1 font-weight-500">
                                    Type
                                  </label>
                                  <br />
                                  <input
                                    type="text"
                                    placeholder="E.g. Coffee"
                                    className=""
                                    style={{ wordBreak: "break-all" }}
                                    value={type}
                                    onChange={(e) => checkType(e)}
                                  />
                                </div>
                              </div>
                              <span className="error-decoration">
                                {typeError}
                              </span>
                              <br />
                              <div className="d-flex align-items-end">
                                <div className="flex-fill">
                                  <label className="pb-1 font-weight-500">
                                    Monetary Value (EUR)
                                  </label>
                                  <br />
                                  <input
                                    type="number"
                                    placeholder=""
                                    className=""
                                    value={monetaryValue}
                                    onChange={checkMonetaryValue}
                                    onKeyDown={(e) => keydown(e)}
                                  />
                                </div>
                              </div>
                              <span className="error-decoration">
                                {monetaryValueError}
                              </span>
                              <br />
                              <label className="pb-1 font-weight-500">
                                Disclaimer
                              </label>
                              <div className="d-flex">
                                <div className="flex-fill position-relative">
                                  <textarea
                                    placeholder="Use this to describe to which limits the voucher can be used. E.g. Only at specific bakeries"
                                    rows={5}
                                    style={{ resize: "none" }}
                                    onChange={noteCheck}
                                    value={note}
                                  />
                                  <span
                                    id="display_count"
                                    style={{
                                      position: "absolute",
                                      top: "74%",
                                      left: "82%",
                                      width: "46px",
                                      textAlign: "right",
                                    }}
                                  >
                                    {note?.length}/80
                                  </span>
                                </div>
                              </div>
                              <span className="error-decoration">
                                {noteError}
                              </span>
                              <br />
                              <div className="ui-options d-flex">
                                {[
                                  {
                                    id: 1,
                                    name: "QR-Code",
                                    value: 1,
                                    icon: qr_code,
                                  },
                                  {
                                    id: 2,
                                    name: "BAR-Code",
                                    value: 2,
                                    icon: bar_code,
                                  },
                                ].map((item, index) => (
                                  <div className="text-center" key={index}>
                                    <div
                                      className={`option ${
                                        rewardCodeType !== item?.value &&
                                        "active"
                                      }`}
                                      key={item.id}
                                      onClick={() => {
                                        setRewardCodeType(item?.value);
                                      }}
                                    >
                                      <img
                                        src={item?.icon}
                                        height={26}
                                        width={26}
                                      />
                                    </div>
                                    <p
                                      className="text-capitalize mb-0"
                                      style={{
                                        opacity:
                                          rewardCodeType !== item?.value &&
                                          "0.5",
                                        fontSize: "9px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.name}
                                    </p>
                                  </div>
                                ))}
                              </div>
                              <br />
                              <div className="h-100 align-items-end">
                                <button
                                  className="btn-upload d-inline px-4"
                                  onClick={submit}
                                  style={{
                                    opacity:
                                      type?.length !== 0 &&
                                      monetaryValue != 0 &&
                                      note?.length != 0
                                        ? ""
                                        : "0.6",
                                  }}
                                >
                                  {btnUpload ? (
                                    <Spinner
                                      animation="border"
                                      role="status"
                                      style={{ height: "23px", width: "23px" }}
                                    >
                                      <span
                                        className="visually-hidden"
                                        style={{
                                          height: "23px",
                                          width: "23px",
                                        }}
                                      >
                                        Loading...
                                      </span>
                                    </Spinner>
                                  ) : null}
                                  Save Changes
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <Accordion
                    defaultActiveKey="1"
                    className="detail-accordion mt-4"
                  >
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="1"
                          onClick={() => {
                            setToggleList(!toggleList);
                          }}
                        >
                          <h4
                            className="mt-4 d-inline me-3"
                            style={{ fontWeight: "400" }}
                          >
                            Code list
                          </h4>
                          <img
                            className="position-relative"
                            src={toggleSwitch(toggleList)}
                            height={7}
                            width={7}
                            style={{ top: "-5px" }}
                          />
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <div className="mt-4 d-flex flex-wrap justify-content-between detail-reward align-items-center">
                            <div className="col-lg-4">
                              {
                                <div className="d-flex search-type">
                                  <select
                                    className="select edit-select w-100"
                                    onChange={onDropdownChange}
                                    style={{
                                      padding: "9px",
                                      fontWeight: "bold",
                                      backgroundPosition: "right 8px center",
                                      fontSize: "13px",
                                      border: "1px solid #d2e7ef",
                                    }}
                                  >
                                    {codeStatuses?.map((item, i) => (
                                      <option key={i} value={item.name}>
                                        {item.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              }
                            </div>
                            {/* <div
                              className="col-lg-6  d-flex align-items-center justify-content-left search-type"
                              onClick={handleSortIconClick}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="h-100 d-flex align-items-center me-2 mt-1 ms-md-2">
                                <img src={sort_icon} height={12} width={12} />
                              </div>
                              <span>Sort by &nbsp;</span>{" "}
                              <p
                                className="mb-0"
                                style={{ fontWeight: "bold" }}
                              >
                                {sortName}
                              </p>
                            </div> */}
                            <div
                              className={
                                btnFileUpload ? "col-lg-4" : "col-lg-3"
                              }
                            >
                              <button
                                className="btn-excel w-100 position-relative"
                                style={{ width: "100% !important" }}
                              >
                                {btnFileUpload ? (
                                  <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ height: "23px", width: "23px" }}
                                  >
                                    <span
                                      className="visually-hidden"
                                      style={{
                                        height: "23px",
                                        width: "23px",
                                      }}
                                    >
                                      Loading...
                                    </span>
                                  </Spinner>
                                ) : null}
                                upload excel list
                                <input
                                  className="custom-file-upload-detail"
                                  type="file"
                                  onChange={uploadFile}
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                              </button>
                              <span>{fileName}</span>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex flex-row align-items-center">
                            <div className="reward-checkbox styled-input-single d-inline">
                              <input
                                type="checkbox"
                                name="all"
                                id="all"
                                checked={checkedAll}
                                onChange={selectAllClickfun}
                              />
                              <label htmlFor={"all"} />
                              <label htmlFor={"all"}>
                                <u>Select all</u>
                              </label>
                            </div>
                            <ConfirmDeleteModal
                              deletedItems={clickedItems?.length}
                              deletedItemsArray={clickedItems}
                              rewardId={detail.id}
                              seekChange={seekchange2}
                              emptyDeletedItems={emptyDeletedItems}
                            />
                          </div>
                          <hr />
                          <div className="d-flex col-lg-10">
                            <div className="table-responsive w-100">
                              <LoadingOverlay
                                spinner={
                                  <HashLoader color="#5ba6b7" size={90} />
                                }
                                active={screenLoader}
                              >
                                <table
                                  size="sm"
                                  className="reward-table reward-detail-table w-100"
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sorting("UpdatedAt")}
                                      >
                                        Date updated{" "}
                                        {sortingTableObj[0].type ? (
                                          <FontAwesomeIcon
                                            icon={faChevronDown}
                                          />
                                        ) : (
                                          <FontAwesomeIcon icon={faChevronUp} />
                                        )}
                                      </th>
                                      <th
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sorting("Code")}
                                      >
                                        Code{" "}
                                        {sortingTableObj[1].type ? (
                                          <FontAwesomeIcon
                                            icon={faChevronDown}
                                          />
                                        ) : (
                                          <FontAwesomeIcon icon={faChevronUp} />
                                        )}
                                      </th>
                                      <th
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sorting("StatusKey")}
                                      >
                                        Status{" "}
                                        {sortingTableObj[2].type ? (
                                          <FontAwesomeIcon
                                            icon={faChevronDown}
                                          />
                                        ) : (
                                          <FontAwesomeIcon icon={faChevronUp} />
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {rewardsDetailState?.map((item, ind) => {
                                      return (
                                        <tr key={item.id}>
                                          <td>
                                            <div className="reward-checkbox styled-input-single">
                                              <input
                                                type="checkbox"
                                                name={item.id}
                                                id={item.id}
                                                onChange={(e) =>
                                                  handleCheckbox(e, item)
                                                }
                                              />
                                              <label htmlFor={item.id} />
                                            </div>
                                          </td>
                                          <td className="align-cb-field">
                                            {item.createdAtTimeAgo}{" "}
                                            {moment(item?.createdAt).format(
                                              "DD.MM.YYYY"
                                            )}
                                          </td>
                                          <td className="align-cb-field">
                                            {item.code}
                                          </td>
                                          <td className="align-cb-field">
                                            {item.status}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </LoadingOverlay>
                              <div className="align-items-center mt-3">
                                <p
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                  }}
                                  className="m-0"
                                >
                                  Showing{" "}
                                  <b>
                                    {rewardsDetailState?.length} of {totalCount}
                                  </b>{" "}
                                  Records
                                </p>
                              </div>
                              <div className="d-flex pagination-wrapper">
                                {rewardsDetailState.length > 0 && (
                                  <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={nextPageApiCall}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    activeLinkClassName={"active-link"}
                                    forcePage={pageNumberForSearch - 1}
                                  />
                                )}
                              </div>
                            </div>
                            <div></div>
                            <div></div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
            </span>
          </div>
        </Modal.Body>
      </Modal>
      {/* duplicate modal  */}
      <Modal
        show={duplicateEntriesModal}
        centered
        size="md"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="add-license-modal">
            <div className="modal-header">
              <h1 className="mb-0 mt-3 me-2">Duplicate entries</h1>
              <img
                src="/assets/icons/Cross.png"
                onClick={() => {
                  setDuplicateEntriesModal(false);
                }}
                // style={{ cursor: "pointer" }}
              />
            </div>
            <p className="mb-0 mt-3 me-2">
              You have {duplicateEntries} duplicate codes in this file, which
              are not added against this reward
            </p>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="reward-next"
                onClick={() => {
                  setDuplicateEntriesModal(false);
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showErrorModal ? (
        <ErrorModal
          errors={errors}
          showErrorModal={showErrorModal}
          closeErrModal={closeErrModal}
        />
      ) : null}
    </>
  );
};

export default DetailRewardModal;
