import React, { Component } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import sortIcon from "../../../../assets/icons/sort-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faBars,
  faFileExport,
  faSearch,
  faThLarge,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import AddContractor from "./addContractor/AddContractor";

import {
  exportClientListContractors,
  fetchClientContractorCompany,
  searchAndSortClient,
} from "../../../services/ClientServices";

import toast, { Toaster } from "react-hot-toast";
import { withRouter } from "react-router-dom";
import {
  MASTER_SORT_LIST,
  clientLicenses,
} from "../../../constant/AppConstant";

import RestrictedAction from "../../../common/modal/RestrictedAction";
import GridView from "./views/GridView";
import ListView from "./views/ListView";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";

class ClientLicensesList extends Component {
  state = {
    screenLoader: true,
    searchKeyword: "",
    dropdownKey: "",
    sortList: MASTER_SORT_LIST,
    clientContractorList: [],
    showCard: false,
    clientId: 0,
    restrictedActionModal: false,
    dataView: "grid",
    sortValueType: "",
    sortValueName: "",
    sortingArr: clientLicenses,
    screenLoaderTable: true,
    pageNumberForSearch: 1,
    enteredKeyword: "",
  };
  componentDidMount = async () => {
    if (localStorage.getItem("isHold") === "true") {
      this.setState({
        restrictedActionModal: true,
      });
    }
    setTimeout(await this.fetchContractorList(), 1000);
  };
  fetchContractorList = async () => {
    this.setState({
      screenLoaderTable: true,
    });
    let clientsList = await fetchClientContractorCompany(1, 20);
    if (clientsList?.status === 200) {
      this.setState({
        clientContractorList: clientsList?.data?.data,
        screenLoader: false,
        screenLoaderTable: false,
      });
    } else {
      toast.error("Error while fetching data!");
      this.setState({
        clientContractorList: [],
        screenLoader: false,
        screenLoaderTable: false,
      });
    }
  };

  companyDetail = (id) => {
    this.setState({
      showCard: true,
      clientId: id,
    });
    localStorage.setItem("showCard", true);
  };
  closeCompanyCard = () => {
    this.setState({
      showCard: false,
    });
  };
  closeDetail = () => {
    this.props.history.push(`/client-licenses`);
  };
  changeSearchValue = async (e) => {
    this.setState({
      searchKeyword: e.target.value,
      searchTrue: e.target.value.length == 0 ? false : true,
    });
  };
  onEnter = (e) => {
    if (e.code === "Enter") {
      this.searchAInput();
    }
  };
  searchAInput = async () => {
    const { searchKeyword } = this.state;
    this.setState({
      enteredKeyword: searchKeyword,
      pageNumberForSearch: 1,
    });
    let searchUrl = `?SearchBy=${searchKeyword}&PageNumber=1&PageSize=20&OrderType=${
      this.state.sortValueType
    }&OrderBy=${
      this.state.dataView == "grid"
        ? this.state.dropdownKey
        : this.state.sortValueName
    }`;
    console.log(searchUrl);
    await this.fetchSearchResults(searchUrl);
  };
  searchAndSorting = async (e) => {
    const { enteredKeyword, pageNumberForSearch } = this.state;
    this.setState({
      dropdownKey: e.target.value,
      sortValueType: "DESC",
    });
    let searchUrl = `?SearchBy=${enteredKeyword}&PageNumber=${pageNumberForSearch}&PageSize=20&OrderType=DESC&OrderBy=${e.target.value}`;
    console.log(searchUrl);
    await this.fetchSearchResults(searchUrl);
  };

  searchAndSortingListView = async (value) => {
    const { enteredKeyword, pageNumberForSearch } = this.state;

    this.setState({
      sortValueName: value,
    });
    this.setState({
      dropdownKey: value,
    });
    let sort_value_type = this.state.sortValueType;
    if (this.state.sortValueType == "ASC") {
      sort_value_type = "DESC";
      this.setState({
        sortValueType: "DESC",
      });
    } else {
      sort_value_type = "ASC";
      this.setState({
        sortValueType: "ASC",
      });
    }

    let newArr = this.state.sortingArr.map((item) => {
      if (item.name == value) {
        if (sort_value_type == "ASC") {
          item.type = true;
        } else {
          item.type = false;
        }
      } else {
        item.type = false;
      }
      return item;
    });
    this.setState({
      sortingArr: newArr,
    });
    console.log("myarr---", newArr);

    let searchUrl = `?SearchBy=${enteredKeyword}&PageNumber=${pageNumberForSearch}&PageSize=20&OrderType=${sort_value_type}&OrderBy=${value}`;
    console.log(searchUrl);
    await this.fetchSearchResults(searchUrl);
  };

  gridClick = (name) => {
    if (name === "grid") {
      let arr = this.state.sortingArr.map((item) => {
        item.type = false;
        return item;
      });
      this.setState({
        sortingArr: arr,
      });
    }
  };
  fetchSearchResults = async (searchUrl) => {
    this.setState({
      screenLoaderTable: true,
    });
    let searchFeedBack = await searchAndSortClient(searchUrl);
    if (searchFeedBack?.status === 200) {
      console.log("searchfeedbach", searchFeedBack);
      this.setState({
        clientContractorList: searchFeedBack?.data?.data,
        screenLoader: false,
        screenLoaderTable: false,
      });
    } else {
      toast.error("Error while getting data!");
      this.setState({
        clientContractorList: [],
        screenLoader: false,
        screenLoaderTable: false,
      });
    }
  };
  nextPageApiCall = async (data) => {
    const { enteredKeyword } = this.state;

    let doc = document.getElementsByTagName("li");
    let firstDoc = doc[1].children[0];
    firstDoc.classList.remove("active-link");
    let pageNumberForSearch = data.selected + 1;
    let searchUrl = `?SearchBy=${enteredKeyword}&PageNumber=${pageNumberForSearch}&PageSize=20&OrderType=${
      this.state.sortValueType
    }&OrderBy=${
      this.state.dataView == "grid"
        ? this.state.dropdownKey
        : this.state.sortValueName
    }`;

    this.setState({
      pageNumberForSearch,
    });
    await this.fetchSearchResults(searchUrl);
  };
  resetSearch = async () => {
    await this.fetchContractorList();
    this.setState({
      searchTrue: false,
      searchKeyword: "",
      enteredKeyword: "",
    });
  };
  renderTooltip = (clientContractorList) => (
    <Tooltip
      id="button-tooltip"
      {...clientContractorList}
      className="contractor-tooltip"
    >
      Licenses used by Clients:{" "}
      {clientContractorList?.items?.noOfLicensesUsedByClient} Contractors:{" "}
      {clientContractorList?.items?.noOfLicensesUsedByContractors}
    </Tooltip>
  );

  downloadContractorUsers = async () => {
    const { dropdownKey, sortValueType, enteredKeyword, pageNumberForSearch } =
      this.state;
    let params = {
      SearchBy: enteredKeyword,
      PageNumber: pageNumberForSearch,
      PageSize: 20,
      OrderBy: dropdownKey,
      OrderType: sortValueType,
    };
    await exportClientListContractors(params);
  };
  accLicense = () => {
    const { clientContractorList } = this.state;
    if (
      clientContractorList.items?.noOfLicenses >
      clientContractorList.items?.requestedLicencesCount
    ) {
      return "deducted from";
    } else {
      return "added to";
    }
  };
  render() {
    const { sortList, searchKeyword, clientContractorList } = this.state;
    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />
        <div className="my-section d-flex justify-content-between sidebar-for-mobile">
          <div className="my-container px-5">
            <div className="myDiv" id="detail">
              <div className="d-flex justify-content-between">
                <h1>Licenses</h1>
                <h1 style={{ fontWeight: "400" }}>
                  <OverlayTrigger
                    placement="top"
                    // delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip(clientContractorList)}
                  >
                    <FontAwesomeIcon
                      style={{
                        cursor: "pointer",
                        margin: "4px 10px 4px 2px",
                        fontSize: "25px",
                        color: " rgb(52, 99, 109)",
                      }}
                      icon={faInfoCircle}
                    />
                  </OverlayTrigger>
                  <b>{clientContractorList.items?.noOfLicensesUsed}</b> /{" "}
                  {clientContractorList.items?.isActive == true ? (
                    <>
                      <span
                        style={{
                          // textDecoration:
                          //   clientContractorList?.isActive == true &&
                          //   "line-through",
                          fontWeight:
                            clientContractorList.items?.isActive == true &&
                            "bold",
                          color:
                            clientContractorList.items?.isActive == true &&
                            "red",
                        }}
                        className="red-notice"
                      >
                        {clientContractorList.items?.requestedLicencesCount}
                      </span>{" "}
                      <div className="red-notice-card">
                        <img
                          src="/assets/icons/chevUp.png"
                          alt="chev"
                          className="position-absolute mt-0"
                          style={{
                            right: "16%",
                            top: "-4%",
                          }}
                        />
                        <Row>
                          <Col lg={1}>
                            <img
                              src="/assets/icons/warning.png"
                              alt="warning"
                            />
                          </Col>
                          <Col lg={11}>
                            <h6 className="primary-text text-decoration-underline">
                              App access is paused for all users.
                            </h6>
                            <ul className="secondary-text ps-2">
                              <li>
                                {clientContractorList.items?.noOfLicenses -
                                  clientContractorList.items
                                    ?.requestedLicencesCount}{" "}
                                license
                                {clientContractorList.items
                                  ?.requestedLicencesCount -
                                  clientContractorList.items?.noOfLicenses >
                                  1 && "s"}{" "}
                                have been {this.accLicense()} your account. (
                                {clientContractorList.items?.noOfLicenses}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />{" "}
                                {
                                  clientContractorList.items
                                    ?.requestedLicencesCount
                                }
                                )
                              </li>
                              <li>
                                Your current license distribution (
                                {clientContractorList.items?.noOfLicenses})
                                overshoots your new license limit (
                                {
                                  clientContractorList.items
                                    ?.requestedLicencesCount
                                }
                                ) by{" "}
                                {clientContractorList.items?.noOfLicenses -
                                  clientContractorList.items
                                    ?.requestedLicencesCount}
                                .
                              </li>
                            </ul>
                            <p className="secondary-text">
                              If you chose to not take action, the system will
                              automatically enforce license distribution in 20
                              days, based on the ratios of current licenses
                              assigned.
                            </p>
                            <hr />
                            <h6 className="primary-text">
                              Please reduce the licenses per contractor to
                              resume app usage.
                            </h6>
                          </Col>
                        </Row>
                      </div>
                      used
                      <br />
                      {/* <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Licenses have been reduced!</Tooltip>}
                      >
                        <h1
                          style={{
                            color: "#34636d",
                            cursor: "pointer",
                            marginLeft: "auto",
                            fontWeight: 600,
                          }}
                        >
                          {clientContractorList.requestedLicencesCount}{" "}
                          <span style={{ fontSize: "15px" }}>available</span>
                        </h1>
                      </OverlayTrigger> */}
                    </>
                  ) : (
                    <>
                      <span
                        style={{
                          textDecoration:
                            clientContractorList.items?.isHold == true &&
                            "line-through",
                          color:
                            clientContractorList.items?.isHold == true && "red",
                        }}
                      >
                        {clientContractorList.items?.noOfLicenses} used
                      </span>{" "}
                    </>
                  )}{" "}
                </h1>
              </div>
              <Row className="mt-5 my-4">
                <Col lg={1}>
                  <div className="ui-options d-flex">
                    {[
                      { id: 1, name: "grid", icon: faThLarge },
                      { id: 2, name: "list", icon: faBars },
                    ].map((item, index) => (
                      <div className="text-center" key={index}>
                        <div
                          className={`option ${
                            this.state.dataView !== item?.name && "active"
                          }`}
                          key={item.id}
                          onClick={() => {
                            this.closeCompanyCard();
                            this.setState({
                              dataView: item?.name,
                            });
                            this.gridClick(item?.name);
                          }}
                        >
                          <FontAwesomeIcon icon={item?.icon} />
                        </div>
                        <p
                          className="text-capitalize mb-0"
                          style={{
                            opacity:
                              this.state.dataView !== item?.name && "0.5",
                          }}
                        >
                          {item?.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Company name, person etc."
                      onFocus={this.closeDetail}
                      onChange={this.changeSearchValue}
                      onKeyUp={this.onEnter}
                      value={searchKeyword}
                      // autoComplete="off"
                    />
                    <FontAwesomeIcon
                      //onClick={searchTrue && this.resetSearch}
                      //icon={searchTrue ? faTimes : faSearch}
                      icon={faSearch}
                      onClick={this.searchAInput}
                    />
                  </div>
                </Col>
                <Col lg={3}>
                  {this.state.dataView === "grid" && (
                    <div className="d-flex search-type">
                      <p className="p-width">Sort by</p>
                      <img
                        src={sortIcon}
                        alt="sort-icon"
                        width={25}
                        height={25}
                      />
                      <select
                        className="select"
                        onChange={this.searchAndSorting}
                      >
                        {sortList?.map((item, i) => (
                          <option key={i} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </Col>

                <Col lg={5} className="d-flex justify-content-end">
                  <div
                    className="d-flex mt-2 me-3"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={this.downloadContractorUsers}
                  >
                    <FontAwesomeIcon
                      icon={faFileExport}
                      style={{
                        color: "#2b4755",
                      }}
                    />{" "}
                    <p className="text-decoration-underline ms-2 mb-0 text-uppercase">
                      Export
                    </p>{" "}
                  </div>
                  <div>
                    <AddContractor
                      updateConctractor={this.fetchContractorList}
                      totalicenses={clientContractorList.items?.noOfLicenses}
                      usedLicenses={
                        clientContractorList.items?.noOfLicensesUsed
                      }
                    />
                  </div>
                </Col>
              </Row>
              {this.state.dataView === "grid" ? (
                <GridView
                  updateList={this.fetchContractorList}
                  fetchContractorList={this.fetchContractorList}
                  clientId={this.state.clientId}
                  closeCard={this.closeCompanyCard}
                  list={clientContractorList}
                  companyDetails={this.companyDetail}
                  nextPage={this.nextPageApiCall}
                  page={this.state.pageNumberForSearch}
                  card={this.state.showCard}
                />
              ) : (
                <LoadingOverlay
                  spinner={<HashLoader color="#5ba6b7" size={90} />}
                  active={this.state.screenLoaderTable}
                >
                  <ListView
                    updateList={this.fetchContractorList}
                    clientId={this.state.clientId}
                    closeCard={this.closeCompanyCard}
                    list={clientContractorList}
                    companyDetails={this.companyDetail}
                    card={this.state.showCard}
                    sort={this.searchAndSortingListView}
                    nextPage={this.nextPageApiCall}
                    page={this.state.pageNumberForSearch}
                    sortingArr={this.state.sortingArr}
                  />
                </LoadingOverlay>
              )}
            </div>
          </div>
        </div>
        <RestrictedAction
          show={this.state.restrictedActionModal}
          handleClose={() => {
            this.setState({
              restrictedActionModal: false,
            });
            localStorage.setItem("isHold", false);
          }}
          newText={true}
          text="You are currently on hold!"
        />
      </>
    );
  }
}
export default withRouter(ClientLicensesList);
