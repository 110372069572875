import React, { useEffect, useState } from "react";
import {
  faSearch,
  faCircle,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { rewardsTableSorting } from "./rewardsData";
import { Col, Row, Table } from "react-bootstrap";
import AddRewardModal from "./addReward/AddRewardModal";
import DetailRewardModal from "./detailReward/DetailRewardModal";
import "./Reward.css";
import EditRewardModal from "./editReward/EditRewardModal";
import SimpleBar from "simplebar-react";
import {
  fetchAllClientCompany,
  fetchDashboardRewards,
  searchAndSortDashboardRewards,
  fetchRewardDistribution,
} from "../../../services/MasterServices";
import ReactPaginate from "react-paginate";
import moment from "moment";
import toast from "react-hot-toast";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";

const Rewards = () => {
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [detail, setDetail] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [dashboardRewards, setDashboardRewards] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [enteredSearchValue, setEnteredSearchValue] = useState("");
  const [pageNumberForSearch, setPageNumberForSearch] = useState(1);
  const [availableRewards, setAvailableRewards] = useState(1);
  const [player, setPlayer] = useState(0);
  const [contractor, setContractor] = useState(0);
  const [givenRewards, setGivenRewards] = useState(0);
  const [rewardDate, setRewardDate] = useState("");
  const [rewardPlaceList, setRewardPlaceList] = useState([]);
  const [usedRewards, setUsedRewards] = useState(1);
  const [insufficientCodes, setInsufficientCodes] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  const [fetchedPlaceList, setFetchedPlaceList] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [sortingTableObj, setSortingTableObj] = useState(rewardsTableSorting);
  const cliendId = JSON.parse(
    localStorage.getItem("staySafeUserData")
  ).clientId;
  const role = JSON.parse(localStorage.getItem("staySafeUserData")).role;

  const sorting = async (value) => {
    let newObj = sortingTableObj.map((item) => {
      value == item.name ? (item.type = !item.type) : (item.type = false);
      return item;
    });
    newObj.forEach((item) => {
      if (value == item.name) {
        sortingurl(value, item);
      }
    });
    setSortingTableObj(newObj);
  };
  const sortingurl = (value_name, obj) => {
    if (obj.type == false) {
      const call = async () => {
        let searchUrl = `?clientId=${selectedClient}&OrderBy=${value_name}ASC&PageNumber=${pageNumberForSearch}&SearchKey=${enteredSearchValue}&PageSize=10`;
        setPageNumberForSearch(pageNumberForSearch);
        await fetchSearchResults(searchUrl);
        setSortValue(value_name + "ASC");
      };
      return call();
    } else {
      const call = async () => {
        let searchUrl = `?clientId=${selectedClient}&OrderBy=${value_name}DESC&PageNumber=${pageNumberForSearch}&SearchKey=${enteredSearchValue}&PageSize=10`;
        setPageNumberForSearch(pageNumberForSearch);
        await fetchSearchResults(searchUrl);
        setSortValue(value_name + "DESC");
      };
      return call();
    }
  };
  const itemDate = (item) => {
    const calculatedDate = new Date(
      item.match(/(\d+)/g)[0],
      item.match(/(\d+)/g)[1] - 1,
      item.match(/(\d+)/g)[2]
    );
    return calculatedDate;
  };

  useEffect(() => {
    let initialClientId,
      clientLogin = false;
    if (role == "CA") {
      clientLogin = true;
      initialClientId = cliendId;
    } else if (role == "MA") {
      clientLogin = false;
      initialClientId = "";
    }
    const selected_client = (res) =>
      clientLogin ? initialClientId : res?.data?.data?.items[0]?.companyId;
    const getTotalClients = async () => {
      const result = await fetchAllClientCompany();
      if (result?.status == 200) {
        setSelectedClient(selected_client(result));
        rewards_table(selected_client(result), 1);
        rewards_distribution(selected_client(result));
      } else {
        toast.error("Error fetching clients");
      }
    };
    getTotalClients();
  }, []);
  const table_rewards_error = () => {
    toast.error("Error while fetching dashboard rewards");
    setScreenLoader(false);
    setDashboardRewards([]);
    setPageCount(0);
    setTotalCount(0);
  };
  const rewards_table = async (id, page) => {
    const result2 = await fetchDashboardRewards(id, page);
    setScreenLoader(true);
    if (result2?.status == 200) {
      setPageNumberForSearch(page);
      setDashboardRewards(result2?.data?.data?.items);
      setPageCount(result2?.data?.data?.totalPages);
      setTotalCount(result2?.data?.data?.totalCount);
      setScreenLoader(false);
    } else {
      table_rewards_error();
    }
  };
  const rewards_distribution = async (id) => {
    const result3 = await fetchRewardDistribution(id);
    setFetchedPlaceList(false);
    if (result3.status == 200) {
      setAvailableRewards(result3?.data?.data?.availableRewards);
      setInsufficientCodes(result3?.data?.data?.insufficientRewardCodes);
      setContractor(result3?.data?.data?.contractorCount);
      setPlayer(result3?.data?.data?.topPlayersCount);
      setGivenRewards(
        result3?.data?.data?.topPlayersCount *
          result3?.data?.data?.contractorCount
      );
      setUsedRewards(result3?.data?.data?.usedRewards);
      setRewardDate(result3?.data?.data?.rewardDistributionTime);
      setRewardPlaceList(result3?.data?.data?.rewardPlaceList);
      setFetchedPlaceList(true);
    } else {
      toast.error("Error while fetching data");

      setAvailableRewards(0);
      setInsufficientCodes(false);
      setContractor(0);
      setPlayer(0);
      setGivenRewards([]);
      setUsedRewards(0);
      setRewardDate();
      setRewardPlaceList([]);
      setFetchedPlaceList(true);
    }
  };
  const dashboardRewardCall = () => {
    rewards_table(parseInt(selectedClient), pageNumberForSearch);
    rewards_distribution(selectedClient);
  };
  const dashboardRewardCall2 = () => {
    rewards_table(parseInt(selectedClient), 1);
    rewards_distribution(selectedClient);
  };

  const changeSearchKey = (e) => {
    setSearchValue(e.target.value);
  };
  const onEnter = (e) => {
    if (e.code === "Enter") {
      searchDashboardApi();
    }
  };
  const searchDashboardApi = async () => {
    setPageNumberForSearch(1);
    setEnteredSearchValue(searchValue);
    let searchUrl = `?clientId=${selectedClient}&OrderBy=${sortValue}&SearchKey=${searchValue}&PageNumber=1&PageSize=10`;
    setPageNumberForSearch(pageNumberForSearch);
    await fetchSearchResults(searchUrl);
  };
  const nextPageApiCall = async (data) => {
    let parent = document.getElementsByTagName("ul")[1];
    let doc = parent.getElementsByTagName("li");
    let firstDoc = doc[1].children[0];
    firstDoc.classList.remove("active-link");
    let pageNumberForSearchnext = data.selected + 1;
    let searchUrl = `?clientId=${selectedClient}&OrderBy=${sortValue}&SearchKey=${enteredSearchValue}&PageNumber=${pageNumberForSearchnext}&PageSize=10&SearchKey=${searchValue}`;
    setPageNumberForSearch(pageNumberForSearchnext);
    await fetchSearchResults(searchUrl);
  };
  const fetchSearchResults = async (searchUrl) => {
    setScreenLoader(true);
    let searchFeedBack = await searchAndSortDashboardRewards(searchUrl);
    if (searchFeedBack?.status === 200) {
      setScreenLoader(false);
      setDashboardRewards(searchFeedBack?.data?.data?.items);
      setTotalCount(searchFeedBack?.data?.data?.totalCount);
      setPageCount(searchFeedBack?.data?.data?.totalPages);
    } else {
      table_rewards_error();
    }
  };

  const showRewardDetail = (item) => {
    setDetail(item);
    setShowDetailModal(true);
  };
  const closeRewardModal = () => {
    setShowDetailModal(false);
  };
  const recallDistributionApi = () => {
    rewards_distribution(selectedClient);
  };

  const renderedPlacelist = (start, end) => {
    return rewardPlaceList?.slice(start, end).map((item, index) => {
      return (
        <div key={index} className="d-flex justify-content-between mt-2">
          <div
            className="index rounded-circle d-inline-block"
            style={{ background: item.bgColor }}
          >
            <span className="w-100 h-100 text-center d-inline-block">
              {item.place}.
            </span>
          </div>
          <div className="d-inline-block value default-color">
            {item.rewardName}
          </div>
        </div>
      );
    });
  };

  const sufficientCodes = () => {
    return usedRewards == 0
      ? "Running out of codes in " + 0 + " months"
      : "Running out of codes in " +
          Math.floor(availableRewards / usedRewards) +
          " months";
  };
  const topThreeBackground = (index) => {
    switch (index) {
      case 0:
        return "rgb(255, 219, 65)";
      case 1:
        return "rgb(216, 216, 216)";
      case 2:
        return "rgb(248, 201, 124)";
      default:
        return "";
    }
  };
  return (
    <>
      <div className="my-section d-flex justify-content-between sidebar-for-mobile">
        <div className="my-container" style={{ padding: "2.6rem 4rem 0" }}>
          <div className="myDiv">
            <div className="d-flex flex-column col-lg-3 mb-4"></div>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <h1
                className="mb-0"
                style={{ fontWeight: "bold", fontSize: "34px" }}
              >
                Rewards
              </h1>
              <div style={{ display: selectedClient ? "block" : "none" }}>
                <h4
                  className="mb-0 text-right"
                  style={{
                    color: "#34636d",
                    marginBottom: 0,
                    fontWeight: "bold",
                    textAlign: "right",
                    fontSize: "22px",
                  }}
                >
                  {availableRewards} available
                </h4>
                <h4
                  className="mb-0"
                  style={{
                    color: "#34636d",
                    marginBottom: 0,
                    fontWeight: "bold",
                    textAlign: "right",
                    fontSize: "22px",
                  }}
                >
                  {usedRewards} used per month
                </h4>
                <p
                  className="w-100 d-block text-end"
                  style={{
                    fontSize: "16px",
                    color: insufficientCodes ? "#C11E1E" : "rgb(52, 99, 109)",
                    fontWeight: "normal",
                  }}
                >
                  {insufficientCodes
                    ? "Not enough codes for this month!"
                    : sufficientCodes()}
                </p>
              </div>
            </div>
            <div
              className="mt-4"
              style={{
                display: selectedClient ? "flex" : "none",
                flexWrap: "wrap",
              }}
            >
              <Col lg={4} sm={12} className="rewards-left p-0">
                <p style={{ fontSize: "16px", fontWeight: "normal" }}>
                  {player !== 0 ? (
                    <>
                      Rewards will be given out to the{" "}
                      <b style={{ fontStyle: "italic" }}>
                        top {player} players of each contractor
                      </b>
                      . They are distributed monthly, with the next distribution
                      on&nbsp;
                      <b style={{ fontStyle: "italic" }}>
                        {rewardDate && moment(rewardDate).format("DD.MM.YYYY")},{" "}
                        {rewardDate && moment(rewardDate).format("LT")}
                      </b>
                      . With the current amount of contractors ({contractor}),{" "}
                      <b style={{ fontStyle: "italic" }}>
                        {givenRewards} rewards
                      </b>{" "}
                      will be given out.
                    </>
                  ) : (
                    <>
                      {" "}
                      Rewards will be given out to the{" "}
                      <b style={{ fontStyle: "italic" }}>
                        top players of each contractor
                      </b>
                      . They are distributed monthly, with the next distribution
                      on&nbsp;
                      <b style={{ fontStyle: "italic" }}>
                        {rewardDate && moment(rewardDate).format("DD.MM.YYYY")},{" "}
                        {rewardDate && moment(rewardDate).format("LT")}
                      </b>
                    </>
                  )}
                </p>
                <ul className="default-color" style={{ listStyleType: "none" }}>
                  <li>
                    <FontAwesomeIcon
                      style={{ fontSize: "4px", height: "14px" }}
                      icon={faCircle}
                    />
                    &nbsp;&nbsp;Players will need a minimum rating of 50 to be
                    eligible.
                  </li>
                  <li>
                    <FontAwesomeIcon
                      style={{ fontSize: "4px", height: "14px" }}
                      icon={faCircle}
                    />
                    &nbsp;&nbsp;Admin accounts using the app will not be
                    eligible for rewards.
                  </li>
                </ul>
                <>
                  <h6 className="default-color">
                    <strong>Distribution Settings</strong>
                  </h6>

                  <>
                    <br />
                    {insufficientCodes && (
                      <p style={{ color: "#C11E1E" }}>
                        One of the rewards is insufficient for distribution. The
                        system
                        <br /> is on hold while this is the case.
                        <br /> The system will resume distribution once…
                        <br />
                        <FontAwesomeIcon
                          style={{ fontSize: "4px", height: "12px" }}
                          icon={faCircle}
                        />
                        &nbsp;… distribution settings have been adjusted
                        accordingly.
                        <br />
                        <FontAwesomeIcon
                          style={{ fontSize: "4px", height: "12px" }}
                          icon={faCircle}
                        />
                        &nbsp;… or sufficient codes have been added.
                      </p>
                    )}
                  </>
                  <div className="d-flex flex-row flex-wrap justify-content-between">
                    <div className="flex-row d-flex flex-wrap col-lg-11">
                      <Col xl={6} lg={12} className="">
                        {rewardPlaceList?.slice(0, 5).map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="d-flex justify-content-between mt-2"
                            >
                              <div
                                className="index rounded-circle d-inline-block"
                                style={{
                                  background: topThreeBackground(index),
                                }}
                              >
                                <span className="w-100 h-100 text-center d-inline-block">
                                  {item.place}.
                                </span>
                              </div>
                              <div className="d-inline-block value default-color">
                                {item.rewardName}
                              </div>
                            </div>
                          );
                        })}
                      </Col>
                      <Col xl={6} lg={12} className="">
                        {rewardPlaceList?.length > 5 &&
                          renderedPlacelist(5, 10)}
                      </Col>
                      <Col xl={6} lg={12} className="">
                        {rewardPlaceList?.length > 10 &&
                          renderedPlacelist(10, 15)}
                      </Col>
                      <Col xl={6} lg={12} className="">
                        {rewardPlaceList?.length > 15 &&
                          renderedPlacelist(15, 20)}
                      </Col>
                    </div>
                    <div className="flex-fill text-end">
                      {fetchedPlaceList && (
                        <EditRewardModal
                          selectedClient={selectedClient}
                          player={player}
                          rewardPlaceListApidata={rewardPlaceList}
                          recallDistributionApi={recallDistributionApi}
                        />
                      )}
                    </div>
                  </div>
                </>
              </Col>
              <Col lg={1} className="seperate">
                <div className="d-flex h-100 justify-content-center">
                  <div className="vertical-line"></div>
                </div>
              </Col>
              <Col lg={7} className="col-12">
                <Row className="mb-4">
                  <Col lg={4}>
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder=""
                        //   onFocus={this.closeDetail}
                        onKeyDown={(e) => onEnter(e)}
                        onChange={(e) => changeSearchKey(e)}
                        value={searchValue}
                      />
                      <FontAwesomeIcon
                        id="searchButton"
                        //   onClick={this.searchAInput}
                        style={{ cursor: "pointer" }}
                        icon={faSearch}
                        onClick={searchDashboardApi}
                        //icon={searchTrue ? faTimes : faSearch}
                      />
                    </div>
                  </Col>
                  {/* <Col>
                    <div
                      className="d-flex search-type"
                      onClick={handleSortIconClick}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="h-100">
                        <img src={sort_icon} height={12} width={12} />
                      </div>
                      <p className="p-width">Sort by</p>
                      <p style={{ fontWeight: "bold" }}>{sortName}</p>
                    </div>
                  </Col> */}
                </Row>
                <LoadingOverlay
                  spinner={<HashLoader color="#5ba6b7" size={90} />}
                  active={screenLoader}
                >
                  <SimpleBar
                    className="my-scrollbar"
                    contentclassname="my-scrollbar-content"
                    // style={{ maxHeight: 350 }}
                  >
                    <div className="table-responsive">
                      <Table
                        bordered
                        size="sm"
                        className="reward-table list-view-table"
                      >
                        <thead>
                          <tr>
                            <th
                              style={{ paddingLeft: "0" }}
                              onClick={() => sorting("RewardName")}
                            >
                              Reward name{" "}
                              {rewardsTableSorting[0].type ? (
                                <FontAwesomeIcon icon={faChevronDown} />
                              ) : (
                                <FontAwesomeIcon icon={faChevronUp} />
                              )}
                            </th>
                            <th
                              style={{ paddingLeft: "0" }}
                              onClick={() => sorting("UsedCodeCount")}
                            >
                              Used codes{" "}
                              {rewardsTableSorting[1].type ? (
                                <FontAwesomeIcon icon={faChevronDown} />
                              ) : (
                                <FontAwesomeIcon icon={faChevronUp} />
                              )}
                            </th>
                            <th
                              style={{ paddingLeft: "0" }}
                              onClick={() => sorting("UnDistributedCodeCount")}
                            >
                              Undistributed codes{" "}
                              {rewardsTableSorting[2].type ? (
                                <FontAwesomeIcon icon={faChevronDown} />
                              ) : (
                                <FontAwesomeIcon icon={faChevronUp} />
                              )}
                            </th>
                            <th
                              style={{ paddingLeft: "0" }}
                              onClick={() => sorting("UpdatedAt")}
                            >
                              Last change{" "}
                              {rewardsTableSorting[3].type ? (
                                <FontAwesomeIcon icon={faChevronDown} />
                              ) : (
                                <FontAwesomeIcon icon={faChevronUp} />
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dashboardRewards?.map((item, ind) => {
                            return (
                              <tr
                                key={item.id}
                                onClick={() => showRewardDetail(item)}
                              >
                                <td style={{ paddingLeft: "7px" }}>
                                  {item.rewardName}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.usedCodeCount}
                                </td>
                                <td style={{ fontWeight: "bold" }}>
                                  {item.unDistributedCodeCount}
                                </td>
                                <td>
                                  {item.updatedAtTimeAgo}{" "}
                                  {"(" +
                                    moment(item?.updatedAt).format(
                                      "DD.MM.YYYY"
                                    ) +
                                    ")"}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      {showDetailModal && (
                        <DetailRewardModal
                          detail={detail}
                          // clientId={selectedClient}
                          clientId={selectedClient}
                          dashboardRewardCall={dashboardRewardCall}
                          show={showDetailModal}
                          // indexdetail={detailIndex}
                          close={closeRewardModal}
                        />
                      )}
                      <div className="d-flex justify-content-around align-items-center">
                        <p
                          style={{
                            fontSize: "12px",
                          }}
                          className="m-0"
                        >
                          Showing{" "}
                          <b>
                            {dashboardRewards?.length} of {totalCount}
                          </b>{" "}
                          Records
                        </p>
                        {dashboardRewards?.length !== 0 && (
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={nextPageApiCall}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            activeLinkClassName={"active-link"}
                            forcePage={pageNumberForSearch - 1}
                          />
                        )}
                      </div>
                    </div>
                  </SimpleBar>
                </LoadingOverlay>
                <div className="d-flex justify-content-end">
                  <AddRewardModal
                    selectedClient={selectedClient}
                    dashboardRewardCall={dashboardRewardCall2}
                  />
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rewards;
