import React, { Component } from "react";
import { supportDetail } from "../../../../services/UserManagementServices";
import toast, { Toaster } from "react-hot-toast";
import { getKeyTranslation } from "../../../../utils/Translations";
class EditSupport extends Component {
  state = {
    title: "",
    text: "",
  };
  validation = (item, type) => {
    const { value } = item.target;
    if (type === "title") {
      this.setState({
        title: value,
        errorTitle: "",
      });
    } else if (type === "text") {
      this.setState({
        text: value,
        errorText: "",
      });
    }
  };

  submitRequest = async (e) => {
    e.preventDefault();
    const { title, text } = this.state;
    let error = false;
    if (title.length === 0) {
      error = true;
      this.setState({
        errorTitle: " Please enter the title",
      });
    }
    if (text.length === 0) {
      error = true;
      this.setState({
        errorText: " Please enter the detail",
      });
    }
    if (!error) {
      let obj = {
        title: title,
        message: text,
      };
      let supportResponse = await supportDetail(obj);
      if (supportResponse.status === 200) {
        toast.success(getKeyTranslation(supportResponse?.data?.message));
        this.setState({
          text: "",
          title: "",
        });
      } else {
        toast.error("An error occured while adding record");
      }
    }
  };
  render() {
    const { text, title, errorText, errorTitle } = this.state;
    return (
      <>
        <Toaster position="top-center" reverseOrder={true} />
        <form>
          <div className="edit-support d-flex flex-column">
            <label className="screen-label">
              For any questions please contact support through this form
            </label>
            <label className="screen-label">Title</label>
            <input
              onChange={(item) => this.validation(item, "title")}
              className="screen-input"
              value={title}
              type="text"
            />
            <p className="text-color text-danger">{errorTitle}</p>
            <label className="screen-label">Text</label>
            <textarea
              onChange={(item) => this.validation(item, "text")}
              className="screen-input"
              type="text"
              rows={7}
              value={text}
            />
            <p className="text-color text-danger">{errorText}</p>
            <button onClick={this.submitRequest} className="screen-button">
              Send
            </button>
          </div>
        </form>
      </>
    );
  }
}
export default EditSupport;
