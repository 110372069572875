import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ReactSwitch from "../../../../common/ReactSwitch";
import {
  fetchEditClientDetail,
  updateEditClientDetail,
} from "../../../../services/MasterServices";
import {
  BussinessIntelligenceYearlyPlan,
  contentAgreementPrice,
  getUserPrice,
} from "../../../../utils/addLicensesCalculations";
import "./EditLicense.css";
import toast from "react-hot-toast";
import { getKeyTranslation } from "../../../../utils/Translations";
class EditLicense extends Component {
  state = {
    intelligenceAccess: true,
    contentAgreement: false,
    noOfLicneses: 0,
    licensesPrice: 0,
    BussinessIntelligencePrice: {
      oneYear: 0,
      twoAndThreeyear: 0,
      fourYear: 0,
    },
    contentPrice: { oneYear: 18, twoAndThreeyear: 15, fourYear: 13 },
    feePlan: "",
    oneYearPlan: "",
    twoAndThreeYearPlan: "",
    fourYearPlan: "",
  };

  changeIntelligenceAccess = async () => {
    const { intelligenceAccess, BussinessIntelligencePrice } = this.state;
    let BIprice = BussinessIntelligenceYearlyPlan(!intelligenceAccess);
    if (BIprice) {
      this.setState({
        intelligenceAccess: !intelligenceAccess,
        BussinessIntelligencePrice: BIprice,
      });
    } else {
      this.setState({
        intelligenceAccess: !intelligenceAccess,
        BussinessIntelligencePrice: BussinessIntelligencePrice,
      });
    }
    this.calculateDynmicValues();
  };
  changeContentAgreement = async () => {
    const { contentAgreement } = this.state;
    let Amount = contentAgreementPrice(!contentAgreement);
    this.setState({
      contentAgreement: !contentAgreement,
      contentPrice: Amount,
    });
    this.calculateDynmicValues();
  };
  addLicenses = async (e, oneyear, twoYear, fourYear) => {
    let amountOfLicenses = e.target.value;
    console.log("==>", amountOfLicenses);
    let pricePerLicense = getUserPrice(+amountOfLicenses, "PricePerUser");
    this.setState({
      noOfLicneses: e.target.value,
      amountOfLiscenseError: "",
      licensesPrice: pricePerLicense,
    });
    this.calculateDynmicValues();
  };
  keyDown = (e) => {
    var invalidChars = ["-", "+", "e", "E", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  openEditPlan = async () => {
    await this.fetchEditDetail();
    this.setState({
      editLicenseModal: true,
    });
  };
  fetchEditDetail = async () => {
    let id = this.props?.id;
    let detail = await fetchEditClientDetail(id);
    if (detail?.status === 200) {
      let pricePerLicense = getUserPrice(
        +detail.data.data.noOfLicenses,
        "PricePerUser"
      );
      this.setState({
        intelligenceAccess: !detail.data.data.isBusinessIntelligence,
        contentAgreement: !detail.data.data.isContentAgreement,
        noOfLicneses: +detail.data.data.noOfLicenses,
        licensesPrice: pricePerLicense,
        feePlan: detail.data.data.feePlan,
      });
      this.changeContentAgreement();
      this.changeIntelligenceAccess();
      this.calculateDynmicValues();
    }
  };

  closeEditModal = () => {
    this.setState({
      editLicenseModal: false,
    });
  };
  checkBoxSelection(e, year) {
    var myCheckbox = document.getElementsByName("myCheckbox");
    Array.prototype.forEach.call(myCheckbox, function (el) {
      el.checked = false;
    });
    console.log(year);
    e.target.checked = true;
    this.setState({
      selectAmountError: "",
      monthlyAmount: e.target.value,
      feePlan: year,
    });
  }
  dynamicAmountHnadler = (oneYear, twoYear, fourYear) => {
    switch (this.state.feePlan) {
      case "annual":
        this.setState({
          monthlyAmount: oneYear,
        });
        break;

      case "biennial":
        this.setState({
          monthlyAmount: twoYear,
        });
        break;

      case "quadrennial":
        this.setState({
          monthlyAmount: fourYear,
        });
        break;

      default:
        break;
    }
  };
  calculateDynmicValues = () => {
    const {
      noOfLicneses,
      licensesPrice,
      BussinessIntelligencePrice,
      contentPrice,
    } = this.state;
    let totalLisencesPrice = noOfLicneses * licensesPrice;
    let oneYearPlan =
      12 *
      (totalLisencesPrice +
        BussinessIntelligencePrice?.oneYear +
        contentPrice.oneYear);
    let twoAndThreeYearPlan =
      36 *
      (totalLisencesPrice +
        BussinessIntelligencePrice?.twoAndThreeyear +
        contentPrice.twoAndThreeyear);
    let fourYearPlan =
      48 *
      (totalLisencesPrice +
        BussinessIntelligencePrice?.fourYear +
        contentPrice.fourYear);
    console.log("dfrom", oneYearPlan, twoAndThreeYearPlan, fourYearPlan);
    this.dynamicAmountHnadler(oneYearPlan, twoAndThreeYearPlan, fourYearPlan);
    this.setState({
      oneYearPlan: oneYearPlan,
      twoAndThreeYearPlan: twoAndThreeYearPlan,
      fourYearPlan: fourYearPlan,
    });
  };
  handleSubmitUser = async () => {
    const { noOfLicneses, contentAgreement, intelligenceAccess } = this.state;
    if (!noOfLicneses) {
      this.setState({
        amountOfLiscenseError: "Please enter the require amount of licenses",
      });
    } else {
      let obj = {
        amountOfLicenses: +noOfLicneses,
        isBusinessIntelligence: intelligenceAccess,
        isContentAgreement: contentAgreement,
        //totalFee: +monthlyAmount,
        //feePlan: feePlan,
      };
      console.log(obj);
      let id = this.props?.id;
      let response = await updateEditClientDetail(id, obj);
      if (response.status === 200) {
        toast.success(getKeyTranslation(response?.data?.message));
        window.location.reload();
        this.props?.closeCard();
      } else {
        toast.error(getKeyTranslation(response?.data?.message));
      }
    }
  };

  render() {
    const {
      intelligenceAccess,
      contentAgreement,
      editLicenseModal,
      noOfLicneses,
      selectAmountError,
      amountOfLiscenseError,
      feePlan,
      oneYearPlan,
      twoAndThreeYearPlan,
      fourYearPlan,
    } = this.state;

    return (
      <>
        <button onClick={this.openEditPlan}>edit plan</button>
        <Modal
          show={editLicenseModal}
          centered
          size="md"
          className="edit-add modal-screen"
        >
          <div className="edit-add-modal">
            <div className="modal-header">
              <h1>Edit plan</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={this.closeEditModal}
              ></button>
            </div>
            <Row>
              <Col lg={6}>
                <label>Amount of licenses</label>
                <br />
                <input
                  onChange={(e) =>
                    this.addLicenses(
                      e,
                      oneYearPlan,
                      twoAndThreeYearPlan,
                      fourYearPlan
                    )
                  }
                  onKeyDown={(e) => this.keyDown(e)}
                  value={noOfLicneses}
                  type="number"
                  min="0"
                />
                <p className="error-decoration">{amountOfLiscenseError}</p>
                <div className="d-none">
                  <label>Business intelligence access</label>
                  <br />
                  <ReactSwitch
                    value={intelligenceAccess}
                    handleChange={(e) =>
                      this.changeIntelligenceAccess(
                        e,
                        oneYearPlan,
                        twoAndThreeYearPlan,
                        fourYearPlan
                      )
                    }
                  />
                  <br />
                  <label>Content agreement</label>
                  <br />
                  <ReactSwitch
                    value={contentAgreement}
                    handleChange={(e) =>
                      this.changeContentAgreement(
                        e,
                        oneYearPlan,
                        twoAndThreeYearPlan,
                        fourYearPlan
                      )
                    }
                  />
                </div>
              </Col>
              <Col lg={6} className="mt-auto">
                <div className="d-none">
                  <label>Monthly Fee</label>
                  <div className="d-flex amount styled-input-single">
                    <input
                      id="checkbox-example-one-1"
                      className="w-25 mt-3 annual"
                      type="checkbox"
                      name="myCheckbox"
                      value={oneYearPlan}
                      defaultChecked={feePlan === "annual"}
                      onClick={(e) =>
                        this.checkBoxSelection(e, "annual", oneYearPlan)
                      }
                    />
                    <label htmlFor={"checkbox-example-one-1"} />
                    <p>1 year </p>
                    <h4> {oneYearPlan} €</h4>
                  </div>
                  <div className="d-flex amount styled-input-single">
                    <input
                      id={"checkbox-example-one-2"}
                      className="w-25 mt-3 biennial "
                      type="checkbox"
                      name="myCheckbox"
                      value={twoAndThreeYearPlan}
                      defaultChecked={feePlan === "biennial"}
                      onClick={(e) =>
                        this.checkBoxSelection(
                          e,
                          "biennial",
                          twoAndThreeYearPlan
                        )
                      }
                    />
                    <label htmlFor={"checkbox-example-one-2"} />
                    <p>2 & 3 year </p>
                    <h4>{twoAndThreeYearPlan} €</h4>
                  </div>
                  <div className="d-flex amount styled-input-single">
                    <input
                      id={"checkbox-example-one-3"}
                      className="w-25 mt-3 myClass quadrennial "
                      type="checkbox"
                      name="myCheckbox"
                      value={fourYearPlan}
                      defaultChecked={feePlan === "quadrennial"}
                      onClick={(e) =>
                        this.checkBoxSelection(e, "quadrennial", fourYearPlan)
                      }
                    />
                    <label htmlFor={"checkbox-example-one-3"} />
                    <p> 4 + year </p>
                    <h4>{fourYearPlan} €</h4>
                  </div>
                  <span className="error-decoration">{selectAmountError}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <col-12>
                <div class="modal-footer">
                  <div className="d-flex  add-license-buttons-edit ">
                    <button onClick={this.closeEditModal}>cancel</button>
                    <button onClick={this.handleSubmitUser}>Save</button>
                  </div>
                </div>
              </col-12>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}
export default withRouter(EditLicense);
