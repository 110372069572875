import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { deleteClientCompany } from "../../../../services/ClientServices";
import toast from "react-hot-toast";
import { getKeyTranslation } from "../../../../utils/Translations";
class DeleteContractor extends Component {
  state = {
    deleteButton: false,
  };
  deleteConfirmation = async () => {
    this.setState({
      deleteButton: true,
    });
    let deleteUser = await deleteClientCompany(this.props.id);
    if (deleteUser?.status === 200) {
      toast.success("User has been successfully deleted!");
      this.props.updateLicensesList();
      this.props.closeCard();
    } else {
      toast.error(getKeyTranslation(deleteUser?.data?.message));
    }
  };
  render() {
    const { companyName } = this.props;
    return (
      <>
        <Modal centered size="md" show={this.props.deleteContractor}>
          <div className="bg-white change-delete-modals">
            <div className="modal-header">
              <h1>Delete Contractor</h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.closeDeleteContractorModal()}
              ></button>
            </div>
            <label className="mt-3">
              Are you sure you want to delete
              <b> {companyName}</b>?
            </label>
            <div className="screen-buttons float-end">
              <button onClick={() => this.props.closeDeleteContractorModal()}>
                cancel
              </button>
              <button
                disabled={this.state.deleteButton}
                onClick={this.deleteConfirmation}
              >
                delete contractor
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default withRouter(DeleteContractor);
