import React, { Component } from "react";
import sortIcon from "../../../../../assets/icons/sort-icon.png";
import { Table } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { withRouter } from "react-router-dom";

class CompanyList extends Component {
  state = {};
  testFunction = (id) => {
    this.props.invoiceDetail();
  };
  render() {
    return (
      <>
        <div className="company-list-table">
          <SimpleBar style={{ maxHeight: 700 }}>
            <Table borderless className="sticky-table">
              <thead>
                <tr>
                  <th>
                    <img src={sortIcon} className="img-fluid" alt="" />
                    <label>Company name</label>
                  </th>
                  <th>
                    <img src={sortIcon} className="img-fluid" alt="" />
                    <label>Date</label>
                  </th>
                  <th>
                    <img src={sortIcon} className="img-fluid" alt="" />
                    <label>Amount</label>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.financeList?.map((item, index) => (
                  <tr
                    onClick={() => this.testFunction(item.financeId)}
                    key={index}
                  >
                    <td className="company-name">{item.companyName}</td>
                    <td>{item.date}</td>
                    <td>{item.amount} €</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </SimpleBar>
        </div>
      </>
    );
  }
}
export default withRouter(CompanyList);
