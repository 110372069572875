import React from "react";

const CreateImgGrid = (
  dimensions,
  s_img,
  bg_color,
  preview,
  selectedGridsFunction,
  createQuestionArray,
  selectedTabIndex
) => {
  let rows = [];
  let cols = [];
  let d_row = dimensions?.charAt(0);
  let d_col = dimensions?.charAt(dimensions.length - 1);
  rows = Array.from({ length: d_row }, (v, k) => k + 1);
  cols = Array.from({ length: d_col }, (v, k) => k + 1);
  return rows.map((row, ind) => {
    return (
      <div
        key={ind}
        className="grid-outside"
        style={{
          borderBottom: preview == "" ? "4px solid white" : "none",
        }}
      >
        {cols.map((col, ind2) => {
          return (
            <div
              onClick={() =>
                preview == ""
                  ? selectedGridsFunction(row, col, dimensions)
                  : null
              }
              key={ind2}
              className="grid-div grid-inside"
              style={{
                background: createQuestionArray[
                  selectedTabIndex
                ].options[0].optionTitles[0].optionTitle.includes(
                  `${col}${row}`
                )
                  ? bg_color
                  : "",
                position: "relative",
                borderRight: preview == "" ? "4px solid white" : "none",
              }}
            >
              {createQuestionArray[
                selectedTabIndex
              ].options[0].optionTitles[0].optionTitle.includes(
                `${col}${row}`
              ) && (
                <div className="position-relative h-100 w-100 d-flex align-items-center justify-content-center">
                  <img
                    src={s_img}
                    style={{
                      position: "absolute",
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  });
};

export default CreateImgGrid;
