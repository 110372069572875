export const dynamicFormEmptyCheck = (form) => {
  console.log(form);
  let nullCheck;
  form?.forEach(function (v) {
    if (
      Object.keys(v).some(function (k) {
        return v[k] === "";
      })
    ) {
      nullCheck = true;
    } else {
      nullCheck = false;
    }
  });
  return nullCheck;
};
