import React, { Component } from "react";
import {
  editUserInfo,
  fetchUserInfo,
} from "../../../../services/UserManagementServices";
import toast, { Toaster } from "react-hot-toast";
import { withRouter } from "react-router-dom";
import { getLoginStorage } from "../../../../utils/UserDataManagement";
import { jsonToForm } from "../../../../utils/JsonToFormObject";
import {
  ALPHABET_AND_SPACE_ONLY,
  EMPTY_FIELD,
  PHONE_ONLY,
  UPDATE_ERROR,
} from "../../../../constant/AppConstant";
import VerifyPasswordModal from "../../../../common/VerifyPasswordModal";
class EditAccountInfo extends Component {
  state = {
    companyName: "",
    contactPerson: "",
    phone: "",
    email: "",
    showVerificationModal: false,
  };
  componentDidMount = async () => {
    await this.fetchAccountInfo();
  };
  fetchAccountInfo = async () => {
    let id = getLoginStorage("id");
    let info = await fetchUserInfo(id);
    console.log(info);
    if (info.status === 200) {
      let information = info.data.data;
      this.setState({
        companyName: information.name,
        phone: information.phoneNo,
        contactPerson: information.contactPerson,
      });
    } else if (info.status === 401) {
      toast.error(UPDATE_ERROR);
    }
  };

  validation = (item, type) => {
    if (type === "companyName") {
      const valid = item.target.value.length <= 50;
      if (valid) {
        this.setState({
          companyName: item.target.value.trimStart(),
          errorCompany: "",
        });
      }
    } else if (type === "contctPerson") {
      const valid =
        item.target.value.match(ALPHABET_AND_SPACE_ONLY) &&
        item.target.value.length <= 50;
      if (valid || item.target.value === "") {
        this.setState({
          contactPerson: item.target.value.trimStart(),
          errorContactperson: "",
        });
      }
    } else {
      const valid =
        item.target.value.match(PHONE_ONLY) && item.target.value.length <= 15;
      if (valid || item.target.value === "") {
        this.setState({
          phone: item.target.value.trimStart(),
          errorPhone: "",
        });
      }
    }
  };

  submitForm = async () => {
    const { companyName, contactPerson, phone } = this.state;
    if (companyName.length === 0) {
      this.setState({
        errorCompany: EMPTY_FIELD,
      });
    } else if (contactPerson.length === 0) {
      this.setState({
        errorContactperson: EMPTY_FIELD,
      });
    } else if (phone.trim().length === 0 && phone.length <= 15) {
      this.setState({
        errorPhone:
          phone.length > 15
            ? "Phone number can have max length of 15 numbers"
            : EMPTY_FIELD,
      });
    } else {
      this.setState({
        showVerificationModal: true,
      });
    }
  };
  closeVerificationModal = async () => {
    this.setState({
      showVerificationModal: false,
    });
    await this.fetchAccountInfo();
  };
  verifiedPassowrd = async () => {
    const { companyName, contactPerson, phone } = this.state;
    let requestPayload = {
      CompanyName: companyName,
      ContactPerson: contactPerson,
      PhoneNumber: phone,
      Type: "client",
    };
    let formData = jsonToForm(requestPayload);
    let infoEditResponse = await editUserInfo(formData);
    if (infoEditResponse.status === 200) {
      this.setState({
        showVerificationModal: false,
      });
      await this.fetchAccountInfo();
      toast.success("Data has been successfully updated");
    } else if (infoEditResponse.status === 401) {
      toast.error(UPDATE_ERROR);
    }
  };
  render() {
    const {
      showVerificationModal,
      phone,
      companyName,
      errorCompany,
      errorContactperson,
      contactPerson,
      errorPhone,
    } = this.state;
    return (
      <>
        <Toaster position="top-center" reverseOrder={true} />
        <div className="edit-account d-flex flex-column">
          <label className="screen-label">Email</label>
          <input
            disabled
            type="email"
            value={getLoginStorage("email")}
            className="screen-input"
          />
          <label className="screen-label">Company Name</label>
          <input
            onChange={(item) => this.validation(item, "companyName")}
            type="text"
            className="screen-input"
            value={companyName}
            maxLength={50}
          />
          <p className="text-color">{errorCompany}</p>
          <label className="screen-label">Contact Person</label>
          <input
            onChange={(item) => this.validation(item, "contctPerson")}
            type="text"
            className="screen-input"
            value={contactPerson}
            maxLength={50}
          />
          <p className="text-color">{errorContactperson}</p>
          <label className="screen-label">Phone Nr.</label>
          <input
            onChange={(item) => this.validation(item, "phone")}
            type="text"
            className="screen-input"
            value={phone}
            maxLength={15}
          />
          <p className="text-color">{errorPhone}</p>
          <button onClick={this.submitForm} className="screen-button">
            Save changes
          </button>
          <VerifyPasswordModal
            show={showVerificationModal}
            close={this.closeVerificationModal}
            updateFunction={this.verifiedPassowrd}
          />
        </div>
      </>
    );
  }
}
export default withRouter(EditAccountInfo);
