import React, { Component } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import rightArrow from "../../../../assets/icons/arrow-right.png";
import {
  ALPHABET_AND_SPACE_ONLY,
  EMPTY_FIELD,
  PHONE_ONLY,
  UPDATE_ERROR,
  VALID_REGION,
  NUMERIC_ONLY,
} from "../../../constant/AppConstant";
import { jsonToForm } from "../../../utils/JsonToFormObject";
import {
  editUserInfo,
  fetchUserInfo,
} from "../../../services/UserManagementServices";
import {
  getLocalStorage,
  getLoginStorage,
  setLoginStorage,
} from "../../../utils/UserDataManagement";
import toast, { Toaster } from "react-hot-toast";
import { withRouter } from "react-router-dom";
import { BASE_URL_IMAGE, CLIENT_INSTRUCTIONS } from "../../../constant/ApiUrls";
class LoginInformationClient extends Component {
  state = {
    country: "",
    Address: "",
    city: "",
    zip: "",
    companyName: "",
    contactPerson: "",
    phone: "",
    file: "",
  };
  async componentDidMount() {
    let id = getLoginStorage("id");
    let info = await fetchUserInfo(id);
    console.log(info);
    if (info?.status === 200) {
      let information = info.data.data;
      this.setState({
        companyName: information.name || "",
        phone: information.phoneNo || "",
        contactPerson: information.contactPerson || "",
      });
    } else if (info.status === 401) {
      toast.error(UPDATE_ERROR);
    }
  }
  typeCheck = (len, i, r = null) => {
    return r !== null
      ? (i.target.value.match(r) && i.target.value.length <= len) ||
          i.target.value === ""
      : i.target.value.length <= len;
  };
  validation = (item, type) => {
    if (type === "companyName") {
      this.typeCheck(50, item) &&
        this.setState({
          companyName: item.target.value,
          errorCompany: "",
        });
    } else if (type === "contctPerson") {
      this.typeCheck(50, item, ALPHABET_AND_SPACE_ONLY) &&
        this.setState({
          contactPerson: item.target.value,
          errorContactperson: "",
        });
    } else if (type === "phone") {
      this.typeCheck(15, item, PHONE_ONLY) &&
        this.setState({
          phone: item.target.value,
          errorPhone: "",
        });
    } else if (type === "Address") {
      this.typeCheck(50, item) &&
        this.setState({
          Address: item.target.value,
          errorAddress: "",
        });
    } else if (type === "city") {
      this.typeCheck(50, item, VALID_REGION) &&
        this.setState({
          city: item.target.value,
          errorCity: "",
        });
    } else if (type === "Zip") {
      this.typeCheck(50, item, NUMERIC_ONLY) &&
        this.setState({
          zip: item.target.value,
          errorZip: "",
        });
    } else if (type === "Country") {
      this.typeCheck(50, item, VALID_REGION) &&
        this.setState({
          country: item.target.value,
          errorCountry: "",
        });
    }
  };
  submitFrom = async () => {
    const {
      country,
      fileString,
      Address,
      city,
      zip,
      companyName,
      contactPerson,
      phone,
      file,
    } = this.state;
    console.log(file);
    let error = false;
    const phoneErr =
      phone.trim().length > 15
        ? "Phone number can have max length of 15 numbers"
        : EMPTY_FIELD;
    if (companyName.trim().length === 0) {
      error = true;
      this.setState({
        errorCompany: EMPTY_FIELD,
      });
    }
    if (contactPerson.trim().length === 0) {
      error = true;
      this.setState({
        errorContactperson: EMPTY_FIELD,
      });
    }
    if (phone.trim().length === 0) {
      error = true;
      this.setState({
        errorPhone: phoneErr,
      });
    }
    if (phone.trim().length > 15) {
      error = true;
      this.setState({
        errorPhone: phoneErr,
      });
    }
    if (Address.trim().length === 0) {
      error = true;
      this.setState({
        errorAddress: EMPTY_FIELD,
      });
    }
    if (city.trim().length === 0) {
      error = true;
      this.setState({
        errorCity: EMPTY_FIELD,
      });
    }
    if (country.trim().length === 0) {
      error = true;
      this.setState({
        errorCountry: EMPTY_FIELD,
      });
    }
    if (zip.trim().length === 0) {
      error = true;
      this.setState({
        errorZip: EMPTY_FIELD,
      });
    }
    if (!JSON.parse(localStorage.getItem("staySafeUserData"))["profilePic"]) {
      if (!file && getLoginStorage("isFirstLogin")) {
        error = true;
        this.setState({
          errorImage: "Please select profile image",
        });
      }
    }

    if (!error) {
      let requestPayload = {
        CompanyName: companyName,
        ContactPerson: contactPerson,
        PhoneNumber: phone,
        Address: Address,
        City: city,
        Country: country,
        Zip: zip,
        CompanyIcon: fileString,
        Type: "client",
      };
      let formData = jsonToForm(requestPayload);

      let infoEditResponse = await editUserInfo(formData);
      this.editUserInfoCall(infoEditResponse);
    }
  };
  editUserInfoCall = (res) => {
    if (res?.status === 200) {
      toast.success("Data has been successfully updated");
      if (getLoginStorage("isFirstLogin")) {
        let storage = getLocalStorage("staySafeUserData");
        storage.isFirstLogin = false;
        setLoginStorage(storage);
      }
      this.props.history.push("/client-licenses");
    } else if (res.status === 401) {
      toast.error(UPDATE_ERROR);
    }
  };
  imageChange = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(e.target.value)) {
      alert(
        "Please upload correct file, allowed file types are .jpg, .jpeg, .png"
      );
      return false;
    } else {
      reader.onloadend = () => {
        let fileStringGenerator = reader.result.split(",")[1];
        this.setState({
          file: e.target.files[0],
          fileName: e.target.files[0].name,
          preview: URL.createObjectURL(e.target.files[0]),
          fileString: fileStringGenerator,
        });
        e.target.value = null;
      };
    }
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.submitFrom();
    }
  };

  render() {
    return (
      <div className="login-info mt-4">
        <Toaster position="top-center" reverseOrder={true} />
        <Container>
          <Row>
            <Col lg={5}>
              <h2>Info</h2>
              <div className="edit-account d-flex flex-column">
                <label className="screen-label">Company Name</label>
                <input
                  onChange={(item) => this.validation(item, "companyName")}
                  type="text"
                  className="screen-input"
                  value={this.state.companyName}
                  maxLength={50}
                  onKeyPress={this.handleKeyPress}
                />
                <p className="text-color text-danger">
                  {this.state.errorCompany}
                </p>
                <label className="screen-label">Contact Person</label>
                <input
                  onChange={(item) => this.validation(item, "contctPerson")}
                  type="text"
                  className="screen-input"
                  value={this.state.contactPerson}
                  maxLength={50}
                  onKeyPress={this.handleKeyPress}
                />
                <p className="text-color text-danger">
                  {this.state.errorContactperson}
                </p>
                <label className="screen-label">Phone Nr.</label>
                <input
                  onChange={(item) => this.validation(item, "phone")}
                  type="text"
                  className="screen-input"
                  value={this.state.phone}
                  maxLength={15}
                  onKeyPress={this.handleKeyPress}
                />
                <p className="text-color text-danger">
                  {this.state.errorPhone}
                </p>
                <label className="screen-label">Company icon</label>
                <input
                  type="file"
                  onChange={this.imageChange}
                  id="uploadImage"
                  style={{ display: "none" }}
                />
                <button
                  className="screen-button"
                  onClick={() => document.getElementById("uploadImage").click()}
                >
                  {this.state.fileName ? "Change Image" : "Upload Image"}
                </button>
                <p className=" text-info">{this.state.fileName}</p>
                {this.state.fileName && (
                  <Card style={{ width: "18rem", height: "10rem" }}>
                    <Card.Img
                      style={{ width: "100%", height: "100%" }}
                      variant="top"
                      src={this.state.preview}
                    />
                  </Card>
                )}
                {getLoginStorage("isFirstLogin") &&
                  getLoginStorage("profilePic") &&
                  this.state.file == "" && (
                    <Card style={{ width: "18rem", height: "10rem" }}>
                      <Card.Img
                        style={{ width: "100%", height: "100%" }}
                        variant="top"
                        src={
                          BASE_URL_IMAGE +
                          JSON.parse(localStorage.getItem("staySafeUserData"))[
                            "profilePic"
                          ]
                        }
                      />
                    </Card>
                  )}
                <p className="text-color text-danger">
                  {this.state.errorImage}
                </p>
                {this.state.fileName && (
                  <button
                    className="screen-button"
                    onClick={() =>
                      this.setState({
                        file: "",
                        fileName: "",
                      })
                    }
                  >
                    Cancel
                  </button>
                )}
              </div>
            </Col>
            <Col lg={5}>
              <h2>Billing Address</h2>
              <div className="edit-billing d-flex flex-column">
                <label className="screen-label">Address</label>
                <input
                  onChange={(item) => this.validation(item, "Address")}
                  className="screen-input"
                  type="text"
                  value={this.state.Address}
                  onKeyPress={this.handleKeyPress}
                />
                <p className="text-color text-danger">
                  {this.state.errorAddress}
                </p>
                <label className="screen-label">City</label>
                <input
                  onChange={(item) => this.validation(item, "city")}
                  className="screen-input"
                  type="text"
                  value={this.state.city}
                  onKeyPress={this.handleKeyPress}
                />
                <p className="text-color text-danger">{this.state.errorCity}</p>
                <label className="screen-label">Zip</label>
                <input
                  onChange={(item) => this.validation(item, "Zip")}
                  className="screen-input"
                  type="text"
                  value={this.state.zip}
                  maxLength={6}
                  onKeyPress={this.handleKeyPress}
                />
                <p className="text-color text-danger">{this.state.errorZip}</p>
                <label className="screen-label">Country</label>
                <input
                  value={this.state.country}
                  onChange={(item) => this.validation(item, "Country")}
                  className="screen-input"
                  type="text"
                  maxLength={30}
                  onKeyPress={this.handleKeyPress}
                />
                <p className="text-color text-danger">
                  {this.state.errorCountry}
                </p>
                <div className="d-flex mb-3">
                  <a
                    href={CLIENT_INSTRUCTIONS}
                    download={CLIENT_INSTRUCTIONS}
                    target="_blank"
                  >
                    <button
                      className="screen-button mt-5"
                      style={{ width: "auto" }}
                    >
                      Download Instruction
                    </button>
                  </a>
                  <button
                    onClick={this.submitFrom}
                    className="screen-button mt-5"
                    style={{ marginLeft: "1.8rem" }}
                  >
                    <img src={rightArrow} alt="" />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default withRouter(LoginInformationClient);
