import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ContractorDetails from "../contractorDetail/ContractorDetails";

class ListView extends Component {
  render() {
    const {
      card,
      updateList,
      clientId,
      closeCard,
      list,
      companyDetails,
      sort,
      nextPage,
      page,
    } = this.props;
    return (
      <>
        <div className="detail-card overflow-hidden" id="detail">
          {card && (
            <ContractorDetails
              updateList={updateList}
              clientId={clientId}
              closeCard={closeCard}
            />
          )}
        </div>

        <div className="table-responsive">
          <Table bordered size="sm" className="list-view-table">
            <thead>
              <tr>
                <th onClick={() => sort("companyName")}>
                  Contractor Name{" "}
                  {this.props.sortingArr[0]?.type ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}{" "}
                </th>
                <th onClick={() => sort("contactPerson")}>
                  Contact Person{" "}
                  {this.props.sortingArr[1]?.type ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </th>
                <th onClick={() => sort("NoOfLicensesInInt")}>
                  Licenses Received{" "}
                  {this.props.sortingArr[2]?.type ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </th>
                <th onClick={() => sort("NoOfLicensesUsedInInt")}>
                  Licenses Assigned{" "}
                  {this.props.sortingArr[3]?.type ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </th>
                <th onClick={() => sort("averageRating")}>
                  Avg. Rating{" "}
                  {this.props.sortingArr[4]?.type ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </th>
                <th onClick={() => sort("CreatedDate")}>
                  Date Added{" "}
                  {this.props.sortingArr[5]?.type ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.items?.contractors?.map((item, index) => (
                <tr key={index} onClick={() => companyDetails(item.companyId)}>
                  <td>{item?.companyName}</td>
                  <td>{item?.contactPerson}</td>
                  <td>
                    <b>{item?.noOfLicenses}</b>
                  </td>
                  <td>
                    <b>{item?.noOfLicensesUsed}</b>
                  </td>
                  <td>
                    {item?.averageRating > 0 ? item?.averageRating : 0} / 100
                  </td>
                  <td>{item?.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-around align-items-center">
            <p
              style={{
                fontSize: "12px",
              }}
              className="m-0"
            >
              Showing{" "}
              <b>
                {list?.items?.contractors?.length} of {list?.totalCount}
              </b>{" "}
              Records
            </p>

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={list?.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={nextPage}
              containerClassName={"pagination"}
              activeClassName={"active"}
              activeLinkClassName={"active-link"}
              forcePage={page - 1}
            />
          </div>
        </div>
      </>
    );
  }
}
export default ListView;
