import React, { Component } from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Table, ListGroup } from "react-bootstrap";
import "./Invoices.css";
import staySafeByCompany from "../../../../../assets/images/stay-safe-by-company.png";
import {
  fetchFinanceInvoice,
  donwlaodInvoice,
} from "../../../../services/MasterServices";
import toast from "react-hot-toast";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";

class Invoices extends Component {
  state = {
    invoicDetail: {},
    screenLoader: true,
    downloadLoader: false,
  };
  componentDidMount = async () => {
    let id = this.props.match.params.id;
    let invoicDetail = await fetchFinanceInvoice(id);
    if (invoicDetail?.status === 200) {
      this.setState({
        invoicDetail: invoicDetail?.data?.data,
        screenLoader: false,
      });
    } else {
      toast.error("Error while fetching data!");
    }
  };
  downLoadPdf = async () => {
    this.setState({
      downloadLoader: true,
    });
    let id = this.props.match.params.id;
    let file = await donwlaodInvoice(id);
    if (file?.status === 500) {
      toast.error("Error while fetching data!");
    } else {
      this.setState({
        downloadLoader: false,
      });
      toast.success("Your invoice is successfully downloaded!");
    }
  };
  render() {
    const { invoicDetail, screenLoader, downloadLoader } = this.state;

    return (
      <>
        <LoadingOverlay
          className="loader_overlay "
          spinner={<HashLoader color="#5ba6b7" size={90} />}
          active={screenLoader}
        >
          <div className="invoice">
            <div className="d-flex justify-content-between">
              <label className="invoice-heading">Invoice</label>
              <button onClick={this.downLoadPdf} className="download-icon">
                {downloadLoader ? (
                  <HashLoader color="#5ba6b7" size={30} />
                ) : (
                  <FontAwesomeIcon icon={faDownload} />
                )}
              </button>
            </div>
            <div className="invoice-card my-3">
              <img
                src={staySafeByCompany}
                className="img-fluid"
                alt="by-company"
              />
              <Row className="invoice-detail-cards mt-3">
                <Col lg={6}>
                  <div className="your-detail mt-3">
                    <h6>Your details:</h6>
                    <h5>FROM</h5>
                    <p className="text-uppercase">
                      {invoicDetail?.adminDetail?.name}
                    </p>
                    <p>{invoicDetail?.adminDetail?.address}</p>

                    <p>{invoicDetail?.adminDetail?.phone}</p>
                    <p>{invoicDetail?.adminDetail?.email}</p>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="client-detail mt-3">
                    <h6>Client's details:</h6>
                    <p>Company Name: {invoicDetail?.clientDetail?.name}</p>
                    <p>
                      Contact Person:{" "}
                      {invoicDetail?.clientDetail?.contactPerson}
                    </p>
                    <p>Address: {invoicDetail?.clientDetail?.address}</p>
                    <p>Zip Code: {invoicDetail?.clientDetail?.zipCode}</p>
                    <p>Country: {invoicDetail?.clientDetail?.country}</p>
                    <p>Phone Nr. {invoicDetail?.clientDetail?.phone}</p>
                    <p>Email: {invoicDetail?.clientDetail?.email}</p>
                  </div>
                </Col>
              </Row>
              <div className="invoice-details text-end mt-3">
                <p>Invoice Number: {invoicDetail?.invoiceNo}</p>
                <p>Client Number: {invoicDetail?.clientNo}</p>
                <p>Invoice Date: {invoicDetail?.invoiceDate}</p>
              </div>
              <div className="invoice-table">
                <Table borderless className="sticky-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>QTY</th>
                      <th>Rate</th>
                      <th>Tax</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoicDetail?.invoiceDetail?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.item}</td>
                        <td>{item.quantity}</td>
                        <td>{item.rate}</td>
                        <td>{item.tax}</td>
                        <td>{item.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="invoice-summery">
                <ListGroup as="ul">
                  <ListGroup.Item as="li" active className="text-end">
                    Invoice Summery
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    <label>Subtotal</label>
                    <p>{invoicDetail?.subtotal}</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    <label>Tax</label>
                    <p>{invoicDetail?.tax}</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    <label>Total</label>
                    <p>{invoicDetail?.total}</p>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
export default Invoices;
