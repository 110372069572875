import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import { getKeyTranslation } from "../../../../utils/Translations";
import { getRoleList, updateRole } from "../../../../services/ClientServices";
import { getLoginStorage } from "../../../../utils/UserDataManagement";
import WarningPopUp from "../../../client/users/WarningPopup";

class ChangeEndUserRole extends Component {
  state = {
    showRoleModal: false,
    roleList: [],
    currentRole: 0,
    changeUserRole: {},
    warningPopUp: false,
  };
  handleRoleShow = async (c_role) => {
    if (this.props.isHold) {
      toast.error("user is on hold");
      return;
    }
    console.log("curr_role", c_role);
    // this.setState({});
    let detail = await getRoleList();
    if (detail?.status === 200) {
      this.setState({
        roleList: detail?.data?.data,
        currentRole: c_role,
        showRoleModal: true,
      });
    }
    console.log(detail.data.data, "lllllllllllll");
    console.log(this.state.changeUserRole, "changeUserRole role obj");
  };
  handleRoleClose = () => {
    this.setState({ showRoleModal: false });
  };
  onchangeRole = (e) => {
    let obj = {};
    obj = this.state.changeUserRole;
    obj.roleId = parseInt(e.target.value);
    this.setState({
      changeUserRole: obj,
    });
  };
  onSaveRole = async () => {
    if (this.state.changeUserRole.roleId != 0) {
      let result = await updateRole(this.state.changeUserRole);
      if (result?.status == 200) {
        toast.success(result.data.message);
        this.handleRoleClose();
        this.setState({ warningPopUp: false });
        this.props.refresh();
        return this.props.endUserList();
      } else {
        toast.error(getKeyTranslation(result.data.message));
        this.handleRoleClose();
        this.setState({ warningPopUp: false });
      }
    } else {
      toast.error("Please select role to change");
      // this.handleRoleClose();
    }
  };
  onSaveRole1 = async () => {
    if (
      this.state.changeUserRole.roleId != 0 &&
      this.state.changeUserRole.contractorId
    ) {
      this.setState({
        warningPopUp: true,
      });
    }
  };
  closeWarning = () => {
    this.setState({
      warningPopUp: false,
    });
  };

  render() {
    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />
        {getLoginStorage("isMainAdmin") && (
          <FontAwesomeIcon
            style={{
              marginTop: "0.6rem",
              marginLeft: "0.3rem",
              cursor: "pointer",
            }}
            //   className={`d-flex  ${data.isMainAdmin === true ? "invisible" : ""} `}
            icon={faPeopleArrows}
            onClick={(e) => {
              let changeUserRole = {
                userId: this.props.userId,
                email: this.props.email,
                roleId: 0,
                clientId: 0,
                contractorId: getLoginStorage("companyId"),
              };
              this.setState({ changeUserRole });

              this.handleRoleShow(4);
            }}
          />
        )}
        <Modal centered size="md" show={this.state.showRoleModal}>
          <div className="bg-white change-delete-modals">
            <div className="modal-header">
              {/* <h1>Delete {this.state.selectedUser.role}</h1> */}
              <h3 className="default-color">Change Role</h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => this.handleRoleClose()}
              ></button>
            </div>
            <h5 className="default-color">Select role</h5>

            <select className="select" onChange={(e) => this.onchangeRole(e)}>
              <option value="" selected disabled>
                Please select role
              </option>
              {this.state.roleList.map((option, index_opt) => {
                return !(
                  option.roleId == 1 ||
                  option.roleId == 2 ||
                  option.roleId == this.state.currentRole
                ) ? (
                  // <option value={this.state.currentRole}  disabled></option>
                  <option
                    key={option.roleId}
                    disabled={
                      this.state.currentRole == option.roleId ||
                      option.roleId == 1 ||
                      option.roleId == 2
                    }
                    value={option.roleId}
                  >
                    {option?.name}
                  </option>
                ) : null;
              })}
            </select>
            {/* <b> {this.state.selectedUser.companyName}</b>? */}

            <div className="screen-buttons float-end">
              <button onClick={() => this.handleRoleClose()}>cancel</button>
              <button
                onClick={() => {
                  this.onSaveRole1();
                }}
                style={{
                  opacity: this.state.changeUserRole.roleId ? "1" : "0.7",
                }}
                disabled={!this.state.changeUserRole.roleId}
              >
                save
              </button>
              {this.state.warningPopUp && (
                <WarningPopUp
                  warningPopUp={this.state.warningPopUp}
                  email={this.state.changeUserRole.email}
                  onSaveRole1={this.onSaveRole1}
                  closeWarning={this.closeWarning}
                  onSaveRole={this.onSaveRole}
                  roleId={this.state.changeUserRole.roleId}
                  currentRole={this.state.currentRole}
                />
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default ChangeEndUserRole;
