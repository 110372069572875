import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import "./CreateContent.css";
import InfoPopup from "./infoPopup/InfoPopup";
import {
  contentPackSetting,
  createContentPack,
  editContentPack,
  getCategoryContentPack,
} from "../../../../services/ClientServices";
import { BASE_URL_IMAGE } from "../../../../constant/ApiUrls";
import { getKeyTranslation } from "../../../../utils/Translations";
import ReactSelect from "react-select";
import toast from "react-hot-toast";
const CreateContent = (props) => {
  const [showCreateContentModal, setShowCreateContentModal] = useState(false);

  const [packName, setPackName] = useState("");
  const [packVisibility, setPackVisibility] = useState(
    JSON.parse(localStorage.getItem("staySafeUserData")).role == "KA"
      ? "private"
      : "public"
  );
  const [tagsArray, setTagsArray] = useState([]);
  const [languageCheckedItems, setLanguageCheckedItems] = useState([]);
  const [tagsValue, setTagsValue] = useState("");
  const [tagsErrorMessage, setTagsErrorMessage] = useState("");
  const [languages, setLanguages] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [packNameError, setPackNameError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [btnLoad2, setBtnLoad2] = useState(false);
  const [translationArray, setTranslationArray] = useState([]);
  const [selectedTranslation, setSelectedTranslation] = useState(Number);
  const [initialSelectedLanguages, setInitialSelectedLanguages] = useState([]);
  const [visibilityType, setVisibilityType] = useState([]);
  const [packType, setPackType] = useState([]);

  useEffect(async () => {
    let result = await contentPackSetting();
    if (result?.status === 200) {
      setVisibilityType(result.data.data.contentPackVisibility);
      setPackType(result.data.data.contentPackTypes);
      localStorage.setItem(
        "jobTypes",
        JSON.stringify(result.data.data.jobDescriptions)
      );
    }
    if (props.edit) {
      setEditMode(true);
    } else {
      setLanguages(props?.languages);
      let checkedinitial = props?.languages.filter((i) => i.isDefault === true);
      setLanguageCheckedItems(checkedinitial);
      let translationArr = checkedinitial.map((item, ind) => {
        return { name: "", languageId: item.id };
      });
      setPackVisibility(contractorOrClientVisibility());
      setSelectedTranslation(3);
      setTranslationArray(translationArr);
    }
  }, [showCreateContentModal]);

  const onClickEditContent = async () => {
    if (props.edit) {
      setEditMode(true);
      let result = await getCategoryContentPack(props.categoryId);
      if (result?.status == 200) {
        setPackName(result.data.data.name);
        setLanguageCheckedItems(result.data.data.languages);
        setLanguages(props.languages);

        setInitialSelectedLanguages(result.data.data.languages);
        setTagsArray(
          result.data.data.contentPackTags.map((item) => {
            return item.label;
          })
        );
        setTagsValue(
          result.data.data.contentPackTags.map((item) => {
            return item.label;
          })
        );
        setPackVisibility(result.data.data.contentPackVisibility);
        let pack_name;
        result.data.data.translations.forEach((item) => {
          if (result.data.data.languages[0]?.id === item?.languageId) {
            pack_name = item.name;
          }
        });
        setSelectedTranslation(result.data.data.languages[0]?.id);
        setPackName(pack_name);
        setPackVisibility(result.data.data.contentPackVisibility);
        setTranslationArray(result.data.data.translations);
      }
    } else {
      setLanguages(props?.languages);
      let checkedinitial = props?.languages.filter((i) => i.isDefault === true);
      setLanguageCheckedItems(checkedinitial);
      let translationArr = checkedinitial.map((item, ind) => {
        return { name: "", languageId: item.id };
      });
      setSelectedTranslation(3);
      setTranslationArray(translationArr);
    }
    setShowCreateContentModal(true);
  };

  const packVisivility = (item) => {
    if (JSON.parse(localStorage.getItem("staySafeUserData")).role !== "KA") {
      document.getElementById(item.type).checked = true;
      setPackVisibility(item?.type);
    }
  };

  const splitArray = (value) => {
    setTagsValue(value);
    if (value === "") {
      setTagsArray([]);
    } else {
      if (value.trim() != "") {
        let split = value.trim().split(",");
        setTagsArray(split);
        setTagsErrorMessage("");
      }
    }
  };
  const onEnter = (e) => {
    if (e.code === "Enter") {
      setTagsValue(tagsValue + ",");
      splitArray(tagsValue + ",");
    }
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  };
  const handleLanguagesCreateContent = (item) => {
    if (selectedTranslation !== item.id) {
      setLanguageError("");
      let ele = document.getElementById(item.name + "lan-create-content");
      ele.checked ? (ele.checked = false) : (ele.checked = true);
      let existsIndex = false;
      let arr = languageCheckedItems?.slice();
      arr?.forEach((element) => {
        if (element.name == item.name) {
          existsIndex = true;
          arr.splice(
            arr.findIndex((v) => v.name === item.name),
            1
          );
          setLanguageCheckedItems(arr);
          let new_Arr = [];
          new_Arr = arr.map((i) => {
            return { name: "", languageId: i.id };
          });
          arr.forEach((item1, ind) => {
            translationArray.forEach((item2, ind2) => {
              if (item2.languageId == item1.id) {
                new_Arr[ind] = { name: item2.name, languageId: item1.id };
              }
            });
          });
          setTranslationArray(new_Arr);
        }
      });
      if (!existsIndex) {
        arr.push(item);
        setLanguageCheckedItems(arr);
        let new_Arr = [];
        new_Arr = arr.map((item_obj) => {
          return { name: "", languageId: item_obj.id };
        });
        arr.forEach((objt, ind) => {
          translationArray.forEach((item2, ind2) => {
            if (item2.languageId == objt.id) {
              new_Arr[ind] = { name: item2.name, languageId: objt.id };
            }
          });
        });
        setTranslationArray(new_Arr);
      }
    } else {
      setLanguageError("selected language cannot be unchecked");
    }
  };

  const fieldValidations = () => {
    let tags_error = false;
    let validation = false;
    let str =
      "^[^~+-=!@#$%&*_1234567890:|\\\"<>[\\]{}\\()';?//\\n]*[^~+-=!@#$%&*_1234567890:|\\\"<>[\\]{}\\()';?//\\s][^~+-=!@#$%&*_1234567890:|\\\"<>[\\]{}\\()';?//\\n]*$";
    let tagregex = new RegExp(str);
    if (tagsArray.length <= 5) {
      tags_error = false;
      tagsArray?.forEach((item, ind) => {
        if (item.length > 20 || !tagregex.test(item) || item.trim() == "") {
          tags_error = true;
        }
      });
    } else {
      tags_error = true;
    }
    if (translationArray.filter((item) => item.name === "").length > 0) {
      setPackNameError("Pack name is required for all checked languages");
    } else if (
      packName?.length == 0 ||
      translationArray.some((item) => {
        return !tagregex.test(item.name);
      }) ||
      packName?.length > 50
    ) {
      setPackNameError(
        "Pack name should accept only alphabets with max-length 50"
      );
    } else if (tags_error) {
      setPackNameError("");
      setTagsErrorMessage(
        "Max 5 non-empty alphabetic tags with max length 20 are allowed"
      );
    } else if (languageCheckedItems.length == 0) {
      setTagsErrorMessage("");
      setLanguageError("Required selected pack name");
    } else {
      validation = true;
      setPackNameError("");
      setTagsErrorMessage("");
      setLanguageError("");
    }
    return validation;
  };
  {
    console.log(translationArray, "translationArray");
  }
  const onClickSave = async (save_mode) => {
    if (fieldValidations()) {
      let LanguageIds_array = languageCheckedItems.map((item) => {
        return item.id;
      });
      var obj = {
        name: packName,
        packVisibilityId: packVisibility.toLowerCase() == "public" ? 1 : 2,
        packTypeId: 1,
        languages: LanguageIds_array,
        tags: tagsArray,
        categoryNames: translationArray,
      };
      if (editMode) {
        obj.categoryId = props.categoryId;
        save_mode == "navigate" ? setBtnLoad2(true) : setBtnLoad(true);
        let result = await editContentPack(obj);
        if (result?.status == 200) {
          toast.success(getKeyTranslation(result.data.message));
          save_mode == "navigate" ? setBtnLoad2(false) : setBtnLoad(false);
          closeModal();
          props.recallListApi();
          if (save_mode == "navigate") {
            setTimeout(() => {
              window.location = "/create-question";
            }, 3000);
          }
        } else {
          let pre = result?.data?.preMessage ? result?.data?.preMessage : "";
          let post = result?.data?.postMessage ? result?.data?.postMessage : "";

          props.callingToaster(
            pre + " " + getKeyTranslation(result.data.message) + " " + post,
            "error"
          );
          save_mode == "navigate" ? setBtnLoad2(false) : setBtnLoad(false);
        }
      } else {
        save_mode == "navigate" ? setBtnLoad2(true) : setBtnLoad(true);
        let result = await createContentPack(obj);
        if (result?.status == 200) {
          toast.success(getKeyTranslation(result?.data?.message));
          save_mode == "navigate" ? setBtnLoad2(false) : setBtnLoad(false);
          closeModal();
          props.recallListApi();
          console.log(result.data);
          let newObj = {
            categoryId: result?.data.data.id,
            categoryName: result?.data.data.name,
            createdAt: result?.data.data.createdAt,
            updatedAt: result?.data.data.updatedAt,
            storage_languages: result?.data.data.languages,
          };
          let obj2 = {
            id: result?.data.data.id,
            contentPackVisibility: result?.data.data.contentPackVisibility,
          };
          if (save_mode == "navigate") {
            localStorage.setItem("contentDetail", JSON.stringify(obj2));
            localStorage.setItem("question_category", JSON.stringify(newObj));
            setTimeout(() => {
              window.location = "/create-question";
            }, 3000);
          }
        } else {
          let pre = result?.data?.preMessage ? result?.data?.preMessage : "";
          let post = result?.data?.postMessage ? result?.data?.postMessage : "";

          props.callingToaster(
            pre + " " + getKeyTranslation(result.data.message) + " " + post,
            "error"
          );
          save_mode == "navigate" ? setBtnLoad2(false) : setBtnLoad(false);
        }
      }
    }
  };
  const contractorOrClientVisibility = () => {
    return JSON.parse(localStorage.getItem("staySafeUserData")).role == "KA"
      ? "private"
      : "public";
  };
  const saveContent = async () => {
    onClickSave("");
  };
  const saveContentAndNavigate = async () => {
    onClickSave("navigate");
  };
  const closeModal = () => {
    setPackName("");
    setPackVisibility(
      JSON.parse(localStorage.getItem("staySafeUserData")).role == "KA"
        ? "private"
        : "public"
    );
    setTagsArray([]);
    setTagsValue("");
    setLanguageCheckedItems([]);
    setTranslationArray([]);
    setShowCreateContentModal(false);
    setPackNameError("");
    setLanguageError("");
    setTagsErrorMessage("");
  };

  const onChangeSelect = (e) => {
    translationArray.forEach((item) => {
      if (item.languageId == e.value) {
        setPackName(item.name);
      }
    });
    setSelectedTranslation(e.value);
  };

  const changepackName = (e) => {
    setPackName(e.target.value);
    translationArray.forEach((item) => {
      if (item.languageId == selectedTranslation) {
        return (item.name = e.target.value);
      }
      return item;
    });
  };
  const options = languageCheckedItems?.map((item, i) => {
    return {
      value: item.id,
      label: (
        <>
          <img
            height={16}
            width={20}
            src={BASE_URL_IMAGE + "Flags/" + item?.icon}
          />
          <span>
            {"  "} {item.title}
          </span>
        </>
      ),
    };
  });

  return (
    <>
      {props.edit ? (
        <div onClick={onClickEditContent}>Edit content</div>
      ) : (
        <button
          onClick={() => setShowCreateContentModal(true)}
          className="add-license mt-0"
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{ fontSize: "15px" }}
            className="me-1"
          />
          Create new content pack
        </button>
      )}

      <Modal
        show={showCreateContentModal}
        centered
        size="xl"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="modal-header">
            <h1 className="mb-0 mt-3 me-2">
              {editMode ? "Edit content pack" : "Create new content pack"}
            </h1>
            <img
              src="/assets/icons/Cross.png"
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div>
            {/* start form  */}
            <Row className="mt-4 form">
              <Col lg={5}>
                <div className="d-flex align-items-end">
                  <div className="flex-fill content-form">
                    <div className="d-flex align-items-end pb-1 ">
                      <label className="font-weight-500 text-capitalize">
                        Content pack name
                      </label>
                      <ReactSelect
                        className="w-50 ms-auto"
                        onChange={(e) => onChangeSelect(e)}
                        options={options}
                        defaultValue={options[0]}
                        isSearchable={false}
                      />
                    </div>
                    <input
                      type="text"
                      // placeholder="Falling Hazard"
                      className=""
                      style={{ wordBreak: "break-all" }}
                      value={packName}
                      onChange={(e) => changepackName(e)}
                      onKeyDown={(e) =>
                        e.keyCode === 32 ? e.stopPropagation() : null
                      }
                    />
                  </div>
                </div>
                <span className="error-decoration">{packNameError}</span>
                <br />
                {JSON.parse(localStorage.getItem("staySafeUserData")).role !==
                  "KA" && (
                  <>
                    <div className="d-flex align-items-end">
                      <div className="flex-fill content-form">
                        <label className="pb-1 font-weight-500 text-capitalize">
                          Pack visibility
                        </label>
                      </div>
                    </div>
                    <div className="customized-radio-btn d-flex flex-row">
                      {visibilityType?.map((item, ind) => {
                        return (
                          <div
                            key={ind}
                            onClick={() => packVisivility(item)}
                            className="d-flex radios flex-row align-items-center content-form"
                          >
                            <input
                              type="radio"
                              name="access"
                              // disabled={
                              //   JSON.parse(localStorage.getItem("staySafeUserData"))
                              //     .role == "KA" &&
                              //   item.type.toLowerCase() !==
                              //     packVisibility.toLowerCase()
                              // }
                              disabled={true}
                              value={item.type}
                              id={item.type}
                              defaultChecked={
                                item.type.toLowerCase() ==
                                packVisibility.toLowerCase()
                              }
                            />
                            <label
                              className="radio"
                              htmlFor="access"
                              style={{
                                opacity:
                                  JSON.parse(
                                    localStorage.getItem("staySafeUserData")
                                  ).role == "KA" &&
                                  item.type.toLowerCase() !==
                                    packVisibility.toLowerCase()
                                    ? "0.8"
                                    : "1",
                              }}
                            ></label>
                            <span
                              className="any"
                              style={{
                                padding: "0 10px",
                                opacity:
                                  JSON.parse(
                                    localStorage.getItem("staySafeUserData")
                                  ).role == "KA" &&
                                  item.type.toLowerCase() !==
                                    packVisibility.toLowerCase()
                                    ? "0.8"
                                    : "1",
                              }}
                            >
                              {item.type}
                            </span>
                          </div>
                        );
                      })}
                      {/* </div> */}
                    </div>
                    <div className="d-flex align-items-end mt-1">
                      <div className="flex-fill content-form">
                        <label className="pb-1 font-weight-500 text-capitalize">
                          Pack type
                        </label>
                      </div>
                    </div>
                    <div className="customized-radio-btn d-flex flex-row">
                      {packType?.map((item, ind) => {
                        return (
                          <div
                            key={ind}
                            // onClick={() => packVisivility(item)}
                            className="d-flex radios flex-row align-items-center content-form"
                          >
                            <input
                              type="radio"
                              name="pack_type"
                              value={item.type}
                              id={item.type}
                              defaultChecked={
                                item.type.toLowerCase() == "question"
                              }
                            />
                            <label className="radio" htmlFor="access"></label>
                            <span className="any" style={{ padding: "0 10px" }}>
                              {item.type}
                            </span>
                          </div>
                        );
                      })}
                      {/* </div> */}
                    </div>
                  </>
                )}

                <div className="d-flex align-items-end">
                  <div className="flex-fill content-form mt-4">
                    <div className="d-flex flex-row justify-content-between">
                      <label className="pb-1 font-weight-500">Tags</label>
                      <InfoPopup
                        paragraph_1="Use comma to separate tags. Use tags to better define the content
                          pack. "
                        paragraph_2="You can also use tags to "
                        paragraph_3="assign content packs to the fields of activity (e.g. #craftsmen)"
                        heading="Info"
                      />
                    </div>
                    <input
                      type="text"
                      // placeholder="heights, PSA, falling hazards, craftsmen"
                      className=""
                      style={{ wordBreak: "break-all" }}
                      value={tagsValue}
                      onChange={(e) => splitArray(e.target.value)}
                      onKeyDown={(e) => onEnter(e)}
                    />
                  </div>
                </div>
                <span className="error-decoration">{tagsErrorMessage}</span>
                <div className="d-flex flex-wrap mt-2">
                  {tagsArray?.map((item, ind) => {
                    return (
                      <span key={ind} className="content-tags">
                        #{item}
                      </span>
                    );
                  })}
                </div>
                <br />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="d-flex align-items-end">
                  <div className="flex-fill content-form">
                    <label className="pb-1 font-weight-500">Languages</label>
                  </div>
                </div>
                <div className="content-checkbox d-flex flex-wrap content-language-checkbox ">
                  {languages?.map((item, ind) => {
                    return (
                      <div
                        key={ind}
                        className="col-lg-4 me-4 reward-checkbox styled-input-single d-inline "
                      >
                        <input
                          // style={{
                          //   top: "7px",
                          //   left: "2px",
                          //   zIndex: "1",
                          //   visibility: "visible",
                          //   opacity: "0",
                          // }}
                          type="checkbox"
                          name={item.id}
                          defaultChecked={
                            initialSelectedLanguages?.filter(
                              (i) => i.id === item.id
                            ).length > 0 ||
                            (!editMode && item.isDefault === true)
                          }
                          id={item.name + "lan-create-content"}
                        />
                        <label
                          htmlFor={item.name}
                          onClick={() => handleLanguagesCreateContent(item)}
                          style={{ marginLeft: "-27px" }}
                        />
                        <span
                          className="any"
                          style={{ padding: "0 10px", marginLeft: "30px" }}
                        >
                          {item.title}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <span className="error-decoration">{languageError}</span>
              </Col>
              <Col lg={6}>
                <div className="h-100 align-items-start d-flex justify-content-end create-content-buttons">
                  {editMode && (
                    <button
                      className="save d-inline me-4"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() => setShowCreateContentModal(false)}
                      disabled={btnLoad}
                    >
                      BACK
                    </button>
                  )}
                  <button
                    className={`${
                      editMode
                        ? "save-and-edit d-inline px-4 me-4"
                        : "save d-inline px-4 me-4"
                    }`}
                    onClick={saveContent}
                    style={{ padding: "11px 0" }}
                  >
                    {btnLoad ? (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ height: "23px", width: "23px" }}
                      >
                        <span
                          className="visually-hidden"
                          style={{
                            height: "23px",
                            width: "23px",
                          }}
                        >
                          Loading...
                        </span>
                      </Spinner>
                    ) : null}
                    {!btnLoad && "Save"}
                  </button>
                  {!editMode && (
                    <button
                      className="save-and-edit d-inline"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={saveContentAndNavigate}
                    >
                      {btnLoad2 ? (
                        <Spinner
                          animation="border"
                          role="status"
                          style={{
                            height: "23px",
                            width: "23px",
                          }}
                        >
                          <span
                            className="visually-hidden"
                            style={{
                              height: "23px",
                              width: "23px",
                            }}
                          >
                            Loading...
                          </span>
                        </Spinner>
                      ) : null}
                      {!btnLoad2 && "Save & Edit"}
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateContent;
