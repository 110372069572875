import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import export_icon from "../../../../../../assets/icons/export.png";
import arrow_tooltip from "../../../../../../assets/images/arrow_tooltip.png";
import "./PopOverExport.css";

const PopOverExport = ({
  exportQuestionClick,
  exportEmptyClick,
  question_length,
}) => {
  return (
    <>
      <OverlayTrigger
        trigger="click"
        rootClose
        delay={{ hide: 400 }}
        // defaultShow={true}
        placement="bottom"
        overlay={
          <Popover id="popover-positioned-bottom">
            <div className="custom-arrow">
              <img src={arrow_tooltip} style={{ opacity: "0.4" }} />
            </div>
            {/* <FontAwesomeIcon
              icon={faCaretUp}
              className="custom-arrow"
              style={{ color: "white" }}
            /> */}
            <Popover.Content>
              <div
                className="d-flex flex-row mb-2"
                style={{ cursor: "pointer" }}
                onClick={exportQuestionClick}
              >
                <img
                  src={export_icon}
                  height={20}
                  width={20}
                  className="me-2"
                />
                <div>
                  <h4 className="mb-0">
                    <u>Current version</u>
                  </h4>
                  <span>
                    Export the content from the editor. {question_length}{" "}
                    Questions
                  </span>
                </div>
              </div>
              <div
                className="d-flex flex-row"
                style={{ cursor: "pointer" }}
                onClick={exportEmptyClick}
              >
                <img
                  src={export_icon}
                  height={20}
                  width={20}
                  className="me-2"
                />
                <div>
                  <h4 className="mb-0">
                    <u>Empty template</u>
                  </h4>
                  <span>Perfect for building a content pack from scratch.</span>
                </div>
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <div className="position-relative">
          <button className="save d-inline px-4 me-3 w-auto py-2">
            EXPORT .XCL
          </button>
          {/* <div className="custom-arrow">
            <img src={arrow_tooltip} />
          </div> */}
        </div>
      </OverlayTrigger>
    </>
  );
};

export default PopOverExport;
