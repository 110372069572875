import React, { Component } from "react";
import Anime from "react-anime";
import { Card, Col, Row } from "react-bootstrap";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import brandHolder from "../../../../../assets/icons/brandHolder.jpg";

import { BASE_URL_IMAGE } from "../../../../constant/ApiUrls";
import LicenseDetails from "../detailLicense/LicenseDetails";
import ReactPaginate from "react-paginate";

class GridView extends Component {
  render() {
    const {
      card,
      updateList,
      LicenseDeletedSuccess,
      clientId,
      closeCard,
      list,
      companyDetails,
      nextPage,
      page,
    } = this.props;
    return (
      <>
        <div className="detail-card overflow-hidden">
          {card && (
            <LicenseDetails
              updateList={updateList}
              LicenseDeletedSuccess={LicenseDeletedSuccess}
              clientId={clientId}
              closeCard={closeCard}
            />
          )}
        </div>

        <div className="section-card-list license-list-for-mobile">
          <div className="container1">
            <Row>
              {list?.items?.map((item, index) => (
                <Col key={index} xl={2} lg={3} md={4} sm={6} className="mb-4">
                  <Anime delay={(el, index2) => index2 * 540} scale={[0.01, 1]}>
                    <Card
                      key={index}
                      onClick={() => companyDetails(item.companyId)}
                      className="w-100 single-card pb-1 overflow-hidden"
                    >
                      {item?.isHold && (
                        <div className="disabled-card">
                          <label> On Hold</label>
                        </div>
                      )}
                      <Card.Img
                        style={{
                          height: "7rem",
                          backgroundColor: "d2e7ef",
                        }}
                        variant="top"
                        src={
                          item.companyIcon
                            ? `${BASE_URL_IMAGE}${item.companyIcon}`
                            : brandHolder
                        }
                      />
                      <Card.Body className="p-0">
                        <p className=" text-elpsis license-name mb-0">
                          {item.companyName}
                        </p>

                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <p className="mb-0" title={item.contactPerson}>
                              {item.contactPerson?.slice(0, 25)}
                            </p>
                            <p className="mb-0">
                              {item.noOfLicenses + " Licenses"}
                            </p>
                          </div>
                          <div className="my-progress-circle-mini">
                            <CircularProgressbarWithChildren
                              value={item?.averageRating?.toFixed(1)}
                            >
                              <div className="text-center">
                                <strong
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "900",
                                  }}
                                >
                                  {item?.averageRating?.toFixed(1)}
                                </strong>
                                <br />
                                <p
                                  className="m-0"
                                  style={{
                                    marginTop: "-6px !important",
                                  }}
                                >
                                  /110
                                </p>
                              </div>
                            </CircularProgressbarWithChildren>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Anime>
                </Col>
              ))}
            </Row>
            <div className="d-flex justify-content-around align-items-center">
              <p
                style={{
                  fontSize: "12px",
                }}
                className="m-0"
              >
                Showing{" "}
                <b>
                  {list?.items?.length} of {list?.totalCount}
                </b>{" "}
                Records
              </p>

              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={list?.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={nextPage}
                containerClassName={"pagination"}
                activeClassName={"active"}
                activeLinkClassName={"active-link"}
                forcePage={page - 1}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default GridView;
