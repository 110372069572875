import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import brandImg from "../../../assets/images/brand.png";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import ForgetPassword from "./forgetPassword/ForgetPassword";
import { VALID_EMAIL_1 } from "../../constant/AppConstant";
import { loginUser } from "../../services/LoginServices";
import { translationData } from "../../services/TranslationServices";
import HashLoader from "react-spinners/HashLoader";
import "./Login.css";
import {
  setLoginStorage,
  getLoginStorage,
} from "../../utils/UserDataManagement";
import toast, { Toaster } from "react-hot-toast";
import { getKeyTranslation } from "../../utils/Translations";

class Login extends Component {
  state = {
    email: "",
    password: "",
    emailError: "",
    unableButton: true,
    buttonLoader: false,
  };
  componentDidMount() {
    window._paq.push(["setUserId", "anonymousUser"]);
    window._paq.push(["trackPageView"]);
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      let valid = VALID_EMAIL_1.test(String(value).toLowerCase());
      if (!valid) {
        this.setState({
          emailError: "Invalid E-mail!",
          email: value,
          emailFieldColor: "2px solid red",
          emailErrorColor: "red",
          unableButton: true,
        });
        if (!value) {
          this.setState({
            emailError: "",
            emailFieldColor: "1px solid #d2e7ef",
          });
        }
      } else if (valid) {
        this.setState({
          unableEmail: false,
          emailError: "E-mail Valid!",
          email: value,
          emailFieldColor: "1px solid lightgreen",
          emailErrorColor: "lightgray",
          unableButton: false,
        });
      }
    } else if (name === "password") {
      this.setState({
        password: value,
        emailpassword: "",
      });
    }
    if (
      this.state.emailError === "E-mail Valid!" &&
      this.state.password.length !== 0
    ) {
      this.setState({
        unableButton: false,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    let data = {
      email: email,
      password: password,
    };
    if (password.length !== 0 && email.length !== 0) {
      this.onLogin(data);
    } else {
      this.setState({
        emailpassword: "Enter your password",
        emailpasswordColor: "red",
      });
    }
  };
  initialRenderedPage = (role) => {
    switch (role) {
      case "MA":
        setTimeout(() => {
          window.location = "/master-licenses";
        }, 1500);
        break;
      case "CA":
        if (getLoginStorage("isFirstLogin")) {
          setTimeout(() => {
            window.location = "/client-info";
          }, 1500);
        } else {
          setTimeout(() => {
            window.location = "/client-licenses";
          }, 1500);
        }
        break;
      case "KA":
        if (getLoginStorage("isFirstLogin")) {
          setTimeout(() => {
            window.location = "/contractor-info";
          }, 1500);
        } else {
          setTimeout(() => {
            window.location = "/contractor-licenses";
          }, 1500);
        }
        break;
      default:
        setTimeout(() => {
          window.location = "/contractor-licenses";
        }, 1500);
    }
  };
  onLogin = async (data) => {
    this.setState({
      buttonLoader: true,
    });
    let inComingResponse = await loginUser(data);
    await translationData();
    if (inComingResponse?.status === 200) {
      setLoginStorage(inComingResponse?.data.data);
      let userEmail = getLoginStorage("email");
      let role = getLoginStorage("role");
      let menuList = inComingResponse.data.data.menuStructure;
      localStorage.setItem("menuStructure", JSON.stringify(menuList));
      localStorage.setItem("isHold", inComingResponse?.data?.data?.isHold);
      window._paq.push(["setUserId", userEmail]);
      window._paq.push(["trackPageView"]);
      this.initialRenderedPage(role);
      toast.success(getKeyTranslation(inComingResponse?.data?.message));
    } else {
      let error = inComingResponse?.data.errors;
      console.log(error);
      if (error[0]?.propertyName === "Password") {
        this.setState({
          emailpassword: getKeyTranslation(error[0]?.errorMessage),
          emailpasswordColor: "red",
          buttonLoader: false,
        });
      } else if (error[0]?.propertyName === "Email") {
        this.setState({
          emailError: getKeyTranslation(error[0]?.errorMessage),
          emailErrorColor: "red",
          buttonLoader: false,
        });
      } else {
        this.setState({
          buttonLoader: false,
        });
      }
    }
  };

  render() {
    const {
      buttonLoader,
      emailErrorColor,
      emailFieldColor,
      email,
      emailError,
      password,
      emailpassword,
      emailpasswordColor,
    } = this.state;
    return (
      <>
        <div>
          <Toaster position="top-right" reverseOrder={false} />
        </div>
        <div className="login-page">
          <Container>
            <form>
              <Row>
                <Col lg={8}>
                  <img
                    src={brandImg}
                    className="brand"
                    height={120}
                    alt="brand"
                  />
                  <h1 className="mt-4 text-uppercase">Admin Interface</h1>
                  <div className="form-data mt-4">
                    <label>E-mail</label>
                    <div className="input-div d-flex">
                      <div className="input-field">
                        <input
                          style={{ border: emailFieldColor }}
                          type="email"
                          name="email"
                          placeholder="error@mail.com"
                          onChange={this.handleChange}
                          value={email}
                        />
                        <p className="mb-0" style={{ color: emailErrorColor }}>
                          {emailError}
                        </p>
                      </div>
                    </div>

                    <br />
                    <label className="mt-3">Password</label>
                    <div className="input-div d-flex">
                      <div className="input-field">
                        <input
                          type="password"
                          name="password"
                          placeholder="●●●●●●●"
                          onChange={this.handleChange}
                          value={password}
                        />
                        <p
                          className="mb-0"
                          style={{ color: emailpasswordColor }}
                        >
                          {emailpassword}
                        </p>
                      </div>
                    </div>

                    <br />
                  </div>
                  <div className="mt-3 mb-5">
                    <ForgetPassword />
                    <button
                      disabled={this.state.unableButton}
                      className="default-button border-0"
                      onClick={(e) => this.handleSubmit(e)}
                    >
                      {buttonLoader ? (
                        <HashLoader color="#5ba6b7" size={30} />
                      ) : (
                        <FontAwesomeIcon icon={faArrowRight} />
                      )}
                    </button>
                  </div>
                </Col>
                <Col lg={4}></Col>
              </Row>
            </form>
          </Container>
        </div>
      </>
    );
  }
}
export default withRouter(Login);
