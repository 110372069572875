import { BASE_URL, REFRESH_TOKEN_URL } from "../constant/ApiUrls";
import axios from "axios";
import { api_version, content_type } from "./NetworkManager";

axios.interceptors.request.use((request) => {
  if (request.status === 200) {
    console.log("fetched");
  }
  return request;
});
const response_403_401 = () => {
  localStorage.clear();
  window.location = "/login";
};
axios.interceptors.response.use(
  async (response) => {
    localStorage.setItem("showRestrictedModal", true);

    return response;
  },
  async (err) => {
    try {
      if (err.response.status === 403) {
        response_403_401();
      } else if (
        err.config.url.includes(REFRESH_TOKEN_URL) &&
        err.response.status === 401
      ) {
        response_403_401();
      } else if (
        !err.config.url.includes("Users") &&
        err.response.status === 401
      ) {
        let request_data = err.response.config;
        let data = {
          jwtToken: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("RefreshToken"),
        };
        const refreshTokenApiheaders = {
          "Content-Type": content_type(),
          "x-api-version": api_version(),
        };
        let apiResult = await axios.post(BASE_URL + REFRESH_TOKEN_URL, data, {
          headers: refreshTokenApiheaders,
        });
        if (apiResult && apiResult.status === 200) {
          localStorage.setItem("token", apiResult.data.jwtToken);
          localStorage.setItem("RefreshToken", apiResult.data.refreshToken);

          let headers = {
            "Content-Type": content_type(),
            Authorization: `Bearer ${apiResult.data.jwtToken}`,
            "x-api-version": api_version(),
          };
          return await axios({
            url: request_data.url,
            method: request_data.method,
            data: request_data.data,
            headers: headers,
          });
        } else if (
          (apiResult && apiResult.status === 500) ||
          apiResult.status === 400
        ) {
          localStorage.clear();
          window.location = "/login";
        }
      } else if (err.response.status === 406) {
        return await err.response;
      } else {
        return err.response;
      }
    } catch (error) {
      console.log("catch", error);
      return error.response;
    }
    console.log("return error interceptor", err.response);
    return err.response;
  }
);
