import React, { Component } from "react";
import { Modal, Row, Col, Button, Spinner } from "react-bootstrap";
import "./AddLicenseModal.css";
import ReactSwitch from "../../../../common/ReactSwitch";
import { addNewClientMasterCompany } from "../../../../services/MasterServices";
import toast from "react-hot-toast";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dynamicFormEmptyCheck } from "../../../../utils/DynamicFormValidation";
import { withRouter } from "react-router-dom";
import ScrollBar from "react-scrollbar";
import {
  getUserPrice,
  BussinessIntelligenceYearlyPlan,
  contentAgreementPrice,
} from "../../../../utils/addLicensesCalculations";
import { VALID_EMAIL_1 } from "../../../../constant/AppConstant";
import { getKeyTranslation } from "../../../../utils/Translations";
class AddLicenseModal extends Component {
  state = {
    users: [{ email: "" }],
    addLicenseModal: false,
    feePlan: "",
    companyName: "",
    licensesCount: 0,
    intelligenceAccess: false,
    contentAgreement: false,
    companyNameError: "",
    amountOfLiscenseError: "",
    emailPassowrdError: "",
    yearAmount: "",
    licensesPrice: 0,
    BussinessIntelligencePrice: {
      oneYear: 0,
      twoAndThreeyear: 0,
      fourYear: 0,
    },
    contentPrice: { oneYear: 18, twoAndThreeyear: 15, fourYear: 13 },
    disableButton: false,
  };

  openAddClient = () => {
    this.setState({
      addLicenseModal: true,
    });
  };
  handleChangeEmail = (event, index) => {
    if (event.target.value.length <= 50) {
      const { users } = this.state;
      users[index].email = event.target.value.trimStart();
      this.setState({
        users,
        emailPassowrdError: "",
      });
    }
  };
  handleChangePassword = (event, index) => {
    const { users } = this.state;
    users[index].password = event.target.value;
    this.setState({
      users,
      emailPassowrdError: "",
    });
  };
  addNewUser = () => {
    if (this.state.users.length <= this.state.licensesCount) {
      let users = [...this.state.users, { email: "" }];
      this.setState({
        users,
      });
    } else {
      toast.error("Please increase amount of licenses");
    }
  };
  removeClick(i) {
    let users = [...this.state.users];
    users.splice(i, 1);
    this.setState({ users });
  }
  handleSubmitUser = async () => {
    const {
      companyName,
      licensesCount,
      users,
      contentAgreement,
      intelligenceAccess,
    } = this.state;
    let error = false;
    if (users.length - 1 > licensesCount) {
      toast.error(
        "Additional admins can not be greater than amount of licenses."
      );
      error = true;
    }

    let dynamicFormValidity = dynamicFormEmptyCheck(this.state.users);

    for (var obj_1 of this.state.users) {
      var keys = Object.keys(obj_1); // if you don't know the keys
      if (keys[0] === "email") {
        var value = obj_1[keys[0]]; //in case your object has only one key
        if (!value.match(VALID_EMAIL_1)) {
          error = true;
          this.setState({
            emailPassowrdError: "*please enter valid email",
          });
        }
      }
    }

    if (!companyName.trim()) {
      error = true;
      this.setState({
        companyNameError: "Company Name is required",
      });
    }
    if (!licensesCount) {
      error = true;
      this.setState({
        amountOfLiscenseError: "Please enter the require amount of licenses",
      });
    }
    if (dynamicFormValidity) {
      error = true;
      this.setState({
        emailPassowrdError: "Required field is empty",
      });
      // } else if (!monthlyAmount) {
      // 	this.setState({
      // 		selectAmountError: "Please select a plan",
      // 	});
      //
    }
    if (!error) {
      let obj = {
        companyName: companyName,
        amountOfLicenses: +licensesCount,
        isBusinessIntelligence: intelligenceAccess,
        isContentAgreement: contentAgreement,
        //totalFee: +monthlyAmount,
        users: users,
        //feePlan: feePlan,
      };
      this.setState({
        disableButton: true,
      });

      let response = await addNewClientMasterCompany(obj);
      if (response.status === 200) {
        this.closeAddLicenseModal();
        this.props.LicenseAddedSuccess();
        this.setState({
          disableButton: false,
        });
        toast.success(getKeyTranslation(response?.data?.message));
      } else if (response?.status === 452) {
        this.setState({
          disableButton: false,
        });
      } else {
        toast.error(getKeyTranslation(response?.data?.message));
        this.setState({
          disableButton: false,
        });
      }
    }
  };
  resetModalState = () => {
    this.setState({
      companyNameError: "",
      amountOfLiscenseError: "",
      emailPassowrdError: "",
      categoryError: "",
      companyName: "",
      licensesCount: "",
      //users: [{ email: "", password: "" }],
      users: [{ email: "" }],
      intelligenceAccess: false,
      contentAgreement: false,
    });
  };
  changeIntelligenceAccess = () => {
    const { intelligenceAccess, BussinessIntelligencePrice } = this.state;
    let BIprice = BussinessIntelligenceYearlyPlan(!intelligenceAccess);
    if (BIprice) {
      this.setState({
        intelligenceAccess: !intelligenceAccess,
        BussinessIntelligencePrice: BIprice,
      });
    } else {
      this.setState({
        intelligenceAccess: !intelligenceAccess,
        BussinessIntelligencePrice: BussinessIntelligencePrice,
      });
    }
    console.log(BIprice);
  };
  changeContentAgreement = () => {
    const { contentAgreement } = this.state;
    let Amount = contentAgreementPrice(!contentAgreement);
    this.setState({
      contentAgreement: !contentAgreement,
      contentPrice: Amount,
    });
  };
  closeAddLicenseModal = () => {
    this.setState({
      addLicenseModal: false,
    });
    this.resetModalState();
  };

  checkBoxSelection(e, year) {
    var myCheckbox = document.getElementsByName("myCheckbox");
    Array.prototype.forEach.call(myCheckbox, function (el) {
      el.checked = false;
    });
    console.log(year);
    e.target.checked = true;
    this.setState({
      selectAmountError: "",
      monthlyAmount: e.target.value,
      feePlan: year,
    });
  }
  addLicenses = (e) => {
    let amountOfLicenses = e.target.value;
    if (amountOfLicenses.length === 0) {
      this.setState({
        licensesCount: 0,
        amountOfLiscenseError: "",
      });
    } else {
      let pricePerLicense = getUserPrice(+amountOfLicenses, "PricePerUser");
      this.setState({
        licensesCount: amountOfLicenses,
        amountOfLiscenseError: "",
        licensesPrice: pricePerLicense,
      });
    }
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmitUser();
    }
  };
  keyDown = (e) => {
    var invalidChars = ["-", "+", "e", "E", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  render() {
    const {
      users,
      companyNameError,
      amountOfLiscenseError,
      emailPassowrdError,
      BussinessIntelligencePrice,
      addLicenseModal,
      companyName,
      licensesCount,
      contentPrice,
      licensesPrice,
      selectAmountError,
    } = this.state;
    let totalLisencesPrice = licensesCount * licensesPrice;
    let oneYearPlan =
      12 *
      (totalLisencesPrice +
        BussinessIntelligencePrice?.oneYear +
        contentPrice.oneYear);
    let twoAndThreeYearPlan =
      36 *
      (totalLisencesPrice +
        BussinessIntelligencePrice?.twoAndThreeyear +
        contentPrice.twoAndThreeyear);
    let fourYearPlan =
      48 *
      (totalLisencesPrice +
        BussinessIntelligencePrice?.fourYear +
        contentPrice.fourYear);

    return (
      <>
        <button onClick={this.openAddClient} className="add-license mt-0">
          <FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: "15px" }} />{" "}
          add client
        </button>

        <Modal
          show={addLicenseModal}
          centered
          size="lg"
          className="modal-screen updateModal-screen"
        >
          <Modal.Body>
            <div className="add-license-modal">
              <div className="modal-header">
                <h1>Add New Client</h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  disabled={this.state.disableButton}
                  onClick={this.closeAddLicenseModal}
                ></button>
              </div>
              <Row>
                <Col lg={4}>
                  <label>Company Name</label>
                  <br />
                  <input
                    type="text"
                    className="w-100"
                    value={companyName}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        this.setState({
                          companyName: e.target.value,
                          companyNameError: "",
                        });
                      }
                    }}
                    onKeyPress={this.handleKeyPress}
                  />
                  <span className="error-decoration">{companyNameError}</span>
                  <br />
                  <br />
                </Col>
                <Col lg={3}>
                  <label>Amount of licenses</label>
                  <br />
                  <input
                    type="number"
                    min="0"
                    className="w-100"
                    value={licensesCount}
                    onChange={(e) => {
                      this.addLicenses(e);
                    }}
                    onKeyPress={this.handleKeyPress}
                    onKeyDown={this.keyDown}
                  />
                  <span className="error-decoration">
                    {amountOfLiscenseError}
                  </span>
                  <br />
                  <div className="d-none">
                    <label>Business intelligence access</label>
                    <br />
                    <ReactSwitch
                      value={this.state.intelligenceAccess}
                      handleChange={this.changeIntelligenceAccess}
                    />

                    <br />
                    <label>Content agreement</label>
                    <br />
                    <ReactSwitch
                      value={this.state.contentAgreement}
                      handleChange={this.changeContentAgreement}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={7}>
                  <ScrollBar
                    style={{ maxHeight: 200 }}
                    className="my-scrollbar"
                    contentClassName="my-scrollbar-content"
                    speed={0.6}
                  >
                    {users?.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between"
                      >
                        <div className=" w-100 ">
                          {index === 0 && (
                            <label>
                              E-mail{" "}
                              <span> (Main admin for this company) </span>{" "}
                            </label>
                          )}
                          {index === 1 && (
                            <label>
                              E-mail{" "}
                              <span>
                                (Additional admin/s for this company){" "}
                              </span>{" "}
                            </label>
                          )}

                          <br />
                          <input
                            type="email"
                            className="mb-1"
                            name="email"
                            value={item.email}
                            onChange={(e) => this.handleChangeEmail(e, index)}
                            onKeyPress={this.handleKeyPress}
                          />
                        </div>
                        <div className="pt-4 icon-margin">
                          {users?.length !== 1 && (
                            <FontAwesomeIcon
                              onClick={this.removeClick.bind(this, index)}
                              className="fa-times-color"
                              icon={faTimes}
                            />
                          )}
                        </div>
                        {/* <div className='password-alignment'>
                                            <label>Passowrd</label>
                                            <br />
                                            <input
                                                type='password'
                                                className='p-1 w-100'
                                                name='password'
                                                value={item.password}
                                                onChange={(e) => this.handleChangePassword(e, index)}
                                            />
                                        </div> */}
                      </div>
                    ))}

                    <span className="error-decoration">
                      {emailPassowrdError}
                    </span>
                    <div className="add-license-buttons m-0 mt-2">
                      <Button onClick={this.addNewUser} className="another-btn">
                        Add Another Admin{" "}
                        <FontAwesomeIcon
                          onClick={this.addNewUser}
                          className="cursor-behavior"
                          icon={faPlusCircle}
                        />
                      </Button>
                    </div>
                  </ScrollBar>
                </Col>
                <Col lg={5} className="mt-auto ml-auto">
                  <div className="d-none">
                    <label>Monthly Fee</label>
                    <div className="d-flex amount styled-input-single">
                      <input
                        id={"checkbox-example-one-1"}
                        className="w-25 mt-3 "
                        type="checkbox"
                        name="myCheckbox"
                        value={oneYearPlan}
                        onClick={(e) => this.checkBoxSelection(e, "annual")}
                      />
                      <label htmlFor={"checkbox-example-one-1"} />
                      <p>1 year </p>
                      <h4> {oneYearPlan} €</h4>
                    </div>
                    <div className="d-flex amount styled-input-single">
                      <input
                        id={"checkbox-example-one-2"}
                        className="w-25 mt-3 "
                        type="checkbox"
                        name="myCheckbox"
                        value={twoAndThreeYearPlan}
                        onClick={(e) => this.checkBoxSelection(e, "biennial")}
                      />
                      <label htmlFor={"checkbox-example-one-2"} />
                      <p>2 & 3 year </p>
                      <h4>{twoAndThreeYearPlan} €</h4>
                    </div>
                    <div className="d-flex amount styled-input-single">
                      <input
                        id={"checkbox-example-one-3"}
                        className="w-25 mt-3 "
                        type="checkbox"
                        name="myCheckbox"
                        value={fourYearPlan}
                        onClick={(e) =>
                          this.checkBoxSelection(e, "quadrennial")
                        }
                      />
                      <label htmlFor={"checkbox-example-one-3"} />
                      <p> 4 + year </p>
                      <h4>{fourYearPlan} €</h4>
                    </div>
                    <span className="error-decoration">
                      {selectAmountError}
                    </span>
                  </div>
                  <div className="w-100 text-end add-license-buttons updateBtns">
                    {this.state.users.length > 1 && (
                      <div className="noteSection freeHmt">
                        <h6>Note</h6>
                        <p>
                          You’ve added {parseInt(this.state.users.length) - 1}{" "}
                          additional admin to the client. The additional admin
                          is deducted from the licenses, giving the client a
                          total of {parseInt(this.state.licensesCount)} licenses
                          for their own free management.
                        </p>
                      </div>
                    )}
                    <button
                      className="btn-cancel"
                      disabled={this.state.disableButton}
                      onClick={this.closeAddLicenseModal}
                    >
                      cancel
                    </button>

                    <button
                      onClick={this.handleSubmitUser}
                      disabled={this.state.disableButton}
                      style={{
                        opacity: this.state.disableButton ? "0.7" : "1",
                      }}
                    >
                      {this.state.disableButton ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "add client"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default withRouter(AddLicenseModal);
