import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components } from "react-select";

const CaretDownIcon = (props) => {
  return (
    <FontAwesomeIcon
      icon={faCaretDown}
      className="default-color"
      style={{ fontSize: "15px" }}
    />
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon {...props} />
    </components.DropdownIndicator>
  );
};
export default DropdownIndicator;
