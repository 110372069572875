import React, { Component } from "react";
import { Modal } from "react-bootstrap";
class DeleteContractor extends Component {
  render() {
    return (
      <>
        <Modal centered size="lg" show={this.props.deleteContractor}>
          <div className="bg-white change-delete-modals">
            <div className="modal-header">
              <h1>Delete Contractor</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.closeDeleteContractorModal()}
              ></button>
            </div>
            <label className="mt-3">
              Are you sure you want to delete COMPANY?
            </label>
            <div className="screen-buttons float-end">
              <button onClick={() => this.props.closeDeleteContractorModal()}>
                cancel
              </button>
              <button>delete client</button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default DeleteContractor;
