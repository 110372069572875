import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import {
  faSearch,
  faFileExport,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  exportAuditListClients,
  searchAndSortAuditTrails,
} from "../../../services/MasterServices";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";
import moment from "moment";
import { auditTableSorting } from "../rewards/rewardsData";

const AuditTrails = () => {
  const date_method = (d) => {
    console.log("fff", d);
    return new Date(d);
  };
  console.log("llll", moment().subtract(1, "months").format("YYYY-MM-DD"));
  const [users, setUsers] = useState([""]);
  const [screenLoader, setScreenLoader] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    date_method(moment().format("YYYY-MM-DD"))
  );
  const [endDate, setEndDate] = useState(
    date_method(moment().subtract(1, "months").format("YYYY-MM-DD"))
  );
  const [renderStartDate, setRenderStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [renderEndDate, setRenderEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [dropdownKey, setDropdownKey] = useState("");
  const [pageNumberForSearch, setPageNumberForSearch] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [enteredValue, setEnteredValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [auditObj, setAuditObj] = useState(auditTableSorting);

  const sorting = async (value) => {
    setDropdownKey(value);
    let newObj = auditObj.map((item) => {
      if (value == item.name) {
        item.type = !item.type;
      } else {
        item.type = false;
      }
      return item;
    });
    auditObj.forEach((item) => {
      if (value == item.name) {
        if (item.type == false) {
          const call = async () => {
            let searchUrl = `?SearchKey=${enteredValue.replace(
              "+",
              "%2B"
            )}&PageNumber=${pageNumberForSearch}&PageSize=20&OrderType=&OrderBy=${value}ASC&StartDate=${renderStartDate}&EndDate=${renderEndDate}`;
            await fetchSearchResults(searchUrl);
            setSortValue(value + "ASC");
          };
          return call();
        } else {
          const call = async () => {
            let searchUrl = `?SearchKey=${enteredValue.replace(
              "+",
              "%2B"
            )}&PageNumber=${pageNumberForSearch}&PageSize=20&OrderType=&OrderBy=${value}DESC&StartDate=${renderStartDate}&EndDate=${renderEndDate}`;
            await fetchSearchResults(searchUrl);
            setSortValue(value + "DESC");
          };
          return call();
        }
      }
    });
    setAuditObj(newObj);
    //nnn
  };
  const sortIconPosition = (ind) => {
    return auditObj[ind].type ? (
      <FontAwesomeIcon icon={faChevronDown} />
    ) : (
      <FontAwesomeIcon icon={faChevronUp} />
    );
  };
  const downloadContractorUsers = async () => {
    let params = {
      SearchKey: searchInputValue.replace("+", "%2B"),
      PageNumber: pageNumberForSearch,
      PageSize: 20,
      // OrderType: "asc",
      OrderBy: sortValue,
      StartDate: renderStartDate,
      EndDate: renderEndDate,
    };

    await exportAuditListClients(params);
  };
  const SearchInput = async () => {
    setPageNumberForSearch(1);
    setEnteredValue(searchInputValue);
    let searchUrl = `?SearchKey=${searchInputValue.replace(
      "+",
      "%2B"
    )}&OrderBy=${sortValue}&PageNumber=1&PageSize=20&StartDate=${renderStartDate}&EndDate=${renderEndDate}`;
    await fetchSearchResults(searchUrl);
  };
  {
    console.log("searchInputValue", searchInputValue);
  }
  const onEnter = (e) => {
    if (e.code === "Enter") {
      SearchInput();
    }
  };

  const onChange = async (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setPageNumberForSearch(1);
    if (end && start) {
      let formatted_start_date = moment(start).format("YYYY-MM-DD");
      let formatted_end_date = moment(end).format("YYYY-MM-DD");
      let searchUrl = `?SearchKey=${enteredValue.replace(
        "+",
        "%2B"
      )}&PageNumber=${1}&PageSize=20&OrderBy=${sortValue}&OrderType=&StartDate=${formatted_start_date}&EndDate=${formatted_end_date}`;
      await fetchSearchResults(searchUrl);
      setRenderStartDate(formatted_start_date);
      setRenderEndDate(formatted_end_date);
    }
  };
  useEffect(() => {
    (() => {
      if (startDate && endDate) {
        setIsOpen(false);
      }
    })();
  }, [startDate, endDate]);

  useEffect(() => {
    const auditTrails = async () => {
      let searchUrl = `?SearchKey=${enteredValue.replace(
        "+",
        "%2B"
      )}&OrderBy=${sortValue}&PageNumber=1&PageSize=20&OrderType=&OrderBy=${dropdownKey}&StartDate=${renderStartDate}&EndDate=${renderEndDate}`;
      await fetchSearchResults(searchUrl);
    };

    auditTrails().catch(console.log("error"));
  }, []);

  const fetchSearchResults = async (searchUrl) => {
    setScreenLoader(true);
    let searchFeedBack = await searchAndSortAuditTrails(searchUrl);
    if (searchFeedBack?.status === 200) {
      setScreenLoader(false);
      setUsers(searchFeedBack?.data?.data?.items);
      setPageCount(searchFeedBack?.data?.data?.totalPages);
      setTotalCount(searchFeedBack?.data?.data?.totalCount);
    } else {
      setScreenLoader(false);
      setUsers([]);
      setPageCount("");
      setTotalCount("");
      toast.error("Error while getting data!");
    }
  };
  const nextPageApiCall = async (data) => {
    let doc = document.getElementsByTagName("li");
    let firstDoc = doc[1].children[0];
    firstDoc.classList.remove("active-link");
    let pageNumberForSearchNext = data.selected + 1;
    let searchUrl = `?SearchKey=${enteredValue.replace(
      "+",
      "%2B"
    )}&OrderBy=${sortValue}&PageNumber=${pageNumberForSearchNext}&PageSize=20&StartDate=${renderStartDate}&EndDate=${renderEndDate}`;
    setPageNumberForSearch(pageNumberForSearchNext);
    await fetchSearchResults(searchUrl);
  };
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="my-section d-flex justify-content-between sidebar-for-mobile">
        <div className="my-container">
          <div className="myDiv" id="detail">
            <LoadingOverlay
              spinner={<HashLoader color="#5ba6b7" size={90} />}
              active={screenLoader}
            >
              <Row className="mt-5 my-4">
                <Col lg={3}>
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Type here to search"
                      onChange={(e) => {
                        setSearchInputValue(e.target.value);
                      }}
                      onKeyDown={(e) => onEnter(e)}
                      value={searchInputValue}
                    />
                    <FontAwesomeIcon
                      id="searchButton"
                      onClick={SearchInput}
                      //   onClick={this.searchAInput}
                      icon={faSearch}
                      //onClick={searchTrue && this.resetSearch}
                      //icon={searchTrue ? faTimes : faSearch}
                    />
                  </div>
                </Col>
                <Col lg={4} className="d-flex justify-content-start">
                  <div
                    className="d-flex flex-column"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="date-select">
                      <input
                        style={{ background: "transparent" }}
                        type="button"
                        value={`${renderStartDate} - ${renderEndDate}`}
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    {isOpen && (
                      <div className="position-relative">
                        <div className="position-absolute">
                          <DatePicker
                            // selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col lg={3}>
                  {
                    // <div className="d-flex search-type">
                    //   <p className="p-width">Sort by</p>
                    //   <img
                    //     src={sortIcon}
                    //     alt="sort-icon"
                    //     width={25}
                    //     height={25}
                    //   />
                    //   <select className="select">
                    //     {sortList?.map((item, i) => (
                    //       <option key={i} value={item.value}>
                    //         {item.name}
                    //       </option>
                    //     ))}
                    //   </select>
                    // </div>
                  }
                </Col>

                <Col lg={2} className="d-flex justify-content-end">
                  <div
                    className="d-flex mt-2 me-3"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={downloadContractorUsers}
                  >
                    <FontAwesomeIcon
                      icon={faFileExport}
                      style={{
                        color: "#2b4755",
                      }}
                    />{" "}
                    <p className="text-decoration-underline ms-2 mb-0 text-uppercase">
                      Export
                    </p>{" "}
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table bordered size="sm" className="list-view-table">
                  <thead>
                    <tr>
                      <th onClick={() => sorting("AuditId")}>
                        ID {sortIconPosition(0)}
                      </th>
                      <th onClick={() => sorting("Action")}>
                        Action {sortIconPosition(1)}
                      </th>
                      {/* <th onClick={() => sorting("Description")}>
                        Description{" "}
                        {toggleDescription ? (
                          <FontAwesomeIcon icon={faChevronDown} />
                        ) : (
                          <FontAwesomeIcon icon={faChevronUp} />
                        )}
                      </th> */}
                      <th onClick={() => sorting("InitiatedBy")}>
                        Initiated By {sortIconPosition(2)}
                      </th>
                      <th onClick={() => sorting("Module")}>
                        Module
                        {sortIconPosition(3)}
                      </th>
                      <th onClick={() => sorting("Timestamp")}>
                        Date
                        {sortIconPosition(4)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((item, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{item?.id}</td>
                          <td>{item?.action}</td>
                          {/* <td>{item?.description}</td> */}
                          <td>{item?.initiatedBy}</td>
                          <td>{item?.module}</td>
                          <td>
                            {moment(item?.timestamp).format(
                              "DD.MM.YYYY h:mm:ss"
                            )}
                          </td>
                          {/* <td>{item?.timestamp}</td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-around align-items-center">
                  <p
                    style={{
                      fontSize: "12px",
                    }}
                    className="m-0"
                  >
                    Showing{" "}
                    <b>
                      {users?.length} of {totalCount}
                    </b>{" "}
                    Records
                  </p>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={nextPageApiCall}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    activeLinkClassName={"active-link"}
                    forcePage={pageNumberForSearch - 1}
                  />
                </div>
              </div>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditTrails;
