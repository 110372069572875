import React from "react";
import { Nav } from "react-bootstrap";
import { BASE_URL_IMAGE } from "../../../../../constant/ApiUrls";
import cross_unfaded from "../../../../../../assets/images/cross_unfaded.png";

const QuestionList = ({
  deleteQuestionClick,
  ontabclick,
  createQuestionArray,
  selectedQuestionValue,
}) => {
  return (
    <Nav variant="pills" className="flex-column question-nav">
      {createQuestionArray?.map((item, ind) => {
        return (
          <Nav.Item
            key={ind}
            id={ind + "nav-tab-index"}
            className="mb-1 w-100 "
          >
            <Nav.Link eventKey={ind + "question"} className="w-100">
              <div className="question-container d-flex flex-row w-100">
                <div
                  className="img-container "
                  onClick={() => ontabclick(item, ind)}
                >
                  {item.image !== "" && (
                    <img
                      src={`${BASE_URL_IMAGE}${item.image}`}
                      style={{ border: "2px solid #2C8CA3" }}
                    />
                  )}
                </div>
                <div className="question flex-fill col-lg-6">
                  <div className="d-flex justify-content-between h-100 me-2">
                    <div
                      onClick={(e) => ontabclick(item, ind)}
                      className="flex-fill quetion-block"
                    >
                      <h6 className="mb-0">Q{ind + 1}</h6>
                      <p
                        className="mb-0"
                        style={{
                          wordBreak: "break-all",
                        }}
                      >
                        {selectedQuestionValue(item?.questionTitles)}
                      </p>
                    </div>
                    {localStorage.getItem("tab_visibility") !== "reporting" && (
                      <img
                        height={8}
                        width={8}
                        src={cross_unfaded}
                        className="close cursor-pointer"
                        onClick={(e) => {
                          deleteQuestionClick(e, ind, ind + "nav-tab-index");
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Nav.Link>
          </Nav.Item>
        );
      })}
    </Nav>
  );
};

export default QuestionList;
