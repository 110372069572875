import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "simplebar/src/simplebar.css";
import "react-circular-progressbar/dist/styles.css";
import './components/services/Interceptor';
import {RestrictProvider} from "./../src/components/utils/Restrict"
ReactDOM.render(
  <BrowserRouter>
    <RestrictProvider>

    <App />
    </RestrictProvider>
  </BrowserRouter>
  ,
  document.getElementById("root")
);
