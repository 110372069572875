import React, { useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import close from "../../../../../assets/images/close.png";
import { deleteRewardCodes } from "../../../../services/MasterServices";
import { getKeyTranslation } from "../../../../utils/Translations";

const ConfirmDeleteModal = (props) => {
  const { deletedItems, deletedItemsArray, seekChange, emptyDeletedItems } =
    props;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDelete2, setConfirmDelete2] = useState(false);

  const fistClick = () => {
    let check = false;
    deletedItemsArray?.map((item) => {
      if (
        item.status.toUpperCase() == "Marked as used".toUpperCase() ||
        item.status.toUpperCase() == "in use".toUpperCase() ||
        item.status.toUpperCase() == "expired".toUpperCase()
      ) {
        check = true;
      }
    });
    if (check) {
      setConfirmDelete2(true);
    } else {
      setConfirmDelete(true);
    }
    check = false;
  };

  const deleteCodes1 = async () => {
    let ids = [];
    deletedItemsArray.map((item) => {
      ids.push(item.id);
    });

    let payload = {
      rewardId: deletedItemsArray[0]["rewardId"],
      rewardCodeIds: ids,
    };
    const rewardDetails = await deleteRewardCodes(payload);

    if (rewardDetails?.status == 200) {
      setConfirmDelete(false);
      seekChange();
      emptyDeletedItems();
      setTimeout(() => {
        toast.success(getKeyTranslation(rewardDetails?.data.message));
      }, 3000);
    } else {
      setConfirmDelete(false);
      setConfirmDelete2(false);
      toast.error(getKeyTranslation(rewardDetails?.data.message));
    }
  };
  const deleteCodes2 = async () => {
    let ids = [];
    deletedItemsArray.map((item) => {
      ids.push(item.id);
    });

    let payload = {
      rewardId: deletedItemsArray[0]["rewardId"],
      rewardCodeIds: ids,
    };
    const rewardDetails = await deleteRewardCodes(payload);
    if (rewardDetails?.status == 200) {
      setConfirmDelete(false);
      setConfirmDelete2(false);
      seekChange();
      emptyDeletedItems();
    } else {
      setConfirmDelete(false);
      setConfirmDelete2(false);
      toast.error("Error deleting reward codes");
    }
  };
  return (
    <>
      <div
        className="delete-alert-container d-flex justify-content-between ms-4"
        style={{ visibility: deletedItems > 0 ? "visible" : "hidden" }}
      >
        <p className="d-inline">{deletedItems} item selected</p>
        <Badge
          pill
          bg="secondary"
          onClick={fistClick}
          style={{ cursor: "pointer" }}
        >
          Delete selected
        </Badge>
      </div>
      <Modal
        show={confirmDelete}
        centered
        size="md"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="detail-reward-modal">
            <div className="d-flex flex-row justify-content-between">
              <p className="col-11">
                Are you sure you want to delete the selected codes?
              </p>
              <img
                src={close}
                height={12}
                width={12}
                onClick={() => {
                  setConfirmDelete(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div></div>
            <div className="d-flex mt-2 edit-buttons justify-content-end w-100">
              <button
                className="cancel text-uppercase"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  setConfirmDelete(false);
                }}
              >
                cancel
              </button>
              <button
                className="save text-uppercase"
                style={{ fontSize: "12px" }}
                onClick={deleteCodes1}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={confirmDelete2}
        centered
        size="md"
        className="modal-screen updateModal-screen"
      >
        <Modal.Body>
          <div className="detail-reward-modal">
            <div className="d-flex flex-row justify-content-between">
              <p className="col-11">
                Some of the codes that you have selected are currently
                distributed to the users. Deleting the codes will forcefully
                remove their rewards from these users. Are you sure you want to
                continue?
              </p>
              <img
                src={close}
                height={12}
                width={12}
                onClick={() => {
                  setConfirmDelete2(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div></div>
            <div className="d-flex mt-2 edit-buttons justify-content-end w-100">
              <button
                className="cancel text-uppercase"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  setConfirmDelete2(false);
                }}
              >
                cancel
              </button>
              <button
                className="save text-uppercase"
                style={{ fontSize: "12px" }}
                onClick={deleteCodes2}
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmDeleteModal;
