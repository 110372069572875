import React, { Component } from "react";
import { Modal, Row, Col, Button, Spinner } from "react-bootstrap";
import "./AddContractor.css";
import "../../../../../App.css";
import ScrollBar from "react-scrollbar";
import {
  getCategories,
  addNewContractorClientCompany,
} from "../../../../services/ClientServices";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dynamicFormEmptyCheck } from "../../../../utils/DynamicFormValidation";
import { getLoginStorage } from "../../../../utils/UserDataManagement";
import toast from "react-hot-toast";
import { VALID_EMAIL_1 } from "../../../../constant/AppConstant";
import { getKeyTranslation } from "../../../../utils/Translations";
class AddContractor extends Component {
  state = {
    companyName: "",
    licensesCount: 0,
    users: [{ email: "" }],
    categoriesList: [],
    categoriesName: "1,2,3",
    addContractorModal: false,
    addContractorModal_2: false,
    addContractorModal_3: false,
    companyNameError: "",
    amountOfLiscenseError: "",
    emailPassowrdError: "",
    categoryError: "",
    categoriesId: [],
    disableButton: false,
    reward: false,
  };
  resetModalState = () => {
    this.setState({
      companyNameError: "",
      amountOfLiscenseError: "",
      emailPassowrdError: "",
      categoryError: "",
      companyName: "",
      licensesCount: 0,
      users: [{ email: "" }],
      categoriesList: [],
      categoriesId: [],
      reward: false,
    });
  };
  companyAndEmail_validate = () => {
    const { companyName, users } = this.state;

    let dynamicFormValidity = dynamicFormEmptyCheck(this.state.users);
    let error = false;
    for (var obj of users) {
      var keys = Object.keys(obj); // if you don't know the keys
      if (keys[0] === "email") {
        var value = obj[keys[0]]; //in case your object has only one key
        if (!value.match(VALID_EMAIL_1)) {
          error = true;
          this.setState({
            emailPassowrdError: "*please enter valid email",
          });
        }
      }
    }

    if (!companyName.trim()) {
      error = true;
      this.setState({
        companyNameError: "Company Name is required",
      });
    }
    if (dynamicFormValidity) {
      error = true;
      this.setState({
        emailPassowrdError: "Required field is empty",
      });
    }
    return error;
  };
  license_validate = () => {
    const { licensesCount, users } = this.state;

    if (users.length - 1 > licensesCount) {
      toast.error(
        "Additional admin(s) can not be greater than amount of licences."
      );
      return;
    }
    let error = false;
    if (!licensesCount) {
      error = true;
      this.setState({
        amountOfLiscenseError: "Please enter the require amount of licenses",
      });
    }
    return error;
  };

  handleSubmitContractor = async () => {
    const { companyName, licensesCount, users, categoriesId } = this.state;

    if (users.length - 1 > licensesCount) {
      toast.error(
        "Additional admin(s) can not be greater than amount of licences."
      );
      return;
    }
    let error = false;
    error = this.license_validate() || this.companyAndEmail_validate();
    if (categoriesId.length === 0) {
      error = true;
      this.setState({
        categoryError: "Please select alteast one Category",
      });
    }
    if (!error) {
      let obj2 = {
        clientId: getLoginStorage("clientId"),
        companyName: companyName,
        amountOfLicenses: licensesCount,
        users: users,
        categories: categoriesId,
        qualifiedForRewards: this.state.reward,
      };
      this.setState({
        disableButton: true,
      });

      let response = await addNewContractorClientCompany(obj2);
      if (response.status === 200) {
        toast.success(getKeyTranslation(response?.data?.message));
        this.props.updateConctractor();
        this.setState({
          disableButton: false,
        });
        this.closeAddContractorModal_3();
      } else {
        toast.error(getKeyTranslation(response?.data?.message));
        this.setState({
          disableButton: false,
        });
      }
    }
  };

  addNewContractor = () => {
    let users = [...this.state.users, { email: "" }];
    this.setState({
      users,
    });
  };
  handleChangeEmail = (event, index) => {
    const { users } = this.state;
    this.setState({
      emailPassowrdError: "",
    });
    if (event.target.value.length < 50) {
      users[index].email = event.target.value;
      this.setState({
        users,
      });
    }
  };
  handleChangePassword = (event, index) => {
    const { users } = this.state;
    users[index].password = event.target.value;
    this.setState({
      users,
    });
  };

  handleChangeCategories = (item) => {
    console.log("categoriesId", this.state.categoriesId);
    if (item === "all") {
      if (this.state.categoriesId.length === this.state.categoriesList.length) {
        this.setState({
          categoriesId: [],
        });
      } else {
        let array = [];
        for (let i of this.state.categoriesList) {
          array.push(i.categoryId);
        }

        this.setState({
          categoriesId: [...array],
        });
      }
    } else {
      let array = this.state.categoriesId;
      if (array.includes(item.categoryId)) {
        let arr = array.filter((i) => i !== item.categoryId);
        this.setState({
          categoriesId: arr,
        });
      } else {
        this.setState({
          categoriesId: [...this.state.categoriesId, item.categoryId],
        });
      }
    }
    console.log("categoriesId", this.state.categoriesId);
  };
  closeAddContractorModal = () => {
    this.setState({
      addContractorModal: false,
    });
    this.resetModalState();
  };
  closeAddContractorModal_2 = () => {
    this.setState({
      addContractorModal_2: false,
    });
    this.resetModalState();
  };
  backAddContractorModal_2 = () => {
    this.setState({
      addContractorModal_2: false,
      addContractorModal: true,
    });
  };
  closeAddContractorModal_3 = () => {
    this.setState({
      addContractorModal_3: false,
    });
    this.resetModalState();
  };
  backAddContractorModal_3 = () => {
    this.setState({
      addContractorModal_3: false,
      addContractorModal_2: true,
    });
  };
  openAddContractorModal_2 = () => {
    if (!this.companyAndEmail_validate()) {
      this.setState({
        addContractorModal: false,
        addContractorModal_2: true,
      });
    }
  };
  openAddContractorModal_3 = () => {
    if (!this.license_validate()) {
      this.setState({
        addContractorModal_2: false,
        addContractorModal_3: true,
      });
    }
  };

  openAddContractorModal = async () => {
    let response = await getCategories();
    this.setState({
      categoriesList: response?.data?.data,
      addContractorModal: true,
    });
  };
  removeClick(i) {
    let users = [...this.state.users];
    users.splice(i, 1);
    this.setState({ users });
  }
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmitContractor();
    }
  };
  keyDown = (e) => {
    var invalidChars = ["-", "+", "e", "E", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  publicAndPrivateContent = (visibility_type) => {
    let content = this.state.categoriesList.map((item, ind) => {
      if (item?.type.toLowerCase() === visibility_type) {
        return (
          <div className="category d-flex" key={item.id}>
            <div className="styled-input-single">
              <input
                type="checkbox"
                name="fieldset-5"
                id={"checkbox-example-one" + item.categoryId}
                onChange={() => this.handleChangeCategories(item)}
                checked={this.state.categoriesId?.includes(item.categoryId)}
              />
              <label htmlFor={"checkbox-example-one" + item.categoryId} />
            </div>
            <p className="name mr-2">{item.categoryName}</p>
          </div>
        );
      } else return;
    });
    return this.state.categoriesList.filter((item) => {
      return item?.type.toLowerCase() === visibility_type;
    }).length ? (
      content
    ) : (
      <p className="text-start" style={{ fontSize: "20px" }}>
        No data to show
      </p>
    );
  };
  render() {
    const {
      users,
      companyNameError,
      amountOfLiscenseError,
      emailPassowrdError,
      categoryError,
    } = this.state;

    return (
      <>
        <button
          className="add-license mt-0"
          onClick={this.openAddContractorModal}
        >
          <FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: "15px" }} />{" "}
          add contractor
        </button>
        {/* modal 1 */}
        <Modal
          show={this.state.addContractorModal}
          centered
          size="xl"
          className="modal-screen updateModal-screen newContractorModal"
        >
          <Modal.Body style={{ height: "70vh" }}>
            <div className="add-contractor-modal add-license-modal pl-5 d-flex flex-column h-100">
              <div className="modal-header">
                <h1 className="mb-0">Add new contractor</h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  disabled={this.state.disableButton}
                  onClick={() => this.closeAddContractorModal()}
                ></button>
              </div>
              <span className="screen-label font-weight-normal mb-1">
                Step 1 out of 3
              </span>
              <Row className="flex-grow-1">
                <Col lg={6}>
                  <Row>
                    <Col lg={7}>
                      <label className="screen-label">Company Name</label>
                      <br />
                      <input
                        type="text"
                        className="screen-input w-100"
                        value={this.state.companyName}
                        onChange={(e) => {
                          if (e.target.value.length < 50) {
                            this.setState({
                              companyName: e.target.value,
                              companyNameError: "",
                            });
                          }
                        }}
                        onKeyPress={this.handleKeyPress}
                      />
                      <br />
                      <span className="error-decoration">
                        {companyNameError}
                      </span>
                      <br />
                    </Col>
                  </Row>
                  <Row className="h-100">
                    <Col lg={12}>
                      <ScrollBar
                        style={{ maxHeight: 280 }}
                        className="my-scrollbar"
                        contentClassName="my-scrollbar-content"
                        speed={0.6}
                      >
                        {users?.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex justify-content-between"
                          >
                            <div className="w-100">
                              {index === 0 && (
                                <label>
                                  E-mail{" "}
                                  <span> (Main admin for this company) </span>{" "}
                                </label>
                              )}
                              {index === 1 && (
                                <label>
                                  E-mail{" "}
                                  <span>
                                    (Additional admin/s for this company){" "}
                                  </span>{" "}
                                </label>
                              )}
                              <br />
                              <input
                                type="email"
                                className="mb-1"
                                value={item.email}
                                onChange={(e) =>
                                  this.handleChangeEmail(e, index)
                                }
                                onKeyPress={this.handleKeyPress}
                              />
                            </div>
                            <div className="pt-4 icon-margin">
                              {users.length !== 1 && (
                                <FontAwesomeIcon
                                  onClick={this.removeClick.bind(this, index)}
                                  className="fa-times-color"
                                  icon={faTimes}
                                  style={{
                                    visibility:
                                      index == 0 ? "hidden" : "visible",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                        <span className="error-decoration">
                          {emailPassowrdError}
                        </span>
                        <div className="add-license-buttons m-0 mt-2">
                          <Button
                            onClick={this.addNewContractor}
                            className="another-btn another-btn-2"
                          >
                            ADD ANOTHER ADMIN{" "}
                            <FontAwesomeIcon
                              className="cursor-behavior"
                              icon={faPlusCircle}
                            />
                          </Button>
                        </div>
                      </ScrollBar>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className="h-100 justify-content-end align-items-end">
                    <Col
                      lg={6}
                      className="d-flex flex-column justify-content-between"
                    >
                      {/* <div>
                        <p style={{ marginTop: "5px" }}>Rewards</p>
                        <div className="category d-flex ">
                          <div
                            className="styled-input-single d-flex flex-row"
                            style={{ paddingTop: 0 }}
                          >
                            <input
                              type="checkbox"
                              name="fieldset-5"
                              id={"reward"}
                              onChange={() =>
                                this.setState({
                                  reward: !this.state.reward,
                                })
                              }
                              checked={this.state.reward}
                            />
                            <label
                              htmlFor={"reward"}
                              style={{ fontSize: "12px", marginTop: "-2px" }}
                              className="default-color"
                            >
                              Qualify for rewards
                            </label>
                          </div>
                        </div>
                      </div> */}
                      {this.state.users.length > 1 && (
                        <div className="noteSection2">
                          <h6>Note</h6>
                          <p>
                            You’ve added {parseInt(this.state.users.length) - 1}{" "}
                            additional admins to the contractor. These admins
                            will also take up licenses.
                          </p>
                        </div>
                      )}
                      <div className="double-buttons">
                        <div className="d-flex float-end add-edit-buttons">
                          <button
                            disabled={this.state.disableButton}
                            onClick={() => this.closeAddContractorModal()}
                            className="cancel width-100px"
                          >
                            cancel
                          </button>
                          <button
                            className="width-100px"
                            onClick={() => this.openAddContractorModal_2()}
                          >
                            {this.state.disableButton ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "next step"
                            )}
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        {/* modal 2 */}
        <Modal
          show={this.state.addContractorModal_2}
          centered
          size="xl"
          className="modal-screen updateModal-screen newContractorModal"
        >
          <Modal.Body style={{ height: "70vh" }}>
            <div className="add-contractor-modal add-license-modal pl-5 d-flex flex-column h-100">
              <div className="modal-header">
                <h1 className="mb-0">
                  Select plan for {this.state.companyName}
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  disabled={this.state.disableButton}
                  onClick={() => this.closeAddContractorModal_2()}
                ></button>
              </div>
              <span className="screen-label font-weight-normal mb-1">
                Step 2 out of 3
              </span>
              <Row className="flex-grow-1">
                <Col lg={6}>
                  <Row>
                    <Col lg={5}>
                      <label className="screen-label">
                        Total Amount of Licenses
                      </label>
                      <br />
                      <input
                        type="number"
                        min="0"
                        className="screen-input w-100"
                        value={this.state.licensesCount}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            this.setState({
                              licensesCount: e.target.value,
                              amountOfLiscenseError: "",
                            });
                          }
                        }}
                        onKeyPress={this.handleKeyPress}
                        onKeyDown={this.keyDown}
                      />
                      <span className="error-decoration">
                        {amountOfLiscenseError}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={5}>
                      <div>
                        <p style={{ marginTop: "5px" }}>Rewards</p>
                        <div className="category d-flex ">
                          <div
                            className="styled-input-single d-flex flex-row"
                            style={{ paddingTop: 0 }}
                          >
                            <input
                              type="checkbox"
                              name="fieldset-5"
                              id={"reward"}
                              onChange={() =>
                                this.setState({
                                  reward: !this.state.reward,
                                })
                              }
                              checked={this.state.reward}
                            />
                            <label
                              htmlFor={"reward"}
                              style={{ fontSize: "12px", marginTop: "-2px" }}
                              className="default-color"
                            >
                              Qualify for Rewards
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className="h-100 justify-content-end align-items-end">
                    <Col
                      lg={6}
                      className="d-flex flex-column justify-content-between"
                    >
                      <div className="double-buttons">
                        <div className="d-flex float-end add-edit-buttons">
                          <button
                            disabled={this.state.disableButton}
                            onClick={() => this.backAddContractorModal_2()}
                            className="back width-100px"
                          >
                            back
                          </button>
                          <button
                            className="width-100px"
                            onClick={this.openAddContractorModal_3}
                          >
                            {this.state.disableButton ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "next step"
                            )}
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        {/* modal 3 */}
        <Modal
          show={this.state.addContractorModal_3}
          centered
          size="xl"
          className="modal-screen updateModal-screen newContractorModal"
        >
          <Modal.Body>
            <div className="add-contractor-modal add-license-modal pl-5">
              <div className="modal-header">
                <h1 className="mb-0">
                  Select content for {this.state.companyName}
                </h1>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  disabled={this.state.disableButton}
                  onClick={() => this.closeAddContractorModal_3()}
                ></button>
              </div>
              <span className="screen-label font-weight-normal mb-1">
                Step 3 out of 3
              </span>

              <Row className="mt-2">
                <Col lg={6}>
                  <div className="category d-flex align-items-center">
                    <div className="styled-input-single radio-button-styling">
                      <input
                        type="checkbox"
                        name="fieldset-5"
                        id={"checkbox-example-one" + 0}
                        onChange={() => this.handleChangeCategories("all")}
                        checked={
                          this.state.categoriesId.length ===
                          this.state.categoriesList.length
                        }
                      />
                      <label htmlFor={"checkbox-example-one" + 0} />
                    </div>
                    <p
                      className="name mb-0"
                      style={{
                        paddingLeft: "4px",
                        marginTop: "-3px",
                      }}
                    >
                      Select All
                    </p>
                  </div>
                  <p style={{ color: "black" }}>
                    {/* <p> ({this.state.categoriesId.length})</p> */}
                    <span className="error-decoration">{categoryError}</span>
                  </p>
                  <Row>
                    <Col lg={6}>
                      <div className="categories mngHeight">
                        {/* <p>Categories ({this.state.categoriesId.length})</p>
                        <span className="error-decoration">
                          {categoryError}
                        </span> */}
                        <p>Public Content</p>
                        <ScrollBar
                          style={{ maxHeight: 300 }}
                          className="my-scrollbar"
                          contentClassName="my-scrollbar-content"
                          speed={0.6}
                        >
                          {/* <div className="category d-flex">
                            <div className="styled-input-single">
                              <input
                                type="checkbox"
                                name="fieldset-5"
                                id={"checkbox-example-one" + 0}
                                onChange={() =>
                                  this.handleChangeCategories("all")
                                }
                                checked={
                                  this.state.categoriesId.length ===
                                  this.state.categoriesList.length
                                }
                              />
                              <label htmlFor={"checkbox-example-one" + 0} />
                            </div>
                            <p className="name">All</p>
                          </div> */}

                          {this.publicAndPrivateContent("public")}
                        </ScrollBar>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="categories mngHeight">
                        <p>Private Content</p>
                        <ScrollBar
                          style={{ maxHeight: 300 }}
                          className="my-scrollbar"
                          contentClassName="my-scrollbar-content"
                          speed={0.6}
                        >
                          {this.publicAndPrivateContent("private")}
                        </ScrollBar>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} className="d-flex flex-column justify-content-end">
                  <div className="double-buttons">
                    <div className="d-flex float-end add-edit-buttons">
                      <button
                        disabled={this.state.disableButton}
                        onClick={() => this.backAddContractorModal_3()}
                        className="back width-100px"
                      >
                        back
                      </button>
                      <button
                        className=""
                        onClick={this.handleSubmitContractor}
                        disabled={this.state.disableButton}
                        style={{
                          opacity:
                            this.state.disableButton ||
                            this.state.licensesCount < 1
                              ? "0.7"
                              : "1",
                          pointerEvents:
                            this.state.licensesCount < 1 ? "none" : "",
                        }}
                      >
                        {this.state.disableButton ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "add contractor"
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default AddContractor;
