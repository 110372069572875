import React from "react";
import { Modal } from "react-bootstrap";

const EditorWarningPopup = ({
  showErrorModal,
  modalText,
  setShowErrorModal,
  deleteConfirm,
  goToBack,
  cross_faded,
}) => {
  return (
    <Modal
      show={showErrorModal}
      centered
      size={`${modalText == "delete" ? "sm" : "md"}`}
      className={`${
        modalText !== "delete" ? "modal-screen updateModal-screen" : ""
      }`}
      style={{ borderRadius: "0" }}
    >
      <Modal.Body>
        <div className="modal-header add-license-modal">
          {modalText !== "delete" && (
            <>
              <h4 className="mb-2 mt-3 me-2" style={{ color: "#34636d" }}>
                Warning
              </h4>
              <img
                src={cross_faded}
                onClick={() => setShowErrorModal(false)}
                style={{ cursor: "pointer" }}
              />
            </>
          )}
          {modalText == "delete" && (
            <div>
              <h4
                className="editor-dark-color mb-4"
                style={{ fontWeight: "bold", fontSize: "14px" }}
              >
                {" "}
                Delete Question?
              </h4>
              <p
                className="editor-dark-color mb-1"
                style={{ fontSize: "15px", fontWeight: "normal" }}
              >
                This action cannot be undone!
              </p>
              <p
                className="editor-dark-color mb-0"
                style={{ fontSize: "15px", fontWeight: "normal" }}
              >
                Are you sure you want to proceed!
              </p>
            </div>
          )}
        </div>
        <h6>
          {modalText !== "sameIndex" &&
            modalText !== "delete" &&
            modalText !== "back" &&
            modalText}
        </h6>
        {modalText !== "sameIndex" &&
          modalText !== "delete" &&
          modalText !== "back" && (
            <>
              <p style={{ color: "red" }}>
                Question and options are required for default language
              </p>
              <p style={{ color: "red" }}>Image is required</p>
              <p style={{ color: "red" }}>Answer should be checked</p>
            </>
          )}
        {modalText == "back" && (
          <>
            <p>
              Unsaved data will be lost. Are you sure you wanted to go back?
            </p>
          </>
        )}

        <div>
          <div
            className={`${
              modalText == "delete"
                ? "create-content-buttons mx-4"
                : "create-content-buttons"
            }`}
          >
            {modalText == "delete" && (
              <button
                className="save-and-edit d-inline px-4 me-4 mb-2 d-block w-100"
                onClick={deleteConfirm}
              >
                YES, DELETE QUESTION
              </button>
            )}
            {modalText == "delete" && (
              <button
                className="save d-inline px-4 me-4 d-block w-100"
                onClick={() => setShowErrorModal(false)}
              >
                NO, RETURN TO EDITOR
              </button>
            )}

            {modalText == "back" && (
              <button className="save d-inline px-4 me-4" onClick={goToBack}>
                Yes
              </button>
            )}
            {modalText !== "delete" && (
              <button
                className="save d-inline px-4 me-4"
                onClick={() => setShowErrorModal(false)}
              >
                {
                  // modalText == "Please fill all questions data correctly" &&
                  modalText !== "back" ? "Ok" : "No"
                }
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditorWarningPopup;
