import { Col, Nav, Row, Tab } from "react-bootstrap";
import OverviewCard from "./overview/OverviewCard";
import "./Content.css";
import { amount_of_question, contentSortingValues } from "./contentData";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import sort_icon from "../../../../assets/icons/sort_desc.png";
import CreateContent from "./createContent/CreateContent";
import {
  copyContentPack,
  creatorsClients,
  deleteContentPack,
  getContentPack,
  getContentPackReporting,
  getLanguageCms,
  markFavourite,
  updateVisibilityContentPack,
} from "../../../services/ClientServices";
import toast, { Toaster } from "react-hot-toast";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";
import { getKeyTranslation } from "../../../utils/Translations";
import ReactPaginate from "react-paginate";

const Content = () => {
  const [creatorAnyClicked, setCreatorAnyClicked] = useState(false);
  const [creatorCheckedItems, setCreatorCheckedItems] = useState([]);
  const [creatorAny, setCreatorAny] = useState(true);
  const [creatorsArray, setCreatorsArray] = useState([]);
  const [languageAnyClicked, setLanguageAnyClicked] = useState(false);
  const [languageCheckedItems, setLanguageCheckedItems] = useState([]);
  const [languageAny, setLanguageAny] = useState(true);
  const [languagesArray, setLanguagesArray] = useState([]);
  const [packVisibility, setPackVisibility] = useState(
    JSON.parse(localStorage.getItem("staySafeUserData")).role == "KA"
      ? "private"
      : "public"
  );
  const [questionFilter, setQuestionFilter] = useState(Number);
  const [searchKey, setSearchKey] = useState("");
  const [initialSearchKey, setInitialSearchKey] = useState("");
  const [listing, setListing] = useState([]);
  const [index, setIndex] = useState(0);
  const [sortValue, setSortValue] = useState(contentSortingValues[0].value);
  const [overviewLoader, setOverviewLoader] = useState(false);
  const [pageCount, setPageCount] = useState("");
  const [pageNumberForSearch, setPageNumberForSearch] = useState(1);

  useEffect(async () => {
    let c_1 = await languageapi();
    let c_2 = await creatorapi();
    if (c_1 && c_2) {
      let searchUrl, searchUrlReporting;
      if (localStorage.getItem("content-filters")) {
        let parsed_arr = JSON.parse(localStorage.getItem("content-filters"));
        setPackVisibility(parsed_arr.pack_Visibility);
        setLanguageCheckedItems(parsed_arr.language_CheckedItems);
        setCreatorCheckedItems(parsed_arr.creator_CheckedItems);
        setQuestionFilter(parseInt(parsed_arr.question_Filter));
        setSearchKey(parsed_arr.search_Key);
        setInitialSearchKey(parsed_arr.search_Key);
        setSortValue(parsed_arr.sort_Value);
        contentSortingValues.forEach((item, i) => {
          if (item.value == parsed_arr.sort_Value) {
            setIndex(i);
          }
        });
        localStorage.setItem("tab_visibility", parsed_arr.pack_Visibility);
        //searck key
        //visiblity
        //creator
        parsed_arr.creator_CheckedItems.length
          ? setCreatorAny(false)
          : setCreatorAny(true);
        parsed_arr.creator_CheckedItems.forEach((item) => {
          document.getElementById(item + "creators-content").checked = true;
        });

        //language
        parsed_arr.language_CheckedItems.length
          ? setLanguageAny(false)
          : setLanguageAny(true);
        parsed_arr.language_CheckedItems.forEach((item) => {
          document.getElementById(item + "content-language").checked = true;
        });
        //searchurl
        let visibility_check =
          parsed_arr.pack_Visibility === "favourite"
            ? `&IsFavourite=true`
            : `&ContentPackVisibility=${parsed_arr.pack_Visibility}`;
        // language check
        let language_check =
          parsed_arr.language_CheckedItems.length > 0
            ? "&LanguageIds=" + parsed_arr.language_CheckedItems
            : [];
        // creator check
        let creator_check =
          parsed_arr.creator_CheckedItems.length > 0
            ? "&CreatorIds=" + parsed_arr.creator_CheckedItems
            : [];
        //call api
        searchUrl = `?SearchKey=${parsed_arr.search_Key.replace(
          "#",
          "%23"
        )}&OrderBy=${
          parsed_arr.sort_Value
        }&${visibility_check}${language_check}&AmountOfQuesitons=${
          parsed_arr.question_Filter
        }${creator_check}&PageNumber=1&PageSize=10`;
        searchUrlReporting = `?SearchKey=${parsed_arr.search_Key.replace(
          "#",
          "%23"
        )}&OrderBy=${
          parsed_arr.sort_Value
        }&${language_check}&AmountOfQuesitons=${
          parsed_arr.question_Filter
        }${creator_check}&PageNumber=1&PageSize=10`;
        if (parsed_arr.pack_Visibility == "reporting") {
          listapiReporting(searchUrlReporting);
        } else {
          listapi(searchUrl);
        }
      } else {
        searchUrl = `?ContentPackVisibility=${packVisibility}&AmountOfQuesitons=0&OrderBy=${sortValue}&PageNumber=1&PageSize=10`;
        searchUrlReporting = `?AmountOfQuesitons=0&OrderBy=${sortValue}&PageNumber=1&PageSize=10`;
        if (packVisibility == "reporting") {
          listapiReporting(searchUrlReporting);
        } else {
          listapi(searchUrl);
        }
        localStorage.setItem("tab_visibility", packVisibility);
      }
    }
    setPageNumberForSearch(1);

    localStorage.removeItem("content-filters");
  }, []);
  const recallListApi = () => {
    //visibility check
    let visibility_check =
      packVisibility === "favourite"
        ? `&IsFavourite=true`
        : `&ContentPackVisibility=${packVisibility}`;
    // language check
    let language_check =
      languageCheckedItems.length > 0
        ? "&LanguageIds=" + languageCheckedItems
        : [];
    // creator check
    let creator_check =
      creatorCheckedItems.length > 0
        ? "&CreatorIds=" + creatorCheckedItems
        : [];
    //call api
    let searchUrl = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&OrderBy=${sortValue}&${visibility_check}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=1&PageSize=10`;
    let searchUrlReporting = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&OrderBy=${sortValue}&${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=1&PageSize=10`;
    setPageNumberForSearch(1);
    if (packVisibility == "reporting") {
      listapiReporting(searchUrlReporting);
    } else {
      listapi(searchUrl);
    }
  };
  const deleteApi = async (id) => {
    let obj = {
      categoryId: id,
    };
    let result = await deleteContentPack(obj);
    if (result?.status === 200) {
      setOverviewLoader(true);
      toast.success(getKeyTranslation(result.data.message));
      recallListApi();
    } else {
      toast.error("Error deleting content pack");
    }
  };

  const callingToaster = (value, name) => {
    if (name == "success") {
      toast.success(value);
    } else {
      toast.error(value);
    }
  };
  const copyCategory = async (id) => {
    let obj = {
      categoryId: id,
    };
    setOverviewLoader(true);
    let result = await copyContentPack(obj);
    if (result?.status === 200) {
      toast.success(getKeyTranslation(result.data.message));
      setTimeout(() => {
        recallListApi();
      }, 2000);
    } else {
      toast.error(getKeyTranslation(result.data.message));
    }
  };
  const updateVisibility = async (id) => {
    let obj = {
      categoryId: id,
      packVisibilityId: 1,
    };
    let result = await updateVisibilityContentPack(obj);
    if (result?.status === 200) {
      setOverviewLoader(true);
      toast.success(getKeyTranslation(result.data.message));
      // setTimeout(() => {
      recallListApi();
      // }, 3000);
    } else {
      toast.error(getKeyTranslation(result.data.message));
    }
  };
  const visibility = (visibility_type) => {
    localStorage.setItem("tab_visibility", visibility_type);

    setListing([]);
    let searchUrl;
    // visibility check
    let visibility_check =
      visibility_type === "favourite"
        ? `&IsFavourite=true`
        : `&ContentPackVisibility=${visibility_type}`;
    // language check
    let language_check =
      languageCheckedItems.length > 0
        ? "&LanguageIds=" + languageCheckedItems
        : [];
    // creator check
    let creator_check =
      creatorCheckedItems.length > 0
        ? "&CreatorIds=" + creatorCheckedItems
        : [];
    //page_number_for_search
    let page_number;
    if (
      packVisibility.toLocaleLowerCase() == visibility_type.toLocaleLowerCase()
    ) {
      page_number = pageNumberForSearch;
    } else {
      page_number = 1;
      setPageNumberForSearch(page_number);
    }
    // call api
    searchUrl = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&OrderBy=${sortValue}${visibility_check}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=${page_number}&PageSize=10`;
    setPackVisibility(visibility_type);
    listapi(searchUrl);
  };
  const visibilityReporting = (visibility_type) => {
    localStorage.setItem("tab_visibility", visibility_type);
    setListing([]);
    let searchUrl;
    // visibility check
    // let visibility_check =
    //   visibility_type === "favourite"
    //     ? `&IsFavourite=true`
    //     : `&ContentPackVisibility=${visibility_type}`;
    // language check
    let language_check =
      languageCheckedItems.length > 0
        ? "&LanguageIds=" + languageCheckedItems
        : [];
    // creator check
    let creator_check =
      creatorCheckedItems.length > 0
        ? "&CreatorIds=" + creatorCheckedItems
        : [];
    //page_number_for_search
    let page_number;
    if (
      packVisibility.toLocaleLowerCase() == visibility_type.toLocaleLowerCase()
    ) {
      page_number = pageNumberForSearch;
    } else {
      page_number = 1;
      setPageNumberForSearch(page_number);
    }
    // call api
    searchUrl = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&OrderBy=${sortValue}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=${page_number}&PageSize=10`;
    setPackVisibility(visibility_type);
    listapiReporting(searchUrl);
  };
  const markedFavourite = async (id) => {
    let obj = {
      contentPackId: id,
    };
    const result = await markFavourite(obj);
    if (result?.status == 200) {
      // visibility check
      let visibility_check =
        packVisibility === "favourite"
          ? `&IsFavourite=true`
          : `&ContentPackVisibility=${packVisibility}`;
      // language check
      let language_check =
        languageCheckedItems.length > 0
          ? "&LanguageIds=" + languageCheckedItems
          : [];
      // creator check
      let creator_check =
        creatorCheckedItems.length > 0
          ? "&CreatorIds=" + creatorCheckedItems
          : [];
      // call api
      let searchUrl = `?SearchKey=${searchKey.replace(
        "#",
        "%23"
      )}&ContentPackType=&OrderBy=${sortValue}${visibility_check}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=1&PageSize=10`;
      listapi(searchUrl);
      setPageNumberForSearch(1);
      toast.success(getKeyTranslation(result.data.message));
    }
  };
  const handleQuestions = (e) => {
    setQuestionFilter(e.target.value);
    // visibility check
    let visibility_check =
      packVisibility === "favourite"
        ? `&IsFavourite=true`
        : `&ContentPackVisibility=${packVisibility}`;
    // language check
    let language_check =
      languageCheckedItems.length > 0
        ? "&LanguageIds=" + languageCheckedItems
        : [];
    // creator check
    let creator_check =
      creatorCheckedItems.length > 0
        ? "&CreatorIds=" + creatorCheckedItems
        : [];
    // call api
    let searchUrl = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&ContentPackType=&OrderBy=${sortValue}${visibility_check}${language_check}&AmountOfQuesitons=${
      e.target.value
    }${creator_check}&PageNumber=1&PageSize=10`;
    let searchUrlReporting = `??SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&AmountOfQuesitons=${
      e.target.value
    }&OrderBy=${sortValue}${language_check}${creator_check}&PageNumber=1&PageSize=10`;
    if (packVisibility == "reporting") {
      listapiReporting(searchUrlReporting);
    } else {
      listapi(searchUrl);
    }
    setPageNumberForSearch(1);
  };
  const listapi = async (params) => {
    setOverviewLoader(true);
    setListing([]);
    let result = await getContentPack(`${params}`);
    if (result?.status == 200) {
      setOverviewLoader(false);
      setListing(result.data.data.items);
      setPageCount(result?.data?.data?.totalPages);
    } else {
      setOverviewLoader(false);
      setListing([]);
      let pre = result?.data?.preMessage ? result?.data?.preMessage : "";
      let post = result?.data?.postMessage ? result?.data?.postMessage : "";
      toast.error(
        pre + " " + getKeyTranslation(result?.data?.message) + " " + post
      );
    }
  };
  const listapiReporting = async (params) => {
    setOverviewLoader(true);
    setListing([]);
    let result = await getContentPackReporting(`${params}`);
    if (result?.status == 200) {
      setOverviewLoader(false);
      setListing(result.data.data.items);
      setPageCount(result?.data?.data?.totalPages);
    } else {
      setOverviewLoader(false);
      setListing([]);
      let pre = result?.data?.preMessage ? result?.data?.preMessage : "";
      let post = result?.data?.postMessage ? result?.data?.postMessage : "";
      toast.error(
        pre + " " + getKeyTranslation(result?.data?.message) + " " + post
      );
    }
  };
  const nextPageApiCall = async (data) => {
    let doc = document.getElementsByTagName("li");
    let firstDoc = doc[1].children[0];
    firstDoc.classList.remove("active-link");
    let pageNumberForSearchnext = data.selected + 1;
    setPageNumberForSearch(pageNumberForSearchnext);
    // visibility check
    let visibility_check =
      packVisibility === "favourite"
        ? `&IsFavourite=true`
        : `&ContentPackVisibility=${packVisibility}`;
    // language check
    let language_check =
      languageCheckedItems.length > 0
        ? "&LanguageIds=" + languageCheckedItems
        : [];
    // creator check
    let creator_check =
      creatorCheckedItems.length > 0
        ? "&CreatorIds=" + creatorCheckedItems
        : [];
    // call api
    let searchUrl = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&ContentPackType=&OrderBy=${sortValue}${visibility_check}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=${pageNumberForSearchnext}&PageSize=10`;
    let searchUrlReporting = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&AmountOfQuesitons=${questionFilter}&OrderBy=${sortValue}${language_check}${creator_check}&PageNumber=${pageNumberForSearchnext}&PageSize=10`;
    if (packVisibility == "reporting") {
      listapiReporting(searchUrlReporting);
    } else {
      listapi(searchUrl);
    }
  };
  const languageapi = async () => {
    const result = await getLanguageCms();

    if (result?.status === 200) {
      setLanguagesArray(result.data.data);
      return true;
    }
  };
  const creatorapi = async () => {
    const result = await creatorsClients();
    if (result?.status === 200) {
      setCreatorsArray(result.data.data.items);
      return true;
    }
  };
  useEffect(() => {
    let arr_with_ids = [];
    if (creatorAnyClicked) {
      if (creatorAny) {
        arr_with_ids = [];
        setCreatorCheckedItems(arr_with_ids);
        creatorsArray?.forEach((item, ind) => {
          let ele = document.getElementById(item.clientId + "creators-content");
          ele.checked = false;
        });
        setCreatorAny(true);
      } else {
        creatorsArray?.forEach((item, ind) => {
          let ele = document.getElementById(item.clientId + "creators-content");
          ele.checked = false;
        });
        arr_with_ids = [];
        setCreatorCheckedItems(arr_with_ids);
      }
      setCreatorAnyClicked(false);
      let searchUrl;
      // visibility check
      let visibility_check =
        packVisibility === "favourite"
          ? `&IsFavourite=true`
          : `&ContentPackVisibility=${packVisibility}`;
      // language check
      let language_check =
        languageCheckedItems.length > 0
          ? "&LanguageIds=" + languageCheckedItems
          : [];
      // creator check
      let creator_check =
        arr_with_ids.length > 0 ? "&CreatorIds=" + arr_with_ids : [];
      // call api
      searchUrl = `?SearchKey=${searchKey.replace(
        "#",
        "%23"
      )}&OrderBy=${sortValue}${visibility_check}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=1&PageSize=10`;
      setPageNumberForSearch(1);
      let searchUrlReporting = `?SearchKey=${searchKey.replace(
        "#",
        "%23"
      )}&AmountOfQuesitons=${questionFilter}&OrderBy=${sortValue}${language_check}${creator_check}&PageNumber=1&PageSize=10`;
      if (packVisibility == "reporting") {
        listapiReporting(searchUrlReporting);
      } else {
        listapi(searchUrl);
      }
    }
  }, [creatorAny]);
  useEffect(() => {
    let arr_with_ids = [];
    if (languageAnyClicked) {
      if (languageAny) {
        arr_with_ids = [];
        setLanguageCheckedItems(arr_with_ids);
        languagesArray?.forEach((item) => {
          let ele = document.getElementById(item.id + "content-language");
          ele.checked = false;
        });
        setLanguageAny(true);
      } else {
        languagesArray?.forEach((item) => {
          let ele = document.getElementById(item.id + "content-language");
          ele.checked = false;
        });
        arr_with_ids = [];
        setLanguageCheckedItems(arr_with_ids);
      }
      setLanguageAnyClicked(false);
      let searchUrl;
      // visibility check
      let visibility_check =
        packVisibility === "favourite"
          ? `&IsFavourite=true`
          : `&ContentPackVisibility=${packVisibility}`;
      // language check
      let language_check =
        arr_with_ids.length > 0 ? "&LanguageIds=" + arr_with_ids : [];
      // creator check
      let creator_check =
        creatorCheckedItems.length > 0
          ? "&CreatorIds=" + creatorCheckedItems
          : [];
      // call api
      searchUrl = `?SearchKey=${searchKey.replace(
        "#",
        "%23"
      )}&OrderBy=${sortValue}${visibility_check}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=${pageNumberForSearch}&PageSize=10`;
      let searchUrlReporting = `?SearchKey=${searchKey.replace(
        "#",
        "%23"
      )}&AmountOfQuesitons=${questionFilter}&OrderBy=${sortValue}${language_check}${creator_check}&PageNumber=${pageNumberForSearch}&PageSize=10`;
      if (packVisibility == "reporting") {
        listapiReporting(searchUrlReporting);
      } else {
        listapi(searchUrl);
      }
    }
  }, [languageAny]);

  const sorting = () => {
    let ind;
    if (index < contentSortingValues.length - 1) {
      setIndex(index + 1);
      ind = index + 1;
      setSortValue(contentSortingValues[ind + 1]?.value);
    } else {
      setIndex(0);
      setSortValue(contentSortingValues[0]?.value);
      ind = 0;
    }
    let searchUrl;
    let sort_value = contentSortingValues[ind].value;
    // visibility check
    let visibility_check =
      packVisibility === "favourite"
        ? `&IsFavourite=true`
        : `&ContentPackVisibility=${packVisibility}`;
    // language check
    let language_check =
      languageCheckedItems.length > 0
        ? "&LanguageIds=" + languageCheckedItems
        : [];
    // creator check
    let creator_check =
      creatorCheckedItems.length > 0
        ? "&CreatorIds=" + creatorCheckedItems
        : [];
    // call api
    searchUrl = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&OrderBy=${sort_value}${visibility_check}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=${pageNumberForSearch}&PageSize=10`;
    setSortValue(sort_value);
    let searchUrlReporting = `?SearchKey=${initialSearchKey.replace(
      "#",
      "%23"
    )}&AmountOfQuesitons=${questionFilter}&OrderBy=${sort_value}${language_check}${creator_check}&PageNumber=${pageNumberForSearch}&PageSize=10`;
    if (packVisibility == "reporting") {
      listapiReporting(searchUrlReporting);
    } else {
      listapi(searchUrl);
    }
  };
  const handleLanguages = (item) => {
    setLanguageAny(false);
    let existsIndex = false;
    let arr = languageCheckedItems?.slice();
    arr?.forEach((ele) => {
      if (ele == item.id) {
        existsIndex = true;
        arr.splice(
          arr.findIndex((v) => v === item.id),
          1
        );
        setLanguageCheckedItems(arr);
      }
    });
    if (!existsIndex) {
      arr.push(item.id);
      setLanguageCheckedItems(arr);
    }
    if (arr.length) {
      setLanguageAny(false);
    } else {
      setLanguageAny(true);
    }

    let searchUrl;
    //visibility check
    let visibility_check =
      packVisibility === "favourite"
        ? `&IsFavourite=true`
        : `&ContentPackVisibility=${packVisibility}`;
    // language check
    let language_check = arr.length > 0 ? "&LanguageIds=" + arr : [];
    // creator check
    let creator_check =
      creatorCheckedItems.length > 0
        ? "&CreatorIds=" + creatorCheckedItems
        : [];
    //call api
    searchUrl = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&OrderBy=${sortValue}&${visibility_check}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=1&PageSize=10`;
    setPageNumberForSearch(1);
    let searchUrlReporting = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&AmountOfQuesitons=${questionFilter}&OrderBy=${sortValue}${language_check}${creator_check}&PageNumber=1&PageSize=10`;
    if (packVisibility == "reporting") {
      listapiReporting(searchUrlReporting);
    } else {
      listapi(searchUrl);
    }
  };

  const handleCreators = (item) => {
    let existsIndex = false;
    let arr = creatorCheckedItems?.slice();
    arr?.forEach((ele) => {
      if (ele == item.clientId) {
        existsIndex = true;
        arr.splice(
          arr.findIndex((v) => v === item.clientId),
          1
        );
        setCreatorCheckedItems(arr);
      }
    });
    if (!existsIndex) {
      arr.push(item.clientId);
      setCreatorCheckedItems(arr);
    }
    if (arr.length) {
      setCreatorAny(false);
    } else {
      setCreatorAny(true);
    }

    let searchUrl;
    //visibility check
    let visibility_check =
      packVisibility === "favourite"
        ? `&IsFavourite=true`
        : `&ContentPackVisibility=${packVisibility}`;
    // language check
    let language_check =
      languageCheckedItems.length > 0
        ? "&LanguageIds=" + languageCheckedItems
        : [];
    // creator check
    let creator_check = arr.length > 0 ? "&CreatorIds=" + arr : [];
    //call api
    searchUrl = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&OrderBy=${sortValue}&${visibility_check}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=1&PageSize=10`;
    setPageNumberForSearch(1);
    let searchUrlReporting = `?SearchKey=${searchKey.replace(
      "#",
      "%23"
    )}&AmountOfQuesitons=${questionFilter}&OrderBy=${sortValue}${language_check}${creator_check}&PageNumber=1&PageSize=10`;
    if (packVisibility == "reporting") {
      listapiReporting(searchUrlReporting);
    } else {
      listapi(searchUrl);
    }
  };

  const LanguageAnyClickedfun = (e) => {
    if (!languageAny) {
      setLanguageAny(!languageAny);
      setLanguageAnyClicked(true);
    }
  };
  const CreatorAnyClickedfun = (e) => {
    if (!creatorAny) {
      setCreatorAny(!creatorAny);
      setCreatorAnyClicked(true);
    }
  };

  const changeSearchKey = (e) => {
    setInitialSearchKey(e.target.value);
  };
  const searchContentPack = () => {
    setSearchKey(initialSearchKey);
    let searchUrl;
    //visibility check
    let visibility_check =
      packVisibility === "favourite"
        ? `&IsFavourite=true`
        : `&ContentPackVisibility=${packVisibility}`;
    // language check
    let language_check =
      languageCheckedItems.length > 0
        ? "&LanguageIds=" + languageCheckedItems
        : [];
    // creator check
    let creator_check =
      creatorCheckedItems.length > 0
        ? "&CreatorIds=" + creatorCheckedItems
        : [];
    //call api
    setPageNumberForSearch(1);
    searchUrl = `?SearchKey=${initialSearchKey.replace(
      "#",
      "%23"
    )}&OrderBy=${sortValue}&${visibility_check}${language_check}&AmountOfQuesitons=${questionFilter}${creator_check}&PageNumber=1&PageSize=10`;

    let searchUrlReporting = `?SearchKey=${initialSearchKey.replace(
      "#",
      "%23"
    )}&AmountOfQuesitons=${questionFilter}&OrderBy=${sortValue}${language_check}${creator_check}&PageNumber=1&PageSize=10`;
    if (packVisibility == "reporting") {
      listapiReporting(searchUrlReporting);
    } else {
      listapi(searchUrl);
    }
  };
  const onEnter = (e) => {
    if (e.code === "Enter") {
      searchContentPack();
    }
  };
  const retainFilters = () => {
    let obj = {
      search_Key: searchKey,
      pack_Visibility: packVisibility,
      question_Filter: questionFilter,
      creator_CheckedItems: creatorCheckedItems,
      language_CheckedItems: languageCheckedItems,
      sort_Value: sortValue,
    };
    localStorage.setItem("content-filters", JSON.stringify(obj));
  };
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="my-section d-flex justify-content-between sidebar-for-mobile">
        <div className="my-container px-5">
          <div className="myDiv" id="detail">
            <div className="content">
              <h1
                className="mb-4"
                style={{ fontWeight: "bold", fontSize: "34px" }}
              >
                Content Overview
              </h1>
              <LoadingOverlay
                spinner={<HashLoader color="#5ba6b7" size={90} />}
                active={overviewLoader}
              >
                {/* top searchbar filter */}
                <div className="mb-4 d-flex flex-wrap align-items-center">
                  <div className="flex-grow-1 col-lg-4">
                    <div
                      className="search-input"
                      style={{ width: "90%", height: "43px" }}
                    >
                      <input
                        type="text"
                        style={{ width: "90%", height: "43px" }}
                        placeholder="Search by name or tag..."
                        onKeyDown={(e) => onEnter(e)}
                        value={initialSearchKey}
                        className="cms"
                        onChange={(e) => changeSearchKey(e)}
                      />
                      <FontAwesomeIcon
                        onClick={searchContentPack}
                        icon={faSearch}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div className="flex-lg-grow-1 col-lg-5">
                    <div className="d-flex flex-row align-items-center">
                      <div
                        onClick={() => sorting()}
                        className="h-100 cursor-pointer d-flex align-items-center me-2 mt-1 ms-md-2"
                      >
                        <img src={sort_icon} height={12} width={12} />
                      </div>
                      <span
                        className="cursor-pointer"
                        onClick={() => sorting()}
                      >
                        Sort by &nbsp;
                      </span>{" "}
                      <p
                        className="mb-0 cursor-pointer"
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                        onClick={() => sorting()}
                      >
                        {contentSortingValues[index].name}
                        {", "}
                        {contentSortingValues[index].order}
                      </p>
                    </div>
                  </div>
                  <div className="text-lg-start flex-lg-grow-1 col-lg-3">
                    {languagesArray.length !== 0 && (
                      <CreateContent
                        callingToaster={callingToaster}
                        languages={languagesArray}
                        recallListApi={recallListApi}
                      />
                    )}
                  </div>
                </div>
                <Row>
                  {/* access spcifiers */}
                  <Col lg={9}>
                    <Tab.Container
                      id="left-tabs-example"
                      activeKey={packVisibility}
                    >
                      <Row>
                        <Col sm={2}>
                          <Nav variant="pills" className="flex-column">
                            {JSON.parse(
                              localStorage.getItem("staySafeUserData")
                            ).role !== "KA" && (
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="public"
                                  // onClick={(e) => tabClick(e)}
                                  onClick={(e) => visibility("public")}
                                >
                                  Public library
                                </Nav.Link>
                              </Nav.Item>
                            )}
                            <Nav.Item>
                              <Nav.Link
                                eventKey="private"
                                // onClick={(e) => tabClick(e)}
                                onClick={(e) => visibility("private")}
                              >
                                Private library
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="favourite"
                                // onClick={(e) => tabClick(e)}
                                onClick={(e) => visibility("favourite")}
                              >
                                Favourites
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="reporting"
                                // onClick={(e) => tabClick(e)}
                                onClick={(e) =>
                                  visibilityReporting("reporting")
                                }
                              >
                                Reporting
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          {packVisibility === "public" && (
                            <p className="tabs-bottom-text mt-4 public">
                              The public library is a compendium of content,
                              where everyone can contribute.
                            </p>
                          )}
                          {packVisibility === "private" && (
                            <p className="tabs-bottom-text mt-4 private">
                              The private library is your playground for testing
                              and prototyping new content.
                              <br />
                              <br />
                              Content that is in the private library is not
                              connected to reward system.
                            </p>
                          )}
                          {packVisibility === "favourite" && (
                            <p className="tabs-bottom-text mt-4 fav">
                              These are your favorites. They allow you to curate
                              the content pieces that you find most valuable.
                              <br />
                              <br />
                              This list also represents what you can choose from
                              when you assign content to a contractor.
                            </p>
                          )}
                          {packVisibility === "reporting" && (
                            <p className="tabs-bottom-text mt-4 fav">
                              The reporting-library is a dynamic compedium of
                              content, where you can only see questions that got
                              reported by users.
                            </p>
                          )}
                        </Col>
                        <Col sm={10}>
                          <Tab.Content>
                            <Tab.Pane eventKey="public">
                              {languagesArray.length !== 0 &&
                                !overviewLoader &&
                                (listing.length ? (
                                  <OverviewCard
                                    retainFilters={retainFilters}
                                    callingToaster={callingToaster}
                                    deleteApi={deleteApi}
                                    access_specifier="public"
                                    listing={listing}
                                    markedFavourite={markedFavourite}
                                    languages={languagesArray}
                                    copyCategory={copyCategory}
                                    recallListApi={recallListApi}
                                  />
                                ) : (
                                  <p
                                    className="text-center"
                                    style={{ fontSize: "20px" }}
                                  >
                                    No data to show
                                  </p>
                                ))}
                              {listing.length > 0 && (
                                <ReactPaginate
                                  id="pppp"
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"..."}
                                  breakClassName={"break-me"}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={nextPageApiCall}
                                  containerClassName={"pagination"}
                                  activeClassName={"active"}
                                  activeLinkClassName={"active-link"}
                                  forcePage={pageNumberForSearch - 1}
                                />
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="private">
                              {languagesArray.length !== 0 &&
                                !overviewLoader &&
                                (listing.length ? (
                                  <OverviewCard
                                    retainFilters={retainFilters}
                                    callingToaster={callingToaster}
                                    updateVisibility={updateVisibility}
                                    deleteApi={deleteApi}
                                    access_specifier="private"
                                    listing={listing}
                                    markedFavourite={markedFavourite}
                                    languages={languagesArray}
                                    copyCategory={copyCategory}
                                    recallListApi={recallListApi}
                                  />
                                ) : (
                                  <p
                                    className="text-center"
                                    style={{ fontSize: "20px" }}
                                  >
                                    No data to show
                                  </p>
                                ))}
                              {listing.length > 0 && (
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"..."}
                                  breakClassName={"break-me"}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={nextPageApiCall}
                                  containerClassName={"pagination"}
                                  activeClassName={"active"}
                                  activeLinkClassName={"active-link"}
                                  forcePage={pageNumberForSearch - 1}
                                />
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="favourite">
                              {languagesArray.length !== 0 &&
                                !overviewLoader &&
                                (listing.length ? (
                                  <OverviewCard
                                    retainFilters={retainFilters}
                                    callingToaster={callingToaster}
                                    deleteApi={deleteApi}
                                    updateVisibility={updateVisibility}
                                    access_specifier="favourite"
                                    copyCategory={copyCategory}
                                    listing={listing}
                                    markedFavourite={markedFavourite}
                                    languages={languagesArray}
                                    recallListApi={recallListApi}
                                  />
                                ) : (
                                  <p
                                    className="text-center"
                                    style={{ fontSize: "20px" }}
                                  >
                                    No data to show
                                  </p>
                                ))}
                              {listing.length > 0 && (
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"..."}
                                  breakClassName={"break-me"}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={nextPageApiCall}
                                  containerClassName={"pagination"}
                                  activeClassName={"active"}
                                  activeLinkClassName={"active-link"}
                                  forcePage={pageNumberForSearch - 1}
                                />
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="reporting">
                              {languagesArray.length !== 0 &&
                                !overviewLoader &&
                                (listing.length ? (
                                  <OverviewCard
                                    retainFilters={retainFilters}
                                    callingToaster={callingToaster}
                                    deleteApi={deleteApi}
                                    updateVisibility={updateVisibility}
                                    access_specifier="reporting"
                                    copyCategory={copyCategory}
                                    listing={listing}
                                    markedFavourite={markedFavourite}
                                    languages={languagesArray}
                                    recallListApi={recallListApi}
                                  />
                                ) : (
                                  <p
                                    className="text-center"
                                    style={{ fontSize: "20px" }}
                                  >
                                    No data to show
                                  </p>
                                ))}
                              {listing.length > 0 && (
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"..."}
                                  breakClassName={"break-me"}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={nextPageApiCall}
                                  containerClassName={"pagination"}
                                  activeClassName={"active"}
                                  activeLinkClassName={"active-link"}
                                  forcePage={pageNumberForSearch - 1}
                                />
                              )}
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Col>

                  {/* content filters  */}
                  <Col lg={3} className="p-0">
                    <div className="">
                      <h6 className="filters-heading">Amount of Questions</h6>
                    </div>
                    <div className="customized-radio-btn">
                      <div className="radios">
                        <div className="radios">
                          <div className="d-flex flex-row align-items-center">
                            <input
                              type="radio"
                              name="qsn"
                              value={0}
                              id="qsn"
                              checked={questionFilter == 0}
                              onChange={(e) => handleQuestions(e)}
                            />
                            <label className="radio" htmlFor="qsn"></label>
                            <span className="any">Any</span>
                          </div>
                          {amount_of_question?.map((item, ind) => {
                            return (
                              <React.Fragment key={ind}>
                                <div className="d-flex flex-row align-items-center">
                                  <input
                                    type="radio"
                                    name="qsn"
                                    value={item.value}
                                    checked={questionFilter == item.value}
                                    id={item.label}
                                    onChange={(e) => handleQuestions(e)}
                                  />
                                  <label
                                    className="radio"
                                    htmlFor={item.label}
                                  ></label>
                                  <span className="any">{item.label}</span>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {JSON.parse(localStorage.getItem("staySafeUserData"))
                      .role !== "KA" && (
                      <>
                        <div className="">
                          <h6 className="filters-heading mt-3">Creator</h6>
                        </div>

                        <div className="content-checkbox checkbox-radio">
                          <div className="reward-checkbox styled-input-single d-inline">
                            <input
                              type="checkbox"
                              name="all"
                              id="all"
                              checked={creatorAny}
                              onChange={CreatorAnyClickedfun}
                            />
                            <label htmlFor={"all"} />
                            Any
                          </div>
                        </div>
                        <div className="content-checkbox">
                          {creatorsArray?.map((item, ind) => {
                            return (
                              <div
                                className="reward-checkbox styled-input-single"
                                key={ind}
                                // onClick={() => handleCreators(item)}
                              >
                                <input
                                  type="checkbox"
                                  id={item.clientId + "creators-content"}
                                  className="cursor-pointer"
                                  name={item.clientId}
                                  style={{
                                    top: "7px",
                                    left: "2px",
                                    zIndex: "1",
                                    visibility: "visible",
                                    opacity: "0",
                                  }}
                                  onChange={() => handleCreators(item)}
                                />
                                <label htmlFor={item.name} />
                                {item.name}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                    <div className="">
                      <h6 className="filters-heading mt-3">Languages</h6>
                    </div>

                    <div className="content-checkbox checkbox-radio">
                      <div className="reward-checkbox styled-input-single d-inline">
                        <input
                          type="checkbox"
                          name="anyLan"
                          id="anyLan"
                          checked={languageAny}
                          onChange={LanguageAnyClickedfun}
                        />
                        <label htmlFor={"anyLan"} />
                        Any
                      </div>
                    </div>
                    <div className="content-checkbox">
                      {languagesArray?.map((item, ind) => {
                        return (
                          <div
                            className="reward-checkbox styled-input-single"
                            key={ind}
                          >
                            <input
                              style={{
                                top: "7px",
                                left: "2px",
                                zIndex: "1",
                                visibility: "visible",
                                opacity: "0",
                              }}
                              className="cursor-pointer"
                              type="checkbox"
                              name={item.id + "content-language"}
                              id={item.id + "content-language"}
                              onChange={() => handleLanguages(item)}
                            />
                            <label htmlFor={item.title} />
                            {item?.title}
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
