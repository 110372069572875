import React, { Component } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import brandIconGrey from "../../../../../assets/icons/brandHolder.jpg";
import sortIcon from "../../../../../assets/icons/sort-icon.png";
import ScrollBar from "react-scrollbar";
import DeleteContractor from "../deleteContractor/DeleteContractor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./ContractorDetail.css";
import { withRouter } from "react-router-dom";
import toast from "react-hot-toast";
import {
  fetchContractorCompanyDetail,
  fetchContractorEndUsers,
} from "../../../../services/ContractorServices";
import DeleteEndUserModal from "./DeleteEndUserModal";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import { BASE_URL_IMAGE } from "../../../../constant/ApiUrls";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import RevokeEndUserModal from "./RevokeEndUserModal";
import ChangeEndUserRole from "./ChangeEndUserRole";
class ContractorDetails extends Component {
  state = {
    deleteContractorModal: false,
    endUser: [],
    detail: "",
    screenLoader: false,
    endUserCopy: [],
    searchKey: "",
  };
  fetchClientData = async () => {
    let id = this.props?.clientId;

    this.setState({
      screenLoader: true,
    });
    let detail = await fetchContractorCompanyDetail(id);
    if (detail?.status === 200) {
      this.setState({
        detail: detail?.data?.data,
        screenLoader: false,
      });
    } else {
      this.setState({
        detail: {},
        screenLoader: false,
      });
      toast.error("Error while fetching data!");
    }
    this.fetchContractorEndUserList();
  };
  componentDidMount = async () => {
    this.setState({
      screenLoader: true,
    });
    await this.fetchClientData();
  };
  componentDidUpdate = async () => {
    let check = localStorage.getItem("showCard");
    if (check == "true") {
      localStorage.removeItem("showCard");
      await this.fetchClientData();
    }
  };
  fetchContractorEndUserList = async () => {
    let endUser = await fetchContractorEndUsers();
    if (endUser?.status === 200) {
      this.setState({
        endUser: endUser?.data?.data,
        screenLoader: false,
        endUserCopy: endUser?.data?.data,
      });
    } else {
      toast.error("Error while fetching data!");
    }
  };
  closeDeleteContractorModal = () => {
    this.setState({
      deleteContractorModal: false,
    });
  };
  search = (e) => {
    const { endUser, endUserCopy } = this.state;
    let search = e.target.value;
    this.setState({
      searchKey: search,
    });
    if (e.target.value.length === 0) {
      this.setState({
        endUser: endUserCopy,
      });
    } else {
      let result = endUser.filter((user) =>
        user.name.toLowerCase().includes(search.toLowerCase())
      );
      this.setState({
        endUser: result,
      });
    }
  };
  clearSearchField = () => {
    const { endUserCopy } = this.state;
    this.setState({
      endUser: endUserCopy,
      searchKey: "",
    });
  };
  renderTooltip = (detail) => (
    <Tooltip id="button-tooltip" className="contractor-tooltip">
      Licenses used by Contractors: {detail.noOfLicensesUsedByContractor}{" "}
      End-Users: {detail.noOfLicensesUsedByEndUsers}
    </Tooltip>
  );
  renderTooltipCategories = (detail) => (
    <Tooltip
      id="button-tooltip"
      // {...active,inactive}
      className="contractor-tooltip"
    >
      Inactive Categories: {detail?.inactiveCategoriesCount}
      <br />
      Active Categories: {detail?.activeCategoriesCount}
    </Tooltip>
  );

  defineLengthOption = (value) => {
    if (value) {
      let dots = value.length > 10 ? "..." : "";
      return value.slice(0, 10) + dots;
    }
  };
  render() {
    const { detail, endUser, screenLoader, searchKey } = this.state;
    return (
      <>
        <LoadingOverlay
          spinner={<HashLoader color="#5ba6b7" size={90} />}
          active={screenLoader}
        >
          <div className="container1 manageLeft mb-5 item-details-section">
            {detail?.isHold && (
              <div className="disabled-card">
                <label> On Hold</label>
              </div>
            )}
            {/* <SimpleBar style={{ maxHeight: 270 }}> */}

            <Row className="h-100 tablet-gapBox">
              <Col lg={2} sm={12}>
                <div className="item-card">
                  <div className="">
                    <img
                      width="160"
                      heigth="100"
                      src={
                        detail.companyIcon
                          ? BASE_URL_IMAGE + detail.companyIcon
                          : brandIconGrey
                      }
                      alt="brand-icon"
                    />
                  </div>
                  <p className="item-name">{detail?.companyName}</p>
                  <p>{detail?.contactPerson}</p>
                  <p>{detail?.noOfLicenses} Licenses</p>
                </div>
              </Col>
              <Col lg={2} sm={12}>
                <div className="item-count">
                  <p className="m-0">Licenses</p>
                  <p className="qty">
                    <span
                      style={{
                        textDecoration:
                          detail?.isActive == true && "line-through",
                        color:
                          (detail?.isActive == true ||
                            detail?.isHold == true) &&
                          "red",
                      }}
                    >
                      {detail?.noOfLicenses}
                    </span>{" "}
                    {detail?.isActive == true && (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Licenses have been reduced!</Tooltip>}
                      >
                        <span
                          style={{
                            color: "#34636d",
                            cursor: "pointer",
                          }}
                        >
                          {detail.requestedLicencesCount}
                        </span>
                      </OverlayTrigger>
                    )}
                  </p>
                  <p>Licenses Used</p>
                  <p className="qty contractor-tooltip">
                    {detail?.noOfLicensesUsed}{" "}
                    <OverlayTrigger
                      placement="top"
                      // delay={{ show: 250, hide: 400 }}
                      overlay={this.renderTooltip(detail)}
                      style={{
                        backgroundColor: "#34636d",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          cursor: "pointer",
                          margin: "4px",
                          fontSize: "30px",
                          color: " rgb(52, 99, 109)",
                        }}
                        icon={faInfoCircle}
                      />
                    </OverlayTrigger>
                  </p>
                </div>
              </Col>
              <Col lg={3} sm={12}>
                <div className="categories">
                  <p>Categories</p>
                  <ScrollBar
                    style={{ maxHeight: 400 }}
                    className="my-scrollbar"
                    contentClassName="my-scrollbar-content"
                    speed={0.6}
                  >
                    {detail?.categories?.map((item, index) => (
                      <div className="category d-flex" key={index}>
                        <img src={sortIcon} alt="brand-icon" />
                        <p className="name">{item.categoryName}</p>
                        <p
                          style={{
                            marginLeft: "auto",
                            marginRight: "0.9rem",
                            //visibility: "hidden",
                          }}
                        >
                          <strong
                            style={{ fontSize: "15px", color: "#f8dc80" }}
                          >
                            {item?.averageRating > 0
                              ? Math.round(item?.averageRating)
                              : 0}
                          </strong>
                          /110
                        </p>
                      </div>
                    ))}
                  </ScrollBar>
                </div>
              </Col>
              <Col lg={3} sm={12}>
                <div className="categories contractor-col w-100">
                  <p>Users</p>
                  <div className="search-input">
                    <input
                      type="text"
                      className="w-75 mt-3 mb-4"
                      placeholder="Search User"
                      onChange={this.search}
                      value={searchKey}
                    />
                    <FontAwesomeIcon
                      onClick={this.clearSearchField}
                      icon={searchKey.length === 0 ? faSearch : faTimes}
                    />
                  </div>
                  <ScrollBar
                    style={{ maxHeight: 250 }}
                    className="my-scrollbar"
                    contentClassName="my-scrollbar-content"
                    speed={0.6}
                  >
                    {endUser?.map((item, index) => (
                      <div className="category d-flex" key={index}>
                        <div style={{ margin: "auto 0" }}>
                          <img src={sortIcon} alt="brand-icon" />
                        </div>
                        <p className="name updateName" title={item?.name}>
                          {/* {item?.name} */}
                          {this.defineLengthOption(item?.name)}
                        </p>
                        <p
                          style={{
                            marginLeft: "auto",
                            // visibility: "hidden"
                          }}
                        >
                          <strong
                            style={{ fontSize: "15px", color: "#93d478" }}
                          >
                            {item?.averageRating > 0
                              ? Math.round(
                                  (item?.averageRating + Number.EPSILON) * 100
                                ) / 100
                              : 0}
                          </strong>
                          /110
                        </p>
                        <DeleteEndUserModal
                          userName={item.name}
                          userId={item.userId}
                          endUserList={this.fetchContractorEndUserList}
                        />
                        {/*<p style={{paddingTop:"4px"}}>{" / "}</p>*/}
                        <RevokeEndUserModal
                          userName={item.name}
                          userId={item.userId}
                          email={item?.email}
                          endUserList={this.fetchContractorEndUserList}
                        />
                        <ChangeEndUserRole
                          userName={item.name}
                          userId={item.userId}
                          email={item?.email}
                          isHold={detail?.isHold}
                          refresh={this.fetchClientData}
                          endUserList={this.fetchContractorEndUserList}
                        />
                      </div>
                    ))}
                  </ScrollBar>
                </div>
              </Col>
              <Col lg={2} sm={12}>
                <div className="code-detail d-flex flex-column justify-content-between">
                  <div className="mt-3">
                    <p
                      className="screen-input w-100"
                      style={{
                        fontSize: "2.3rem",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: `${
                          detail?.isHold ? "red" : " rgb(52, 99, 109)"
                        }`,
                      }}
                    >
                      {detail?.code}
                    </p>

                    <p className="mt-3" style={{ fontSize: "10px" }}>
                      This code allows users to log into <b>staysafe</b>. Users
                      with this code will utilize the license provided by{" "}
                      <b>{detail?.companyName}</b> and will have access to the
                      content packages.
                      <br />
                      <br />A user can be assigned to multiple licenses.
                    </p>
                  </div>
                  <div className="d-flex flex-row">
                    <OverlayTrigger
                      placement="top"
                      // delay={{ show: 250, hide: 400 }}
                      overlay={this.renderTooltipCategories(detail)}
                    >
                      <FontAwesomeIcon
                        style={{
                          cursor: "pointer",
                          margin: "4px 10px 4px 2px",
                          fontSize: "25px",
                          color: " rgb(52, 99, 109)",
                        }}
                        icon={faInfoCircle}
                      />
                    </OverlayTrigger>
                    <div
                      className="my-progress-circle"
                      style={
                        {
                          //  marginLeft: "7rem"
                          //visibility: "hidden",
                        }
                      }
                    >
                      <CircularProgressbarWithChildren
                        value={
                          detail?.averageRating > 0
                            ? Math.round(detail?.averageRating)
                            : detail?.averageRating
                        }
                      >
                        <div style={{ fontSize: 10 }}>
                          <strong
                            style={{ fontSize: "18px", fontWeight: "900" }}
                          >
                            {detail?.averageRating > 0
                              ? Math.round(
                                  (detail?.averageRating + Number.EPSILON) * 100
                                ) / 100
                              : detail?.averageRating}
                          </strong>
                          <br />
                          <p
                            className="m-0 text-center"
                            style={{ marginTop: "-6px !important" }}
                          >
                            /110
                          </p>
                        </div>
                      </CircularProgressbarWithChildren>
                      <h5 className="mt-2" style={{ marginLeft: "1.4rem" }}>
                        Avg. Rating
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <DeleteContractor
              deleteContractor={this.state.deleteContractorModal}
              closeDeleteContractorModal={this.closeDeleteContractorModal}
            />
            {/* </SimpleBar> */}
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
export default withRouter(ContractorDetails);
