export const amount_of_question = [
  {
    value: "10",
    label: "10+",
  },
  {
    value: "20",
    label: "20+",
  },
  {
    value: "100",
    label: "100+",
  },
];
export const tags = [
  {
    value: "#heights",
    label: "#heights",
  },
  {
    value: "#PSA",
    label: "#PSA",
  },
  {
    value: "#fallinghazard",
    label: "#fallinghazard",
  },
  {
    value: "#scafoldings",
    label: "#scafoldings",
  },
];
export const creator = [
  {
    name: "Staysafe",
    value: "Staysafe",
  },
  {
    name: "BASF",
    value: "BASF",
  },
  {
    name: "Shell",
    value: "Shell",
  },
];
export const contractor = [
  {
    name: "c1",
    value: "c1",
  },
  {
    name: "c2",
    value: "c2",
  },
  {
    name: "c3",
    value: "c3",
  },
];
export const languages = [
  {
    name: "German",
  },
  {
    name: "English",
  },
  {
    name: "French",
  },
  {
    name: "Czech",
  },
  {
    name: "Polish",
  },
  {
    name: "Russia",
  },
  {
    name: "Spanish",
  },
  {
    name: "Italian",
  },
];
export const questionArray = [
  {
    typeId: 0,
    matrixSize: "",
    question: "",
    answerTime: 45,
    categoryId: 14,
    languageId: 1,
    image: "safasdf",
    options: [
      {
        option: "",
        correctStatement: "",
        isCorrect: false,
      },
    ],
  },
];
export const questionTranslation = (default_language) => {
  return [
    {
      languageId: default_language,
      questionTitle: "",
    },
  ];
};
export const singleQuestionOption = (default_language) => {
  return [
    {
      isCorrect: false,
      optionId: 0,
      optionTitles: [
        {
          languageId: default_language,
          optionTitle: "",
        },
      ],
    },
    {
      isCorrect: false,
      optionId: 0,
      optionTitles: [
        {
          languageId: default_language,
          optionTitle: "",
        },
      ],
    },
    {
      isCorrect: false,
      optionId: 0,
      optionTitles: [
        {
          languageId: default_language,
          optionTitle: "",
        },
      ],
    },
  ];
};
export const multipleQuestionOption = (default_language) => {
  return [
    {
      isCorrect: false,
      optionId: 0,
      optionTitles: [
        {
          languageId: default_language,
          optionTitle: "",
        },
      ],
    },
    {
      isCorrect: false,
      optionId: 0,
      optionTitles: [
        {
          languageId: default_language,
          optionTitle: "",
        },
      ],
    },
    {
      isCorrect: false,
      optionId: 0,
      optionTitles: [
        {
          languageId: default_language,
          optionTitle: "",
        },
      ],
    },
    {
      isCorrect: false,
      optionId: 0,
      optionTitles: [
        {
          languageId: default_language,
          optionTitle: "",
        },
      ],
    },
  ];
};
export const findFlawQuestionOption = (default_language) => {
  return [
    {
      isCorrect: true,
      optionId: 0,
      optionTitles: [
        {
          languageId: default_language,
          optionTitle: "",
        },
      ],
    },
  ];
};
export const trueFalseQuestionOption = (default_language) => {
  return [
    {
      isCorrect: false,
      optionId: 0,
      optionTitles: [
        {
          languageId: default_language,
          optionTitle: "",
        },
      ],
    },
    {
      isCorrect: false,
      optionTitles: [
        {
          languageId: default_language,
          optionTitle: "",
        },
      ],
    },
  ];
};
export const contentSortingValues = [
  {
    name: "Created At",
    value: "CreatedAtDESC",
    order: "descending",
  },
  {
    name: "Created At",
    value: "CreatedAtASC",
    order: "ascending",
  },
  {
    name: "Content Pack Name",
    value: "NameDESC",
    order: "descending",
  },
  {
    name: "Content Pack Name",
    value: "NameASC",
    order: "ascending",
  },
];
export const contentPackSorting = [{ name: "Name", type: false }];
export const contractorSorting = [
  { name: "CreatedAt", value: "CreatedAtASC", order: "ascending" },
  { name: "CreatedAt", value: "CreatedAtDESC", order: "descending" },
  { name: "Name", value: "ContractorNameASC", order: "ascending" },
  { name: "Name", value: "ContractorNameDESC", order: "descending" },
];
export const questionTypesData = [
  { name: "SC", percentage: 60 },
  { name: "MC", percentage: 40 },
  { name: "FtF", percentage: 30 },
  { name: "TF", percentage: 30 },
];
export const selectedLanguages = [
  { name: "german", id: 3 },
  { name: "English", id: 4 },
];
