import React from "react";
import Switch from "react-switch";
const ReactSwitch = (props) => {
  return (
    <>
      <Switch
        onChange={props.handleChange}
        checked={props.value}
        className="react-switch"
        checkedIcon={false}
        uncheckedIcon={false}
        offColor="#fff"
        onColor="#34636d"
        offHandleColor="#34636d"
        onHandleColor="#2c8ca3"
        value={props.value}
      />
    </>
  );
};

export default ReactSwitch;
