import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from "react-spinners";
import sort_icon from "../../../../../../assets/icons/sort_desc.png";
import moment from "moment";
import {
  assignContractorToCategory,
  categoryContractors,
} from "../../../../../services/ClientServices";
import { getKeyTranslation } from "../../../../../utils/Translations";
import { contractorSorting } from "../../contentData";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AssignContentToContractor = ({ categoryId, detail, callingToaster }) => {
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectAllClicked, setSelectAllClicked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [contractorsArray, setContractorsArray] = useState([]);
  const [contractorsInitialArray, setContractorsInitialArray] = useState([]);
  const [sortValue, setSortValue] = useState(contractorSorting[0]?.value);
  const [index, setIndex] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [enteredSearchKey, setEnteredSearchKey] = useState("");
  const [loader, setLoader] = useState(false);
  const [sameCheckedIds, setSameCheckedIds] = useState(false);

  useEffect(() => {
    if (selectAllClicked) {
      setSameCheckedIds(true);
      let chk_item = [];
      if (selectAll) {
        contractorsArray.forEach((item1, ind) => {
          let exist = false;
          checkedItems?.forEach((item2, ind2) => {
            if (item1.contractorId == item2.contractorId) {
              exist = true;
            }
          });
          if (!exist) {
            chk_item.push(item1);
          }
        });
        let arr1 = [...checkedItems];
        let arr_to_define = [...arr1.concat(chk_item)];
        setCheckedItems([...arr_to_define]);
        contractorsArray?.forEach((item) => {
          let ele = document.getElementById(item.contractorId + "contractor");
          ele.checked = true;
        });
        compare_already_assigned_contractors([...arr_to_define]);
        setSelectAll(true);
      } else {
        chk_item = [...checkedItems];
        let index_to_remove = [];
        contractorsArray?.forEach((item1, ind) => {
          checkedItems?.forEach((item2, ind2) => {
            if (item1.contractorId == item2.contractorId) {
              index_to_remove.push(ind2);
              checkedItems.splice(ind2, 1);
            }
          });
        });
        index_to_remove.forEach((rem_ind) => {
          chk_item.splice(rem_ind, 1);
        });

        setCheckedItems([...chk_item]);
        contractorsArray?.forEach((item) => {
          let ele = document.getElementById(item.contractorId + "contractor");
          ele.checked = false;
        });
        compare_already_assigned_contractors([...chk_item]);
      }
      setSelectAllClicked(false);
    }
  }, [selectAll]);

  const sorting = () => {
    if (index < contractorSorting.length - 1) {
      setIndex(index + 1);
      setSortValue(contractorSorting[index + 1]?.name);
      let params = `?CategoryId=${categoryId}&SearchKey=${enteredSearchKey}&OrderBy=${
        contractorSorting[index + 1]?.value
      }`;
      getCategoriesContractorsApi(params, false, "sorting");
    } else {
      setIndex(0);
      setSortValue(contractorSorting[0]?.name);
      let params = `?CategoryId=${categoryId}&SearchKey=${enteredSearchKey}&OrderBy=${contractorSorting[0]?.value}`;
      getCategoriesContractorsApi(params, false, "sorting");
    }
  };

  const getCategoriesContractorsApi = async (
    params,
    initial_render,
    sorting_val
  ) => {
    setLoader(true);
    let result = await categoryContractors(params);
    let chk = [];
    setContractorsArray(result.data?.data?.items);

    setShowAssignModal(true);
    if (result?.status === 200) {
      setLoader(false);
      if (initial_render) {
        setContractorsInitialArray(result.data?.data?.items);
        result.data?.data?.items.map((i) => {
          if (i.assigned == true) {
            chk.push(i);
          }
        });
        setCheckedItems(chk);

        setLoader(true);

        chk.forEach((i) => {
          let ele = document.getElementById(i.contractorId + "contractor");
          ele.checked = true;
        });
        if (result.data?.data?.items.length == chk.length) {
          setSelectAll(true);
        }
        setLoader(false);
      } else {
        setLoader(true);
        result.data?.data?.items.map((i) => {
          if (document.getElementById(i.contractorId + "contractor")) {
            let ele = document.getElementById(i.contractorId + "contractor");
            ele.checked = false;
          }
        });
        checkedItems?.forEach((i) => {
          if (document.getElementById(i.contractorId + "contractor")) {
            let ele = document.getElementById(i.contractorId + "contractor");
            ele.checked = true;
          }
        });

        let flag_equal = true;
        if (contractorsInitialArray.length >= checkedItems.length) {
          result.data?.data?.items.map((i1) => {
            let exist = false;
            checkedItems.forEach((i2) => {
              if (i1.contractorId == i2.contractorId) {
                exist = true;
              }
            });
            if (!exist) {
              flag_equal = false;
            }
          });
        } else {
          flag_equal = false;
        }
        if (flag_equal) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const getCategories = (initial_render) => {
    let params = `?CategoryId=${categoryId}&SearchKey=${enteredSearchKey}&OrderBy=${sortValue}`;
    getCategoriesContractorsApi(params, initial_render);
  };
  const searchContentPack = () => {
    setEnteredSearchKey(searchKey);
    let params = `?CategoryId=${categoryId}&SearchKey=${searchKey}&OrderBy=${sortValue}`;
    getCategoriesContractorsApi(params, false);
  };
  const changeSearchKey = (e) => {
    setSearchKey(e.target.value);
  };
  const onEnter = (e) => {
    if (e.code === "Enter") {
      searchContentPack();
    }
    if (e.keyCode === 32) {
      e.stopPropagation();
    }
  };

  const compare_already_assigned_contractors = (checkbox_id_arr) => {
    let checked_ids = checkbox_id_arr.map((item) => {
      return item.contractorId;
    });
    let exist = false;
    let already_initialised_ids = [];
    contractorsInitialArray.forEach((item) => {
      if (item.assigned) {
        already_initialised_ids.push(item.contractorId);
      }
    });
    if (checked_ids.length == already_initialised_ids.length) {
      checked_ids.forEach((id) => {
        exist = false;
        already_initialised_ids.forEach((id_2) => {
          if (id == id_2) {
            exist = true;
          }
        });
      });
      if (already_initialised_ids.length == 0) {
        exist = true;
      }
      if (exist) {
        setSameCheckedIds(false);
      } else {
        setSameCheckedIds(true);
      }
    }
  };

  const handleCheckbox = (ele_id, item, e) => {
    setSameCheckedIds(true);
    e.preventDefault();
    let ele = document.getElementById(ele_id);
    if (ele.checked) {
      ele.checked = false;
    } else {
      ele.checked = true;
    }
    let existsIndex = false;
    let arr = checkedItems?.slice();
    arr?.forEach((el) => {
      if (el?.contractorId == item.contractorId) {
        existsIndex = true;
        arr.splice(
          arr.findIndex((v) => v.contractorId === item.contractorId),
          1
        );
        setCheckedItems(arr);
      }
    });
    if (!existsIndex) {
      arr.push(item);
      setCheckedItems(arr);
    }
    let flag_equal = true;
    if (contractorsArray.length == arr.length) {
      contractorsArray.forEach((i1) => {
        let exist = false;
        arr.forEach((ele2) => {
          if (i1.contractorId == ele2.contractorId) {
            exist = true;
          }
        });
        if (!exist) {
          flag_equal = false;
        }
      });
    } else {
      flag_equal = false;
    }
    if (flag_equal) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    compare_already_assigned_contractors(arr);
  };

  const SelectAllClickedfun = (e) => {
    setSelectAll(!selectAll);
    setSelectAllClicked(true);
  };

  const saveChanges = async () => {
    let ids = checkedItems.map((item) => {
      return item.contractorId;
    });
    let obj = {
      categoryId: categoryId,
      contractorIds: ids,
    };
    let result = await assignContractorToCategory(obj);
    if (result?.status === 200) {
      callingToaster(getKeyTranslation(result?.data?.message), "success");

      closeModal();
    } else {
      callingToaster(getKeyTranslation(result?.data?.message), "error");
      closeModal();
    }
  };
  const closeModal = () => {
    setSameCheckedIds(false);
    setSearchKey("");
    setEnteredSearchKey("");
    setCheckedItems([]);
    setSelectAll(false);
    setShowAssignModal(false);
  };
  return (
    <div>
      <>
        {detail == "detail" ? (
          <button
            style={{
              width: "auto",
              padding: "8px",
              minWidth: "217px",
            }}
            className="save d-inline px-4 text-start"
            onClick={() => getCategories(true)}
          >
            Assign to a contractor
          </button>
        ) : (
          <div onClick={() => getCategories(true)}>Assign to...</div>
        )}

        <Modal
          show={showAssignModal}
          centered
          size="lg"
          className="modal-screen updateModal-screen"
        >
          <Modal.Body>
            <div className="modal-header">
              <h4
                className="mb-0 mt-3 me-2 default-color"
                style={{ fontWeight: "normal" }}
              >
                Assign content to...
              </h4>
              <img
                src="/assets/icons/Cross.png"
                onClick={closeModal}
                style={{ cursor: "pointer" }}
              />
            </div>
            <LoadingOverlay
              spinner={<HashLoader color="#5ba6b7" size={90} />}
              active={loader}
            >
              {/* first row */}
              <div className="mt-4 d-flex flex-wrap justify-content-between detail-reward align-items-center">
                <div className="col-lg-4 my-section">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search contractors"
                      onKeyDown={(e) => onEnter(e)}
                      value={searchKey}
                      onChange={(e) => changeSearchKey(e)}
                    />
                    <FontAwesomeIcon
                      onClick={searchContentPack}
                      icon={faSearch}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div
                  className="col-lg-6 d-flex align-items-center justify-content-left search-type"
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex" onClick={sorting}>
                    <div className="h-100 d-flex align-items-center me-2 mt-1 ms-md-2">
                      <img src={sort_icon} height={12} width={12} />
                    </div>
                    <span>Sort by &nbsp;</span>{" "}
                    <p className="mb-0" style={{ fontWeight: "bold" }}>
                      {contractorSorting[index].name},{" "}
                      {contractorSorting[index].order}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <button
                    className="btn-excel w-100 position-relative"
                    style={{
                      width: "100% !important",
                      opacity: sameCheckedIds ? "1" : "0.8",
                    }}
                    onClick={saveChanges}
                    disabled={sameCheckedIds ? false : true}
                  >
                    Done
                  </button>
                </div>
              </div>
              {/* second row */}

              <hr />
              {contractorsArray.length > 0 && (
                <>
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className="content-checkbox checkbox-radio"
                      // onClick={SelectAllClickedfun}
                    >
                      <div className="reward-checkbox styled-input-single d-inline">
                        <input
                          type="checkbox"
                          name="all"
                          id="all"
                          checked={selectAll}
                          onChange={SelectAllClickedfun}
                        />
                        <label
                          htmlFor={"all"}
                          onClick={SelectAllClickedfun}
                          style={{ marginLeft: "-39px" }}
                        />
                        <span
                          htmlFor={"all"}
                          className="default-color"
                          style={{ paddingLeft: "77px", fontWeight: "500" }}
                        >
                          <u>Select all</u>
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              )}
              {/* third row */}
              <div className="d-flex col-lg-6">
                <div className="table-responsive w-100">
                  <table
                    size="sm"
                    className="reward-table reward-detail-table w-100"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th style={{ cursor: "pointer" }}>Date created</th>
                        <th style={{ cursor: "pointer" }}>Name </th>
                      </tr>
                    </thead>
                    <tbody>
                      {contractorsArray?.map((item, ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <div className="content-checkbox">
                                <div className="reward-checkbox styled-input-single">
                                  <input
                                    type="checkbox"
                                    name={item.contractorId + "contractor"}
                                    id={item.contractorId + "contractor"}
                                  />
                                  <label
                                    onClick={(e) =>
                                      handleCheckbox(
                                        item.contractorId + "contractor",
                                        item,
                                        e
                                      )
                                    }
                                    style={{ marginLeft: "-47px" }}
                                    htmlFor={item.contractorId + "contractor"}
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="align-cb-field">
                              {moment(item.createdAt).format("DD.MM.YYYY")}
                            </td>
                            <td className="align-cb-field">
                              {item.contractorName}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div></div>
                <div></div>
              </div>
            </LoadingOverlay>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default AssignContentToContractor;
