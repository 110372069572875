import React, { Component } from "react";
import "./YourPlan.css";
import verifiedIcon from "../../../../../assets/icons/verified-icon.png";
import ChangePlan from "./changePlan/ChangePlan";
import { withRouter } from "react-router-dom";
class CompanyList extends Component {
  state = {
    changePlan: false,
  };
  closeChangePlan = () => {
    this.setState({
      changePlan: false,
    });
  };
  render() {
    const { FinanceDetail } = this.props;
    return (
      <>
        <div className="your-plan">
          <label>Your plan</label>
          <button
            className="change-plan"
            onClick={() =>
              this.setState({
                changePlan: true,
              })
            }
          >
            change plan
          </button>
          <div className="plan-desc">
            <p>
              <b>$ {FinanceDetail?.amountPerMonth}</b> per month
            </p>
            <p>
              <b>{FinanceDetail?.licenses}</b> licenses
            </p>
            <p>
              <b>BI access center </b>
              {FinanceDetail?.isBusinessIntelligence && (
                <img src={verifiedIcon} alt="verified" />
              )}
            </p>
            <p>
              <b>content agreement </b>
              {FinanceDetail?.isContentAgreement && (
                <img src={verifiedIcon} alt="verified" />
              )}
            </p>
          </div>
        </div>
        <ChangePlan
          changePlan={this.state.changePlan}
          closeChangePlan={this.closeChangePlan}
        />
      </>
    );
  }
}
export default withRouter(CompanyList);
