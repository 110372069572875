import { useState, createContext, useContext } from 'react'

const RestrictContext = createContext(null)

export const RestrictProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false)

  const showRestrictModal = () => {
    localStorage.setItem("showRestrictedModal", true);

    setShowModal(true) 
  }

  const unShowRestrictModal = () => {
    setShowModal(false)
    localStorage.setItem("showRestrictedModal", false);
    localStorage.setItem("releaseMessage", "");

  }

  return (
    <RestrictContext.Provider value={{ showModal, showRestrictModal, unShowRestrictModal }}>
      {children}
    </RestrictContext.Provider>
  )
}

export const useRestrictModal = () => {
  return useContext(RestrictContext)
}