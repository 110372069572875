import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { deleteEndUser } from "../../../../services/ContractorServices";
import toast from "react-hot-toast";
class DeleteEndUserModal extends Component {
  state = {
    showModal: false,
    deleteButton: false,
  };
  deleteEndUser = async () => {
    this.setState({
      deleteButton: true,
    });
    let deleteUser = await deleteEndUser(this.props.userId);
    if (deleteUser.status === 200) {
      toast.success("User has been successfully deleted!");
      this.props.endUserList();
      this.setState({
        showModal: false,
        deleteButton: false,
      });
    } else {
      toast.error(`error while deleting User ${this.props.userName}`);
      this.setState({
        showModal: false,
        deleteButton: false,
      });
    }
  };
  render() {
    const { showModal } = this.state;
    return (
      <>
        <FontAwesomeIcon
          onClick={() => {
            this.setState({ showModal: true });
          }}
          icon={faTimes}
          style={{
            marginTop: "0.6rem",
            marginLeft: "0.3rem",
            cursor: "pointer",
          }}
        />
        <Modal centered size="md" show={showModal}>
          <div className="bg-white change-delete-modals">
            <div className="modal-header">
              <h1>Remove User</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ showModal: false });
                }}
              ></button>
            </div>
            <label className="mt-3">
              Are you sure you want to remove <b>{this.props.userName} </b> from
              the license list?
            </label>
            <div className="screen-buttons float-end">
              <button
                onClick={() => {
                  this.setState({ showModal: false });
                }}
              >
                cancel
              </button>
              <button
                disabled={this.state.deleteButton}
                onClick={this.deleteEndUser}
              >
                delete User
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default DeleteEndUserModal;
