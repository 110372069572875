import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "./QuestionType.css";
import ans_selected from "../../../../../../assets/icons/answer_selected.png";
import ans_unselected from "../../../../../../assets/icons/answer_unselected.png";
import { BASE_URL_IMAGE } from "../../../../../constant/ApiUrls";
import { getKeyTranslation } from "../../../../../utils/Translations";

const QuestionType = ({
  item,
  ind,
  selected_img,
  selectedLanguage,
  gridType,
  radioButtons,
  questionTypeTabClick,
  questions,
  renderUploadAndCancelButton,
  onchangeOptionSingle,
  singleChecked,
  onchangeOptionMultiple,
  multipleChecked,
  trueFalseChecked,
  onchangeOptionTrueFalse,
  createImgGrid,
  gridTypeClicked,
  showWarningChangeType,
  selectedGridsFunction,
  createQuestionArray,
  selectedTabIndex,
  removeReport,
}) => {
  const typeNames = (type_id) => {
    if (type_id == 1) {
      return "single_choice";
    } else if (type_id == 2) {
      return "multiple_choice";
    } else if (type_id == 3) {
      return "find_the_flaw";
    } else if (type_id == 4) {
      return "true_and_false";
    } else {
      return "";
    }
  };
  return (
    <>
      <Col
        lg={4}
        className="question-type border-right pe-3 pt-2"
        style={{ padding: "0" }}
      >
        <Tab.Container
          id="left-tabs-example"
          activeKey={typeNames(item.typeId)}
        >
          <Nav variant="pills" className="flex-row justify-content-between">
            {[
              {
                value: "single_choice",
                name: "Single Choice",
              },
              {
                value: "multiple_choice",
                name: "Multiple Choice",
              },
              {
                value: "find_the_flaw",
                name: "Find the Flaw",
              },
              {
                value: "true_and_false",
                name: "True or False",
              },
            ].map((n, ind_) => {
              return (
                <Nav.Item key={ind_}>
                  <Nav.Link
                    eventKey={n.value}
                    style={{
                      pointerEvents:
                        (item.questionId != 0 && item.typeId == ind_ + 1) ||
                        (item.questionId == 0 && item.typeId == ind_ + 1)
                          ? "none"
                          : "",
                    }}
                    onClick={() =>
                      item.questionId != 0
                        ? showWarningChangeType()
                        : questionTypeTabClick(ind_ + 1)
                    }
                  >
                    {n.name}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <hr style={{ marginTop: "0" }}></hr>
          <Row>
            <Tab.Content>
              <Tab.Pane eventKey="single_choice">
                <div className="single-choice">
                  {item.typeId == 1 && radioButtons(item, ind)}
                  {questions(item, ind)}
                  {renderUploadAndCancelButton(item, ind, "singleImg")}
                  <h6
                    className="editor-dark-color"
                    style={{ fontSize: "16px" }}
                  >
                    Answers
                  </h6>
                  <div className="answer">
                    {item.options?.map((item_single, index_single) => {
                      return (
                        <div
                          className="d-flex mb-3"
                          key={index_single + "single"}
                        >
                          <div className="position-relative">
                            <input
                              type="radio"
                              className="position-absolute h-100 w-100 preview-text"
                              name="single"
                              style={{
                                visibility: "hidden",
                              }}
                              id={index_single + "single"}
                            />
                            <img
                              src={
                                item_single.isCorrect
                                  ? ans_selected
                                  : ans_unselected
                              }
                              onClick={() =>
                                singleChecked(
                                  index_single + "single",
                                  index_single
                                )
                              }
                              style={{
                                height: "30px",
                              }}
                              className="me-4 ms-2"
                            />
                          </div>
                          <input
                            className="w-100 editor-default-border p-1 preview-text"
                            onChange={(e) =>
                              onchangeOptionSingle(e, index_single)
                            }
                            value={item_single.optionTitles
                              ?.filter((i) => {
                                return i.languageId == selectedLanguage;
                              })
                              .map(function (obj) {
                                return obj.optionTitle;
                              })}
                          />
                          {/* {reportedQuestion(item)} */}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {item.questionTitles.filter((i) => {
                      return i.languageId == selectedLanguage;
                    })[0]?.reportings?.length > 0 ? (
                      <div className="create-content-buttons text-center pt-3">
                        <div style={{ color: "red", fontWeight: "bold" }}>
                          {item.questionTitles
                            .filter((i) => {
                              return i.languageId == selectedLanguage;
                            })[0]
                            ?.reportings.map((i, ind) => (
                              <div key={ind}>
                                {"Reported: ,," +
                                  getKeyTranslation(
                                    i?.reportingType?.identifier
                                  ) +
                                  "''"}
                              </div>
                            ))}
                        </div>
                        <button
                          className="save mt-1"
                          style={{ padding: "8px 16px" }}
                          onClick={() => removeReport(item)}
                        >
                          &nbsp;&nbsp;&nbsp;remove report and
                          save&nbsp;&nbsp;&nbsp;
                        </button>
                      </div>
                    ) : null}

                    {/* {reportedQuestion(item)} */}
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="multiple_choice">
                <div className="multiple-choice">
                  {item.typeId == 2 && radioButtons(item, ind)}

                  {questions(item, ind)}
                  {renderUploadAndCancelButton(item, ind, "multipleImg")}
                  <h6
                    className="editor-dark-color"
                    style={{ fontSize: "16px" }}
                  >
                    Answers
                  </h6>
                  <div className="answer">
                    {item.options?.map((item_multiple, index_multiple) => {
                      return (
                        <div
                          className="d-flex mb-3"
                          key={index_multiple + "multiple"}
                        >
                          <div className="position-relative">
                            <img
                              src={
                                item_multiple.isCorrect
                                  ? ans_selected
                                  : ans_unselected
                              }
                              style={{
                                height: "30px",
                              }}
                              onClick={() =>
                                multipleChecked(
                                  index_multiple + "multiple",
                                  index_multiple
                                )
                              }
                              className="me-4 ms-2"
                            />
                          </div>

                          <input
                            className="w-100 editor-default-border p-1 preview-text"
                            onChange={(e) =>
                              onchangeOptionMultiple(e, index_multiple)
                            }
                            value={item_multiple.optionTitles
                              ?.filter((i) => {
                                return i.languageId == selectedLanguage;
                              })
                              .map(function (obj) {
                                return obj.optionTitle;
                              })}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  {item.questionTitles.filter((i) => {
                    return i.languageId == selectedLanguage;
                  })[0]?.reportings?.length > 0 ? (
                    <div className="create-content-buttons text-center">
                      <div style={{ color: "red", fontWeight: "bold" }}>
                        {item.questionTitles
                          .filter((i) => {
                            return i.languageId == selectedLanguage;
                          })[0]
                          ?.reportings.map((i, ind) => (
                            <div key={ind}>
                              {"Reported: ,," +
                                getKeyTranslation(
                                  i?.reportingType?.identifier
                                ) +
                                "''"}
                            </div>
                          ))}
                      </div>
                      <button
                        className="save mt-1"
                        onClick={() => removeReport(item)}
                      >
                        remove report and save
                      </button>
                    </div>
                  ) : null}

                  {/* {reportedQuestion(item)} */}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="find_the_flaw">
                <div className="find-the-flaw">
                  {item.typeId == 3 && radioButtons(item, ind)}

                  {questions(item, ind)}
                  {renderUploadAndCancelButton(item, ind, "flawImg")}

                  <div className="col-6 d-flex align-items-start">
                    <h6
                      className="me-2 editor-dark-color"
                      style={{ fontSize: "16px" }}
                    >
                      Answers
                    </h6>
                    <p
                      className="me-2"
                      style={{
                        fontSize: "14px",
                        textDecoration:
                          item.matrixSize !== "4x4" ? "underline" : "none",
                        cursor: "pointer",
                      }}
                      onClick={() => gridTypeClicked("4x4")}
                    >
                      4x4
                    </p>
                    <p
                      onClick={() => gridTypeClicked("6x6")}
                      style={{
                        fontSize: "14px",
                        textDecoration:
                          item.matrixSize !== "6x6" ? "underline" : "none",
                        cursor: "pointer",
                      }}
                    >
                      6x6
                    </p>
                  </div>
                  <div
                    style={{
                      width: "282px",
                      height: "300px",

                      backgroundImage:
                        item.image !== ""
                          ? `url(${BASE_URL_IMAGE}${item.image})`
                          : "",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                    className="d-grid"
                  >
                    {createImgGrid(
                      item.matrixSize != "" ? item.matrixSize : gridType,
                      selected_img,
                      "rgba(92, 231, 65, 0.8)",
                      "",
                      selectedGridsFunction,
                      createQuestionArray,
                      selectedTabIndex
                    )}
                  </div>
                </div>
                <div>
                  {item.questionTitles.filter((i) => {
                    return i.languageId == selectedLanguage;
                  })[0]?.reportings?.length > 0 ? (
                    <div className="create-content-buttons text-center">
                      <div style={{ color: "red", fontWeight: "bold" }}>
                        {item.questionTitles
                          .filter((i) => {
                            return i.languageId == selectedLanguage;
                          })[0]
                          ?.reportings.map((i, ind) => (
                            <div key={ind}>
                              {"Reported: ,," +
                                getKeyTranslation(
                                  i?.reportingType?.identifier
                                ) +
                                "''"}
                            </div>
                          ))}
                      </div>
                      <button
                        className="save mt-1"
                        onClick={() => removeReport(item)}
                      >
                        remove report and save
                      </button>
                    </div>
                  ) : null}

                  {/* {reportedQuestion(item)} */}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="true_and_false">
                <div className="true-and-false">
                  {item.typeId == 4 && radioButtons(item, ind)}

                  {questions(item, ind)}
                  {renderUploadAndCancelButton(item, ind, "trueFalseImg")}
                  <h6 className="editor-dark-color">Answer</h6>
                  {item.options?.map((item_true_false, index_true_false) => {
                    return (
                      <div
                        className="d-flex mb-3"
                        key={index_true_false + "true/false"}
                      >
                        <div className="position-relative">
                          <img
                            src={
                              item_true_false.isCorrect
                                ? ans_selected
                                : ans_unselected
                            }
                            style={{
                              height: "30px",
                            }}
                            onClick={() =>
                              trueFalseChecked(
                                index_true_false + "true/false",
                                index_true_false
                              )
                            }
                            className="me-4 ms-2"
                          />
                        </div>
                        <input
                          className="w-100 editor-default-border p-1 preview-text"
                          placeholder={`${
                            index_true_false == 0 ? "True" : "False"
                          }`}
                          value={item_true_false.optionTitles
                            ?.filter((i) => {
                              return i.languageId == selectedLanguage;
                            })
                            .map(function (obj) {
                              return obj.optionTitle;
                            })}
                          onChange={(e) =>
                            onchangeOptionTrueFalse(e, index_true_false)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  {item.questionTitles.filter((i) => {
                    return i.languageId == selectedLanguage;
                  })[0]?.reportings?.length > 0 ? (
                    <div className="create-content-buttons text-center">
                      <div style={{ color: "red", fontWeight: "bold" }}>
                        {item.questionTitles
                          .filter((i) => {
                            return i.languageId == selectedLanguage;
                          })[0]
                          ?.reportings.map((i, ind) => (
                            <div key={ind}>
                              {"Reported: ,," +
                                getKeyTranslation(
                                  i?.reportingType?.identifier
                                ) +
                                "''"}
                            </div>
                          ))}
                        {/* Reported for multiple reasons. */}
                      </div>
                      <button
                        className="save mt-1"
                        onClick={() => removeReport(item)}
                      >
                        remove report and save
                      </button>
                    </div>
                  ) : null}

                  {/* {reportedQuestion(item)} */}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
      </Col>
    </>
  );
};

export default QuestionType;
