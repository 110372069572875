import React, { Component } from "react";
import { ListGroup, Row, Col } from "react-bootstrap";
import { Link, Switch, withRouter } from "react-router-dom";
import AuthGuard from "../../../routes/AuthGuard";
import EditAccountInfo from "./editDetails/EditAccountInfo";
import "./Setting.css";
import EditPassword from "./editDetails/EditPassword";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
class Settings extends Component {
  state = {
    screenLoader: true,
  };
  componentDidMount() {
    setTimeout(
      this.setState({
        screenLoader: false,
      }),
      3000
    );
  }
  render() {
    const { screenLoader } = this.state;
    return (
      <>
        <div className="settings-section d-flex justify-content-between sidebar-for-mobile">
          <div className="my-container container-fluid px-5">
            <div className="myDiv">
              <h1>Settings</h1>
              <LoadingOverlay
                className="loader_overlay "
                spinner={<HashLoader color="#5ba6b7" size={90} />}
                active={screenLoader}
              >
                <Row>
                  <Col lg={4}>
                    <div className="setting-tabs">
                      <ListGroup as="ul">
                        <ListGroup.Item
                          as="li"
                          className={`${
                            this.props.match.params.id === "edit-info" &&
                            "active-tab"
                          }`}
                        >
                          <Link to="edit-info">Edit Account Info</Link>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className={`${
                            this.props.match.params.id === "edit-password" &&
                            "active-tab"
                          }`}
                        >
                          <Link to="edit-password">Change Password</Link>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Col>

                  <Col lg={8}>
                    <div className="mt-4">
                      <Switch>
                        <AuthGuard
                          path="*/edit-info"
                          component={EditAccountInfo}
                        />
                        <AuthGuard
                          path="*/edit-password"
                          component={EditPassword}
                        />
                      </Switch>
                    </div>
                  </Col>
                </Row>
              </LoadingOverlay>
            </div>
          </div>
          {/* <SideBar linkName='settings' /> */}
        </div>
      </>
    );
  }
}

export default withRouter(Settings);
