import React, { useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { dynamicFormEmptyCheck } from "../../../../utils/DynamicFormValidation";
import { VALID_EMAIL_1 } from "../../../../constant/AppConstant";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-hot-toast";
import { getKeyTranslation } from "../../../../utils/Translations";
import { addSecondaryContractor } from "../../../../services/ClientServices";
import ScrollBar from "react-scrollbar";
import { getLoginStorage } from "../../../../utils/UserDataManagement";

const AddSecondaryContractor = ({ detail }) => {
  const [showModal, setShowModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [users, setUsers] = useState([{ email: "" }]);
  console.log("detail", detail);

  const handleChangeEmail = (event, index) => {
    console.log("eeeeeeee");
    let userArr = [...users];
    setEmailError("");
    if (event.target.value.length < 50) {
      console.log(users[index].email, event.target.value);
      userArr[index].email = event.target.value;
      setUsers(userArr);
    }
  };

  const removeClick = (i) => {
    let userArr = [...users];
    userArr.splice(i, 1);
    setUsers(userArr);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      //   handleSubmitContractor();
    }
  };

  const email_validate = () => {
    let userArr = [...users];
    let dynamicFormValidity = dynamicFormEmptyCheck(userArr);
    let error = false;
    for (var obj of userArr) {
      var keys = Object.keys(obj); // if you don't know the keys
      if (keys[0] === "email") {
        var value = obj[keys[0]]; //in case your object has only one key
        if (!value.match(VALID_EMAIL_1)) {
          error = true;

          setEmailError("*please enter valid email");
        }
      }
    }
    if (dynamicFormValidity) {
      error = true;
      setEmailError("Required field is empty");
    }
    return error;
  };

  const handleSubmitContractor = async () => {
    const { noOfLicenses } = detail;

    if (users.length - 1 > noOfLicenses) {
      toast.error(
        "Additional admin(s) can not be greater than amount of licences."
      );
      return;
    }
    let error = false;
    error = email_validate();
    if (!error) {
      const categortIds = detail?.categories.map((item) => {
        return item.categoryId;
      });
      console.log(categortIds);
      let obj = {
        clientId: getLoginStorage("clientId"),
        companyName: detail?.companyName,
        amountOfLicenses: detail?.licensesCount,
        users: users,
        categories: categortIds,
        qualifiedForRewards: detail?.qualifiedForRewards,
        amountOfLicenses: detail?.noOfLicenses,
        contractorEmail: detail?.email,
      };
      setDisableButton(true);
      let response = await addSecondaryContractor(obj);
      if (response?.status === 200) {
        setDisableButton(false);
        let pre = response?.data?.preMessage ? response?.data?.preMessage : "";
        let post = response?.data?.postMessage
          ? response?.data?.postMessage
          : "";
        toast.success(
          pre + " " + getKeyTranslation(response?.data?.message) + " " + post
        );
        closeModal();
        // this.props.updateConctractor();
      } else {
        setDisableButton(false);
        let pre = response?.data?.preMessage ? response?.data?.preMessage : "";
        let post = response?.data?.postMessage
          ? response?.data?.postMessage
          : "";
        if (pre) {
          let arr = JSON.parse(pre);
          let message = "";
          arr.map((item, index) => {
            // message = message.concat(
            //   item.Email +
            //     " does already exist as " +
            //     item.RoleName +
            //     arr.length ==
            //     index - 1
            //     ? ""
            //     : ","
            // );
            message =
              " " +
              message +
              item.Email +
              " does already exist as " +
              item.RoleName +
              ", ";

            console.log(message, item, "msg");
          });
          let msg = message.slice(0, -2);
          toast.error(
            `The following Email${
              arr.length > 1 ? "(s)" : ""
            }\n ${msg}.\n Please use a different Email or delete the Email from the other role.`
          );
        } else
          toast.error(
            pre + " " + getKeyTranslation(response?.data?.message) + " " + post
          );
      }
    }
  };
  const addNewContractor = () => {
    let usersState = [...users, { email: "" }];
    setUsers(usersState);
  };
  const closeModal = () => {
    setShowModal(false);
    setUsers([{ email: "" }]);
    setEmailError("");
  };

  return (
    <>
      <button
        onClick={() => {
          setShowModal(true);
        }}
      >
        Add Secondary Admin/s
      </button>
      <Modal
        show={showModal}
        centered
        size={"md"}
        className="edit-add modal-screen"
        style={{ borderRadius: "0" }}
      >
        <div className="edit-add-modal">
          <div className="modal-header">
            <h4 className="heading">Add Secondry Admin/s</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <br />
          <Row className="h-100">
            <Col lg={12}>
              <ScrollBar
                style={{ maxHeight: 280 }}
                className="my-scrollbar"
                contentClassName="my-scrollbar-content"
                speed={0.6}
              >
                {users?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between s-admin"
                  >
                    <div className="w-100">
                      {index === 0 && (
                        <label>
                          E-mail{" "}
                          <span> (Additional admin/s for this company) </span>{" "}
                        </label>
                      )}

                      <br />
                      <input
                        type="email"
                        className="mb-1"
                        value={item.email}
                        onChange={(e) => handleChangeEmail(e, index)}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div className="pt-4 icon-margin">
                      {users.length !== 1 && (
                        <span
                          onClick={() => {
                            removeClick(index);
                          }}
                        >
                          <FontAwesomeIcon
                            className="fa-times-color"
                            icon={faTimes}
                            style={{
                              visibility: index == 0 ? "hidden" : "visible",
                            }}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                <span className="error-decoration">{emailError}</span>
                <div className="add-license-buttons m-0 mt-2">
                  <Button
                    onClick={addNewContractor}
                    className="another-btn-secondary another-btn-2"
                  >
                    ADD ANOTHER ADMIN{" "}
                    <FontAwesomeIcon
                      className="cursor-behavior"
                      icon={faPlusCircle}
                    />
                  </Button>
                </div>
              </ScrollBar>
            </Col>
          </Row>
          <Row>
            <col-12>
              <div className="">
                <div className="double-buttons d-flex justify-content-end float-end flex-column">
                  <div className="d-flex float-end add-edit-buttons mt-4">
                    <button onClick={handleSubmitContractor}>
                      {disableButton ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Add"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </col-12>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default AddSecondaryContractor;
