import React, { Component } from "react";
import toast, { Toaster } from "react-hot-toast";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import { Row, Col, Table } from "react-bootstrap";
import SimpleBar from "simplebar-react";

import { clientSecondaryUsers } from "../../../services/ClientServices";
import DeleteClientAccount from "../../../common/DeleteClientAccount";
import { getLoginStorage } from "../../../utils/UserDataManagement";
import ChangeEndUserRole from "./ChangeEndUserRole";

class ClientUsers extends Component {
  state = {
    screenLoader: true,
    callBack: false,
    rankings: [],
  };
  componentDidMount() {
    this.getSecondaryUsers();
  }
  componentDidUpdate() {
    if (this.state.callBack) {
      this.setState({
        callBack: false,
      });
      this.getSecondaryUsers();
    }
  }
  getSecondaryUsers = async () => {
    let result = await clientSecondaryUsers();
    if (result?.data?.success) {
      this.setState({
        rankings: result?.data?.data?.clientSecondaryUsers,
        screenLoader: false,
      });
    } else {
      toast.error("Error");
    }
  };
  callBackOnDelete = () => {
    this.setState({
      callBack: true,
      screenLoader: true,
    });
  };
  toasterMessage = (type, msg) => {
    if (type == "hold") {
      toast.error(msg);
    } else if (type == "error") {
      toast.error(msg);
    } else {
      toast.success(msg);
    }
  };
  render() {
    const { screenLoader, rankings } = this.state;
    return (
      <div className="my-section d-flex justify-content-between sidebar-for-mobile">
        <div className="my-container container-fluid px-5">
          <Toaster position="top-right" reverseOrder={false} />

          <div className="myDiv">
            <h1>Client Level Users</h1>

            <LoadingOverlay
              spinner={<HashLoader color="#5ba6b7" size={90} />}
              active={screenLoader}
            >
              <Row className="mt-5" style={{ height: "350px" }}>
                <Col lg={12}>
                  <div className="categories">
                    <SimpleBar
                      className="my-scrollbar"
                      contentClassName="my-scrollbar-content"
                      style={{ maxHeight: 800 }}
                    >
                      <div className="request-table-data">
                        <Table
                          bordered
                          responsive
                          style={{ color: "#34636d", fontSize: "12px" }}
                        >
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Name</th>
                              <th colSpan={2}>Total Licenses</th>
                              {/* <th></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {rankings?.map((item, index) => (
                              <tr key={index} className="req-tr">
                                <td>{item.email}</td>
                                <td>{item.name}</td>
                                <td>{item.totalLicenses}</td>
                                <td>
                                  {!item?.isMainAdmin &&
                                  item.email != getLoginStorage("email") ? (
                                    <DeleteClientAccount
                                      companyName={item?.name}
                                      id={item?.id}
                                      callBack={this.callBackOnDelete}
                                    />
                                  ) : null}{" "}
                                  &nbsp;
                                  {!item?.isMainAdmin &&
                                  item.email != getLoginStorage("email") ? (
                                    <ChangeEndUserRole
                                      companyName={item?.name}
                                      id={item?.id}
                                      email={item.email}
                                      callBack={this.callBackOnDelete}
                                      currentRole={2}
                                      isHold={getLoginStorage("isHold")}
                                      toasterCalling={this.toasterMessage}
                                    />
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </SimpleBar>
                  </div>
                </Col>
              </Row>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    );
  }
}
export default ClientUsers;
