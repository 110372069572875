import React from "react";
import "./App.css";
import Routes from "./components/routes/Routes";
import RestrictedAction from "./components/common/modal/RestrictedAction";
import { useRestrictModal } from "./components/utils/Restrict";
import axios from "axios";

const App = () => {
  const restrict = useRestrictModal();

  axios.interceptors.response.use(async (response) => {
    if (response.status == "452") {
      if (
        response.data?.message ==
        "Kindly release the users message in dialog box when client edit license."
      ) {
        localStorage.setItem(
          "releaseMessage",
          "Kindly release the users message in dialog box when client edit license"
        );
      }
      restrict.showRestrictModal();
    }

    return response;
  });

  return (
    <>
      <RestrictedAction
        show={restrict.showModal}
        handleClose={restrict.unShowRestrictModal}
      />

      <Routes />
    </>
  );
};

export default App;
