//Base URL
// Dev
//Prduction
export const BASE_URL = "https://backend.staysafe.digital/api";

export const BASE_URL_IMAGE =
  "https://staysafe-storage-prod.ilidigital-soft.com/";

//Login
export const LOGINURL = "/v1/user/Authenticate";
export const REFRESH_TOKEN_URL = "/v1/user/RefreshToken";
//Master API's
export const GET_AUDIT_TRAILS = "/v1/auditTrail";
export const GET_DASHBOARD_REWARDS = "/v1/reward/rewardDashboard";
export const GET_DETAIL_REWARDS = "/v1/reward/rewardDetail";
export const GET_DETAIL_REWARDS_CODES = "/v1/reward/rewardCodes";
export const DELETE_REWARDS_CODES = "/v1/reward/deleteRewardCode";
export const DELETE_REWARDS = "/v1/reward/deleteReward";
export const DELETE_REWARD_CODE = "/v1/reward/deleteRewardCode";
export const ADD_REWARD = "/v1/reward";
export const EDIT_REWARD = "/v1/reward";
export const REWARD_DISTRIBUTION_SETTING =
  "/v1/reward/rewardDistributionSettingDetail";
export const REWARD_EDIT_DISTRIBUTION_SETTING =
  "/v1/reward/updateRewardDistributionSetting";
export const GET_USER_KPI = "/v1/user/user-kpi";
export const USER_KPI_EXPORT = "/v1/user/exportUserKPIData";

export const GET_MASTER_CLIENT_COMPANY = "/v1/client";
export const GET_TOTAL_MASTER_CLIENT = "/v1/client/admin-total-licenses";
export const GET_MASTER_RANKING = "/v1/client/rankings";
export const GET_CLIENT_RANKING = "/v1/client/";
export const GET_CONTRACTOR_RANKING = "/v1/contractor/";
export const GET_CLIENT_CONTRACTOR_COMPANY = `/v1/client/`;
export const GET_CLIENT_SECONDARY_USERS = `/v1/client/`;
export const ADD_CLIENT_CONTRACTOR_COMPANY = "/v1/contractor";
export const GET_CATEGORIES = "/v1/category";
export const GET_CONTRACT_COMPANY_LIST = `/v1/contractor/`;
export const END_USER = `${ADD_CLIENT_CONTRACTOR_COMPANY}/endusers`;
export const FINANCE_DETAIL = "/v1/finance";
export const CHANGE_PASSWORD = "/v1/user/changePassword";
export const EDIT_USER_INFO = "/v1/client";
export const FORGET_PASSWORD = "/v1/user/forgotPassword";
export const CONTRACTOR_REQUEST = "/v1/request";
export const LANGUAGE_CMS = "/v1/language";
export const PRIMARY_CLIENT_CREATOR_CMS = "/v1/client/primaryClients";
export const CONTENT_PACK_LIST_CMS = "/v1/category/ContentPackList";
export const MARK_FAV_CONTENT_PACK_CMS =
  "/v1/category/markFavouriteContentPack";
export const CREATE_CONTENT_PACK = "/v1/category";
export const EDIT_CONTENT_PACK = "/v1/category";
export const DELETE_CONTENT_PACK = "/v1/category";
export const CATEGORY_CONTENT_PACK = "/v1/category";
export const UPDATE_VISIBILITY_CONTENT_PACK = "/v1/category/updateVisibility";
export const CATEGORY_CONTRACTORS = "/v1/contractor/contractorList";
export const ASSIGN_CONTRACTOR_TO_CATEGORY =
  "/v1/category/assignCategoryToContractor";
export const COPY_CONTENT_PACK = "/v1/category/copyContentPack";
export const GET_CATEGORY_QUESTION = "/v1/question";
export const CONTENT_PACK_SETTING = "/v1/category/contentPackSettings";
export const UPLOAD_QUESTION_IMAGE = "/v1/question/uploadQuestionImage";
export const ADD_QUESTIONS = "/v1/question/addQuestions";
export const DELETE_QUESTION = "/v1/question";
export const EXPORT_QUESTIONS = "/v1/category/exportContentPackData";
export const EXPORT_EMPTY_TEMPLATE = "/v1/category/exportContentPackTemplate";
export const IMPORT_QUESTIONS = "/v1/category/importContentPackData";

export const GET_client_CONTRACTOR_Detail = (id) =>
  `/v1/contractor/${id}/detail`;
export const DELETE_USER = (id, type) => `/v1/client/${id}/${type}`;
export const REMOVE_END_USER = "/v1/request/remove/user/";
export const DELETE_END_USER = "/v1/request/delete/user/";
export const MENU_ROUTES = "/v1/menu";
export const SUPPORT = "/v1/support";
export const REQUEST_APPROVAL = "/v1/request/approval";
export const VERIFY_PASSWORD = "/v1/user/verify-password";
export const DELETE_CLEINT_CONTRACTOR = `/v1/contractor/`;
export const GET_EDIT_DETAIL_CLIENT = "/v1/client/payment-plan/";
export const GET_USER_REMAINING_LICENSES = "/v1/request/remaining-licenses/";
export const GET_TRANSLATIONS = "/v1/translation";
export const CONTENT_PACK_REPORTING_LIST = "/v1/reporting/contentPackList";
export const CONTENT_PACK_REPORTING_QUESTIONS = "/v1/reporting/questions";
export const CONTENT_PACK_REPORTING_REMOVE = "/v1/reporting/remove";
export const MASTER_INSTRUCTIONS =
  "https://staysafe-storage-prod.ilidigital-soft.com/documents/staysafe_mobile_anleitung.pdf";
export const CLIENT_INSTRUCTIONS =
  "https://staysafe-storage-prod.ilidigital-soft.com/documents/staysafe_admin_client_anleitung.pdf";
export const CONTRACTOR_INSTRUCTIONS =
  "https://staysafe-storage-prod.ilidigital-soft.com/documents/staysafe_admin_contractor_anleitung.pdf";
export const SECONDARY_CONTRACTOR = "/v1/contractor/CreateSecondaryContractor";
export const ROLE_LIST = "/v1/user/roleList";
export const CHANGE_ROLE = "/v1/client/ChangeUserRole";
