import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import info_icon from "../../../../../../assets/icons/info.png";
import "./InfoPopup.css";

function InfoPopup({ paragraph_1, paragraph_2, paragraph_3, heading }) {
  return (
    <OverlayTrigger
      placement="right-end"
      // defaultShow={true}
      overlay={
        <Tooltip
          id="info-tooltip"
          style={{ background: "white", padding: "2px" }}
        >
          <div style={{ background: "white" }}>
            <p>{heading}</p>
            <p className="mb-0">{paragraph_1}</p>
            <p className="mb-0">{paragraph_2}</p>
            <p>{paragraph_3}</p>
          </div>
        </Tooltip>
      }
    >
      <img
        src={info_icon}
        height={20}
        width={20}
        style={{ marginRight: "-38px" }}
      />
    </OverlayTrigger>
  );
}

export default InfoPopup;
