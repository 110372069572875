import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class WarningPopUp extends Component {
  clientToContractor = () => {
    return (
      <>
        <span className="default-color fw-bold">{this.props.email}</span> will
        not be able to
        <div className="warning-popup-role mt-3">
          <ul>
            <li>Create public content packs. </li>
            <li>Assign rewards. </li>
            <li>Add a new contractor company. </li>
            <li>Update/Delete the contractor company. </li>
            <li>Delete secondary clients.</li>
          </ul>
        </div>
      </>
    );
  };
  clientToEndUser = () => {
    return (
      <>
        <span className="default-color fw-bold">{this.props.email}</span> will
        not be able to
        <div className="warning-popup-role mt-3">
          <ul>
            <li>Create content packs.</li>

            <li>Assign rewards.</li>

            <li>Add a new contractor company.</li>

            <li>Update/Delete the contractor company.</li>

            <li>Delete secondary clients.</li>
          </ul>
        </div>
      </>
    );
  };
  contractorToEndUser = () => {
    return (
      <>
        <span className="default-color fw-bold">{this.props.email}</span> will
        not be able to
        <div className="warning-popup-role mt-3">
          <ul>
            <li>Create content packs</li>

            <li>Delete and revoke end users.</li>

            <li>Accept/Reject revoked end users' requests.</li>
          </ul>
        </div>
      </>
    );
  };

  contractorToClient = () => {
    return (
      <>
        <span className="default-color fw-bold">{this.props.email}</span> will
        not be able to
        <div className="warning-popup-role mt-3">
          <ul>
            <li>Revoke end users.</li>

            <li>Accept/Reject revoked end users' requests.</li>
          </ul>
        </div>
      </>
    );
  };
  endUserToCAandKA = () => {
    return (
      <>
        <span className="default-color fw-bold">{this.props.email}</span> will
        not
        <div className="warning-popup-role mt-3">
          <ul>
            <li>Appear on the leaderboard.</li>
            <li>Earn any rewards.</li>
          </ul>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        {/* <button
          onClick={() => {
            this.props.onSaveRole1();
          }}
        >
          save
        </button> */}
        <Modal centered size="md" show={this.props.warningPopUp}>
          <div className="bg-white change-delete-modals">
            <div className="modal-header">
              {/* <h1>Delete {this.state.selectedUser.role}</h1> */}
              <h3 style={{ color: "red" }} className="fw-bold">
                Warning
              </h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.closeWarning()}
              ></button>
            </div>
            {this.props.currentRole == 2 && this.props.roleId == 3
              ? this.clientToContractor()
              : this.props.currentRole == 2 && this.props.roleId == 4
              ? this.clientToEndUser()
              : this.props.currentRole == 3 && this.props.roleId == 2
              ? this.contractorToClient()
              : this.props.currentRole == 3 && this.props.roleId == 4
              ? this.contractorToEndUser()
              : this.props.currentRole == 4
              ? this.endUserToCAandKA()
              : null}
            {/* <b> {this.state.selectedUser.companyName}</b>? */}
            <div className="screen-buttons float-end">
              <button onClick={() => this.props.closeWarning()}>cancel</button>
              <button
                onClick={() => {
                  this.props.onSaveRole();
                }}
              >
                ok
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default WarningPopUp;
