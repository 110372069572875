import React, { Component } from "react";
import {
  editUserInfo,
  fetchUserInfo,
} from "../../../../services/UserManagementServices";
import toast, { Toaster } from "react-hot-toast";
import { withRouter } from "react-router-dom";
import { getLoginStorage } from "../../../../utils/UserDataManagement";
import {
  EMPTY_FIELD,
  ALPHABET_AND_SPACE_ONLY,
  NUMERIC_ONLY,
  UPDATE_ERROR,
} from "../../../../constant/AppConstant";
import { jsonToForm } from "../../../../utils/JsonToFormObject";
import VerifyPasswordModal from "../../../../common/VerifyPasswordModal";
class EditBillingInfo extends Component {
  state = { country: "", Address: "", city: "", zip: "" };
  componentDidMount = async () => {
    await this.fetchBillingInfo();
  };

  fetchBillingInfo = async () => {
    let id = getLoginStorage("id");
    let info = await fetchUserInfo(id);
    if (info.status === 200) {
      let information = info.data.data;
      this.setState({
        country: information.country,
        Address: information.address,
        city: information.city,
        zip: information.zip,
      });
    } else if (info.status === 401) {
      toast.error(UPDATE_ERROR);
    }
  };
  valid_Check = (valid, value) => {
    return valid || value === "";
  };
  validation = (item, type) => {
    if (type === "Address") {
      this.setState({
        Address: item.target.value,
        errorAddress: "",
      });
    } else if (type === "city") {
      const valid =
        item.target.value.match(ALPHABET_AND_SPACE_ONLY) &&
        item.target.value.length <= 50;
      if (this.valid_Check(valid, item.target.value)) {
        this.setState({
          city: item.target.value,
          errorCity: "",
        });
      }
    } else if (type === "Zip") {
      const valid =
        item.target.value.match(NUMERIC_ONLY) && item.target.value.length <= 50;
      if (this.valid_Check(valid, item.target.value)) {
        this.setState({
          zip: item.target.value,
          errorZip: "",
        });
      }
    } else if (type === "Country") {
      const valid =
        item.target.value.match(ALPHABET_AND_SPACE_ONLY) &&
        item.target.value.length <= 50;
      if (this.valid_Check(valid, item.target.value)) {
        this.setState({
          country: item.target.value,
          errorCountry: "",
        });
      }
    }
  };

  submitForm = async () => {
    const { Address, city, zip, country } = this.state;
    if (Address.length === 0) {
      this.setState({
        errorAddress: EMPTY_FIELD,
      });
    } else if (city.length === 0) {
      this.setState({
        errorCity: EMPTY_FIELD,
      });
    } else if (country.length === 0) {
      this.setState({
        errorCountry: EMPTY_FIELD,
      });
    } else if (zip.length === 0) {
      this.setState({
        errorZip: EMPTY_FIELD,
      });
    } else {
      this.setState({
        showVerificationModal: true,
      });
    }
  };
  closeVerificationModal = async () => {
    this.setState({
      showVerificationModal: false,
    });
    await this.fetchBillingInfo();
  };
  verifiedPassowrd = async () => {
    const { Address, city, zip, country } = this.state;
    let requestPayload = {
      Address: Address,
      City: city,
      Country: country,
      Zip: zip,
      Type: "client",
    };
    let formData = jsonToForm(requestPayload);
    let infoEditResponse = await editUserInfo(formData);
    if (infoEditResponse.status === 200) {
      toast.success("Data has been successfully updated");
      this.setState({
        showVerificationModal: false,
      });
      await this.fetchBillingInfo();
    } else if (infoEditResponse.status === 401) {
      toast.error(UPDATE_ERROR);
    }
  };

  render() {
    const { showVerificationModal } = this.state;
    return (
      <>
        <Toaster position="top-center" reverseOrder={true} />
        <div className="edit-billing d-flex flex-column">
          <label className="screen-label">Address</label>
          <input
            onChange={(item) => this.validation(item, "Address")}
            className="screen-input"
            type="text"
            value={this.state.Address}
          />
          <p className="text-color">{this.state.errorAddress}</p>
          <label className="screen-label">City</label>
          <input
            onChange={(item) => this.validation(item, "city")}
            className="screen-input"
            type="text"
            value={this.state.city}
          />
          <p className="text-color">{this.state.errorCity}</p>
          <label className="screen-label">Zip</label>
          <input
            onChange={(item) => this.validation(item, "Zip")}
            className="screen-input"
            type="text"
            value={this.state.zip}
            maxLength={6}
          />
          <p className="text-color">{this.state.errorZip}</p>
          <label className="screen-label">Country</label>
          <input
            value={this.state.country}
            onChange={(item) => this.validation(item, "Country")}
            className="screen-input"
            type="text"
            maxLength={30}
          />
          <p className="text-color">{this.state.errorCountry}</p>
          <button onClick={this.submitForm} className="screen-button">
            Save changes
          </button>
        </div>
        <VerifyPasswordModal
          show={showVerificationModal}
          close={this.closeVerificationModal}
          updateFunction={this.verifiedPassowrd}
        />
      </>
    );
  }
}
export default withRouter(EditBillingInfo);
