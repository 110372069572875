import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getLoginStorage } from "../utils/UserDataManagement";
const AuthGuard = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={(routeProps) => {
      let userToekn = getLoginStorage("token");
      // Do all your conditional tests here
      return userToekn !== null ? (
        <Component {...routeProps} />
      ) : (
        <Redirect exact to="/master-panel" />
      );
    }}
  />
);
export default AuthGuard;
