import React, { Component } from "react";
import { Row, Col, Table } from "react-bootstrap";
import sortIcon from "../../../../assets/icons/sort-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AcceptUser from "./acceptUser/AcceptUser";
import DeclineUser from "./declineUser/DeclineUser";
import { fetchContractorRequests } from "../../../services/ContractorServices";
import toast, { Toaster } from "react-hot-toast";
import "./RequestList.css";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
class RequestList extends Component {
  state = {
    requests: [],
    acceptUser: false,
    declineUser: false,
    screenLoader: true,
  };
  componentDidMount = async () => {
    this.fetchRequestList();
  };
  fetchRequestList = async () => {
    let requests = await fetchContractorRequests();
    if (requests.status === 200) {
      this.setState({
        requests: requests.data.data,
        screenLoader: false,
      });
    } else {
      toast.error("Error while fetching data!");
    }
  };
  render() {
    const { requests, screenLoader } = this.state;
    return (
      <>
        <div className="my-section d-flex justify-content-between sidebar-for-mobile">
          <div className="my-container container-fluid px-5">
            <Toaster position="top-right" reverseOrder={false} />

            <div className="myDiv">
              <h1>Requests</h1>
              <Row>
                <Col lg={3}>
                  <div className="d-flex search-type">
                    <p>Sort by</p>
                    <img
                      src={sortIcon}
                      alt="sort-icon"
                      width={25}
                      height={25}
                    />
                    <p>First name</p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Company name, person etc."
                    />
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                </Col>
              </Row>
              <LoadingOverlay
                className="loader_overlay"
                spinner={<HashLoader color="#5ba6b7" size={90} />}
                active={screenLoader}
              >
                <Row className="mt-5">
                  <Col lg={12}>
                    <div className="request-table-data">
                      <Table borderless responsive className="req-table">
                        <thead>
                          <tr>
                            <td>First name</td>
                            <td>Last name</td>
                            <td>Client codes entered</td>
                            <td>Last removed</td>
                            <td />
                          </tr>
                        </thead>
                        <tbody>
                          {requests?.items?.map((item, index) => (
                            <tr key={index} className="req-tr">
                              <td>{item?.firstName}</td>
                              <td>{item?.lastName}</td>
                              <td>
                                <b>{item?.clientCodes}</b>
                              </td>
                              <td>{item.lastRemovedDate}</td>
                              <td>
                                <div className="double-buttons">
                                  <div className="add-edit-buttons">
                                    <AcceptUser
                                      acceptData={item}
                                      list={this.fetchRequestList}
                                    />
                                    <DeclineUser
                                      declineData={item}
                                      list={this.fetchRequestList}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </LoadingOverlay>
            </div>
          </div>
          {/* <SideBar linkName='finances' /> */}
        </div>
      </>
    );
  }
}

export default RequestList;
