import React, { Component } from "react";
import brandIconGrey from "../../../../../assets/icons/brandHolder.jpg";
import sortIcon from "../../../../../assets/icons/sort-icon.png";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ScrollBar from "react-scrollbar";
import DeleteContractor from "../deleteContractor/DeleteContractor";
import EditPlan from "../editPlan/EditPlan";
import { fetchClientContractorCompanyDetail } from "../../../../services/ClientServices";
import toast from "react-hot-toast";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";
import { withRouter } from "react-router-dom";
import { BASE_URL_IMAGE } from "../../../../constant/ApiUrls";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import MembersList from "../../members/MembersList";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddSecondaryContractor from "../addSecondryContractor/AddSecondaryContractor";
class ContractorDetails extends Component {
  state = {
    editPlanModal: false,
    deleteContractorModal: false,
    detail: {},
    screenLoader: false,
  };
  closeDeleteContractorModal = () => {
    this.setState({
      deleteContractorModal: false,
    });
  };
  cancelDetail = () => {
    this.props.history.push("/client-licenses");
  };

  fetchClientDetails = async () => {
    let id = this.props?.clientId;
    this.setState({
      screenLoader: true,
    });
    let detail = await fetchClientContractorCompanyDetail(id);
    if (detail?.status === 200) {
      this.setState({
        detail: detail?.data?.data,
        screenLoader: false,
      });
    } else {
      this.setState({
        detail: {},
        screenLoader: false,
      });
      toast.error("Error while fetching data!");
    }
    this.props.fetchContractorList();
  };
  componentDidMount = async () => {
    this.setState({
      screenLoader: true,
    });
  };
  componentDidUpdate = async () => {
    // console.log(
    //   "scrollbar",
    //   document.getElementsByClassName("scrolling").firstChild()
    // );

    let check = localStorage.getItem("showCard");
    if (check == "true") {
      localStorage.removeItem("showCard");
      await this.fetchClientDetails();
    }
  };
  renderTooltip = (detail) => (
    <Tooltip
      id="button-tooltip"
      // {...active,inactive}
      className="contractor-tooltip"
    >
      Inactive Categories: {detail?.inactiveCategoriesCount}
      <br />
      Active Categories: {detail?.activeCategoriesCount}
    </Tooltip>
  );
  render() {
    const { detail, screenLoader } = this.state;
    return (
      <>
        <LoadingOverlay
          spinner={<HashLoader color="#5ba6b7" size={90} />}
          active={screenLoader}
        >
          <div className="container1 manageLeft mb-5 item-details-section">
            {detail?.isHold && (
              <div className="disabled-card">
                <label> On Hold</label>
              </div>
            )}
            {/* <SimpleBar style={{ maxHeight: 270 }}> */}
            <Row className="h-100 tablet-gapBox">
              <Col lg={2} sm={12}>
                <div className="item-card">
                  <div className="text-start pl-5">
                    <img
                      width="160"
                      height="100"
                      src={
                        detail?.companyIcon
                          ? BASE_URL_IMAGE + detail?.companyIcon
                          : brandIconGrey
                      }
                      alt="brand-icon"
                    />
                  </div>
                  <p className="item-name">{detail?.companyName}</p>
                  <p>{detail?.contactPerson}</p>
                  <p>{detail?.noOfLicenses} Licenses</p>
                  <p>{detail?.email}</p>
                  <p>{detail?.phoneNo}</p>
                </div>
              </Col>
              <Col lg={2} sm={12}>
                <div className="item-count">
                  <p>Licenses</p>
                  <p className="qty">
                    <span
                      style={{
                        textDecoration:
                          detail?.isActive == true && "line-through",
                        color:
                          (detail?.isActive == true ||
                            detail?.isHold == true) &&
                          "red",
                      }}
                    >
                      {detail?.noOfLicenses}
                    </span>{" "}
                    {detail?.isActive == true && (
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Licenses have been reduced!</Tooltip>}
                      >
                        <span
                          style={{
                            color: "#34636d",
                            cursor: "pointer",
                          }}
                        >
                          {detail?.requestedLicencesCount}
                        </span>
                      </OverlayTrigger>
                    )}
                  </p>
                  <p>Licenses Used</p>

                  <MembersList
                    totalNoOfContractors={detail?.totalNoOfContractors}
                    companyId={detail?.companyId}
                    noOfLicensesUsed={detail?.noOfLicensesUsed}
                    refresh={this.fetchClientDetails}
                    isHold={detail?.isHold}
                  />
                </div>
              </Col>
              <Col lg={3} sm={12}>
                <div className="categories">
                  <p>Categories</p>
                  {!screenLoader && (
                    <ScrollBar
                      id="scrollbar123"
                      style={{ maxHeight: 400 }}
                      className="my-scrollbar scrolling"
                      contentClassName="my-scrollbar-content"
                      speed={0.6}
                    >
                      {/* <div className="div-height-categories"> */}
                      {detail?.categories?.map((item, index) => (
                        <div className="category d-flex" key={index}>
                          <img src={sortIcon} alt="brand-icon" />
                          <p className="name">{item?.categoryName}</p>
                          <p
                            style={{ marginLeft: "auto", marginRight: "27px" }}
                          >
                            <strong
                              style={{ fontSize: "15px", color: "#f8dc80" }}
                            >
                              {Math.round(
                                (item?.averageRating + Number.EPSILON) * 100
                              ) / 100}
                            </strong>
                            /110
                          </p>
                        </div>
                      ))}
                      {/* </div> */}
                    </ScrollBar>
                  )}
                </div>
              </Col>
              <Col lg={3} sm={12}>
                <div className="categories catg-wrap">
                  <p>Code</p>
                  <div className="code-detail mt-2">
                    <p
                      className="screen-input w-100"
                      style={{
                        fontSize: "2.5rem",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: `${
                          detail?.isHold ? "red" : " rgb(52, 99, 109)"
                        }`,
                      }}
                    >
                      {detail?.code}
                    </p>

                    <p className="mt-3" style={{ fontSize: "10px" }}>
                      This code allows users to log into <b>staysafe</b>. Users
                      with this code will utilize the license provided by{" "}
                      <b>{detail?.clientCompanyName}</b> and will have access to
                      the content packages.
                      <br />
                      <br />A user can be assigned to multiple licenses.
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                lg={2}
                sm={12}
                className="pl-5 my-progress-circle d-flex flex-column justify-content-between"
              >
                <div style={{ marginTop: "1.5rem " }}>
                  <div className="d-flex flex-row">
                    <OverlayTrigger
                      placement="top"
                      // delay={{ show: 250, hide: 400 }}
                      overlay={this.renderTooltip(detail)}
                    >
                      <FontAwesomeIcon
                        style={{
                          cursor: "pointer",
                          margin: "4px 10px 4px 2px",
                          fontSize: "25px",
                          color: " rgb(52, 99, 109)",
                        }}
                        icon={faInfoCircle}
                      />
                    </OverlayTrigger>
                    <div className="d-flex flex-column">
                      <CircularProgressbarWithChildren
                        value={Math.round(detail?.averageRating)}
                      >
                        <div style={{ fontSize: 10 }}>
                          <strong
                            style={{ fontSize: "18px", fontWeight: "900" }}
                          >
                            {Math.round(
                              (detail?.averageRating + Number.EPSILON) * 100
                            ) / 100}
                          </strong>
                          <br />
                          <p
                            className="m-0 text-center"
                            style={{ marginTop: "-6px !important" }}
                          >
                            /110
                          </p>
                        </div>
                      </CircularProgressbarWithChildren>
                      <h5 className="mt-2" style={{ marginLeft: "1rem" }}>
                        Avg. Rating
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="detail-buttons">
                  <button
                    onClick={() =>
                      this.setState({
                        deleteContractorModal: true,
                      })
                    }
                  >
                    delete contractor
                  </button>
                  <AddSecondaryContractor detail={this.state.detail} />
                  <EditPlan detail={this.state.detail} />
                  <button
                    onClick={() => {
                      this.props.closeCard();
                    }}
                  >
                    cancel
                  </button>
                </div>
              </Col>
            </Row>
            <DeleteContractor
              id={detail?.companyId}
              companyName={detail?.companyName}
              deleteContractor={this.state.deleteContractorModal}
              closeDeleteContractorModal={this.closeDeleteContractorModal}
              updateLicensesList={this.props.updateList}
              closeCard={this.props.closeCard}
            />
            {/* </SimpleBar> */}
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
export default withRouter(ContractorDetails);
