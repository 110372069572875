import Detail from "../../assets/data.json";

export const getUserPrice = (number, keyValue) => {
  if (number >= 0 && number <= 50) {
    return Detail[keyValue].fifty;
  } else if (number >= 51 && number <= 100) {
    return Detail[keyValue].hundred;
  } else if (number >= 101 && number <= 500) {
    return Detail[keyValue].fiveHundred;
  } else if (number >= 501 && number <= 2500) {
    return Detail[keyValue].twentyFiveHundred;
  } else if (number >= 2501 && number <= 5000) {
    return Detail[keyValue].fiveThousand;
  } else if (number > 5000) {
    return Detail[keyValue].moreThenFiveThousand;
  }
};
export const BussinessIntelligenceYearlyPlan = (state) => {
  if (state) {
    return Detail.BussinessIntelligence;
  } else {
    return {
      oneYear: 0,
      twoAndThreeyear: 0,
      fourYear: 0,
    };
  }
};
export const contentAgreementPrice = (state) => {
  if (state) {
    return Detail.ContentAgreement[0].detail;
  } else {
    return Detail.ContentAgreement[1].detail;
  }
};
